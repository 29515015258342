import React, { Fragment } from 'react';
import AddressForm from './AddressForm';
import AddressItem from './AddressItem';
import { AddressWithDatesEntity } from '../../../../redux/entities/entities.types';

interface AddressHistoryContentProps {
  addresses: AddressWithDatesEntity[];
  hasSufficientAddressHistory: boolean;
}

const AddressHistoryContent: React.FC<AddressHistoryContentProps> = ({ addresses, hasSufficientAddressHistory }) => {
  if (addresses.length === 0) {
    return <AddressForm />;
  }

  return (
    <Fragment>
      {addresses.map((address: AddressWithDatesEntity) => (
        <AddressItem key={address.id} address={address} />
      ))}
    </Fragment>
  );
};

export default AddressHistoryContent;
