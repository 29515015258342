import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  TenancyUserActionTypes,
  FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED,
  fetchTenancyUserFromOrderCustomerFailed,
  fetchTenancyUserFromOrderCustomerSuccess,
} from './tenancy-user.actions';
import { createOrderCustomersApi, runSagaWithAuth } from '../utils/api.utils';
import { OrderCustomersApi } from '@reposit/api-client';
import { FetchTenancyUserPayload } from './tenancy-user.types';

export function* fetchTenancyUserFromOrderCustomer({ payload }: { type: string; payload: FetchTenancyUserPayload }) {
  try {
    const orderCustomersApi: OrderCustomersApi = yield createOrderCustomersApi();
    const { data } = yield call(runSagaWithAuth(() => orderCustomersApi.getTenancyUserById(payload.orderId, payload.customerId)));
    yield put<TenancyUserActionTypes>(fetchTenancyUserFromOrderCustomerSuccess(data));
  } catch (e) {
    yield put<TenancyUserActionTypes>(fetchTenancyUserFromOrderCustomerFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchTenancyUserSagas() {
  yield takeLatest(FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED, fetchTenancyUserFromOrderCustomer);
}
