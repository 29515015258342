import React from 'react';
import styled from 'styled-components';
import { RepositTheme } from '../../../../base/theme';

const TermsContainer = styled.div`
  font-size: 10pt;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  color: ${() => RepositTheme.colors.body};
`;

const TermsH1 = styled.h1`
  font-size: 14pt;
  font-weight: 700;
  margin-top: 2pt;
  margin-bottom: 2pt;
  text-align: center;
  line-height: 1.5;
`;

const TermsH3 = styled.h3`
  font-size: 12pt;
  font-weight: 700;
  margin-top: 2pt;
  margin-bottom: 2pt;
  line-height: 1.5;
`;

const TermsP = styled.p`
  line-height: 1.5;
  margin: 0 0 12pt;
`;

const TermsStrong = styled.strong`
  font-weight: bolder;
`;

const TermsAnchor = styled.a`
  background-color: transparent;
  font-weight: 500;
  color: #3a315e;
  text-decoration: none;
`;

const TermsUl = styled.ul`
  list-style: lower-alpha;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
`;

const TermsLi = styled.li`
  line-height: 1.5;
  list-style: lower-alpha;
`;

const TermsOl = styled.ol`
  padding-left: 16px;
  padding-bottom: 8px;
`;

const TenantTerms: React.FC = () => {
  return (
    <TermsContainer>
      <TermsH1>Reposit Group Limited t/a Reposit</TermsH1>
      <TermsH1 style={{ marginBottom: '18pt' }}>Tenant Terms &amp; Conditions</TermsH1>
      <TermsP>These terms are effective from 14th March 2024.</TermsP>
      <TermsP>
        <TermsStrong>These terms and conditions apply to tenants using our services</TermsStrong>
      </TermsP>
      <TermsP>
        These are the terms and conditions <TermsStrong>("Conditions")</TermsStrong> upon which we provide our services. We will
        ask you to accept these Conditions when you sign-up to use our services on our{' '}
        <TermsAnchor href="https://reposit.co.uk">
          <u>website</u>
        </TermsAnchor>
        . If you do not agree to comply with these Conditions, do not use our services. Please contact us if you have questions.
      </TermsP>
      <TermsP>
        <TermsStrong>About us and how to contact us</TermsStrong>
      </TermsP>
      <TermsP>
        We are Reposit Group Limited t/a Reposit <TermsStrong>("Reposit")</TermsStrong>. We are a company registered in England
        &amp; Wales. Our registered office is located at 128 City Road, London, EC1V 2NX and our registered number is 09581330. We
        are registered by the Financial Conduct Authority with registration number 844985.
      </TermsP>
      <TermsP>
        If you need to contact us, please email us at <TermsStrong>hello@reposit.co.uk</TermsStrong> or fill in the Contact Us
        form on our website.
      </TermsP>
      <TermsP>
        In these Conditions, where we use <TermsStrong>"we"</TermsStrong>,<TermsStrong>"our"</TermsStrong> and{' '}
        <TermsStrong>"us"</TermsStrong>, we are referring to Reposit
      </TermsP>
      <TermsP>
        <TermsStrong>About you and how we will contact you</TermsStrong>
      </TermsP>
      <TermsP>
        When we use words like <TermsStrong>"you"</TermsStrong> or
        <TermsStrong>"your"</TermsStrong> in these Conditions, we are referring to you, an individual who is looking to use our
        services in relation to accommodation which you are renting or hoping to rent as domestic accommodation and not for
        business purposes. If you are looking to rent accommodation to use for business purposes these Conditions do not apply and
        you may not use our services. If we need to contact you, we will use the contact details which you provide to us when you
        register to use our services on our website.
      </TermsP>
      <div style={{ border: 'solid', padding: 8, marginBottom: 8 }}>
        <TermsP>
          <TermsStrong> Summary of Key Terms:</TermsStrong>
        </TermsP>
        <TermsP>
          To help you understand how Reposit works we have summarised some of the key terms below. The summary does not reflect
          all of the terms and conditions which apply between us and you can read these in full below the summary box.
        </TermsP>
        <TermsP>
          You may cancel and claim a full refund during the 14 day cooling off period (provided your Tenancy Agreement has not yet
          started)
        </TermsP>
        <TermsUl>
          <TermsLi>
            You have the right to cancel and claim a full refund during the 14 day cooling off period, which starts on the
            Purchase Date, provided that your Tenancy Agreement has not yet started.
          </TermsLi>
          <TermsLi>
            If you do this, we will notify your landlord that your Reposit has been cancelled and they may require you to pay a
            cash deposit. (see paragraph 11 (Cancelling the Agreement) for details).
          </TermsLi>
        </TermsUl>
        <TermsP>
          Additional rights to cancel after the end of the cooling off period but before the Tenancy Agreement starts
        </TermsP>
        <TermsUl>
          <TermsLi>
            After the end of the 14 day cooling off period, and provided that your Tenancy Agreement has not yet started, you may
            cancel our agreement and claim a full refund with your landlord’s consent. We will ask you to provide proof that your
            landlord has agreed to the cancellation.
          </TermsLi>
          <TermsLi>
            If you do this, we will notify your landlord that your Reposit has been cancelled and they may require you to pay a
            cash deposit. (see paragraph 11 (Cancelling the Agreement) for details).
          </TermsLi>
        </TermsUl>
        <TermsP>Once your Tenancy Agreement has started you cannot cancel our agreement and the fee is non-refundable.</TermsP>
        <TermsP>How Reposit works</TermsP>
        <TermsUl>
          <TermsLi>
            Reposit allows you to rent without having to pay a cash deposit of up to 6 weeks’ worth of rent at the start of your
            tenancy.
          </TermsLi>
          <TermsLi>
            If you prefer to pay a cash security deposit instead of using Reposit, you can agree to do this with your landlord.
          </TermsLi>
          <TermsLi>
            The cost of Reposit is an upfront fee equal to one week’s worth of rent, subject to a minimum of £150. This is
            non-refundable (except for where you cancel the agreement (see above for where this is allowed) and cannot be offset
            against the value of any Charges (see below, but Charges cover things like unpaid rent, cleaning costs, costs to
            repair damage which you have caused, re-letting fees (where applicable)) at the end of tenancy. For tenancies
            continuing for 12 months or longer, you will be required to pay us an annual fee of £30 per tenancy payable on each
            anniversary of the start date of your tenancy.
          </TermsLi>
          <TermsLi>
            With Reposit, you remain completely responsible for paying for the cost of repairing damage or dilapidations you have
            caused to the property, as well as any rent arrears or for other financial loss suffered by your landlord. Your
            tenancy agreement will set out full details of what you are responsible for.
          </TermsLi>
          <TermsLi>
            Under the Reposit scheme, the maximum amount that your landlord can claim is the lower of 8 weeks’ worth of rent or
            £5,000. However if you owe them more than this under your tenancy agreement, your landlord may take action to recover
            the additional sums from you directly.
          </TermsLi>
          <TermsLi>
            You will be notified by Reposit of any Charges raised by your landlord at the end of your tenancy. You must respond to
            these through your online Reposit account within 7 calendar days. If you do not, you will be deemed to have accepted
            the Charges in full and we will take steps to recover the amount owed from you (see Paragraph 9 for full details).
          </TermsLi>
          <TermsLi>
            If you do not agree with the Charges, you will have the opportunity to contest them with your landlord and, if they
            remain unsettled, raise a formal dispute with an independent, third party adjudicator. In order to raise a formal
            dispute, a fee of £60 must be paid. This adjudication fee will be refunded to you in full if the adjudicator considers
            your dispute to be valid. Paragraph 9 (Charges raised by the Landlord) sets out the Charges process in full.
          </TermsLi>
          <TermsLi>
            If you are renting with other people on the same tenancy agreement, you are responsible for all Charges made by the
            Landlord (including any share of the Charges due from the other tenants, if they fail to pay themselves).
          </TermsLi>
          <TermsLi>
            During your tenancy, you are required to look after the Accommodation and communicate with your Landlord (or the
            letting agent) regarding any issues that may arise. We are not responsible for any communications or resolving any
            issues.
          </TermsLi>
        </TermsUl>
        <TermsP>Payments to us</TermsP>
        <TermsUl>
          <TermsLi>
            You must pay us the Fees for the duration of the Agreement. Fees are payable in addition to any Charges which might be
            payable to your Landlord (see paragraph 8 (Payment) and paragraph 9 (Charges raised by Landlord).
          </TermsLi>
          <TermsLi>
            By providing us with payment card details, you authorise us to take further payments from you using these payment card
            details. We will only use this authorisation where you owe us money and you have failed to respond to our reasonable
            attempts to contact you. See paragraph 6 (Continuous Payment Authority) for more information and about how to cancel
            this authorisation.
          </TermsLi>
          <TermsLi>If you are having difficulty paying, please contact us.</TermsLi>
        </TermsUl>
        <TermsP>We limit our liability under these Conditions</TermsP>
        <TermsUl>
          <TermsLi>
            Paragraph 13 (Our Liability) explains what we will and will not be liable for if things go wrong under the Agreement.
          </TermsLi>
        </TermsUl>
        <TermsP>
          We have included this summary and also paragraph headings to help you navigate this document. But that’s all they are
          there for. They shouldn’t be treated as having any impact at all on how the wording of any of these Conditions should be
          interpreted.
        </TermsP>
        <TermsP>
          We may amend these terms and conditions to reflect changes in laws and regulations or to make minor adjustments and
          improvements. If we want to make any other changes and these have a significant impact on the Conditions, we will notify
          you in advance and you can discuss any questions which our customer services team and terminate the Agreement if you do
          not agree with the changes.
        </TermsP>
      </div>
      <TermsH3>1. Definitions which we use in these Conditions</TermsH3>
      <TermsP>
        We will use a number of words and phrases repeatedly in these Conditions so, to make things easier, we will define what we
        mean when we use them here:
      </TermsP>
      <TermsP>
        <TermsStrong>Accommodation</TermsStrong> means the rental accommodation in relation to which the Fee has been paid or is
        payable.
      </TermsP>
      <TermsP>
        <TermsStrong>Agreement</TermsStrong> means the legal agreement between you and us, based on these Conditions and excluding
        any other Terms and Conditions or other documents.
      </TermsP>
      <TermsP>
        <TermsStrong>Annual Fee:</TermsStrong> the fee payable by you for using our Services as explained to you when you signed
        up for our Service, which is payable on each anniversary of the start date of the Tenancy Agreement.
      </TermsP>
      <TermsP>
        <TermsStrong>Applicable Legislation</TermsStrong> means any statute, statutory provision, statutory instrument, law,
        direction notice, rule or order made under any statute, any regulation or bye-law or requirement of any local authority or
        of any statutory undertaker who has jurisdiction with regard to the tenancy the subject of the Charge and any of the
        Pre-Tenancy Documents and the Tenancy Agreement.
      </TermsP>
      <TermsP>
        <TermsStrong>Charge</TermsStrong> means any charges that the Landlord may make after the end of the Rental Period relating
        to your Default, up to an amount equal to the lower in value of an amount equivalent to eight weeks’ rent for the
        Accommodation or £5000.
      </TermsP>
      <TermsP>
        <TermsStrong>Default</TermsStrong> means any failure by you to:
      </TermsP>
      <TermsOl type="a">
        <TermsLi>
          pay the whole or any part of the rents, interest and any other sums due to, or recoverable by, the landlord under the
          Tenancy Agreement, whether or not reserved by way of rent, including any utility bills (whether included in the rent or
          where the tenant has failed to pay directly), and which are outstanding at the expiry or termination of the Tenancy
          Agreement and where you have vacated the Accommodation;
        </TermsLi>
        <TermsLi>
          observe and perform the tenant covenants and conditions for repair contained or referred to in the Tenancy Agreement
          (save for fair wear and tear);
        </TermsLi>
        <TermsLi>
          return the Accommodation to your Landlord at the end of the Tenancy Agreement in the same condition (save for fair wear
          and tear) and state of cleanliness as the Accommodation was in at the start of the Tenancy Agreement, resulting in
          cleaning costs being incurred by the Landlord;
        </TermsLi>
        <TermsLi>
          remove your property and/or unwanted or unneeded items or waste materials from the Accommodation at the end of the
          Tenancy Agreement, resulting in costs being incurred by the Landlord to remove, store and dispose of such property
          and/or unwanted or unneeded items or waste materials.
        </TermsLi>
      </TermsOl>
      <TermsP>
        <TermsStrong>Fee</TermsStrong> means either the Nominal Reposit Fee or the Reposit Fee, as applicable, plus any Further
        Fees which apply. We will confirm the applicable Fees to you through our website as part of your sign-up process.
      </TermsP>
      <TermsP>
        <TermsStrong>Further Fees</TermsStrong> means any additional administrative fees due to us from you, including:
      </TermsP>
      <TermsOl type="a">
        <TermsLi>any Annual Fees payable where a tenancy continues past the initial period of 12 months;</TermsLi>
        <TermsLi>any dispute fees payable by you where you raise a formal dispute in relation to your Tenancy Agreement;</TermsLi>
        <TermsLi>any fees payable where a new tenant joins an existing tenancy during its term.</TermsLi>
      </TermsOl>
      <TermsP>
        <TermsStrong>Landlord</TermsStrong> means the landlord whose Accommodation you wish to rent or are renting under a Tenancy
        Agreement, or the landlord’s appointed representative.
      </TermsP>
      <TermsP>
        <TermsStrong>Nominal Reposit Fee</TermsStrong> means, where the Landlord pays the Reposit Fee on your behalf, a nominal
        fee to be paid by you to us for our Services. Where applicable, the amount of the Nominal Fee will be confirmed to you as
        part of the sign-up process.
      </TermsP>
      <TermsP>
        <TermsStrong>Notice of Variation</TermsStrong> means notice of variation of certain matters as provided by Article 1 of
        the Private Tenancies Act (Northern Ireland) 2022.
      </TermsP>
      <TermsP>
        <TermsStrong>Parties, Party</TermsStrong> means us and/or you, depending on the context.
      </TermsP>
      <TermsP>
        <TermsStrong>Purchase Date</TermsStrong> means (i) where you are the only tenant renting the Accommodation, the date when
        the Fee has been paid; or (ii) where you are renting the Accommodation with other people, the date when everyone has paid
        their part of the Fee.
      </TermsP>
      <TermsP>
        <TermsStrong>Pre-Tenancy Documents</TermsStrong> means:
      </TermsP>
      <TermsOl type="a">
        <TermsLi>
          FOR PROPERTIES LOCATED IN ENGLAND: those documents as required by The Assured Shorthold Tenancy Notices and Prescribed
          Requirements (England) Regulations 2015, namely the Gas Safety Certificate, the Electrical Installation Condition
          Report, the Energy Performance Certificate and the current version (as at the original start date of the Rental Period)
          of the How to Rent Guide;
        </TermsLi>
        <TermsLi>
          FOR PROPERTIES LOCATED IN WALES: those documents as required by Renting Home (Wales) Act 2016 and Housing (Wales) Act
          2014 (namely the Gas Safety Certificate and the Energy Performance Certificate at the date of these terms and
          conditions);
        </TermsLi>
        <TermsLi>
          FOR PROPERTIES LOCATED IN SCOTLAND those documents as required by Private Housing (Tenancies) (Scotland) Act 2016;
        </TermsLi>
        <TermsLi>
          FOR PROPERTIES LOCATED IN NORTHERN IRELAND those documents as required by: The Private Tenancies (Northern Ireland)
          Order 2006 and the Private Tenancies Act (Northern Ireland) 2022, namely the Tenancy Information Notice, Notice of
          Variation (if applicable), and Rent Receipts; The Energy Performance of Buildings (Certificates and Inspections)
          Regulations (Northern Ireland) 2008 as amended by the Energy Performance of Buildings (Certificates and Inspections)
          (Amendment) Regulations (Northern Ireland) 2013, namely the Energy Performance Certificate; The Gas Safety (Installation
          and Use) Regulations (NI) 2004, namely the Gas Safety Certificate;
        </TermsLi>
      </TermsOl>
      <TermsP>to be provided to the tenant before the commencement of the tenancy.</TermsP>
      <TermsP>
        <TermsStrong>Rental Period</TermsStrong> means the period of time during which you rent the Accommodation from the
        Landlord.
      </TermsP>
      <TermsP>
        <TermsStrong>Rent Receipts</TermsStrong> means a rent receipt for payment in cash as provided by Article 3 of the Private
        Tenancies Act (Northern Ireland) 2022.
      </TermsP>
      <TermsP>
        <TermsStrong>Reposit End Date</TermsStrong> means the date upon which we confirm that your Service has ended in relation
        to a specific Tenancy Agreement.
      </TermsP>
      <TermsP>
        <TermsStrong>Reposit Fee</TermsStrong> means the non-refundable fee payable by you under this Agreement for our Services.
      </TermsP>
      <TermsP>
        <TermsStrong>Reposit Switch:</TermsStrong> has the meaning given in paragraph 10.
      </TermsP>
      <TermsP>
        <TermsStrong>Services</TermsStrong> means (i) our deposit replacement services, Reposit Switch, and/or (ii) the recovery
        of any Charges and/or Fees from you pursuant to this Agreement, and (iii) the specific services set out in paragraph 7, in
        each case in return for payment of the Fees.
      </TermsP>
      <TermsP>
        <TermsStrong>Tenancy Agreement</TermsStrong> means:
      </TermsP>
      <TermsOl type="a">
        <TermsLi>FOR PROPERTIES LOCATED IN ENGLAND: the Assured Shorthold Tenancy Agreement.</TermsLi>
        <TermsLi>FOR PROPERTIES LOCATED IN WALES: the Occupational Contract</TermsLi>
        <TermsLi>
          FOR PROPERTIES LOCATED IN SCOTLAND: the Private Residential Tenancy Agreement and such other documents in relation to
          the tenancy all signed by the Landlord and the Tenant.
        </TermsLi>
        <TermsLi>FOR PROPERTIES LOCATED IN NORTHERN IRELAND: The Private Tenancy Agreement.</TermsLi>
      </TermsOl>
      <TermsP>
        <TermsStrong>Tenancy Information Notice</TermsStrong> means notice of the grant of tenancy as provided by Article 1 of the
        Private Tenancies Act (Northern Ireland) 2022.
      </TermsP>
      <TermsP>
        In these Conditions, for properties located in England we may refer to Acts of Parliament. For properties located in
        Scotland we may refer to Acts of Scottish Parliament. For properties located in Northern Ireland we may refer to Acts and
        Orders of the Northern Ireland Assembly, and Acts of Parliament which also apply in Northern Ireland. For Accommodation
        located in Wales we may refer to Acts of Parliament and Acts of Senedd Cymru. When we refer to these aforementioned Acts,
        you can take it that we are referring both to that Act and any regulations or subordinate legislation made under it. We
        are also referring to any amendments that may be made either to the Act in question or any such regulations and so on. If
        the Act is replaced by something else that takes its place, then, until we update the Conditions to take that into
        account, you can also take it that we are referring to whatever the new legislation is instead.
      </TermsP>
      <TermsP>Just for ease of drafting, we may use the masculine gender or the feminine.</TermsP>
      <TermsP>
        We may use plural or singular. Whichever gender we use, you can deem to include both or, for that matter, all genders and
        likewise you can read singular as including plural and vice versa. When we refer to persons or people, we include
        companies, unincorporated associations and partnerships.
      </TermsP>
      <TermsH3>2. Our Services</TermsH3>
      <TermsP>
        We each agree that we will provide the Services to you from the Start Date until the Agreement expires or is terminated
        early by you or by us.
      </TermsP>
      <TermsP>
        If you already owe us any Fees or Charges we may prevent you from purchasing further Services from us unless and until the
        pre-existing debts are paid in full, along with any interest owed to us.
      </TermsP>
      <TermsH3>3. Your General Obligations to Us</TermsH3>
      <TermsP>You agree:</TermsP>
      <TermsOl type="a">
        <TermsLi>
          Where you provide information to us (for example contact details or reference details), you will ensure it is complete
          and accurate and you confirm that you are allowed to share the information with us.
        </TermsLi>
        <TermsLi>
          That we and/or your Landlord can use the information which you provide to us to carry out reference checks on you with
          the purpose of confirming your identity and financial history. If we determine, in our sole discretion, that the
          information provided by you is false, misleading or illegal, we reserve the right to claim any and all costs and
          expenses which we suffer as a result from you and we may terminate the Agreement with immediate effect. If we terminate
          the agreement under this section, you understand that you may become liable to pay the full deposit for the
          Accommodation to the Landlord immediately;
        </TermsLi>
        <TermsLi>
          To pay the Fees promptly, you understand that we will be unable to provide the Services and the Landlord may not proceed
          with the renting the Accommodation to you until the Fees have been paid in full;
        </TermsLi>
        <TermsLi>To pay the Annual Fee to us on each anniversary of the start date of your Tenancy Agreement;</TermsLi>
        <TermsLi>To comply with all Applicable Legislation;</TermsLi>
        <TermsLi>
          To pay your rent to the Landlord on time and in full, to keep the Accommodation in a satisfactory condition and in
          accordance with the terms of the Tenancy Agreement and to report any issues with the Accommodation (such as repairs and
          faults) to the Landlord as soon as possible;
        </TermsLi>
        <TermsLi>
          To nominate a lead tenant in cases of multiple occupancy, as appropriate, who is responsible for communicating with us
          (understanding that this does not excuse you from performing your obligations under this Agreement);
        </TermsLi>
        <TermsLi>To promptly provide us with up to date contact details if your contact details change.</TermsLi>
        <TermsLi>
          That you are jointly and severally liable with any other tenant under the same Tenancy Agreement as you and we reserve
          the right to recover the full value of the Charge from you notwithstanding that there are other tenants;
        </TermsLi>
        <TermsLi>
          That after the end of the Rental Period, we shall inform you, or the lead tenant as appropriate, if the Landlord has
          made a Charge. Where a Charge has been made, you agree to comply with the terms of paragraph 9 (Charges raised by
          Landlord);
        </TermsLi>
        <TermsLi>
          That any payment by you of the Charge (including any alternative or reduced amount agreed to through the Charge
          process), is not a payment in full and final settlement of any claims the Landlord may have against you. Whilst you will
          not be required to pay any sums to the Landlord in excess of the Charge via Reposit, this is without prejudice to any
          additional sums that the Landlord may be able recover from you outside of Reposit;
        </TermsLi>
        <TermsLi>At all material times to act in good faith towards us;</TermsLi>
        <TermsLi>
          To inform us immediately if the Rental Period terminates early for whatever reason, including if you have received a
          notice seeking possession from the Landlord (or for Scottish properties a notice to quit or notice of proceedings from
          the Landlord, or for Northern Irish properties a notice to quit).
        </TermsLi>
      </TermsOl>
      <TermsP>
        You acknowledge and agree that it is your decision (made freely) to enter into this Agreement as an alternative to paying
        a rental deposit to the Landlord. You understand and accept that we provide no assistance or advice regarding the
        Accommodation’s condition, presentation, marketing, advertising, Tenancy Agreements, termination, Accommodation queries or
        complaints. You will need to contact the Landlord directly with regards to those matters.
      </TermsP>
      <TermsP>
        You understand that if you fail to pay any Fees or Charges due under this Agreement that we may take legal action to
        recover these from you and that this may impact your credit rating.
      </TermsP>
      <TermsH3>4. You must notify us of changes to your Tenancy Agreement</TermsH3>
      <TermsP>If your Tenancy Agreement is extended or if it is terminated, you must tell us immediately.</TermsP>
      <TermsP>You understand that the Annual Fee is payable by you to us for as long as the Tenancy Agreement is active.</TermsP>
      <TermsH3>
        5. You confirm that you are the payment card holder or that you are authorised by the payment card holder to use the
        payment card
      </TermsH3>
      <TermsP>
        Where you provide payment card details to us to make a payment, you represent and warrant that you are the named card
        holder or, if you are not a named cardholder on such payment card, that you have obtained the express authority of the
        cardholder to make such payment and that the card holder understands and agrees to the Continuous Payment Authority
        described in paragraph 6.
      </TermsP>
      <TermsP>
        You agree to indemnify us in full for and against all claims, costs, expenses or liabilities whatsoever and howsoever
        arising incurred or suffered by us including all legal expenses and other professional fees (together with any VAT
        thereon) in relation to any breach of the representation in this paragraph 5.
      </TermsP>
      <TermsH3>
        6. Continuous Payment Authority - by providing us with payment card details, you authorise us to use these details to take
        payments owed under these Conditions. If you cancel our authority to do this, or cancel the payment card, we may charge
        you additional fees.
      </TermsH3>
      <TermsP>
        By providing us with your debit or credit card details, you authorise us to use these details at any time to take payment
        from you for all fees and charges owed by you to us (<TermsStrong>"Continuous Payment Authority"</TermsStrong>).
      </TermsP>
      <TermsP>
        We will rely on the Continuous Payment Authority to take payment from you where we have tried to contact you through your
        Reposit account about outstanding Fees or Charges and (i) you have failed to respond to our messages and (ii) you have
        failed to pay the outstanding Fees or Charges that we have tried to contact you about.
      </TermsP>
      <TermsP>
        If you disagree with any Fees or Charges or if you are having difficulty paying, please contact us to discuss your
        options.
      </TermsP>
      <TermsP>
        <i>Cancelling the Continuous Payment Authority</i>
      </TermsP>
      <TermsP>
        You can cancel the Continuous Payment Authority by notifying us in writing at our registered office address (set out on
        page 1 of these Conditions). If you cancel the Continuous Payment Authority and you owe money, we will charge you an
        additional fee of £125 to cover our additional administrative costs incurred in collecting the debt from you. This
        administrative fee is payable in addition to the Fees or Charges which you owe.
      </TermsP>
      <TermsH3>7. Our Obligations to You</TermsH3>
      <TermsP>Subject to you complying with these Conditions, we will:</TermsP>
      <TermsOl type="a">
        <TermsLi>
          Grant you access to the Reposit system, which allows you to sign-up to Reposit, create an account, and use our Services.
        </TermsLi>
        <TermsLi>
          Collect payment of Fees and (where applicable) Charges and/or Further Fees from you, in addition to any other amounts
          due to us under these Conditions. Once payment of Charges is received, we will then settle these Charges with your
          Landlord on your behalf.
        </TermsLi>
        <TermsLi>
          Maintain records in relation to this Agreement and the Tenancy Agreement for the purpose of performing our obligations
          under this Agreement and our obligations to the Landlord.
        </TermsLi>
        <TermsLi>
          Facilitate communications between the Landlord and you in relation to any Charge. As set out in paragraph 9 (Charges),
          this includes providing access to a third party independent adjudicator if a dispute arises.
        </TermsLi>
      </TermsOl>
      <TermsH3>8. Payment</TermsH3>
      <TermsP>
        To receive our Services, you (and each other tenant, if you are renting with others) must pay the Fees in full using a
        credit or bank card via our website. Each tenant is required to use a separate payment card when paying us.
      </TermsP>
      <TermsP>
        Once the Tenancy Agreement starts, the Fees are non-refundable. Prior to the start of the Tenancy Agreement you have some
        rights to cancel our agreement and claim a full refund – see paragraph 11 for details of your rights.
      </TermsP>
      <TermsP>All sums payable under this Agreement:</TermsP>
      <TermsOl type="a">
        <TermsLi>
          are exclusive of VAT or other applicable sales tax (unless otherwise stated), which shall be payable in addition where
          VAT or other applicable sales tax is chargeable; and
        </TermsLi>
        <TermsLi>
          shall be paid in full without any deductions (including deductions in respect of items such as income, corporation, or
          other taxes, charges and/or duties) except where you are required by law to deduct withholding tax.
        </TermsLi>
      </TermsOl>
      <TermsP>
        If you fail to make any payment due to us under this Agreement by the due date for payment, then, in addition to other
        remedies that we may have under these Conditions, you will pay interest on the overdue amount and our administrative costs
        and our costs of recovering any sums due to us including any legal costs and court fees. The interest will be at the rate
        of 4% per annum above the Bank of England’s base rate from time to time, but at 4% a year for any period when that base
        rate is below 0%. Interest shall accrue on a daily basis from the due date until actual payment of the overdue amount
        (whether before or after judgment). You shall pay the interest together with the overdue amount.
      </TermsP>
      <TermsH3>9. Charges raised by Landlord</TermsH3>
      <TermsP>
        <i>What happens if the Landlord raises a Charge?</i>
      </TermsP>
      <TermsP>
        We will share the details of the Charge with you via email and through your account on our website ("
        <TermsStrong>Charge Notification</TermsStrong>"").
      </TermsP>
      <TermsP>
        You agree that the Landlord may share with us information about you and your tenancy for the purpose of verifying any
        Charge (such information may include your Tenancy Agreement, the result of reference checks and any other evidence to
        support the Charge).
      </TermsP>
      <TermsP>
        It is important that you follow the process below to either accept or dispute the Charge. If you fail to dispute the
        Charge within 7 days of receiving the Charge Notification, we will assume you accept the Charge and will take steps to
        take payment from you.
      </TermsP>
      <TermsP>
        <i>How do I accept the Charge and pay?</i>
      </TermsP>
      <TermsP>
        If you agree with the Charge, you must pay the Charge by using the payment function available through your account on our
        website.
      </TermsP>
      <TermsP>
        You understand that if we are not able to take payment using these payment methods then we may take payment using the
        Continuous Payment Authority and we may also take legal action to recover the Charge from you. If you are having
        difficulty paying, please contact us to discuss your options.
      </TermsP>
      <TermsP>
        <i> What do I do if I disagree with the Charge? </i>
      </TermsP>
      <TermsP>
        If you disagree with the Charge you must follow the process set out on your Reposit account dashboard to reject the Charge
        within 7 days of receiving the Charge Notification. If you do not let us know that you disagree within this 7 day period,
        we will assume you accept the Charge and will take steps to take payment from you. You understand that if we do not hear
        from you then we may take payment using the Continuous Payment Authority and we may also take legal action to recover the
        Charge from you. If you are having difficulty paying, please contact us to discuss your options.
      </TermsP>
      <TermsP>
        Please note that if you have any outstanding debts with us, we may refuse any requests you make in the future to set up
        new Reposits with us or otherwise use our Services.
      </TermsP>
      <TermsP>
        If you disagree with the Charge, please provide evidence to support why you disagree with the Charge through your account
        on our website and propose an alternative settlement amount to the Landlord (a "
        <TermsStrong>Proposed Settlement</TermsStrong>"). If you make a Proposed Settlement and your Landlord accepts, you confirm
        we may take payment from you immediately.
      </TermsP>
      <TermsP>
        We will share the information which you provide and your Proposed Settlement with the Landlord, who will have 7 days to
        accept it or to propose a final settlement back to you ("<TermsStrong>Final Settlement</TermsStrong>").
      </TermsP>
      <TermsP>
        <i> Options for responding to a Final Settlement </i>
      </TermsP>
      <TermsP>
        If the Landlord proposes a Final Settlement, you have 7 days to accept or reject it. If you accept it, you will pay the
        Final Settlement amount using the payment functions available through your account on our website.
      </TermsP>
      <TermsP>
        If you want to reject the Final Settlement you must do so in our website within 7 days or we will assume you have accepted
        it and will take steps to recover payment from you. You understand that if we do not hear from you then we may take
        payment using the Continuous Payment Authority and we may also take legal action to recover the Charge from you. If you
        are having difficulty paying, please contact us to discuss your options.
      </TermsP>
      <TermsP>
        If you reject the Final Settlement, you authorise us to refer the dispute to an independent adjudicator and take payment
        of the Adjudication Fee from you (see next paragraph).
      </TermsP>
      <TermsP>
        <i> Involving an independent Adjudicator </i>
      </TermsP>
      <TermsP>
        If you reject a Final Settlement and pay us a £60 fee (the "<TermsStrong>Adjudication Fee</TermsStrong>"), we will refer
        the dispute, along with all evidence we have received from you or the Landlord to an independent adjudicator ("
        <TermsStrong>Adjudicator</TermsStrong>"") to resolve the dispute.
      </TermsP>
      <TermsP>
        If the Adjudicator reduces the Final Settlement the Adjudication Fee will be refunded to you in full (otherwise it is
        non-refundable).
      </TermsP>
      <TermsP>
        If the Adjudicator decides that you do owe the Landlord an amount (either the Final Settlement or an alternative amount
        stipulated by the Adjudicator):
      </TermsP>
      <TermsOl type="a">
        <TermsLi>You agree to pay us through your account on the website within 24 hours.</TermsLi>
        <TermsLi>
          If you fail to pay us and we are not able to contact you, you confirm that we may take payment from you under the
          Continuous Payment Authority.
        </TermsLi>
        <TermsLi>
          You agree that if we are not able to take payment using these payment methods then we will take legal action to recover
          the Charge from you.
        </TermsLi>
      </TermsOl>
      <TermsP>
        We reserve the right to refer any disputes relating to a Charge to an Adjudicator for settlement. We may make such a
        referral in the event of a dispute, or for any other reason in our discretion. The Adjudicator’s decision, save in the
        case of manifest error, shall be final and binding on both of us.
      </TermsP>
      <TermsP>
        <i>If you fail to pay, we may take legal action to recover the debt from you</i>
      </TermsP>
      <TermsP>
        If you fail to pay for a Charge when due, you agree that we are entitled to make any claim in respect of such failure to
        pay on behalf of Reposit Group Limited and/or the Landlord including through the service of formal legal proceedings.
      </TermsP>
      <TermsP>
        Any notice and/or any documentation of any description (including those relating to legal proceedings and/or any document
        required to be served in accordance with the Civil Procedure Rules in England and Wales or Scotland or the Rules of the
        Court of Judicature (NI) 1980 in Northern Ireland given in connection with this Agreement) shall be sent and/or served
        upon you by e mail to the e mail address you have provided to us for this purpose and which is associated with your
        account with us (the e mail address which you use to sign into that account) and you irrevocably agree to such notices and
        documents being served by e mail. Service will be deemed to have been effected at the time of transmission of the relevant
        email.
      </TermsP>
      <TermsP>
        This paragraph 9 (Charges Raised by Landlord) shall survive termination of the Agreement. This means that your obligation
        to pay any Charge (or any reduced or alternative amount agreed following the Charge process) amount continues after your
        Tenancy Agreement ends.
      </TermsP>
      <TermsH3>10. Reposit Switch</TermsH3>
      <TermsP>
        If the eligibility criteria below apply to both you and the Landlord, you can start using the Services for an existing
        Tenancy Agreement (either during the term or at the renewal of an existing tenancy) to replace a cash deposit that has
        already been paid by you to your Landlord ("
        <TermsStrong>Reposit Switch</TermsStrong>"). You should liaise with your Landlord to arrange the return of your cash
        deposit.
      </TermsP>
      <TermsP>
        <i> Eligibility criteria: </i>
      </TermsP>
      <TermsOl type="a">
        <TermsLi>
          You and the Landlord must sign the Reposit Addendum to the relevant Tenancy Agreement which shall then be appended to
          form part of such Tenancy Agreement.
        </TermsLi>
        <TermsLi>
          You must have passed referencing at the start of the tenancy in accordance with our referencing criteria. If your
          tenancy has been going on for more than 1 year and the rent has been increased by more than 10% per annum, you must pass
          referencing again before being able to use Switch.
        </TermsLi>
        <TermsLi>
          You must not be or have been in rent arrears or agreed any rent payment holidays in the last 6 months, or since the
          start of your tenancy (whichever is the shorter period).
        </TermsLi>
        <TermsLi>
          There must have been no material change to your tenancy or your financial situation that would mean you are no longer
          eligible to use our Services.
        </TermsLi>
      </TermsOl>
      <TermsH3>11. Cancelling the Agreement</TermsH3>
      <TermsP>
        <i>
          Unless the Tenancy Agreement has started, you have the legal right to cancel during the 14 day cooling off period from
          the Purchase Date
        </i>
      </TermsP>
      <TermsP>
        As you have signed up for your Reposit online, you have a 14 day cooling off period from the Purchase Date during which
        you can cancel the agreement and claim a full refund. The cooling off period automatically ends as soon as your Tenancy
        Agreement starts and so this means your right to cancel and claim a full refund ends when the Tenancy Agreement begins.
      </TermsP>
      <TermsP>
        If you wish to cancel our agreement during the cooling off period you must let us know by emailing us on
        <TermsAnchor href="mailto:hello@reposit.co.uk"> hello@reposit.co.uk</TermsAnchor> or via the live chat functionality on
        our website and you confirm that you will also let your Landlord know that you have cancelled with us.
      </TermsP>
      <TermsP>You understand that if you cancel, your Landlord may require you to pay a cash deposit instead.</TermsP>
      <TermsP>
        <i>
          Following the cooling off period and up to the point at which your Tenancy Agreement starts you can cancel with the
          landlord’s consent and claim a full refund
        </i>
      </TermsP>
      <TermsP>
        In addition to your legal right to cancel during the cooling off period (explained above), you may cancel our agreement
        and claim a full refund if you have the landlord’s consent and only if the Tenancy Agreement has not yet started. If you
        wish to cancel our agreement you must let us know by emailing us on
        <TermsAnchor href="mailto:hello@reposit.co.uk"> hello@reposit.co.uk</TermsAnchor> or via the live chat functionality on
        our website. We will ask for proof that the Landlord has agreed to the cancellation.
      </TermsP>
      <TermsP>You understand that if you cancel, your Landlord may require you to pay a cash deposit instead.</TermsP>
      <TermsP>
        <i> The Fee is non-refundable once the Tenancy Agreement starts </i>
      </TermsP>
      <TermsP>
        Once the Tenancy Agreement starts our agreement cannot be cancelled and the Fee is non-refundable. Your rights to cancel
        are different from any rights which you may have to terminate (if we are in breach of the agreement, for example). See
        paragraph 12 for details of when you or we can terminate this agreement.
      </TermsP>
      <TermsH3>12. Terminating this Agreement</TermsH3>
      <TermsP>
        Unless terminated early by either of us, and subject to our ongoing right to recover any debts from you, the Agreement
        will expire automatically on the Reposit End Date. Without affecting any other right or remedy available, either of us may
        terminate this Agreement with immediate effect by giving written notice to the other Party if:
      </TermsP>
      <TermsOl type="a">
        <TermsLi>
          the other Party fails to pay any amount due on the due date for payment and remains in default not less than 14 days
          after being notified in writing to make such payment;
        </TermsLi>
        <TermsLi>
          the other Party commits a material breach of these Conditions which is irremediable or (if such breach is remediable)
          fails to remedy that breach within a period of 14 days after being notified in writing to do so;
        </TermsLi>
        <TermsLi>
          the other Party repeatedly breaches any of these Conditions in such a manner as reasonably to justify the opinion that
          its conduct is inconsistent with it having the intention or ability to give effect to the Agreement.
        </TermsLi>
      </TermsOl>
      <TermsP>
        Termination or expiry of this Agreement does not limit or restrict: (i) our ability to recover any debts which you owe
        under this Agreement, or (ii) either our or your rights or remedies under this Agreement or which otherwise exist as a
        matter of law. If you owe us money at the point of termination/cancellation/expiry, you agree that we may take legal
        action to recover any debts from you.
      </TermsP>
      <TermsP>
        If this Agreement is cancelled or terminated for any reason and your Tenancy Agreement remains in place, you understand
        that your Landlord may ask you to provide a deposit to them or to their agent.
      </TermsP>
      <TermsH3>13. Our Liability</TermsH3>
      <TermsP>YOUR ATTENTION IS PARTICULARLY DRAWN TO THIS PARAGRAPH.</TermsP>
      <TermsP>
        Nothing in these Conditions shall limit or exclude our liability for death or personal injury caused by our negligence, or
        the negligence of our employees, agents or subcontractors or fraud or fraudulent misrepresentation.
      </TermsP>
      <TermsP>
        You acknowledge and accept that we have no responsibility or liability for the Tenancy Agreement or your relationship with
        the Landlord, except for as it expressly described in these Terms. We do not make any representations, warranties or other
        promises concerning the Accommodation, the Tenancy Agreement, or your ability to rent that Accommodation,
      </TermsP>
      <TermsP>We are not responsible for any loss which is:</TermsP>
      <TermsUl>
        <TermsLi>Unexpected or not obvious that it would happen when we entered into the Agreement.</TermsLi>
        <TermsLi>Caused by a delay outside of our reasonable control.</TermsLi>
        <TermsLi>
          Something which you could have avoided by taking reasonable action. For example, not ignoring communications from us
          when we are trying to reach you to discuss an issue or letting us know if you are having difficulty paying.
        </TermsLi>
        <TermsLi>
          Any business loss, relating to your use of our Services in connection with a business, trade or profession.
        </TermsLi>
      </TermsUl>
      <TermsP>
        Our total liability to you in respect of all other losses arising under or in connection with the Contract, whether in
        contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed a sum
        equal to the Fees.
      </TermsP>
      <TermsP>This paragraph 13 shall survive termination of the Agreement.</TermsP>
      <TermsH3>14. No responsibility for events beyond our reasonable control</TermsH3>
      <TermsP>
        No Party shall be liable or be deemed to be in breach of its obligations if its delay in performing, or failure to
        perform, was due to any cause beyond its reasonable control (an "Event of Force Majeure").
      </TermsP>
      <TermsP>
        We agree to give notice to each other upon becoming aware of an Event of Force Majeure. The notice shall contain details
        of the Event of Force Majeure circumstances. If an Event of Force Majeure continues for more than four weeks, the Party
        not in default may terminate the Agreement.
      </TermsP>
      <TermsP>
        We will not have any liability to each other if the Agreement is terminated due to an Event of Force Majeure.
      </TermsP>
      <TermsH3>15. Data Protection</TermsH3>
      <TermsP>
        <TermsStrong>
          How we use any personal data you give us is set out in our Privacy Policy:
          <TermsAnchor href="https://reposit.co.uk/privacy-policy/">https://reposit.co.uk/privacy-policy/</TermsAnchor>.
        </TermsStrong>
      </TermsP>
      <TermsP>
        You agree that we may share your personal data with our insurers and brokers and other parties with a direct interest in
        relation and ancillary to this Agreement and the provision of the Services.
      </TermsP>
      <TermsH3>16. General Information</TermsH3>
      <TermsP>
        These Conditions constitute the entire agreement between us. You agree that you have not relied upon any representation or
        statement not set out in these Conditions when entering into the Agreement. You acknowledge that these Conditions
        supersede all prior agreements, representations, statements and understandings between us.
      </TermsP>
      <TermsP>
        We may amend these Conditions to reflect changes in laws and regulations or to make minor adjustments and improvements. If
        we want to make any other changes and these have a significant impact on the Conditions, we will notify you in advance and
        you can discuss any questions which our customer services team and terminate the Agreement if you do not agree with the
        changes.
      </TermsP>
      <TermsP>
        If either of us waives a breach or default of any of these Conditions by the other, this will not be deemed to be a waiver
        of any further breach of the same or other provisions. Likewise, if either of us delays or does not exercise any right,
        power or privilege that it has or may have under these Conditions, this will not be deemed to be a waiver of any breach or
        default.
      </TermsP>
      <TermsP>
        If any court or administrative body of competent jurisdiction finds that any of these Conditions or part of them is
        invalid or unenforceable, the rest will continue to apply. We both agree to attempt to substitute any invalid or
        unenforceable Conditions with valid or enforceable Conditions that achieve, to the greatest extent possible, the economic,
        legal and commercial objectives.
      </TermsP>
      <TermsP>
        You may not assign this Agreement or any of your rights and obligations. We will be entitled to assign this Agreement and
        any of our rights and obligations at any time.
      </TermsP>
      <TermsP>
        Any notices to be given by you under this Agreement must be made in writing (not email) and addressed to Reposit Group
        Limited, 128 City Road, London, EC1V 2NX.
      </TermsP>
      <TermsP>
        <TermsStrong>FOR ACCOMMODATION LOCATED IN ENGLAND AND WALES AND NORTHERN IRELAND: </TermsStrong>
        Nobody other than the Parties has any right under the Contracts (Rights of Third Parties) Act 1999 to enforce or enjoy the
        benefit of any term of the Agreement provided that this does not affect any right or remedy of the third party, which
        exists or is available apart from that Act.
      </TermsP>
      <TermsP>
        <TermsStrong>FOR ACCOMMODATION LOCATED IN ENGLAND AND WALES: </TermsStrong>These Conditions shall be governed by and
        construed in accordance with English law and the Parties hereto agree to submit to the exclusive jurisdiction of the
        English Courts.
      </TermsP>
      <TermsP>
        <TermsStrong>FOR ACCOMMODATION LOCATED IN NORTHERN IRELAND: </TermsStrong>These Conditions shall be governed by and
        construed in accordance with Northern Irish law and the Parties hereto agree to submit to the exclusive jurisdiction of
        the Northern Irish Courts.
      </TermsP>
      <TermsP>
        <TermsStrong>FOR ACCOMMODATION LOCATED IN SCOTLAND: </TermsStrong>
      </TermsP>
      <TermsOl type="a">
        <TermsLi>
          Nobody other than the Parties has any right under the Contract (Third Parties Rights) (Scotland) Act 2017 to enforce or
          enjoy the benefit of any term of the Agreement provided that this does not affect any right or remedy of the third
          party, which exists or is available apart from that Act.
        </TermsLi>
        <TermsLi>
          These Conditions shall be governed by and construed in accordance with Scottish law and the Parties hereto agree to
          submit to the exclusive jurisdiction of the Scottish Courts.
        </TermsLi>
      </TermsOl>
    </TermsContainer>
  );
};

export default TenantTerms;
