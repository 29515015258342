import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Location } from 'history';
import { Header2, P1 } from '../../components/Typography';
import { useQuery } from '../../hooks/useQuery';
import { TenancyInventoryError } from './tenancy-inventory-error';

interface TenancyInventoryResponseProps {
  location: Location<any>;
}

const RESPONSE_QUERY = 'response';
const CONFIRMED_VALUE = 'CONFIRMED';
const UNCONFIRMED_VALUE = 'UNCONFIRMED';

export const TenancyInventoryResponse: React.FC<TenancyInventoryResponseProps> = ({ location }) => {
  const query = useQuery(location);
  const response = query && query.get(RESPONSE_QUERY);
  const isConfirmed = response === CONFIRMED_VALUE;
  const isNotConfirmed = response === UNCONFIRMED_VALUE;
  if (!response || (!isConfirmed && !isNotConfirmed)) {
    return <TenancyInventoryError />;
  }

  const header = isConfirmed
    ? 'Thank you for confirming you have received your tenancy inventory.'
    : 'You have confirmed that you have not received your tenancy inventory.';
  const body = isConfirmed
    ? `We will be in touch if we need anything further from you.`
    : 'We will contact your agent/landlord to request they send your tenancy inventory over to you for your review.';
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header2 style={{ fontSize: '1.7em' }}>{header} </Header2>
          <P1 style={{ fontSize: '1.4em', marginTop: 24 }}>{body}</P1>
        </Col>
      </Row>
    </Container>
  );
};
