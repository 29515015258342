import { ClaimDocumentDTO } from '@reposit/api-client';
import React from 'react';
import styled from 'styled-components';
import RepositCard from '../RepositCard';
import ClaimDocument from './ClaimDocument';

interface ClaimDocumentsProps {
  documents: ClaimDocumentDTO[];
}

const Items = styled.div``;

const ClaimDocuments: React.FC<ClaimDocumentsProps> = ({ documents }) => {
  return (
    <RepositCard flush title="Tenancy documents">
      <Items>
        {documents
          .filter((d) => d.document.typeId !== 'REFERENCING')
          .map((document) => (
            <ClaimDocument key={document.id} claimDocument={document} />
          ))}
      </Items>
    </RepositCard>
  );
};

export default ClaimDocuments;
