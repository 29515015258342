import { PaymentPlanInstalmentDTO, PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client/dist';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import SlideDown from 'react-slidedown';
import styled, { css } from 'styled-components';
import Pending from '../../../assets/svg/pending.svg';
import GreenTick from '../../../assets/svg/tick-green.svg';
import { Header4, P2, P3 } from '../../../components/Typography';
import { penceToPounds } from '../../../utils/common.utils';
import { StatusIcon } from './Dashboard';

const INITIAL_SCHEDULE_LENGTH = 5;

const ExpanderTextStyles = css`
  color: ${(props) => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

export const ExpanderText = styled(P3)`
  ${ExpanderTextStyles}
`;

interface InstalmentProps {
  instalment: PaymentPlanInstalmentDTO;
  number: number;
  showStatus: boolean;
}

const Instalment: React.FC<InstalmentProps> = ({ instalment, number, showStatus }) => {
  const getIcon = () => {
    const instalmentIsPaid = instalment.status === PaymentPlanInstalmentDTOStatusEnum.PAID;
    if (instalmentIsPaid) {
      return GreenTick;
    } else {
      return Pending;
    }
  };
  const columnSize = showStatus ? 3 : 4;
  return (
    <Row>
      <Col xs={columnSize}>
        <P2>{number + 1}</P2>
      </Col>
      <Col xs={columnSize}>
        <P2>{moment(instalment.dueDate).format('DD/MM/YY')}</P2>
      </Col>
      <Col xs={columnSize}>
        <P2>£{penceToPounds(instalment.amount)}</P2>
      </Col>
      {showStatus ? (
        <Col xs={columnSize}>
          <P2>
            <StatusIcon icon={getIcon()} />
          </P2>
        </Col>
      ) : null}
    </Row>
  );
};

interface PaymentScheduleProps {
  instalments: PaymentPlanInstalmentDTO[];
  showStatus: boolean;
  expandable?: boolean;
}

export const PaymentSchedule: React.FC<PaymentScheduleProps> = ({ instalments, showStatus, expandable }) => {
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const toggleViewSchedule = () => {
    setIsSliderOpen(!isSliderOpen);
  };
  const columnSize = showStatus ? 3 : 4;
  const sortedInstalments = sortBy(instalments, (i) => i.dueDate);
  const initiallyShowingInstalments = [...sortedInstalments].splice(0, INITIAL_SCHEDULE_LENGTH);
  const remainingInstalments = [...sortedInstalments].splice(INITIAL_SCHEDULE_LENGTH);
  return (
    <>
      <Row>
        <Col xs={columnSize}>
          <Header4>Instalment</Header4>
        </Col>
        <Col xs={columnSize}>
          <Header4>Date</Header4>
        </Col>
        <Col xs={columnSize}>
          <Header4>Amount</Header4>
        </Col>
        {showStatus ? (
          <Col xs={columnSize}>
            <Header4>Status</Header4>
          </Col>
        ) : null}
      </Row>
      {expandable ? (
        <>
          {initiallyShowingInstalments.map((i, index) => (
            <Instalment instalment={i} number={index} showStatus={showStatus} />
          ))}
          {sortedInstalments && sortedInstalments.length > INITIAL_SCHEDULE_LENGTH ? (
            <>
              <SlideDown closed={!isSliderOpen} className={`evidence-slider`}>
                {remainingInstalments.map((i, index) => (
                  <Instalment instalment={i} number={index + INITIAL_SCHEDULE_LENGTH} showStatus={showStatus} />
                ))}
              </SlideDown>

              <ExpanderText onClick={toggleViewSchedule}>
                <span>{isSliderOpen ? 'Hide' : 'Show more'}</span>
              </ExpanderText>
            </>
          ) : null}
        </>
      ) : (
        sortedInstalments.map((i, index) => <Instalment instalment={i} number={index} showStatus={showStatus} />)
      )}
    </>
  );
};
