import React from 'react';
import styled from 'styled-components';
import { Header5 } from '../Typography';
import { DocumentEntity } from '../../redux/entities/entities.types';
import PDFLogo from '../../assets/svg/acrobat-doc.svg';
import DownloadIcon from '../../assets/svg/download-white.svg';
import { RoundButton } from '../Button';

interface DocumentListProps {
  documents?: DocumentEntity[];
  rounded?: boolean;
}

const Documents = styled.div<{ rounded?: boolean }>`
  background: ${(props) => props.theme.colors.bgAccent};
  border-radius: ${(props) => (props.rounded ? `0 0 8px 8px` : '0')};
  margin: 24px 5px 4px;
  padding: 12px 36px;
`;

const List = styled.ul`
  margin: 12px 0 0;
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 42px 0 0;
  padding: 0;
`;

const DocumentListItem = styled.li`
  margin: 0 0 20px;
`;

const DocumentLabel = styled.div`
  color: ${(props) => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${(props) => props.theme.typography.face.secondary};
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  font-size: 12px;
  margin: 0;
  cursor: pointer;

  &:before {
    background: transparent url(${PDFLogo}) no-repeat center center;
    background-size: 20px;
    content: '';
    display: block;
    height: 20px;
    margin: 0 7px 0 0;
    width: 20px;
  }
`;

const renderListItems = (items: DocumentEntity[]): JSX.Element[] => {
  return items.map((item: DocumentEntity) => (
    <DocumentListItem key={item.name}>
      <DocumentLabel>
        {item.name}
        <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ margin: '0 0 0 auto' }}>
          <RoundButton style={{ pointerEvents: 'auto' }} icon={DownloadIcon} />
        </a>
      </DocumentLabel>
    </DocumentListItem>
  ));
};

const DocumentList: React.FC<DocumentListProps> = ({ rounded, documents }) => {
  return (
    <Documents rounded={rounded}>
      <Label>Documents</Label>
      <List>{documents ? renderListItems(documents) : '-'}</List>
    </Documents>
  );
};

export default DocumentList;
