import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-grid-system';
import { Header2, Caption } from '../../components/Typography';

const ContentHeader = styled.div`
  text-align: center;
`;

const Subtitle = styled(Caption)`
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const TenantVacated: React.FC<unknown> = () => {
  return (
    <Container>
      <Row>
        <Col>
          <ContentHeader>
            <Header2>Thanks for letting us know you have left the property</Header2>
            <Subtitle>
              If you have an active Reposit, we will notify your letting agent or landlord to take the required action. However,
              if there are outstanding charges on your Reposit, these will need to be settled.{' '}
            </Subtitle>
          </ContentHeader>
        </Col>
      </Row>
    </Container>
  );
};
