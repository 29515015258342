import { OrderCustomerActionsState } from './order-customer-actions.types';
import {
  OrderCustomerActionsActionTypes,
  GET_PAYMENT_INTENT_API_SUCCESS,
  GET_CLAIM_PAYMENT_INTENT_API_SUCCESS,
  POLL_PAYMENT_SUCCESS_API_REQUESTED,
  POLL_PAYMENT_SUCCESS_API_SUCCESS,
  POLL_PAYMENT_SUCCESS_API_FAILED,
} from './order-customer-actions.actions';

const initialState: OrderCustomerActionsState = {
  paymentIntentSecret: '',
  claimPaymentIntentSecret: '',
  isPolling: false,
};

export default (
  state: OrderCustomerActionsState = initialState,
  action: OrderCustomerActionsActionTypes
): OrderCustomerActionsState => {
  switch (action.type) {
    case GET_PAYMENT_INTENT_API_SUCCESS: {
      return {
        ...state,
        paymentIntentSecret: action.payload.paymentIntentSecret,
      };
    }
    case GET_CLAIM_PAYMENT_INTENT_API_SUCCESS: {
      return {
        ...state,
        claimPaymentIntentSecret: action.payload.paymentIntentSecret,
      };
    }

    case POLL_PAYMENT_SUCCESS_API_REQUESTED: {
      return {
        ...state,
        isPolling: true,
      };
    }

    case POLL_PAYMENT_SUCCESS_API_SUCCESS: {
      return {
        ...state,
        isPolling: false,
      };
    }

    case POLL_PAYMENT_SUCCESS_API_FAILED: {
      return {
        ...state,
        isPolling: false,
      };
    }

    default:
      return state;
  }
};
