import {
  CLEAR_AMOUNT_REMAINING,
  CLEAR_ORDER_CUSTOMER_ID,
  FETCH_AMOUNT_REMAINING_FOR_PAYMENT_PLAN_ORDER_SUCCESS,
  FETCH_ORDER_CUSTOMER_API_SUCCESS,
  FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS,
  FETCH_TENANCY_ORDERS_API_SUCCESS,
  FETCH_TENANCY_ORDER_BY_ID_API_SUCCESS,
  OrderActionTypes,
} from './order.actions';
import { OrderState } from './order.types';

const initialState: OrderState = {
  currentOrderCustomerId: '',
  tenancyOrders: [],
  tenancyAnniversaries: [],
  tenancyOrder: '',
  error: null,
  amountRemaining: undefined,
};

export default (state: OrderState = initialState, action: OrderActionTypes): OrderState => {
  switch (action.type) {
    case FETCH_ORDER_CUSTOMER_API_SUCCESS: {
      return {
        ...state,
        currentOrderCustomerId: action.payload.orderCustomerId,
      };
    }

    case FETCH_TENANCY_ORDERS_API_SUCCESS: {
      return {
        ...state,
        tenancyOrders: action.payload.tenancyOrders,
      };
    }

    case FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS: {
      return {
        ...state,
        tenancyAnniversaries: action.payload.tenancyAnniversaries,
      };
    }

    case FETCH_TENANCY_ORDER_BY_ID_API_SUCCESS: {
      return {
        ...state,
        tenancyOrder: action.payload.id,
      };
    }

    case CLEAR_ORDER_CUSTOMER_ID: {
      return {
        ...state,
        currentOrderCustomerId: '',
      };
    }

    case CLEAR_AMOUNT_REMAINING: {
      return {
        ...state,
        amountRemaining: undefined,
      };
    }

    case FETCH_AMOUNT_REMAINING_FOR_PAYMENT_PLAN_ORDER_SUCCESS: {
      return {
        ...state,
        amountRemaining: action.payload.amount,
      };
    }

    default:
      return state;
  }
};
