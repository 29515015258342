import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import React from 'react';
import { appConfig } from '../../appConfig';
import { RepositTheme } from '../../base/theme';
import CheckoutForm from '../../components/CheckoutForm';

const stripePromise = loadStripe(appConfig.stripe.apiKey);

interface ElementsFormProps {
  warningMessage?: React.FC;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, elements: StripeElements) => void;
  buttonText?: string;
  redWarningMessage?: boolean;
  showCheckbox?: boolean;
  paymentMode: 'setup' | 'payment';
  amount?: number;
}

export const ElementsForm: React.FC<ElementsFormProps> = ({
  warningMessage,
  isSubmitting,
  submitCard,
  buttonText,
  redWarningMessage = true,
  showCheckbox = true,
  paymentMode,
  amount,
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        currency: 'gbp',
        amount,
        mode: paymentMode,
        paymentMethodTypes: ['card'],
        paymentMethodCreation: 'manual',
        setupFutureUsage: 'off_session',
        appearance: {
          variables: {
            fontFamily: RepositTheme.typography.face.secondary,
            fontLineHeight: '1.29',
            borderRadius: '3px',
            focusBoxShadow: 'inset 0 1px 5px 0 rgba(0, 0, 0, 0.15)',
            colorPrimaryText: RepositTheme.colors.body,
            colorText: RepositTheme.colors.body,
            colorTextPlaceholder: RepositTheme.colors.placeholder,
            colorBackground: '#fff',
          },
          rules: {
            '.Input': {
              border: '1px solid #eee',
              boxShadow: 'inset 0 1px 5px 0 rgba(0, 0, 0, 0.15)',
              padding: '9px 4px 9px 16px',
            },
          },
        },
      }}
    >
      <CheckoutForm
        warningMessage={warningMessage}
        isSubmitting={isSubmitting}
        submitCard={submitCard}
        buttonText={buttonText}
        redWarningMessage={redWarningMessage}
        showCheckbox={showCheckbox}
      />
    </Elements>
  );
};
