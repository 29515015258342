import React from 'react';
import styled from 'styled-components';
import { Header2 } from '../Typography';
import HelpIcon from '../../assets/svg/help.svg';
import { RoundButton } from '../Button';
import { getBreakpoint } from '../../base/style';
import PersonIcon from '../../assets/svg//checkout/person-active.svg';

interface PageTitleProps {
  tooltip?: string;
}

const Title = styled(Header2)`
  align-items: center;
  display: flex;
  margin: 0 0 12px;
  padding: 0;

  &:before {
    background: #fff url(${PersonIcon}) no-repeat center center;
    border-radius: 48px;
    content: '';
    display: block;
    height: 48px;
    margin: 0 20px 0 0;
    width: 48px;
  }
`;

const HelpButton = styled(RoundButton)`
  display: none;
  margin: -30px 0 0 15px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: block;
  }
`;

const PageTitle: React.FC<PageTitleProps> = ({ children, tooltip }) => {
  return (
    <Title>
      {children}
      {tooltip && <HelpButton icon={HelpIcon} data-tip={tooltip} />}
    </Title>
  );
};

export default PageTitle;
