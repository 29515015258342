import React from 'react';
import styled from 'styled-components';
import RepositLogo from '../../assets/png/icon-full-colour.png';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/auth/auth.actions';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import { get } from 'lodash';
import { sendEventToGoogleAnalytics } from '../../utils/integrators/analytics.integrator';
import { push } from 'connected-react-router';

const HeaderContainer = styled.div`
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: flex-start;
  height: 72px;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 48px);
  z-index: 999;
`;

const Brand = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  height: 30px;
  flex: 0 0 auto;
`;

const Logo = styled.img`
  width: 32px;
  margin: 0 18px 0 0;
`;

const WelcomeMessage = styled.h2`
  font-family: ${(props) => props.theme.typography.face.primary};
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const Name = styled.span`
  font-weight: bold;
`;

const Navigation = styled.ul`
  display: flex;
  margin: 0 0 0 auto;
`;

const NavItem = styled.li`
  margin: 0 20px;

  font-family: ${(props) => props.theme.typography.face.primary};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.39px;

  a {
    color: #4a4a4a;
    text-decoration: none;

    &.logout,
    &.login {
      color: ${(props) => props.theme.colors.negative};
    }
  }
`;

interface HeaderProps {
  notAuthorised?: boolean;
  hideLogin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ notAuthorised, hideLogin }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const handleLogout = () => {
    sendEventToGoogleAnalytics({ action: 'Logout', category: 'Auth' });
    dispatch(logout());
  };

  const renderNavItems = () => {
    if (notAuthorised && !hideLogin) {
      return (
        <NavLink to="/auth/login" className="login">
          Login
        </NavLink>
      );
    } else if (!notAuthorised) {
      return (
        <NavLink to="#" className="logout" onClick={handleLogout}>
          Logout
        </NavLink>
      );
    }
  };

  return (
    <HeaderContainer>
      <Brand
        onClick={() => {
          if (!hideLogin) {
            dispatch(push('/'));
          }
        }}
      >
        <Logo src={RepositLogo} alt="Reposit" />
        {notAuthorised ? null : (
          <WelcomeMessage>
            Hi, <Name>{get(currentUser, 'firstName')}</Name>
          </WelcomeMessage>
        )}
      </Brand>
      <Navigation>
        <NavItem>{renderNavItems()}</NavItem>
      </Navigation>
    </HeaderContainer>
  );
};

export default Header;
