import { denormalize } from 'normalizr';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { getCurrentUserId } from '../account/account.selectors';
import { UserEntity } from '../entities/entities.types';

export const getCurrentUser = (state: AppState): UserEntity => {
  const currentUserId = getCurrentUserId(state);
  return denormalize(state.entities.user[currentUserId], SCHEMA.user, state.entities);
};
