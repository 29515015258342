import React from 'react';
import Card from '../../../../../components/Card';
import { P1, StyledLink } from '../../../../../components/Typography';
import styled from 'styled-components';
import Button from '../../../../../components/Button';
import { Row, Container, Col } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';

interface ReferenceStartProps {
  onStart: () => void;
}

const Action = styled.div`
  padding: 16px 0 0;
`;

const ReferenceStart: React.FC<ReferenceStartProps> = ({ onStart }) => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Eligibility checks</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <P1>
              Thanks for giving us a bit more information about you. We now need to run a few checks on the data provided to
              ensure you are eligible to use Reposit. This will include identity, anti-fraud and adverse credit history checks.
            </P1>
            <P1>
              Checks usually take less than 1 minute, but can sometimes take longer. You will be able to view the status of your
              checks on this page.
            </P1>
            <P1>
              If you would like to know more about how we use the data your provide us, please read our{' '}
              <StyledLink to="#">privacy policy</StyledLink>
            </P1>
            <Action>
              <Button onClick={onStart}>Start Referencing Check</Button>
            </Action>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceStart;
