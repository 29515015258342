import { createReducer } from '@reduxjs/toolkit';
import { setToken } from './auth.actions';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  token: '',
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setToken, (state, action) => {
    state.token = action.payload;
  });
});
