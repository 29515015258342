import { ACCEPT_PROPOSAL_SUCCESS, ClaimActionTypes, DELETE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS } from '../../claim/claim.actions';

export const claimEntityReducer = (state: any = {}, action: ClaimActionTypes) => {
  switch (action.type) {
    case ACCEPT_PROPOSAL_SUCCESS: {
      const { claimId, claimProposalId } = action.payload;
      const newProposals = state[claimId].proposals.filter((id: string) => id !== claimProposalId);
      return {
        ...state,
        [claimId]: {
          ...state[claimId],
          proposals: newProposals,
        },
      };
    }
    case DELETE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS: {
      const { claimResponseDocumentId, claimId } = action.payload;
      const newClaimResponseDocuments = state[claimId].responseDocuments.filter((id: string) => id !== claimResponseDocumentId);
      return {
        ...state,
        [claimId]: {
          ...state[claimId],
          responseDocuments: newClaimResponseDocuments,
        },
      };
    }
  }
  return state;
};
