import React from 'react';
import Card from '../../Card';
import { getBreakpoint } from '../../../base/style';
import styled from 'styled-components';
import { P2, Header3, Caption, Header5, Header4, P1 } from '../../Typography';
import { getAddressFirstLine } from '../../../utils/common.utils';
import CalendarIcon from '../../../assets/svg/calendar-purple.svg';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCustomerId } from '../../../redux/account/account.selectors';
import { OrderWithOrderCustomersDTOStatusIdEnum, ClaimDTOStatusEnum } from '@reposit/api-client';
import classnames from 'classnames';
import { getOrderCustomerById } from '../../../redux/order/order.selectors';
import { AppState } from '../../../redux/root.reducer';
import { OrderCustomerEntity, DocumentEntity, TenantEntity } from '../../../redux/entities/entities.types';
import { penceToPounds } from '../../../utils/currency';
import Button from '../../Button/index';
import CardMessage from '../../CardMessage/index';
import DocumentList from '../../DocumentList';
import { get } from 'lodash';
import { isTenancyOrderExpired } from '../../../redux/utils/is-tenancy-order-expired';

interface RepositSummaryProps {
  reposit: any;
}

const List = styled.ul`
  margin: 12px 0 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
  }
`;

const Tenant = styled.li`
  margin: 0;
  margin-right: 33px;

  p {
    margin: 0;
  }
`;

const TenantLabel = styled(Header4)`
  align-items: center;
  display: flex;
  margin: 10px 42px 5px 0;
  padding: 0;
`;

const ChargesLabel = styled(Header4)`
  align-items: center;
  display: flex;
  margin: 10px 42px 5px 0;
  padding: 0;
`;

const SummaryContainer = styled.div`
  padding: 0 5px 10px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 5px 20px 40px;
  }
`;

const ColLeft = styled.div`
  padding: 24px 36px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 24px 0 0;
  }
`;

const ColRight = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    max-width: 280px;
  }
`;

const NoAddress = styled(P2)`
  font-style: italic;
`;

const Address = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

const RepositStatus = styled.p`
  border-radius: 8px;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: center;
  color: #fff;
  padding: 7px 20px 9px;

  &.complete {
    background: ${(props) => props.theme.colors.positive};
  }

  &.pending {
    background: ${(props) => props.theme.colors.warning};
  }

  &.checkedOut {
    background: ${(props) => props.theme.colors.disabled};
  }
`;

const PPM = styled(Header3)`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;

  span {
    color: ${(props) => props.theme.colors.placeholder};
    font-family: ${(props) => props.theme.typography.face.secondary};
    font-size: 20px;
    font-weight: normal;
    margin: 0 8px;
  }
`;

const TenancyDates = styled.div`
  margin: 24px 0 20px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    margin: 0 0 0 24px;
  }
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${CalendarIcon}) no-repeat center center;
    background-size: 16px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

const Dates = styled(P2)`
  color: ${(props) => props.theme.colors.body2};
  margin: 6px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 1px 0 0;
    padding: 0;
  }
`;

const Meta = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const RepositSummaryWrapper = styled.div<{ isExpired: boolean; isActionable: boolean }>`
  opacity: ${(props) => (props.isExpired ? 0.4 : 1)};
  cursor: ${(props) => (props.isActionable ? 'pointer' : 'none')};
  pointer-events: ${(props) => (props.isActionable ? 'default' : 'none')};
`;

const Italics = styled.span`
  font-style: italic;
`;

export const useRepositStatus = (reposit: any, customerId: string): [JSX.Element, boolean] => {
  let StatusComponent: JSX.Element;
  let isActionRequired: boolean;
  const { order, tenancy } = reposit;
  const isExpired = isTenancyOrderExpired(reposit);
  const { statusId } = order;
  const repositStatusClass = classnames({
    complete: statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE && !isExpired,
    pending: statusId !== OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE,
    checkedOut: statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE && isExpired,
  });

  const currentOrderCustomer = reposit.order.orderCustomers.find(
    (orderCustomer: OrderCustomerEntity) => orderCustomer.customer.id === customerId
  );

  const orderCustomer = useSelector((state: AppState) => getOrderCustomerById(state, currentOrderCustomer.id));

  if (statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE) {
    if (isExpired) {
      StatusComponent = <RepositStatus className={repositStatusClass}>This Reposit is closed</RepositStatus>;
      isActionRequired = false;
    } else {
      StatusComponent = <RepositStatus className={repositStatusClass}>This Reposit is Active</RepositStatus>;
      isActionRequired = false;
    }
  } else {
    if (tenancy.tenants.length > 1) {
      StatusComponent = (
        <RepositStatus className={repositStatusClass}>
          {orderCustomer.nextAction ? 'You have outstanding actions' : 'Awaiting other tenants'}
        </RepositStatus>
      );
    } else {
      StatusComponent = (
        <RepositStatus className={repositStatusClass}>
          {orderCustomer.nextAction ? 'You have outstanding actions' : 'Processing...'}
        </RepositStatus>
      );
    }
    isActionRequired = !!orderCustomer.nextAction;
  }

  return [StatusComponent, isActionRequired];
};

const filterDocuments = (documents: DocumentEntity[]) => {
  const permittedDocumentTypes = ['SIGNED_ADDENDUM', 'SIGNED_TENANT_TERMS_AND_CONDITIONS'];
  return documents.filter((document: DocumentEntity) => permittedDocumentTypes.includes(document.typeId));
};

const RepositSummary: React.FC<RepositSummaryProps> = ({ reposit }) => {
  const { order, tenancy } = reposit;
  const { isNewTenancy, documents, tenants } = tenancy;

  const claim = get(tenancy, 'checkout.claim', null);
  let settledCharges;
  if (claim) {
    settledCharges = claim.arbitration ? claim.arbitration.arbitratedAmount : claim.finalAmount;
  }

  const address = tenancy.property.address;
  const customerId = useSelector(getCurrentCustomerId);
  const [StatusComponent, isActionRequired] = useRepositStatus(reposit, customerId);
  const startDate = tenancy.startDate ? moment(tenancy.startDate).format('Do MMM YYYY') : undefined;
  const endDate = moment(tenancy.endDate).format('Do MMM YYYY');
  const filteredDocuments = filterDocuments(documents);
  const orderIsComplete = order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE;
  const isClaimResolved = claim && claim.status === ClaimDTOStatusEnum.RESOLVED;
  const isExpired = isTenancyOrderExpired(reposit);

  const allTenantsHaveNames = tenants.every((tenant: TenantEntity) => !!tenant.firstName && !!tenant.lastName);

  return (
    <RepositSummaryWrapper isExpired={isExpired} isActionable={isActionRequired && !reposit.tenancy.completedAt}>
      <Card
        hover
        flush
        style={{ padding: '0 0 1px' }}
        flashMessage={
          isActionRequired && !reposit.tenancy.completedAt ? (
            <CardMessage
              title="Please press continue to complete your Reposit"
              type="primary"
              cta={
                <NavLink to={`checkout/${order.id}/${customerId}`} style={{ textDecoration: 'none' }}>
                  <Button buttonType="white">Continue</Button>
                </NavLink>
              }
            />
          ) : undefined
        }
      >
        <NavLink to={`checkout/${order.id}/${customerId}`} style={{ textDecoration: 'none' }}>
          <SummaryContainer>
            <ColRight>{StatusComponent}</ColRight>
            <ColLeft>
              {address ? (
                <Address>
                  <FirstLineAddress>{getAddressFirstLine(address)}</FirstLineAddress>
                  <TownPostcode>{address.postcode}</TownPostcode>
                </Address>
              ) : (
                <NoAddress>There isn't a property associated with this Reposit</NoAddress>
              )}
              <Meta>
                <PPM>
                  £{penceToPounds(tenancy.PPM)}
                  <span>pcm</span>
                </PPM>
                {startDate && endDate ? (
                  <TenancyDates>
                    <Label>{isNewTenancy ? 'Tenancy dates' : 'Reposit term'}</Label>
                    <Dates>
                      {startDate} - {endDate}
                    </Dates>
                  </TenancyDates>
                ) : null}
              </Meta>
              <TenantLabel>Tenants</TenantLabel>
              <List>
                {tenants &&
                  tenants.map((tenant: TenantEntity) => (
                    <Tenant key={tenant.id}>
                      <P2>{allTenantsHaveNames ? `${tenant.firstName} ${tenant.lastName}` : `${tenant.email}`}</P2>
                    </Tenant>
                  ))}
              </List>
              {isExpired && claim && isClaimResolved ? (
                <>
                  <ChargesLabel>Total charges:</ChargesLabel>
                  <P1>
                    £{penceToPounds(settledCharges)} <Italics>(settled)</Italics>
                  </P1>
                </>
              ) : null}
            </ColLeft>
          </SummaryContainer>
        </NavLink>
        {filteredDocuments.length && orderIsComplete ? <DocumentList rounded documents={filterDocuments(documents)} /> : <></>}
      </Card>
    </RepositSummaryWrapper>
  );
};

export default RepositSummary;
