import { denormalize } from 'normalizr';

import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { ClaimEntity } from '../entities/entities.types';
import { ClaimDocumentDTO } from '@reposit/api-client';

export const getClaimById = (state: AppState, claimId: string) => {
  return denormalize(state.entities.claim[claimId], SCHEMA.claim, state.entities);
};

export const getActiveClaimResponseConfirmModal = (state: AppState) => state.claim.activeModal;

export const getClaimResponseDocs = (state: AppState) => {
  return state.claim.claimResponseDocuments.map((claimResponseDoc) => state.entities.claimResponseDocument[claimResponseDoc]);
};

export const getIsClaimResponseUpdateModalShowing = (state: AppState) => state.claim.isClaimResponseUpdateModalShowing;

export const getArbitration = (arbitrationId: string) => (state: AppState) => {
  return denormalize(state.entities.arbitration[arbitrationId], SCHEMA.arbitration, state.entities);
};

export const getArbitrationByRepaymentOrderId = (orderId: string) => (state: AppState) => {
  const arbs = Object.values(state.entities.arbitration);
  // this was broken?
  const arbitration = arbs.find((arb: any) => {
    if (arb.repaymentOrderId === orderId) {
      return arb;
    }
    return undefined;
  });
  return arbitration;
};

export const getOrderedClaimDocuments = (state: AppState, claimId: string) => {
  const claim: ClaimEntity = getClaimById(state, claimId);

  const getDocumentOrder = (type: string): number => {
    switch (type) {
      case 'INVENTORY_CHECK_OUT':
        return 1;
      case 'MID_TERM_INSPECTION':
        return 2;
      case 'INVENTORY_CHECK_IN':
        return 3;
      case 'SIGNED_TENANCY_AGREEMENT':
        return 4;
      case 'SUPPORTING_EVIDENCE':
        return 5;
      default:
        return 6;
    }
  };

  const sortFunction = (a: ClaimDocumentDTO, b: ClaimDocumentDTO) => {
    return getDocumentOrder(a.document.typeId) - getDocumentOrder(b.document.typeId);
  };
  if (!claim || !claim.documents) return [];
  return claim.documents.sort(sortFunction);
};
