import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PaymentPlanActionDTO,
  PaymentPlanActionsApi,
  PaymentPlansApi,
  SetupIntentSecretDTO,
  UpdateUserAddressHistoryDTO,
} from '@reposit/api-client/dist';
import { PaymentMethod, Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { FlashState } from '../../components/FlashMessage';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { PaymentPlanActionEntity } from '../entities/entities.types';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { fetchPaymentPlanThunk } from '../payment-plan/payment-plan.thunk';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { createStandardPaymentPlanActionsApi, createStandardPaymentPlansApi, runThunkWithAuth } from '../utils/api.utils';
import {
  paymentPlanActionsCardDetailsSuccess,
  PAYMENT_PLAN_ACTIONS_ADDRESS_STORE_KEY,
  PAYMENT_PLAN_ACTIONS_CONFIRM_STORE_KEY,
  PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY,
} from './payment-plan-actions.actions';

interface FetchPaymentPlanActionPayload {
  id: string;
  paymentPlanId: string;
}

interface UpdateAddressPayload {
  apiPayload: UpdateUserAddressHistoryDTO;
  paymentPlanId: string;
}

interface UpdateCardDetailsPayload {
  paymentPlanId: string;
  stripe: Stripe;
  elements: StripeElements;
}

interface ConfirmPaymentPlanActionPayload {
  paymentPlanId: string;
}

export const fetchPaymentPlanActionThunk = createAsyncThunk<
  PaymentPlanActionEntity,
  FetchPaymentPlanActionPayload,
  { state: AppState }
>(`payment-plan-action/fetch`, async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
    const apiResponse: AxiosResponse<PaymentPlanActionDTO> = await runThunkWithAuth(
      () => api.getPaymentPlanAction(payload.id, payload.paymentPlanId),
      dispatch
    );
    const entity: PaymentPlanActionEntity = standardSyncEntitiesAndGetResults(
      apiResponse.data,
      SCHEMA.paymentPlanAction,
      dispatch
    );
    return entity;
  } catch (e) {
    throw e;
  }
});

export const updateAddressPaymentPlanActionThunk = createAsyncThunk<void, UpdateAddressPayload, { state: AppState }>(
  'payment-plan-action/address',
  async (payload, thunkAPI) => {
    const { paymentPlanId, apiPayload } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => api.updateAddress(paymentPlanId, apiPayload), dispatch);
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
    } catch (e) {
      dispatch(
        setFlashMessage({
          key: PAYMENT_PLAN_ACTIONS_ADDRESS_STORE_KEY,
          message: 'Something went wrong',
          state: FlashState.ERROR,
        })
      );
      throw e;
    }
  }
);

export const updateCardDetailsPaymentPlanActionThunk = createAsyncThunk<void, UpdateCardDetailsPayload, { state: AppState }>(
  'payment-plan-action/card-details',
  async (payload, thunkAPI) => {
    const { paymentPlanId, stripe, elements } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const {
        paymentMethod,
        error,
      }: {
        paymentMethod?: PaymentMethod | undefined;
        error?: StripeError | undefined;
      } = await stripe.createPaymentMethod({
        elements: elements as any,
      });

      if (!paymentMethod || error) {
        throw error;
      }
      const ppAPI: PaymentPlansApi = createStandardPaymentPlansApi(state);
      const { data }: AxiosResponse<SetupIntentSecretDTO> = await runThunkWithAuth(
        () => ppAPI.getSetupIntentSecret(paymentPlanId),
        dispatch
      );

      // takes them through 3d secure or any other checks
      const { error: setupIntentError, setupIntent } = await stripe.confirmCardSetup(data.clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (!setupIntent || setupIntentError) {
        throw setupIntentError;
      }
      const actionAPI: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => actionAPI.updateCardDetails(paymentPlanId, { paymentMethodId: paymentMethod.id }), dispatch);
      dispatch(paymentPlanActionsCardDetailsSuccess(paymentPlanId));
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
      dispatch(push(`/payment-plan/${paymentPlanId}/payment-plan-live`));
    } catch (e) {
      dispatch(
        setFlashMessage({
          key: PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY,
          message: 'Something went wrong',
          state: FlashState.ERROR,
        })
      );
      throw e;
    }
  }
);

export const confirmPaymentPlanThunk = createAsyncThunk<void, ConfirmPaymentPlanActionPayload, { state: AppState }>(
  'payment-plan-action/confirm',
  async (payload, thunkAPI) => {
    const { paymentPlanId } = payload;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const api: PaymentPlanActionsApi = createStandardPaymentPlanActionsApi(state);
      await runThunkWithAuth(() => api.confirmPaymentPlan(paymentPlanId), dispatch);
      await dispatch(fetchPaymentPlanThunk({ id: paymentPlanId }));
    } catch (e) {
      dispatch(
        setFlashMessage({
          key: PAYMENT_PLAN_ACTIONS_CONFIRM_STORE_KEY,
          message: 'Something went wrong',
          state: FlashState.ERROR,
        })
      );
      throw e;
    }
  }
);
