import { get, map, flow, compact, first } from 'lodash';
import { AppState } from '../root.reducer';

const getFlashMessageByKey = (keys: string[], state: AppState) => {
  return map(keys, action => get(state, `flashMessages.${action}`));
};

export const createFlashMessagesSelector = (actions: string[]) => (state: AppState) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  const createSelector = flow(
    getFlashMessageByKey,
    compact,
    first
  );
  return createSelector(
    actions,
    state
  );
};
