import React from 'react';
import styled from 'styled-components';
import FormErrorMessage from '../ErrorMessage/index';

export const FieldLabel = styled.div`
  color: ${(props) => props.theme.colors.body};
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 0.875em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: 0 0 6px;

  span {
    font-size: 12px;
    font-weight: normal;
    margin: 0 8px;
  }
`;

const Field = styled.div`
  margin: 0 0 8px;
`;

interface FieldWithLabelProps {
  label?: string;
  style?: any;
  error?: string;
  touched?: boolean;
  description?: string;
}

const FieldWithLabel: React.SFC<FieldWithLabelProps> = ({ label, description, children, error, touched, style }) => {
  return (
    <Field style={style}>
      {label && (
        <FieldLabel>
          {label} <span>{description}</span>
        </FieldLabel>
      )}
      {children}
      {error && touched && <FormErrorMessage error={error} />}
    </Field>
  );
};

export default FieldWithLabel;
