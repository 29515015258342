import React from 'react';
import { Header4, P2 } from '../../../Typography/index';
import { ItemProposal } from '../ClaimItem/ClaimItem';
import { sortBy } from 'lodash';
import TenantsIcon from '../../../../assets/svg/tenants.svg';
import LandlordIcon from '../../../../assets/svg/person-active.svg';
import {
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTOStatusEnum,
  ClaimItemProposalDTOSettledSourceEnum,
} from '@reposit/api-client';
import styled from 'styled-components';
import { penceToPounds } from '../../../../utils/common.utils';
import moment from 'moment';
import { getBreakpoint } from '../../../../base/style';
import { isProposalPublished } from '../ClaimItem/ClaimItemDropdown';

export const AGENT_FIRST_ROUND = 1;
export const TENANT_ROUND = 2;
export const AGENT_FINAL_ROUND = 3;

interface HistoryProps {
  itemProposals: ItemProposal[];
}

interface HistoryItemProps {
  itemProposal: ItemProposal;
}

const HistoryItemContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px lightgrey;
  &:last-child {
    border-bottom: solid 1px white;
  }
`;

export const HistoryContainer = styled.div``;

const BoldText = styled.span`
  font-weight: bold;
`;

const ItalicsText = styled.span`
  font-style: italic;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: 6px;
  margin-right: 10px;
`;

const IconAndHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
    align-items: center;
  }
`;

const Timestamp = styled.span`
  font-weight: normal;
`;

const getIcon = (source: ClaimProposalDTOSourceEnum) => {
  if (source === ClaimProposalDTOSourceEnum.SUPPLIER) {
    return LandlordIcon;
  } else {
    return TenantsIcon;
  }
};

const HistoryItem: React.FC<HistoryItemProps> = ({ itemProposal }) => {
  const { settled: itemSettled, settledSource, claimProposal } = itemProposal;
  const proposalPublishedAt = claimProposal.publishedAt;
  const proposalSettled = claimProposal.status === ClaimProposalDTOStatusEnum.SETTLED;

  const tenantProposed = 'You proposed ';
  const tenantAcceptedCharge = `You accepted your landlord's charge of `;
  const landlordCreatedCharge = 'Your landlord created a charge of ';
  const landlordCreatedFinalProposal = 'Your landlord put forward a final proposal of ';
  const landlordAcceptedProposal = 'Your landlord accepted your proposal of ';

  const leadingText = (round: number) => {
    if (round === AGENT_FIRST_ROUND) {
      if (itemSettled) {
        return tenantAcceptedCharge;
      }
      return landlordCreatedCharge;
    } else if (round === TENANT_ROUND) {
      if (itemSettled) {
        if (settledSource === ClaimItemProposalDTOSettledSourceEnum.SUPPLIER) {
          return landlordAcceptedProposal;
        }
        return tenantAcceptedCharge;
      }
      return tenantProposed;
    } else if (round === AGENT_FINAL_ROUND) {
      if (itemSettled) {
        if (settledSource === ClaimItemProposalDTOSettledSourceEnum.SUPPLIER) {
          return landlordAcceptedProposal;
        } else if (proposalSettled) {
          return tenantAcceptedCharge;
        }
      }
      return landlordCreatedFinalProposal;
    }
  };

  return (
    <HistoryItemContainer>
      <IconAndHeaderContainer>
        <Icon src={getIcon(itemProposal.claimProposal.source)} />
        <Header4 style={{ marginBottom: '6px' }}>
          {leadingText(itemProposal.claimProposal.round)} £{penceToPounds(itemProposal.amount)}{' '}
          <Timestamp>({moment(proposalPublishedAt).fromNow()})</Timestamp>
        </Header4>
      </IconAndHeaderContainer>

      {itemProposal.explanation ? (
        <P2 style={{ margin: 0 }}>
          <BoldText>Reasoning:</BoldText> <ItalicsText>{itemProposal.explanation}</ItalicsText>
        </P2>
      ) : null}
    </HistoryItemContainer>
  );
};

const filterOutUnpublishedProposals = (ip: ItemProposal): boolean => {
  const proposal = ip.claimProposal;
  return isProposalPublished(proposal);
};

const isItemSettledBySupplierInTenantRound = (ip: ItemProposal) => {
  return (
    ip.settled && ip.settledSource === ClaimItemProposalDTOSettledSourceEnum.SUPPLIER && ip.claimProposal.round === TENANT_ROUND
  );
};

const isItemSettledByTenantInAgentRound = (ip: ItemProposal) => {
  return (
    ip.settled &&
    ip.settledSource === ClaimItemProposalDTOSettledSourceEnum.TENANT &&
    (ip.claimProposal.round === AGENT_FINAL_ROUND || ip.claimProposal.round === AGENT_FIRST_ROUND) &&
    ip.claimProposal.status === ClaimProposalDTOStatusEnum.SETTLED
  );
};

export const History: React.FC<HistoryProps> = ({ itemProposals }) => {
  const sortedItemProposals = sortBy(itemProposals, (ip) => ip.createdAt)
    .filter(filterOutUnpublishedProposals)
    .filter((ip, i) => {
      // filter out any previously settled items
      const prev = itemProposals[i - 1];
      if (!prev) return true;
      if (prev.settled) return false;
      return true;
    })
    .reverse();
  return (
    <HistoryContainer>
      {sortedItemProposals.map((sip) => {
        if (isItemSettledBySupplierInTenantRound(sip) || isItemSettledByTenantInAgentRound(sip)) {
          // split item proposal into two

          // the events of one party accepting and the
          // other party proposing are handled by the same item proposal
          return (
            <>
              {/* First item is the acceptance, second is the original proposal */}
              <HistoryItem itemProposal={{ ...sip, explanation: undefined }} />
              <HistoryItem
                itemProposal={{
                  ...sip,
                  settled: false,
                  settledSource: undefined,
                  updatedAt: sip.createdAt,
                }}
              />
            </>
          );
        } else {
          return <HistoryItem itemProposal={sip} />;
        }
      })}
    </HistoryContainer>
  );
};
