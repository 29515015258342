import styled from 'styled-components';
import React from 'react';

import Button from '../../Button/index';
import { P3 } from '../../Typography/index';
import LoadingIcon from '../../../assets/svg/loader.svg';
import UploadDarkIcon from '../../../assets/svg/upload-dark.svg';
import UploadLightIcon from '../../../assets/svg/upload-light.svg';
import { FileUploadProgress } from './FileUpload';

export const DropIcon = styled.i<{ icon: string }>`
  background: white url(${(props) => props.icon}) no-repeat center center;
  background-size: 52px;
  border-radius: 48px;
  display: block;
  height: 65px;
  margin: 0;
  width: 65px;
`;

export const StatusIcon = styled.i<{ icon?: string }>`
  background: ${(props) => (props.icon ? `${props.theme.colors.bgAccent} url(${props.icon}) no-repeat center center` : 'none')};
  background-size: 20px;
  border-radius: 48px;
  display: block;
  height: 20px;
  margin-left: -10px;
  width: 20px;
`;

export const UploadBox = styled.div<{ isActive: boolean; isUploading: boolean; big?: boolean; rounded?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  cursor: pointer;
  height: ${(props) => (props.big ? '150px' : '100px')};
  border-radius: ${(props) => (props.rounded ? '10px' : '0px')};
  border: ${(props) => (props.isActive ? `2px dashed ${props.theme.colors.primaryBrand}` : '1px solid white')};

  ${(props) =>
    !props.isUploading
      ? `  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translate(0, -2px);
  }`
      : ''}
`;

export const ShowDocuments = styled(P3)`
  color: ${(props) => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;
  margin-bottom: 0px;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

export const DocumentLink = styled.a`
  color: ${(props) => props.theme.colors.primaryBrand};
  display: block;
  font-family: ${(props) => props.theme.typography.face.secondary};
  text-decoration: underline;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const ShowDocumentsContainer = styled.div`
  display: flex;
  margin: 10px 0 0;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const ProgressCentreContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;
  margin: 0 0 0 12px;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

export const Progress: React.FC<{ progress: FileUploadProgress }> = ({ progress: { current, total } }) => {
  return (
    <ProgressContainer>
      <ProgressCentreContent>
        <img style={{ marginBottom: -26, width: 40, height: 40 }} src={LoadingIcon} alt="Loading" />
      </ProgressCentreContent>
      <P3 style={{ marginBottom: 8, fontWeight: 'bold' }}>
        Uploading {current} of {total} files
      </P3>
    </ProgressContainer>
  );
};

export const Drop: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  return (
    <ProgressContainer>
      <ProgressCentreContent>
        <DropIcon style={{ marginBottom: -16 }} icon={isActive ? UploadDarkIcon : UploadLightIcon} />
      </ProgressCentreContent>
      <P3 style={{ marginBottom: 8 }}>Drop files here or click to select</P3>
    </ProgressContainer>
  );
};
