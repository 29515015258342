import React from 'react';
import styled from 'styled-components';
import RepositSummary from '../RepositSummary';
import { TenancyOrderEntity } from '../../../redux/entities/entities.types';

interface RepositListProps {
  reposits: TenancyOrderEntity[];
}

const List = styled.div`
  padding: 0;
`;

const RepositList: React.FC<RepositListProps> = ({ reposits }) => {
  return (
    <List>
      {reposits.map(reposit => (
        <RepositSummary key={reposit.id} reposit={reposit} />
      ))}
    </List>
  );
};

export default RepositList;
