import React from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../redux/auth/auth.selectors';
import ErrorBoundary from '../../utils/ErrorBoundary';

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const token = useSelector(getAccessToken);

  // stops flash on logout
  if (!component || !token) {
    return <Redirect to="/auth/login" />;
  }
  const Component = component; // JSX Elements have to be uppercase.
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  return <Route {...rest} render={render} />;
};
