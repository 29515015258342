import { TenancyDTO } from '@reposit/api-client';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Location } from 'history';
import { get } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import FlashMessage from '../../components/FlashMessage/index';
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle/index';
import ProductSummary from '../../components/ProductSummary/index';
import RepositCard from '../../components/RepositCard/index';
import { Caption, P1 } from '../../components/Typography';
import { REPOSIT_PAYMENT_WARNING } from '../../constants/reposit';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  getPaymentIntentRequested,
  GET_PAYMENT_INTENT_STORE_KEY,
  payRequested,
  PAY_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getIsPollingForPaymentCompletion,
  getPaymentIntentSecret,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import {
  fetchOrderCustomerRequested,
  fetchTenancyAnniversariesRequested,
  FETCH_ORDER_CUSTOMER_STORE_KEY,
  FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
} from '../../redux/order/order.actions';
import { getCurrentOrderCustomer, getTenancyFromTenancyAnniversaryOrder } from '../../redux/order/order.selectors';
import { AppState } from '../../redux/root.reducer';
import { getCurrentTenancyUser } from '../../redux/tenancy-user/tenancy-user.selector';
import { getAddressFirstLine, getAddressTownPostcode } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage/index';
import PaymentContainer from '../Payment';

interface TopUpFeePaymentProps {
  location: Location<any>;
  match: any;
}

const WarningMessage = () => {
  return <div style={{ marginTop: '1em' }}>{REPOSIT_PAYMENT_WARNING}</div>;
};

const StyledFlashMessageContainer = styled.div`
  margin: 0 0 24px 0;
`;

const LargeContainer = styled.div`
  flex: 0 1 100%;
`;

const ProductDescription = styled(P1)`
  margin: 0;
  padding: 10px;

  span {
    font-weight: 700;
  }
`;

const PageSubtitle = styled(Caption)`
  font-size: 18px;
  margin: 24px 0 12px 0;
  line-height: 1.33;
  span {
    font-weight: 700;
  }
`;

const SummaryData = styled.div`
  padding: 0 40px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
  }
`;

const TopUpFeePayment: React.FC<TopUpFeePaymentProps> = ({ match }) => {
  const { orderId } = match.params;
  const dispatch = useDispatch();
  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentIntentSecret = useSelector(getPaymentIntentSecret);
  const getPayLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPaymentLoading = useSelector(getPayLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const amount = get(currentOrderCustomer, 'fee');
  const pageLoadingSelector = createLoadingSelector([
    FETCH_ORDER_CUSTOMER_STORE_KEY,
    GET_PAYMENT_INTENT_STORE_KEY,
    FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
  ]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  useEffect(() => {
    if (orderId && currentCustomerId) {
      dispatch(fetchTenancyAnniversariesRequested());
      dispatch(getPaymentIntentRequested(currentCustomerId, orderId));
      dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
    }
  }, [dispatch, currentCustomerId, orderId]);

  const tenancy: TenancyDTO = useSelector((state: AppState) => getTenancyFromTenancyAnniversaryOrder(state, orderId));
  const currentTenancyUser = useSelector(getCurrentTenancyUser);

  const submitPayment = (stripe: Stripe, elements: StripeElements) =>
    dispatch(payRequested({ stripe, paymentIntentSecret, type: PaymentType.TOP_UP, elements }));
  const [flashMessage, dismissFlashMessage] = useFlashMessage([
    FETCH_ORDER_CUSTOMER_STORE_KEY,
    GET_PAYMENT_INTENT_STORE_KEY,
    FETCH_TENANCY_ANNIVERSARIES_STORE_KEY,
  ]);

  const [flashMessagePay, dismissFlashMessagePay] = useFlashMessage([PAY_STORE_KEY]);
  const isLoading = isPageLoading || isPolling || isPaymentLoading;

  const render = () => {
    if (!tenancy) {
      return <Loading />;
    }

    return (
      <Fragment>
        <Container>
          <Row>
            <Col sm={12}>
              {flashMessage ? (
                <StyledFlashMessageContainer>
                  <FlashMessage
                    onDismiss={dismissFlashMessage}
                    timeRemaining={FLASH_MESSAGE_TIMOUT}
                    noMargin={true}
                    payload={flashMessage}
                  />
                </StyledFlashMessageContainer>
              ) : undefined}
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <ProductSummary
                name="Annual fee"
                tenancyUser={currentTenancyUser}
                description={<ProductDescription>The annual fee is equal to £30 per year.</ProductDescription>}
                tooltip="You need to pay an annual fee if you are staying in the property after the first year of your tenancy"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={10} push={{ lg: 1 }}>
              <PageTitle>Annual fee</PageTitle>
              <PageSubtitle>
                We hope you've enjoyed your tenancy so far. As per the Reposit terms and conditions, given you are remaining in
                the property, your annual fee is now due. Please <span>pay now</span> using the form below.
              </PageSubtitle>
              <PageSubtitle>If you are no longer living in this property, please contact your agent or landlord.</PageSubtitle>
            </Col>
          </Row>
          <Row>
            <Col lg={10} push={{ lg: 1 }}>
              <RepositCard flush title="Property Details">
                <Fragment>
                  <SummaryData>
                    <LargeContainer>
                      <P1>
                        {getAddressFirstLine(tenancy.property.address)}, {getAddressTownPostcode(tenancy.property.address)}
                      </P1>
                    </LargeContainer>
                  </SummaryData>
                </Fragment>
              </RepositCard>
            </Col>
          </Row>

          <Row>
            <Col lg={10} push={{ lg: 1 }}>
              {flashMessagePay ? (
                <FlashMessage
                  onDismiss={dismissFlashMessagePay}
                  noMargin={true}
                  timeRemaining={FLASH_MESSAGE_TIMOUT}
                  payload={flashMessagePay}
                />
              ) : undefined}
            </Col>
          </Row>
        </Container>
        {amount ? (
          <PaymentContainer
            fullWidth={false}
            isSubmitting={isLoading}
            amount={`${amount}`}
            submitCard={submitPayment}
            type={PaymentType.TOP_UP}
            warningMessage={WarningMessage}
            title="Your share of the Annual fee is"
            paymentMode={'payment'}
          />
        ) : (
          <Loading />
        )}
      </Fragment>
    );
  };

  return render();
};

export default TopUpFeePayment;
