import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ImageIcon from '../../assets/svg/image.svg';
import { getClaimResponseDocsRequested, GET_CLAIM_RESPONSE_DOCS_STORE_KEY } from '../../redux/claim/claim.actions';
import { getClaimResponseDocs } from '../../redux/claim/claim.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { AppState } from '../../redux/root.reducer';
import { getClaimById } from '../../redux/selectors/claim.selectors';
import { getDocumentById } from '../../redux/selectors/document.selectors';
import Loading from '../Loading/index';
import RepositCard from '../RepositCard/index';
import SecondaryPanel from '../SecondaryPanel/index';
import { P2 } from '../Typography/index';

const Inner = styled.div`
  padding: 0 36px;
`;

interface ClaimDocumentsProps {
  documentIds: string[];
}

interface ClaimDocumentProps {
  documentId: string;
}

const DocumentRow = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 16px;

  &:before {
    background: transparent url(${ImageIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
  }
`;

const Link = styled(P2)`
  a {
    color: inherit;
  }
`;

const ClaimDocument: React.FC<ClaimDocumentProps> = ({ documentId }) => {
  const document = useSelector((state: AppState) => getDocumentById(state, documentId));
  return document ? (
    <DocumentRow key={document.id}>
      <Link style={{ display: 'inline' }}>
        <a href={document.url} target="_blank" rel="noopener noreferrer">
          {document.name}
        </a>
      </Link>
    </DocumentRow>
  ) : null;
};

const ClaimDocuments: React.FC<ClaimDocumentsProps> = ({ documentIds }) => {
  const docs = documentIds.map((id) => <ClaimDocument documentId={id} />);
  return <Fragment>{docs}</Fragment>;
};

interface ClaimAppealProps {
  claimId: string;
}

const ClaimAppeal: React.FC<ClaimAppealProps> = ({ claimId }) => {
  const dispatch = useDispatch();

  const claim = useSelector((state: AppState) => getClaimById(state, claimId));
  const disputeMessage = claim ? claim.tenantDisputeMessage : '';

  const claimDocs = useSelector(getClaimResponseDocs);
  const documentIds = claimDocs.map((crd) => crd.document);
  const getClaimResponseDocsRequestedLoadingSelector = createLoadingSelector([GET_CLAIM_RESPONSE_DOCS_STORE_KEY]);
  const isGetDocsLoading = useSelector(getClaimResponseDocsRequestedLoadingSelector);

  useEffect(() => {
    dispatch(getClaimResponseDocsRequested(claimId));
  }, [dispatch, claimId]);

  return (
    <RepositCard title="You have raised a formal dispute for these charges">
      <Fragment>
        <Inner>
          <P2 bold>What are your reasons for not accepting these charges?</P2>
          <P2>{disputeMessage}</P2>
        </Inner>
        <SecondaryPanel>
          {claimDocs && claimDocs.length ? (
            <Fragment>
              <P2 bold>The evidence you have provided to support this</P2>
              <P2>You have uploaded the following documents:</P2>
              {isGetDocsLoading ? <Loading /> : <ClaimDocuments documentIds={documentIds} />}
            </Fragment>
          ) : (
            <P2>You haven't provided any evidence for these charges</P2>
          )}
        </SecondaryPanel>
      </Fragment>
    </RepositCard>
  );
};

export default ClaimAppeal;
