import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { P1 } from '../../components/Typography/index';

const Action = styled.div`
  padding: 16px 0 0;
  text-align: right;

  a {
    text-decoration: none;
  }
`;

const PaymentPlanLive: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Your Payment Plan is Live!</PageTitle>
          <Card>
            <P1 bold>What happens next?</P1>
            <P1>
              Your instalments will be automatically charged to your card each month, so there's nothing else you need to do for
              now.
            </P1>
            <P1>You will receive an email notification one week and one day before each instalment is due to be charged.</P1>
          </Card>
          <Action>
            <NavLink to={`/`}>
              <Button>Take me to my payment plan</Button>
            </NavLink>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPlanLive;
