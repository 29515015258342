import { all } from 'redux-saga/effects';
import * as AccountSagas from './account/account.saga';
import * as AddressHistorySagas from './address-history/address-history.saga';
import * as ClaimSagas from './claim/claim.saga';
import * as OrderCustomerActionSagas from './order-customer-actions/order-customer-actions.saga';
import * as OrderSagas from './order/order.saga';
import * as ReferenceSagas from './referencing/referencing.saga';
import * as UserSagas from './user/user.saga';
import * as TenancyUserSagas from './tenancy-user/tenancy-user.saga';
import * as ArbitrationSagas from './arbitration/arbitration.saga';
import * as AuthSagas from './auth/auth.saga';
import * as PaymentPlanInstalmentSagas from './payment-plan-instalment/payment-plan-instalment.saga';
import * as RepositOfferSagas from './reposit-offer/reposit-offer.saga';

export default function* rootSaga() {
  yield all([
    AccountSagas.watchAccountSagas(),
    ClaimSagas.watchClaimSagas(),
    UserSagas.watchUserSagas(),
    AddressHistorySagas.watchAddressHistorySagas(),
    OrderSagas.watchOrderSagas(),
    OrderCustomerActionSagas.watchOrderCustomerActionSagas(),
    ReferenceSagas.watchReferencingSagas(),
    TenancyUserSagas.watchTenancyUserSagas(),
    ArbitrationSagas.watchArbitrationSagas(),
    AuthSagas.watchAuthSagas(),
    PaymentPlanInstalmentSagas.watchPaymentPlanInstalmentSagas(),
    RepositOfferSagas.watchRepositOfferSagas(),
  ]);
}
