import { AppState } from '../root.reducer';
import { CheckEntity } from '../entities/entities.types';
import { CheckDTOTypeEnum, CheckDTOStatusEnum, CreateDocumentDTOTypeEnum } from '@reposit/api-client';
import moment from 'moment';

export const getIsReferencePolling = (state: AppState) => state.referencing.isPolling;
export const getReference = (state: AppState) => state.entities.reference[state.referencing.reference];

export const getLatestIdentityCheck = (state: AppState): CheckEntity | null => {
  let result: CheckEntity[] = [];
  const checkIds = state.referencing.checks;

  result = checkIds.map((checkId: string) => state.entities.check[checkId]);

  result = result.filter(check => check.type === CheckDTOTypeEnum.IDENTITY);
  result = result.filter(check => check.status === CheckDTOStatusEnum.INFOREQUIRED);
  result = result.sort((a: CheckEntity, b: CheckEntity) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)));

  return result[0] || null;
};

export const getReferenceDocumentsByType = (state: AppState, type: string) => {
  if (type === CreateDocumentDTOTypeEnum.PROOFOFIDENTITY) return state.referencing.documents.identity;
  if (type === CreateDocumentDTOTypeEnum.PROOFOFADDRESS) return state.referencing.documents.proofOfAddress;
  return [];
};
