import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch } from '../..';
import { getBreakpoint } from '../../base/style';
import Button from '../../components/Button';
import { FullPageLoader } from '../../components/Loading';
import { Header2, Header4, P1 } from '../../components/Typography';
import { confirmPaymentPlanThunk } from '../../redux/payment-plan-actions/payment-plan-actions.thunk';
import { getPaymentPlanById, getPaymentPlanPropertyAddress } from '../../redux/payment-plan/payment-plan.selectors';
import { AppState } from '../../redux/root.reducer';
import { getAddressFirstLine } from '../../utils/common.utils';
import Summary from './components/Summary';
import { useFlashMessage, FLASH_MESSAGE_TIMOUT } from '../FlashMessage';
import { PAYMENT_PLAN_ACTIONS_CONFIRM_STORE_KEY } from '../../redux/payment-plan-actions/payment-plan-actions.actions';
import FlashMessage from '../../components/FlashMessage';

interface ConfirmProps {
  match: any;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    justify-content: center;
  }
`;

const ConfirmPaymentPlan: React.FC<ConfirmProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { paymentPlanId } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const paymentPlan = useSelector((state: AppState) => getPaymentPlanById(state, paymentPlanId));
  const propertyAddress = useSelector((state: AppState) => getPaymentPlanPropertyAddress(state));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([PAYMENT_PLAN_ACTIONS_CONFIRM_STORE_KEY]);
  if (!paymentPlan || !propertyAddress) return <FullPageLoader />;

  const confirmPaymentPlan = async () => {
    try {
      setIsLoading(true);
      await dispatch(confirmPaymentPlanThunk({ paymentPlanId }));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  };
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Header2>Proposed Payment Summary</Header2>
        </Col>
      </Row>

      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Header4>{getAddressFirstLine(propertyAddress)}</Header4>
          <P1>{propertyAddress.postcode}</P1>
        </Col>
      </Row>
      {flashMessagePayload ? (
        <Row>
          <Col>
            <FlashMessage payload={flashMessagePayload} onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Summary paymentPlan={paymentPlan} />
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <ButtonContainer>
            <Button disabled={isLoading} onClick={confirmPaymentPlan}>
              Confirm
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmPaymentPlan;
