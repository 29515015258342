import React from 'react';
import styled from 'styled-components';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../../components/FormFields/index';
import { StyledLink } from '../../../../components/Typography/index';
import Button from '../../../../components/Button/index';
import { ExternalAddressDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { UK_POSTCODE } from '../../../../constants/address.regex';
import { useDispatch, useSelector } from 'react-redux';
import { addAddressRequested } from '../../../../redux/address-history/address-history.actions';
import { getCurrentUser } from '../../../../redux/selectors/user.selectors';
import Select from '../../../../components/FormFields/Select/index';
import countries from 'i18n-iso-countries';

interface ManualAddressFormProps {
  address?: ExternalAddressDTO | null;
  onFormClose: () => void;
}

const Wrapper = styled.div`
  padding: 0 36px 36px;
  max-width: 455px;
`;

const FormAction = styled.div`
  padding: 20px 36px 0;
  text-align: right;
`;

const CancelLink = styled(StyledLink)`
  margin: 0 20px 0 0;
`;

interface ManualAddressFormValues extends ExternalAddressDTO {
  startDate: Date | undefined;
  endDate: Date | undefined;
  currentlyAtAddress: boolean;
}

const Schema = Yup.object().shape(
  {
    buildingNumber: Yup.string().when('buildingName', {
      is: (buildingName) => !buildingName,
      then: Yup.string().required('Either a building number or name is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    buildingName: Yup.string().when('buildingNumber', {
      is: (buildingNumber) => !buildingNumber,
      then: Yup.string().required('Either a building number or name is required').nullable(),
      otherwise: Yup.string().nullable(),
    }),
    street: Yup.string().required('Required'),
    postcode: Yup.string().when('country', {
      is: (value: string) => value === 'GB',
      then: Yup.string().matches(UK_POSTCODE, 'Must be a UK postcode').required('Required'),
      otherwise: Yup.string().required('Required'),
    }),
    country: Yup.string().required('Required'),
  },
  [['buildingNumber', 'buildingName']]
);

const ManualAddressForm: React.FC<ManualAddressFormProps> = ({ address, onFormClose }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countryList = countries.getNames('en');

  return (
    <Formik
      initialValues={{
        buildingName: get(address, 'buildingName', ''),
        roomNumber: get(address, 'roomNumber', ''),
        flatNumber: get(address, 'flatNumber', ''),
        buildingNumber: get(address, 'buildingNumber', ''),
        street: get(address, 'street', ''),
        subStreet: get(address, 'subStreet', ''),
        town: get(address, 'town', ''),
        county: get(address, 'county', ''),
        postcode: get(address, 'postcode', ''),
        country: get(address, 'country', ''),
        externalId: get(address, 'externalId', ''),
        startDate: undefined,
        endDate: undefined,
        currentlyAtAddress: false,
        organizationId: '1',
      }}
      validationSchema={Schema}
      onSubmit={(data) => {
        dispatch(
          addAddressRequested(currentUser.id, {
            ...data,
            country: countries.toAlpha3(data.country),
          })
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: FormikProps<ManualAddressFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Wrapper>
              <FieldWithLabel label="Room Number / Letter" touched={touched.roomNumber} error={errors.roomNumber}>
                <Input
                  name="roomNumber"
                  value={values.roomNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.roomNumber}
                  error={errors.roomNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Flat Number" touched={touched.flatNumber} error={errors.flatNumber}>
                <Input
                  name="flatNumber"
                  value={values.flatNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.flatNumber}
                  error={errors.flatNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Building Number/Letter*" touched={touched.buildingNumber} error={errors.buildingNumber}>
                <Input
                  name="buildingNumber"
                  value={values.buildingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.buildingNumber}
                  error={errors.buildingNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Building Name*" touched={touched.buildingName} error={errors.buildingName}>
                <Input
                  name="buildingName"
                  value={values.buildingName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.buildingName}
                  error={errors.buildingName}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Street*" touched={touched.street} error={errors.street}>
                <Input
                  name="street"
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.street}
                  error={errors.street}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Town" touched={touched.town} error={errors.town}>
                <Input
                  name="town"
                  value={values.town}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.town}
                  error={errors.town}
                />
              </FieldWithLabel>
              <FieldWithLabel label="County" touched={touched.county} error={errors.county}>
                <Input
                  name="county"
                  value={values.county}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.county}
                  error={errors.county}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Postcode*" touched={touched.postcode} error={errors.postcode}>
                <Input
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.postcode}
                  error={errors.postcode}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Country*" touched={touched.country} error={errors.country}>
                <Select
                  name="country"
                  value={countries.toAlpha2(values.country) || 0}
                  onChange={(value: any) => setFieldValue('country', value)}
                  onBlur={handleBlur}
                  defaultValue={0}
                >
                  <option value="">-</option>
                  {Object.keys(countryList).map((country) => (
                    <option key={country} value={country}>
                      {countryList[country]}
                    </option>
                  ))}
                </Select>
              </FieldWithLabel>
            </Wrapper>
            <FormAction>
              <CancelLink to="#" onClick={onFormClose}>
                Cancel
              </CancelLink>
              <Button buttonType="primary">Save Address</Button>
            </FormAction>
          </form>
        );
      }}
    </Formik>
  );
};

export default ManualAddressForm;
