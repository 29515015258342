import { get } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import FlashMessage from '../../../../components/FlashMessage/index';
import PageTitle from '../../../../components/PageTitle';
import RepositCard from '../../../../components/RepositCard';
import { P1 } from '../../../../components/Typography';
import { getCurrentUser } from '../../../../redux/selectors/user.selectors';
import { UPDATE_USER_STORE_KEY } from '../../../../redux/user/user.actions';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../../../FlashMessage/index';
import AddressHistoryContent from './AddressHistoryContent';
import {
  UPDATE_ADDRESS_HISTORY_STORE_KEY,
  updateAddressHistoryRequested,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import { AddressWithDatesEntity } from '../../../../redux/entities/entities.types';
import { ADD_ADDRESS_STORE_KEY } from '../../../../redux/address-history/address-history.actions';
import { getBreakpoint } from '../../../../base/style';

interface AddressHistoryProps {}

const Action = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    justify-content: flex-end;
    flex-direction: row;
    text-align: inherit;
  }

  p {
    margin: 0 20px 0 0;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 0px;
  }
`;

const AddressHistory: React.FC<AddressHistoryProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { order, customer } = useSelector(getCurrentOrderCustomer);
  const [flashMessage, dismissFlashMessage] = useFlashMessage([
    ADD_ADDRESS_STORE_KEY,
    UPDATE_ADDRESS_HISTORY_STORE_KEY,
    UPDATE_USER_STORE_KEY,
  ]);
  const addresses: AddressWithDatesEntity[] = get(currentUser, 'attributes.addresses', []);

  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle tooltip="Before you can purchase your Reposit we need to collect a bit of information about you. This information is used to conduct checks to determine wether you are eligible to use Reposit.">
            Tell us a bit about yourself
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <RepositCard
            title="What’s your current address?"
            flush
            flashMessage={
              flashMessage && (
                <FlashMessage payload={flashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} onDismiss={dismissFlashMessage} />
              )
            }
          >
            <AddressHistoryContent addresses={addresses} hasSufficientAddressHistory={true} />
          </RepositCard>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Action>
            <P1>You need to provide your current address to continue</P1>
            <ButtonWrapper>
              <Button
                disabled={addresses.length < 1}
                onClick={() => dispatch(updateAddressHistoryRequested(customer.id, order.id))}
              >
                Continue
              </Button>
            </ButtonWrapper>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressHistory;
