import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimProposalDTOStatusEnum, CreateClaimItemProposalDTO } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../..';
import {
  createClaimItemDocumentThunk,
  createDocumentThunk,
  deleteClaimItemDocumentThunk,
  CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
} from '../../../../redux/document/document.thunk';
import { ClaimItemProposalEntity, ClaimProposalEntity, DocumentEntity } from '../../../../redux/entities/entities.types';
import { AppState } from '../../../../redux/root.reducer';
import {
  AgentDocumentsAndProposals,
  getAllAgentDocumentsAndProposalsForClaimItem,
  getAllTenantDocumentsAndProposalsForClaimItem,
  TenantDocumentsAndProposal,
} from '../../../../redux/selectors/mediation.selectors';
import { Header4, P2 } from '../../../Typography';
import AuditTrail from '../AuditTrail/AuditTrail';
import { TENANT_ROUND } from '../AuditTrail/History';
import { Explanation } from '../Explanation';
import { ClaimItem, ClaimItemState, ClaimProposal, ItemProposal } from './ClaimItem';
import { setFlashMessage } from '../../../../redux/flash-messages/flash-messages.actions';
import { FlashState } from '../../../FlashMessage';

export const HEADER_HEIGHT = 72;
interface ClaimItemDropdownProps {
  tenantProposal?: ItemProposal;
  initialAgentProposal: ItemProposal;
  secondAgentProposal?: ItemProposal;
  claimItemState: ClaimItemState;
  canRespond: boolean;
  setClaimItemState: (claimItemState: ClaimItemState) => void;
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO, id: string) => Promise<ClaimItemProposalEntity>;
  claimItem: ClaimItem;
  claimId: string;
  acceptProposal: () => Promise<void>;
  panelId: string;
}

export const isProposalPublished = (proposal: ClaimProposalEntity | ClaimProposal | undefined) => {
  if (!proposal) return false;
  else if (proposal.round === TENANT_ROUND) {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT && proposal.status !== ClaimProposalDTOStatusEnum.AWAITINGPAYMENT;
  } else {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT;
  }
};

export const getAgentDocumentsToShow = (docsAndProposals: AgentDocumentsAndProposals | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  const agentFirstDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.firstProposal) ? docsAndProposals.firstDocuments : [];
  const agentSecondDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.secondProposal) ? docsAndProposals.secondDocuments : [];
  return [...agentFirstDocs, ...agentSecondDocs];
};
export const getTenantDocumentsToShow = (docsAndProposals: TenantDocumentsAndProposal | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  return docsAndProposals && isProposalPublished(docsAndProposals.proposal) ? docsAndProposals.documents : [];
};

export const ClaimItemDropdown: React.FC<ClaimItemDropdownProps> = ({
  tenantProposal,
  initialAgentProposal,
  secondAgentProposal,
  claimItemState,
  canRespond,
  setClaimItemState,
  createCounterProposal,
  updateCounterProposal,
  claimItem,
  claimId,
  acceptProposal,
  panelId,
}) => {
  useEffect(() => {
    if (claimItemState === ClaimItemState.REJECTED_TENANT_COUNTER) {
      const el = document.getElementById(panelId);
      if (el) {
        const y = el.getBoundingClientRect().top + window.pageYOffset + -HEADER_HEIGHT;
        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [claimItemState, panelId]);

  const dispatch = useAppDispatch();
  const proposalText =
    'Propose a settlement. Add the settlement amount below. Add evidence and a supporting explanation to increase the chances that your landlord or agent will accept.';

  const proposalTitle = 'Provide a new proposal';

  const showRejectForm =
    claimItemState === ClaimItemState.REJECTED_TENANT_COUNTER ||
    (claimItemState === ClaimItemState.ACCEPTED_TENANT_COUNTER && !!tenantProposal);
  const agentProposal = secondAgentProposal || initialAgentProposal;
  const draftCounterProposalId = get(tenantProposal, 'id', '');

  const agentDocumentsAndProposals = useSelector((state: AppState) =>
    getAllAgentDocumentsAndProposalsForClaimItem(state, claimItem.id)
  );
  const tenantDocumentsAndProposals = useSelector((state: AppState) =>
    getAllTenantDocumentsAndProposalsForClaimItem(state, claimItem.id)
  );

  const agentDocumentsToShow = getAgentDocumentsToShow(agentDocumentsAndProposals);
  const tenantDocumentsToShow = getTenantDocumentsToShow(tenantDocumentsAndProposals);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileUpload = (file: File) => {
    if (tenantProposal) {
      return dispatch(
        createClaimItemDocumentThunk({
          claimId,
          claimItemId: claimItem.id,
          claimItemProposalId: tenantProposal.id,
          file,
          type: 'CLAIM_ITEM',
          isClaimItem: true,
        })
      ).then(unwrapResult);
    } else {
      return dispatch(createDocumentThunk({ file, type: 'CLAIM_ITEM', isClaimItem: true })).then(unwrapResult);
    }
  };

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  return (
    <>
      {claimItemState === ClaimItemState.VIEW_EVIDENCE ? (
        <AuditTrail
          agentDocuments={agentDocumentsToShow}
          tenantDocuments={tenantDocumentsToShow}
          itemProposals={claimItem.itemProposals}
        />
      ) : null}

      {showRejectForm ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 24,
              paddingBottom: 10,
              borderBottom: '1px solid #ccc',
            }}
          >
            <Header4>{proposalTitle}</Header4>
            <P2>{proposalText}</P2>
            <Explanation
              showOffer
              required={false}
              uploadFile={handleFileUpload}
              onError={onFileError}
              showDeleteButton
              deleteFile={async (id: string) => {
                if (tenantProposal) {
                  return dispatch(
                    deleteClaimItemDocumentThunk({
                      claimId,
                      claimItemId: claimItem.id,
                      documentId: id,
                      claimItemProposalId: tenantProposal.id,
                    })
                  ).then(unwrapResult);
                }
              }}
              onSubmit={async (values) => {
                try {
                  setIsSubmitting(true);
                  const func = tenantProposal ? updateCounterProposal : createCounterProposal;
                  await func(
                    {
                      claimItemId: claimItem.id,
                      amount: values.amount as number,
                      explanation: values.explanation,
                      documentIds: values.documentIds,
                    },
                    draftCounterProposalId
                  );
                  setClaimItemState(ClaimItemState.INITIAL);
                  setIsSubmitting(false);
                } catch {
                  setIsSubmitting(false);
                }
              }}
              isSubmitting={isSubmitting}
              negotiationDocuments={get(tenantProposal, 'documents')}
              negotiationExplanation={get(tenantProposal, 'explanation')}
              negotiationAmount={get(tenantProposal, 'amount')}
              agentProposalAmount={get(agentProposal, 'amount')}
              panelId={panelId}
            />
          </div>
          <AuditTrail
            agentDocuments={agentDocumentsToShow}
            tenantDocuments={tenantDocumentsToShow}
            itemProposals={claimItem.itemProposals}
          />
        </>
      ) : null}
    </>
  );
};
