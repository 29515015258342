import React, { useEffect } from 'react';
import ReferenceStart from './components/ReferenceStart';
import ReferenceSuccess from './components/ReferenceSuccess';
import ReferenceLoading from './components/ReferenceLoading';
import ReferenceFailed from './components/ReferenceFailed';
import ReferenceProofRequired from './components/ReferenceProofRequired';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import { createReferenceRequested, pollForReferenceRequested } from '../../../../redux/referencing/referencing.actions';
import { getIsReferencePolling, getReference } from '../../../../redux/referencing/referencing.selectors';
import { ReferenceDTOStatusEnum, OrderCustomerActionDTO } from '@reposit/api-client';
import { fetchOrderCustomerRequested } from '../../../../redux/order/order.actions';
import { get, find } from 'lodash';
import ReferenceInReview from './components/ReferenceInReview';
import ReferenceGuarantorRequired from './components/ReferenceGuarantorRequired';

interface ReferencingProps {}

const getNextAction = (actions: OrderCustomerActionDTO[], nextActionId: string) =>
  find(actions, (action) => action.id === nextActionId);

const Referencing: React.FC<ReferencingProps> = () => {
  const dispatch = useDispatch();
  const { order, customer, actions, nextAction } = useSelector(getCurrentOrderCustomer);
  const isReferencePolling = useSelector(getIsReferencePolling);
  // issue with type thinking it clould be undefined
  const nextOrderCustomerAction = getNextAction(actions || [], nextAction ? nextAction.orderCustomerActionIds[0] : '');
  const existingReferenceId = get(nextOrderCustomerAction, 'details.referenceId');
  const reference = useSelector(getReference);
  let content;

  useEffect(() => {
    if (existingReferenceId && !reference) {
      dispatch(pollForReferenceRequested(existingReferenceId));
    }
  }, [dispatch, existingReferenceId, reference]);

  if (!reference) {
    if (isReferencePolling) return <ReferenceLoading />;
    return <ReferenceStart onStart={() => dispatch(createReferenceRequested(order.id, customer.id))} />;
  }

  switch (reference.status) {
    case ReferenceDTOStatusEnum.PASS:
      content = (
        <ReferenceSuccess
          onNext={() => {
            if (customer && order) {
              dispatch(fetchOrderCustomerRequested({ customerId: customer.id, orderId: order.id }));
            }
          }}
        />
      );
      break;

    case ReferenceDTOStatusEnum.FAIL:
      content = <ReferenceFailed />;
      break;

    case ReferenceDTOStatusEnum.INFOREQUIRED:
      content = <ReferenceProofRequired referenceId={reference.id} />;
      break;

    case ReferenceDTOStatusEnum.INREVIEW:
      content = <ReferenceInReview />;
      break;

    case ReferenceDTOStatusEnum.GUARANTORREQUIRED:
      content = <ReferenceGuarantorRequired />;
      break;

    default:
      content = <ReferenceLoading />;
  }

  return content;
};

export default Referencing;
