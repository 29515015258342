import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header2, P1 } from '../../components/Typography';

export const TenancyInventoryAlreadyResponded: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header2 style={{ fontSize: '1.7em' }}>We have already logged a response from you. </Header2>
          <P1 style={{ fontSize: '1.4em', marginTop: 24 }}>We'll be in touch if we need anything further from you.</P1>
        </Col>
      </Row>
    </Container>
  );
};
