import { ClaimDTOStatusEnum } from '@reposit/api-client/dist';
import moment from 'moment';

export const isTenancyOrderExpired = (tenancyOrder: any) => {
  const { tenancy } = tenancyOrder;
  const { outcomeDeadline, checkout } = tenancy;
  const { claim } = checkout || {};
  const outcomeDeadlineMoment = moment(outcomeDeadline).startOf('day');
  const isAfterOutcomeDeadline = outcomeDeadlineMoment.isBefore(moment().startOf('day'));
  const hasExpiredWithNoClaim = isAfterOutcomeDeadline && !claim;
  const isClaimInValidState =
    claim && [ClaimDTOStatusEnum.DEACTIVATED, ClaimDTOStatusEnum.DECLINED, ClaimDTOStatusEnum.RESOLVED].includes(claim.status);

  // after outcome deadline && no claim
  // OR
  // claim -> deactivated OR declined OR resolved
  const isExpired = hasExpiredWithNoClaim || isClaimInValidState;
  return !!isExpired;
};
