import { unwrapResult } from '@reduxjs/toolkit';
import { PaymentPlanActionDTO, PaymentPlanActionDTOTypeIdEnum, PaymentPlanDTOStatusEnum } from '@reposit/api-client';
import { Location } from 'history';
import { find, get } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import styled from 'styled-components';
import { useAppDispatch } from '../..';
import { FullPageLoader } from '../../components/Loading';
import PaymentPlanProgress from '../../components/PaymentPlanProgress';
import PaymentPlanSummary from '../../components/PaymentPlanProgress/PaymentPlanSummary';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { fetchOrderCustomerRequested } from '../../redux/order/order.actions';
import { getIsPaymentDue, getPaymentPlanById } from '../../redux/payment-plan/payment-plan.selectors';
import { fetchPaymentPlanThunk, fetchPaymentPlanPropertyAddressThunk } from '../../redux/payment-plan/payment-plan.thunk';
import { AppState } from '../../redux/root.reducer';
import { penceToPounds } from '../../utils/currency';
import { ProductDescription } from '../Checkout/Sections/Finish';
import Active from './Active';
import Address from './Address';
import PaymentDetails from './components/PaymentDetails';
import Confirm from './Confirm';
import ManualPayment from './ManualPayment';
import ManualPaymentSuccess from './ManualPaymentSuccess';
import PaymentPlanLive from './PaymentPlanLive';

const RouterContainer = styled.div<{ isPPPending: boolean }>`
  padding: ${(props) => (props.isPPPending ? '70px 0' : '0')};
`;

interface PaymentPlanProps {
  location: Location<any>;
  match: any;
}

interface RouteDefinition {
  path: string;
  exact: boolean;
  component: (props: any) => JSX.Element;
  action?: PaymentPlanActionDTOTypeIdEnum | null;
}

const routes: RouteDefinition[] = [
  {
    path: 'confirm',
    component: (props) => <Confirm {...props} />,
    exact: true,
    action: PaymentPlanActionDTOTypeIdEnum.CONFIRM,
  },
  {
    path: 'address',
    component: (props) => <Address {...props} />,
    exact: true,
    action: PaymentPlanActionDTOTypeIdEnum.ADDRESS,
  },
  {
    path: 'card-details',
    component: (props) => <PaymentDetails {...props} />,
    exact: true,
    action: PaymentPlanActionDTOTypeIdEnum.CARDDETAILS,
  },
  {
    path: 'active',
    component: (props) => <Active {...props} />,
    exact: true,
  },
  {
    path: 'manual-payment',
    component: (props) => <ManualPayment {...props} />,
    exact: true,
  },
  {
    path: 'payment-success',
    component: (props) => <ManualPaymentSuccess {...props} />,
    exact: true,
  },
  {
    path: 'payment-plan-live',
    component: (props) => <PaymentPlanLive {...props} />,
    exact: true,
  },
  {
    path: '*',
    component: (props) => <Redirect key={'not-found'} to={'address'} />,
    exact: false,
  },
];

const PaymentPlan: React.FC<PaymentPlanProps> = ({ match, location }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { paymentPlanId } = match.params;
  const paymentPlan = useSelector((state: AppState) => getPaymentPlanById(state, paymentPlanId));
  const paymentDue = useSelector((state: AppState) => getIsPaymentDue(state, paymentPlanId));
  const isPPPending = paymentPlan && paymentPlan.status === PaymentPlanDTOStatusEnum.PENDING;
  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentPlanOrderId = get(paymentPlan, 'orderCustomer.order.id');

  useEffect(() => {
    const fetchPaymentPlan = async () => {
      return dispatch(fetchPaymentPlanThunk({ id: paymentPlanId })).then(unwrapResult);
    };
    fetchPaymentPlan();
  }, [dispatch, paymentPlanId]);

  useEffect(() => {
    const fetchPaymentPlanPropertyAddress = async () => {
      return dispatch(fetchPaymentPlanPropertyAddressThunk({ paymentPlanId })).then(unwrapResult);
    };
    fetchPaymentPlanPropertyAddress();
  }, [dispatch, paymentPlanId]);

  useEffect(() => {
    if (currentCustomerId && paymentPlanOrderId) {
      dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId: paymentPlanOrderId }));
    }
  }, [dispatch, paymentPlanOrderId, currentCustomerId]);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'POP') {
        history.push('/');
      }
    });
  }, [history]);

  if (!paymentPlan) return <FullPageLoader />;

  const getNextAction = (actions: PaymentPlanActionDTO[], nextActionId: string) => {
    return find(actions, (action) => action.id === nextActionId);
  };

  const nextAction = getNextAction(
    paymentPlan.actions || [],
    paymentPlan.nextAction ? paymentPlan.nextAction.paymentPlanActionIds[0] : ''
  );

  const isPPActive = paymentPlan.status === PaymentPlanDTOStatusEnum.ACTIVE;
  const isPPCancelled = paymentPlan.status === PaymentPlanDTOStatusEnum.CANCELLED;

  // get the current route
  const routesCopy = [...routes];
  const catchAllRoute = routesCopy.pop();
  const currentRoute = routesCopy.find((route) => history.location.pathname.includes(`/${route.path}`)) || catchAllRoute;
  const currentRouteIsActive = currentRoute && currentRoute.path === 'active';
  const currentRouteIsManualPayment = currentRoute && currentRoute.path === 'manual-payment';
  const currentRouteIsPPLive = currentRoute && currentRoute.path === 'payment-plan-live';

  if (!currentRoute) return <Redirect key={'not-found'} to={'address'} />; // This should never happen

  if (isPPCancelled) return <Redirect key="dashboard" to={'/'} />;

  // if the user is not on the route corresponding to the next action, redirect them there.
  if (!isPPActive && nextAction && currentRoute.action !== nextAction.typeId) {
    const nextRoute = find(routes, (route) => route.action === nextAction.typeId);
    if (nextRoute) return <Redirect key={nextRoute.path} to={`${match.url}/${nextRoute.path}`} />;
    return <Redirect key={'not-found'} to={'address'} />;
  }

  if (paymentDue && currentRoute && currentRoute.path !== 'manual-payment')
    return <Redirect key={'pay'} to={`${match.url}/manual-payment`} />;

  if (!nextAction && !isPPActive) return <Redirect key={'dashboard'} to={'/'} />;

  if (isPPActive && !paymentDue && !currentRouteIsActive && !currentRouteIsManualPayment && !currentRouteIsPPLive) {
    return <Redirect key={'active'} to={`${match.url}/active`} />;
  }

  return (
    <Fragment>
      {isPPPending ? (
        <Container>
          <Row>
            <Col sm={12}>
              <PaymentPlanSummary
                name="Your Repayment Summary"
                description={
                  <ProductDescription>
                    Your outstanding balance of <span>£{penceToPounds(paymentPlan.amount)}</span> will be spread over{' '}
                    {paymentPlan.instalments?.length} instalments
                  </ProductDescription>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <PaymentPlanProgress currentUrl={location.pathname} paymentPlanActions={paymentPlan.actions || []} />
            </Col>
          </Row>
        </Container>
      ) : null}

      <RouterContainer isPPPending={isPPPending}>
        <Switch location={location}>
          {routes.map((route: RouteDefinition) => {
            return <Route key={route.path} path={`${match.path}/${route.path}`} exact component={route.component} />;
          })}
        </Switch>
      </RouterContainer>
    </Fragment>
  );
};

export default PaymentPlan;
