import { PollInstalmentPaymentSuccessPayload, InstalmentPayPayload } from './payment-plan-instalment.types';

export const FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_STORE_KEY = 'PAYMENT-PLAN-INSTALMENT/FETCH-INSTALMENT_PAYMENT_INTENT_SECRET';
export const FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_REQUESTED =
  'PAYMENT-PLAN-INSTALMENT/FETCH-INSTALMENT_PAYMENT_INTENT_SECRET_API_REQUESTED';
export const FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_SUCCESS =
  'PAYMENT-PLAN-INSTALMENT/FETCH-INSTALMENT_PAYMENT_INTENT_SECRET_API_SUCCESS';
export const FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_FAILED =
  'PAYMENT-PLAN-INSTALMENT/FETCH-INSTALMENT_PAYMENT_INTENT_SECRET_API_FAILED';

export const INSTALMENT_PAY_STORE_KEY = 'PAYMENT-PLAN-INSTALMENT/PAY';
export const INSTALMENT_PAY_API_REQUESTED = 'PAYMENT-PLAN-INSTALMENT/PAY_API_REQUESTED';
export const INSTALMENT_PAY_API_SUCCESS = 'PAYMENT-PLAN-INSTALMENT/PAY_API_SUCCESS';
export const INSTALMENT_PAY_API_FAILED = 'PAYMENT-PLAN-INSTALMENT/PAY_API_FAILED';

export const POLL_INSTALMENT_PAYMENT_SUCCESS_STORE_KEY = 'PAYMENT-PLAN-INSTALMENT/POLL_PAYMENT_SUCCESS';
export const POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED = 'PAYMENT-PLAN-INSTALMENT/POLL_PAYMENT_SUCCESS_API_REQUSTED';
export const POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS = 'PAYMENT-PLAN-INSTALMENT/POLL_PAYMENT_SUCCESS_API_SUCCESS';
export const POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED = 'PAYMENT-PLAN-INSTALMENT/POLL_PAYMENT_SUCCESS_API_FAILED';
export const POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED = 'PAYMENT-PLAN-INSTALMENT/POLL_PAYMENT_SUCCESS_API_CANCELLED';

export function fetchPaymentIntentSecretRequested(paymentPlanId: string, paymentPlanInstalmentId: string) {
  return {
    type: FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_REQUESTED,
    payload: {
      paymentPlanId,
      paymentPlanInstalmentId,
    },
  } as const;
}

export function fetchPaymentIntentSecretSuccess(paymentIntentSecret: string) {
  return {
    type: FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_SUCCESS,
    payload: {
      paymentIntentSecret,
    },
  } as const;
}

export function fetchPaymentIntentSecretFailed(error: string) {
  return {
    type: FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_FAILED,
    payload: error,
  } as const;
}

export function instalmentPayRequested(payload: InstalmentPayPayload) {
  return {
    type: INSTALMENT_PAY_API_REQUESTED,
    payload,
  } as const;
}

export function instalmentPaySuccess() {
  return {
    type: INSTALMENT_PAY_API_SUCCESS,
  } as const;
}

export function instalmentPayFailed(error: string) {
  return {
    type: INSTALMENT_PAY_API_FAILED,
    payload: error,
  } as const;
}

export function pollInstalmentPaymentSuccessRequested(payload: PollInstalmentPaymentSuccessPayload) {
  return {
    type: POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED,
    payload,
  } as const;
}

export function pollInstalmentPaymentSuccessSuccess(paymentPlanId: string, paymentPlanInstalmentId: string) {
  return {
    type: POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS,
    payload: {
      paymentPlanId,
      paymentPlanInstalmentId,
    },
  } as const;
}

export function pollInstalmentPaymentSuccessFailed(error: string) {
  return {
    type: POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED,
    payload: error,
  } as const;
}

export function pollInstalmentPaymentSuccessCancelled() {
  return {
    type: POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED,
  } as const;
}

export type PaymentPlanInstalmentActionTypes = ReturnType<
  | typeof fetchPaymentIntentSecretRequested
  | typeof fetchPaymentIntentSecretSuccess
  | typeof fetchPaymentIntentSecretFailed
  | typeof instalmentPayRequested
  | typeof instalmentPaySuccess
  | typeof instalmentPayFailed
  | typeof pollInstalmentPaymentSuccessRequested
  | typeof pollInstalmentPaymentSuccessSuccess
  | typeof pollInstalmentPaymentSuccessFailed
  | typeof pollInstalmentPaymentSuccessFailed
  | typeof pollInstalmentPaymentSuccessCancelled
>;
