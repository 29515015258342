import React from 'react'
import { breakpoints } from '../base/style';

export const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
  
    // Return the width so we can use it in our components
    return { width };
  };

  export const useIsMobile = () => {
    const { width } = useViewport();
    const tabletWidth = breakpoints['md'];
    const isMobile = width <= tabletWidth;
    return isMobile;
  }
