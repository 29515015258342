import { appConfig } from '../../appConfig';
import { UserEntity } from '../../redux/entities/entities.types';

interface GoogleAnalyticsTracker {
  name: string;
  id: string;
}

interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

let analyticsBooted = false;

export const initialiseGoogleAnalytics = () => {
  appConfig.analytics.trackers.map(({ id, name }: GoogleAnalyticsTracker) => {
    return (window as any).ga(`create`, id, 'auto', name);
  });
};

export const sendEventToGoogleAnalytics = ({ category, action, label, value }: AnalyticsEvent) => {
  appConfig.analytics.trackers.map(({ name }: GoogleAnalyticsTracker) => {
    return (window as any).ga(`${name}.send`, 'event', category, action, label || '', value);
  });
};

export const sendPageViewToGoogleAnalytics = (path: string) => {
  appConfig.analytics.trackers.map(({ name }: GoogleAnalyticsTracker) => {
    return (window as any).ga(`${name}.send`, 'pageview', path);
  });
};

export const configureGoogleAnalytics = (user: UserEntity) => {
  appConfig.analytics.trackers.map(({ name }: GoogleAnalyticsTracker) => {
    (window as any).ga(`${name}.set`, 'userId', user.id);
    return (window as any).ga(`${name}.send`, 'event', 'Core', 'Identified');
  });
};

export const bootGoogleAnalytics = (user: UserEntity) => {
  if (!analyticsBooted) {
    sendEventToGoogleAnalytics({ action: 'Login', category: 'Auth', label: user.userTypeId });
    configureGoogleAnalytics(user);
    analyticsBooted = true;
  }
};
