import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { Header1, P1 } from '../components/Typography/index';
import disabledIcon from '../assets/svg/disabled.svg';

const ErrorWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 100px 0;
  justify-content: center;
  text-align: center;

  &:before {
    background: transparent url(${disabledIcon}) center center no-repeat;
    background-size: 100px;
    content: '';
    display: block;
    margin: 0 0 48px;
    height: 100px;
    width: 100px;
  }
`;

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
  error: any;
  errorInfo: any;
  count: number;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { count: 0, error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      count: this.state.count + 1,
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div key={this.state.count}>
          <Container>
            <Row>
              <Col sm={12}>
                <ErrorWrapper>
                  <Header1>Something went wrong</Header1>
                  <P1>Please try reloading the page or come back later if the error persists.</P1>
                </ErrorWrapper>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
