import { unwrapResult } from '@reduxjs/toolkit';
import { CreateClaimItemProposalDTO } from '@reposit/api-client';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { useAppDispatch } from '../..';
import HelpIcon from '../../assets/svg/help.svg';
import InfoIcon from '../../assets/svg/info-purple.svg';
import { getBreakpoint } from '../../base/style';
import { RepositTheme } from '../../base/theme';
import { LiabilityTotal } from '../../containers/Claim';
import ClaimDispute from '../../containers/ClaimDispute';
import ClaimPayment from '../../containers/ClaimPayment';
import ClaimProposalPayment from '../../containers/ClaimProposalPayment';
import { useFlashMessage } from '../../containers/FlashMessage';
import { useIsMobile } from '../../hooks/useViewport';
import { getCurrentUserId } from '../../redux/account/account.selectors';
import { setClaimResponseConfirmModal } from '../../redux/claim/claim.actions';
import { getActiveClaimResponseConfirmModal } from '../../redux/claim/claim.selectors';
import {
  acceptProposalThunk,
  counterProposalThunk,
  publishProposalThunk,
  rejectProposalThunk,
  unpublishProposalThunk,
} from '../../redux/claim/claim.thunk';
import { CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY } from '../../redux/document/document.thunk';
import { ClaimEntity, ClaimItemProposalEntity, DocumentEntity } from '../../redux/entities/entities.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { PAY_STORE_KEY } from '../../redux/order-customer-actions/order-customer-actions.actions';
import { getIsPollingForPaymentCompletion } from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { AppState } from '../../redux/root.reducer';
import {
  getFirstTenantProposal,
  getHasTenantAcceptedAnyCharges,
  getHasTenantAutoAcceptedAnyCharges,
  getHasTenantDisputed,
  getHasTenantRespondedToAllItems,
  getIsTenantAuthorisingPayment,
  getIsTenantNegotiating,
  getLatestAgentProposal,
  getLatestProposalTotalAmount,
  getProposalTotalAmountsAreEqual,
  getTenantProposalTotalIsLow,
  getTenantProposalTotalIsZero,
} from '../../redux/selectors/mediation.selectors';
import { penceToPounds } from '../../utils/common.utils';
import Button, { RoundButton } from '../Button';
import Card from '../Card';
import ClaimItems from '../ClaimItems';
import ConfirmationModal from '../ConfirmationModal';
import FlashMessage from '../FlashMessage';
import { ClaimItem } from '../Library/SupplierOffer/ClaimItem/ClaimItem';
import { InfoModal } from '../Modal/InfoModal';
import { Header3, P1, P2, P3 } from '../Typography';
import ApproveClaimModal from './modals/ApproveClaimModal';
import DeclineClaimModal from './modals/DeclineClaimModal';

const Icon = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 4px;
`;

interface ClaimActionProps {
  claim: ClaimEntity;
  claimItems: ClaimItem[];
  claimResponseDocuments: DocumentEntity[];
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO, id: string) => Promise<ClaimItemProposalEntity>;
  setHasGoneBack: (val: boolean, force?: boolean) => void;
  hasGoneBack: boolean;
  hasGoneBackCount: number;
  organizationName: string;
  setShowMediationIntroduction: (val: boolean) => void;
}

const Wrapper = styled.div<{ isTenantNegotiating: boolean }>`
  background: white;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  margin: ${(props) => (props.isTenantNegotiating ? `1rem 0 0.5rem` : `1rem 0 1.75rem`)};

  h3,
  button {
    margin: 10px;
    @media screen and (min-width: ${getBreakpoint('lg')}) {
      margin: 0 10px;
    }
  }

  h3 {
    @media screen and (min-width: ${getBreakpoint('lg')}) {
      width: 400px;
    }
  }

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    text-align: left;
  }

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div<{ isLatestAgentProposalFinal: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: ${getBreakpoint('md')}) {
    flex-direction: ${(props) => (props.isLatestAgentProposalFinal ? 'column' : 'row')};
  }
`;

const ClaimItemsCard = styled(Card)`
  position: relative;
  margin: 0 0 7px 0;
`;

const ClaimFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 12px;
`;

const ClaimTotal = styled(P2)<{ warning: boolean }>`
  text-align: right;
  margin: 0;

  span {
    color: ${(props) => (props.warning ? RepositTheme.colors.negative : RepositTheme.colors.body)};
    font-family: ${(props) => props.theme.typography.face.primary};
    font-weight: bold;
    font-size: 20px;
    margin: 0 16px 0 10px;
  }
`;

const ClaimTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 16px;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin: 20px 16px 0 0;
`;

const ClaimItemsContainer = styled.div`
  padding: 10px 0;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

enum ClaimProposalResponseType {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

const ButtonStyles = css<{ longButtons: boolean }>`
  font-size: 0.8em;
  width: ${(props) => (props.longButtons ? '70%' : '50%')};
  justify-content: center;
  padding: 10px 4px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    font-size: 0.68em;
    width: auto;
    // original - had to set because we changed it above - ugh
    padding: 0.5em 1.5em 0.5em 1.75em;
  }
`;

const AcceptButton = styled(Button)`
  ${ButtonStyles}
  margin-right: 10px;
  background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, #669966)`};
  &:hover {
    background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, ${props.theme.colors.positive})`};
  }
`;

const RejectButton = styled(Button)`
  ${ButtonStyles}
  background-image: linear-gradient(to bottom, ${(props) => props.theme.colors.negative}, #b01e1e);
  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.negative},
      ${(props) => props.theme.colors.negative}
    );
  }
`;

const AcceptMaxLiabilityButton = styled(Button)`
  ${ButtonStyles}
  width: 100%;
  margin-top: 8px;
  background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, #669966)`};
  &:hover {
    background-image: ${(props) => `linear-gradient(to bottom, ${props.theme.colors.positive}, ${props.theme.colors.positive})`};
  }

  @media screen and(min-width: ${getBreakpoint('md')}) {
    margin: 8px 16px 0 0;
    width: 50%;
  }
`;

export const HelpButton = styled(RoundButton)`
  margin-right: 10px;
`;

const MaxLiabilityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ClaimAction: React.FC<ClaimActionProps> = ({
  claim,
  claimResponseDocuments,
  claimItems,
  createCounterProposal,
  updateCounterProposal,
  setHasGoneBack,
  hasGoneBack,
  hasGoneBackCount,
  organizationName,
  setShowMediationIntroduction,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const activeModal = useSelector(getActiveClaimResponseConfirmModal);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const claimId = get(claim, 'id', '');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGoingBackSubmitting, setIsGoingBackSubmitting] = useState(false);

  const isTenantNegotiating = useSelector((state: AppState) => getIsTenantNegotiating(state, claimId));
  const hasTenantAccepted = useSelector((state: AppState) => getHasTenantAcceptedAnyCharges(state, claimId));
  const hasTenantAutoAccepted = useSelector((state: AppState) => getHasTenantAutoAcceptedAnyCharges(state, claimId));
  const hasTenantDisputed = useSelector((state: AppState) => getHasTenantDisputed(state, claimId));
  const tenantProposal = useSelector((state: AppState) => getFirstTenantProposal(state, claimId));
  const tenantProposalId = get(tenantProposal, 'id', '');
  const tenantProposalOrderId = get(tenantProposal, 'orderId', '');
  const totalProposedAmount = useSelector((state: AppState) => getLatestProposalTotalAmount(state, claimId));
  const proposalTotalAmountsAreEqual = useSelector((state: AppState) => getProposalTotalAmountsAreEqual(state, claimId));
  const tenantProposalTotalIsZero = useSelector((state: AppState) => getTenantProposalTotalIsZero(state, claimId));
  const tenantProposalIsLow = useSelector((state: AppState) => getTenantProposalTotalIsLow(state, claimId));
  const isTenantAuthorisingPayment = useSelector((state: AppState) => getIsTenantAuthorisingPayment(state, claimId));
  const latestAgentProposal = useSelector((state: AppState) => getLatestAgentProposal(state, claimId));
  const latestAgentProposalId = get(latestAgentProposal, 'id', '');
  const latestAgentProposalOrderId = get(latestAgentProposal, 'orderId', '');
  const isLatestAgentProposalFinal = latestAgentProposal && ((latestAgentProposal as any).type as any) === 'FINAL';
  const hasTenantRespondedToAllItems = useSelector((state: AppState) => getHasTenantRespondedToAllItems(state, claimId));

  const hasTenantResponded = hasTenantAccepted || isTenantNegotiating || isTenantAuthorisingPayment || hasTenantDisputed;

  const currentUserId = useSelector((state: AppState) => getCurrentUserId(state));
  const proposalCreatedByUserId = tenantProposal && get(tenantProposal, 'createdByUserId', undefined);
  const proposalCreatedByAnotherTenant = currentUserId && proposalCreatedByUserId && currentUserId !== proposalCreatedByUserId;

  const claimLimit = get(claim, 'limit', 0);
  const claimLimitExceeded: boolean = totalProposedAmount ? totalProposedAmount > claimLimit : false;
  const tooltipText = `With Reposit, the landlord is covered for up to 8 weeks worth of rent. This means that you are only liable for up to £${penceToPounds(
    claimLimit
  )}, even if the total claim exceeds this amount.`;

  const shouldPay = hasTenantAccepted;

  const isPollingForPaymentSuccess = useSelector(getIsPollingForPaymentCompletion);
  const getPayRepositLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPaymentLoading = useSelector(getPayRepositLoadingSelector);

  const [flashMessage, onDismissFlashMessage] = useFlashMessage([CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY]);

  let content;

  const isMobile = useIsMobile();

  const handleAcceptProposal = async () => {
    try {
      setIsSubmitting(true);
      setIsModalLoading(true);
      await dispatch(acceptProposalThunk({ claimId, claimProposalId: latestAgentProposalId })).then(unwrapResult);
      dispatch(setClaimResponseConfirmModal(null));
      setIsModalLoading(false);
      setIsSubmitting(false);
    } catch (e) {
      setIsModalLoading(false);
      dispatch(setClaimResponseConfirmModal(null));
      setIsSubmitting(false);
      throw e;
    }
  };

  const handleCounterProposal = async () => {
    try {
      setIsSubmitting(true);
      setIsModalLoading(true);
      if (isLatestAgentProposalFinal || !claim.mediationEnabled) {
        await dispatch(rejectProposalThunk({ claimId, claimProposalId: latestAgentProposalId })).then(unwrapResult);
      } else {
        await dispatch(counterProposalThunk({ claimId, claimProposalId: latestAgentProposalId })).then(unwrapResult);
      }
      setHasGoneBack(false);
      setIsModalLoading(false);
      setIsSubmitting(false);
      // else reject
      dispatch(setClaimResponseConfirmModal(null));
    } catch (e) {
      setIsModalLoading(false);
      setIsSubmitting(false);
      dispatch(setClaimResponseConfirmModal(null));
      throw e;
    }
  };

  const handleSubmitProposal = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(publishProposalThunk({ claimId, claimProposalId: tenantProposalId })).then(unwrapResult);
      setShowSubmitModal(false);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      setShowSubmitModal(false);
      throw e;
    }
  };

  const totalProposalAmountGreaterThanZero = totalProposedAmount && totalProposedAmount > 0;

  const submitModalText =
    totalProposedAmount !== undefined && totalProposedAmount !== null ? (
      <>
        <P2>You are about to submit a new proposal for your letting agent/landlord to review.</P2>
        {totalProposalAmountGreaterThanZero ? (
          <>
            <P2>
              You will need to provide an upfront payment of £{penceToPounds(totalProposedAmount)} to proceed with your proposal.
            </P2>
            <P2>
              Your payment will be held for a maximum of 7 days. This gives time for your letting agent/landlord to respond to
              your proposal.
            </P2>
          </>
        ) : (
          ''
        )}
      </>
    ) : (
      ''
    );

  const acceptMaxLiabilityModalText = (
    <>
      <P2>You have proposed an amount that is higher than your maximum liability for this Reposit.</P2>
      <P2>
        This means that{' '}
        <BoldText>you will not need to pay any more than £{penceToPounds(claimLimit)} directly through Reposit.</BoldText> Please
        press confirm to accept and pay this amount.
      </P2>
    </>
  );

  const draftProposalEqualToAgentProposalText = (
    <>
      <P2>The amount you have proposed is the equal to the charges raised.</P2>
      <P2>
        If you are happy with this amount{totalProposedAmount ? ` (£${penceToPounds(totalProposedAmount)})` : ''}, please click
        confirm to pay the charges and settle your Reposit.
      </P2>
    </>
  );

  const draftProposalIsZeroText = (
    <>
      <P2>You have proposed an amount of £0, meaning you are not willing to pay any portion of the charges raised.</P2>
      <P2>We strongly suggest that you reconsider your proposal, as £0 proposals are very unlikely to be accepted.</P2>
      <P2>
        If you are sure you want to submit this proposal, please click confirm to submit it to your letting agent/landlord for
        review.
      </P2>
    </>
  );

  const draftProposalIsLowText = (
    <>
      <P2>You have proposed an amount of {totalProposedAmount ? `£${penceToPounds(totalProposedAmount)}` : ''}.</P2>
      <P2>
        This is significantly lower than the charges raised. We recommend reviewing each charge again and considering all of the
        evidence provided.
      </P2>
      <P2>
        If you are sure you want to submit this proposal, please click confirm to submit it to your letting agent/landlord for
        review.
      </P2>
    </>
  );

  const renderModal = () => {
    if (activeModal === ClaimProposalResponseType.ACCEPTED) {
      return (
        <ApproveClaimModal
          onSubmit={() =>
            handleAcceptProposal().then(() => {
              // force to be false when a new action is made
              const force = true;
              setHasGoneBack(false, force);
            })
          }
          isLoading={isModalLoading}
          organizationName={organizationName}
        />
      );
    }
    if (activeModal === ClaimProposalResponseType.REJECTED) {
      return (
        <DeclineClaimModal
          onSubmit={() =>
            handleCounterProposal().then(() => {
              // force to be false when a new action is made
              const force = true;
              setHasGoneBack(false, force);
            })
          }
          isLoading={isModalLoading}
        />
      );
    }
    if (showSubmitModal) {
      if (claimLimitExceeded) {
        return (
          <ConfirmationModal
            title="Accept maximum liability"
            text={acceptMaxLiabilityModalText}
            onSubmit={() => handleAcceptProposal().then(() => setShowSubmitModal(false))}
            onDismiss={() => setShowSubmitModal(false)}
            isSubmitting={isSubmitting}
          />
        );
      } else if (proposalTotalAmountsAreEqual) {
        return (
          <ConfirmationModal
            title="Accept charges"
            text={draftProposalEqualToAgentProposalText}
            onSubmit={() => handleAcceptProposal().then(() => setShowSubmitModal(false))}
            onDismiss={() => setShowSubmitModal(false)}
            isSubmitting={isSubmitting}
          />
        );
      } else if (tenantProposalTotalIsZero) {
        return (
          <ConfirmationModal
            title="Submit proposal?"
            text={draftProposalIsZeroText}
            onSubmit={handleSubmitProposal}
            onDismiss={() => setShowSubmitModal(false)}
            isSubmitting={isSubmitting}
          />
        );
      } else if (tenantProposalIsLow) {
        return (
          <ConfirmationModal
            title="Submit proposal?"
            text={draftProposalIsLowText}
            onSubmit={handleSubmitProposal}
            onDismiss={() => setShowSubmitModal(false)}
            isSubmitting={isSubmitting}
          />
        );
      } else {
        return (
          <ConfirmationModal
            title="Submit proposal"
            text={submitModalText}
            onSubmit={handleSubmitProposal}
            onDismiss={() => setShowSubmitModal(false)}
            isSubmitting={isSubmitting}
          />
        );
      }
    }
    return null;
  };

  const isAcceptDisabled = hasTenantAccepted;
  const isRejectDisabled = isTenantNegotiating || isTenantAuthorisingPayment || hasTenantDisputed;

  const TenantNegotiating: React.FC = () => {
    const [showProposalInfoModal, setShowProposalInfoModal] = useState(false);
    const modalParagraph1 = `In order to continue, you will need to respond to each charge individually and then make a payment for the total amount proposed. That payment will then be held for 7 days for your letting agent/landlord to review.`;
    const modalParagraph2 = `If the letting agent/landlord accepts your proposal for the end of tenancy charges, you will receive a notification and the payment will be transferred to the agent/landlord. This will settle the Reposit and it will be closed.`;
    const modalParagraph3 = `If your letting agent/landlord does not accept your proposal, you will be notified to take further action and your proposal payment will be refunded to you in full.`;
    return (
      <>
        <Header3 style={{ marginLeft: 24 }}>Review Charges Raised</Header3>
        <P1 style={{ padding: '0 24px' }}>Click “Agree” if you accept the charge or “Disagree” to propose a settlement.</P1>
        <P1 bold style={{ padding: '0 24px' }}>
          Once you have responded to all charges, please press the 'Continue' button to finish by paying for your proposal.
        </P1>
        {proposalCreatedByAnotherTenant ? (
          <P1 style={{ color: RepositTheme.colors.warning, padding: '0 24px' }}>
            Your co-tenant has already started responding to these charges. This process can be finished by any tenant on the
            Reposit.
          </P1>
        ) : (
          <></>
        )}
        <P3 style={{ paddingLeft: '24px', textDecoration: 'underline', cursor: 'pointer' }}>
          <span onClick={() => setShowProposalInfoModal(true)} style={{ display: 'inline' }}>
            <Icon src={InfoIcon} />
            How does this process work?
          </span>
        </P3>
        {showProposalInfoModal ? (
          <InfoModal
            title={'Submitting a counter proposal'}
            content={
              <>
                <P2>{modalParagraph1}</P2>
                <P2>{modalParagraph2}</P2>
                <P2 style={{ marginBottom: 24 }}>{modalParagraph3}</P2>
              </>
            }
            onDismiss={() => setShowProposalInfoModal(false)}
          />
        ) : null}
      </>
    );
  };

  const renderButton = () => {
    return claimLimitExceeded && hasTenantRespondedToAllItems ? (
      <AcceptMaxLiabilityButton type="submit" onClick={() => setShowSubmitModal(true)} longButtons={false}>
        Accept maximum liability
      </AcceptMaxLiabilityButton>
    ) : (
      <SubmitButton
        buttonType="primary"
        type="submit"
        onClick={() => setShowSubmitModal(true)}
        disabled={!hasTenantRespondedToAllItems}
      >
        Continue
      </SubmitButton>
    );
  };

  if (shouldPay && !hasGoneBack) {
    content = <ClaimPayment orderId={latestAgentProposalOrderId} claimId={claimId} />;
  } else if (hasTenantDisputed && (!hasGoneBack || (hasGoneBack && claim.tenantDisputeMessage && hasGoneBackCount < 2))) {
    content = (
      <Container>
        <Row>
          <Col sm={12}>
            <ClaimDispute
              claim={claim}
              claimResponseDocuments={claimResponseDocuments}
              hasGoneBack={hasGoneBack}
              setHasGoneBack={setHasGoneBack}
            />
          </Col>
        </Row>
      </Container>
    );
  } else if (isTenantNegotiating && !hasGoneBack) {
    content = (
      <Row>
        <Col sm={12}>
          <ClaimItemsCard
            flush
            flashMessage={
              flashMessage ? (
                <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} />
              ) : undefined
            }
          >
            {claim.mediationEnabled ? <TenantNegotiating /> : <Header3 style={{ marginLeft: 24 }}>Charges Raised</Header3>}
            <ClaimItemsContainer>
              <ClaimItems
                claimItems={claimItems}
                viewOnly={false}
                createCounterProposal={createCounterProposal}
                updateCounterProposal={updateCounterProposal}
                isTenantNegotiating={isTenantNegotiating}
                hasGoneBack={hasGoneBack}
              />
              <ClaimFooter>
                <ClaimTotalWrapper>
                  <ClaimTotal warning={false}>
                    Total proposed amount: <span>£{penceToPounds(totalProposedAmount || 0)}</span>
                  </ClaimTotal>
                  {claimLimitExceeded ? (
                    <MaxLiabilityWrapper>
                      <HelpButton icon={HelpIcon} data-tip={tooltipText} data-place="right" />
                      <LiabilityTotal>
                        Your maximum liability: <span>£{penceToPounds(claimLimit)}</span>
                      </LiabilityTotal>
                    </MaxLiabilityWrapper>
                  ) : null}
                  {isTenantNegotiating ? renderButton() : null}
                </ClaimTotalWrapper>
              </ClaimFooter>
            </ClaimItemsContainer>
          </ClaimItemsCard>
        </Col>
      </Row>
    );
  } else if (isTenantAuthorisingPayment) {
    content = <ClaimProposalPayment orderId={tenantProposalOrderId} />;
  } else {
    content = null;
  }

  const acceptButtonText = () => {
    if (isLatestAgentProposalFinal) {
      return 'Pay final charges';
    } else {
      return isMobile ? 'Yes' : 'Yes, I accept';
    }
  };

  const rejectButtonText = () => {
    if (isLatestAgentProposalFinal) {
      return 'Raise Formal Dispute';
    } else {
      return isMobile ? 'No' : `No, I don't accept`;
    }
  };
  const handleGoBack = async () => {
    if (isTenantAuthorisingPayment) {
      setIsGoingBackSubmitting(true);
      await dispatch(unpublishProposalThunk({ claimId, claimProposalId: tenantProposalId }));
      setIsGoingBackSubmitting(false);
    } else {
      setHasGoneBack(true);
    }
  };

  const showBackButton =
    hasTenantResponded &&
    !isPollingForPaymentSuccess &&
    !isPaymentLoading &&
    (!hasGoneBack || (hasGoneBack && claim.tenantDisputeMessage && !hasTenantAccepted && hasGoneBackCount < 2));

  const showMediationIntroductionButton = !hasTenantResponded && !isPollingForPaymentSuccess && !isPaymentLoading;

  return (
    <Fragment>
      <Row>
        <Col sm={12}>
          {showBackButton && !hasTenantAutoAccepted ? (
            <>
              <Button
                type="button"
                buttonType="secondary"
                reverse
                style={{ marginLeft: 15, marginBottom: 20 }}
                onClick={handleGoBack}
                disabled={isGoingBackSubmitting}
              >
                Back
              </Button>
            </>
          ) : null}
          {showMediationIntroductionButton ? (
            <>
              <Button
                type="button"
                buttonType="secondary"
                reverse
                style={{ marginLeft: 15, marginBottom: 20 }}
                onClick={() => setShowMediationIntroduction(true)}
              >
                Read intro
              </Button>
            </>
          ) : null}
          {!showBackButton && !isPollingForPaymentSuccess && !isPaymentLoading ? (
            <Wrapper isTenantNegotiating={isTenantNegotiating}>
              <Header3>Do you accept the charges in full?</Header3>
              <ButtonContainer isLatestAgentProposalFinal={!!isLatestAgentProposalFinal}>
                <AcceptButton
                  buttonType="primary" /* tertiary */
                  disabled={(isAcceptDisabled && !hasGoneBack) || isSubmitting}
                  onClick={() => {
                    if (hasGoneBack && hasTenantAccepted) {
                      // not forcing because this can be multiple local has gone backs
                      setHasGoneBack(false);
                    } else {
                      dispatch(setClaimResponseConfirmModal(ClaimProposalResponseType.ACCEPTED));
                    }
                  }}
                  longButtons={!!isLatestAgentProposalFinal}
                >
                  {acceptButtonText()}
                </AcceptButton>
                <RejectButton
                  buttonType="primary"
                  /* tertiary */ disabled={(isRejectDisabled && !hasGoneBack) || isSubmitting}
                  onClick={async () => {
                    if (hasGoneBack && (isTenantNegotiating || hasTenantDisputed)) {
                      // not forcing because this can be multiple local has gone backs
                      setHasGoneBack(false);
                    } else {
                      if (isLatestAgentProposalFinal) {
                        dispatch(setClaimResponseConfirmModal(ClaimProposalResponseType.REJECTED));
                      } else {
                        await handleCounterProposal();
                      }
                    }
                  }}
                  longButtons={!!isLatestAgentProposalFinal}
                >
                  {rejectButtonText()}
                </RejectButton>
              </ButtonContainer>
            </Wrapper>
          ) : null}
        </Col>
      </Row>
      {content}
      {renderModal()}
    </Fragment>
  );
};

export default ClaimAction;
