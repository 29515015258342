import { Stripe, StripeElements } from '@stripe/stripe-js';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import { useAppDispatch } from '../../..';
import FlashMessage, { FlashState } from '../../../components/FlashMessage';
import Loading from '../../../components/Loading';
import { Header2, P1 } from '../../../components/Typography';
import { setFlashMessage } from '../../../redux/flash-messages/flash-messages.actions';
import { PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY } from '../../../redux/payment-plan-actions/payment-plan-actions.actions';
import { updateCardDetailsPaymentPlanActionThunk } from '../../../redux/payment-plan-actions/payment-plan-actions.thunk';
import { getErrorMessage } from '../../../utils/common.utils';
import { WarningMessage } from '../../AddPaymentMethod';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../../FlashMessage';
import { ElementsForm } from '../../Payment/ElementsForm';

const BoldText = styled.span`
  font-weight: bold;
`;

interface PaymentDetailsProps {
  match: any;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { paymentPlanId } = match.params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY]);
  return (
    <Container>
      {flashMessagePayload ? (
        <Row>
          <Col>
            <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} />
          </Col>
        </Row>
      ) : null}
      <Row justify="center">
        <Col lg={10} push={{ lg: 0 }}>
          <Header2>Payment Details</Header2>
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={10} push={{ lg: 0 }}>
          <P1>
            To successfully complete your payment plan, enter your payment details below for settling the outlined instalment
            payments. These will be <BoldText>automatically charged each month</BoldText> when each instalment is due.
          </P1>
        </Col>
      </Row>
      {isSubmitting ? <Loading /> : null}
      <Row justify="center" style={{ display: isSubmitting ? 'none' : 'flex' }}>
        <Col lg={10} push={{ lg: 0 }}>
          <ElementsForm
            warningMessage={WarningMessage}
            isSubmitting={isSubmitting}
            paymentMode={'setup'}
            submitCard={async (stripe: Stripe, elements: StripeElements) => {
              try {
                setIsSubmitting(true);
                await dispatch(updateCardDetailsPaymentPlanActionThunk({ stripe, elements, paymentPlanId }));
                setIsSubmitting(false);
              } catch (e) {
                setIsSubmitting(false);
                dispatch(
                  setFlashMessage({
                    key: PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY,
                    message: getErrorMessage(e),
                    state: FlashState.ERROR,
                  })
                );
                throw e;
              }
            }}
            buttonText="Confirm my payment plan"
            redWarningMessage={false}
            showCheckbox={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentDetails;
