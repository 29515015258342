import { UploadReferenceDocsRequestedPayload } from './referencing.types';

export const POLL_FOR_REFERENCE_REQUESTED = 'REFERENCING/POLL_FOR_REFERENCE_REQUESTED';
export const POLL_FOR_REFERENCE_SUCCESS = 'REFERENCING/POLL_FOR_REFERENCE_SUCCESS';
export const POLL_FOR_REFERENCE_FAILED = 'REFERENCING/POLL_FOR_REFERENCE_FAILED';
export const POLL_FOR_REFERENCE_CANCELLED = 'REFERENCING/POLL_FOR_REFERENCE_CANCELLED';

export const CREATE_REFERENCE_API_REQUESTED = 'REFERENCING/CREATE_REFERENCE_API_REQUESTED';
export const CREATE_REFERENCE_API_SUCCESS = 'REFERENCING/CREATE_REFERENCE_API_SUCCESS';
export const CREATE_REFERENCE_API_FAILED = 'REFERENCING/CREATE_REFERENCE_API_FAILED';

export const GET_REFERENCE_CHECKS_STORE_KEY = 'REFERENCING/GET_REFERENCE_CHECKS';
export const GET_REFERENCE_CHECKS_API_REQUESTED = 'REFERENCING/GET_REFERENCE_CHECKS_API_REQUESTED';
export const GET_REFERENCE_CHECKS_API_SUCCESS = 'REFERENCING/GET_REFERENCE_CHECKS_API_SUCCESS';
export const GET_REFERENCE_CHECKS_API_FAILED = 'REFERENCING/GET_REFERENCE_CHECKS_API_FAILED';

export const UPLOAD_REFERENCE_DOCS_STORE_KEY = 'REFERENCING/UPLOAD_REFERENCE_DOCS';
export const UPLOAD_REFERENCE_DOCS_API_REQUESTED = 'REFERENCING/UPLOAD_REFERENCE_DOCS_API_REQUESTED';
export const UPLOAD_REFERENCE_DOCS_API_SUCCESS = 'REFERENCING/UPLOAD_REFERENCE_DOCS_API_SUCCESS';
export const UPLOAD_REFERENCE_DOCS_API_FAILED = 'REFERENCING/UPLOAD_REFERENCE_DOCS_API_FAILED';

export const REMOVE_REFERENCE_DOC_STORE_KEY = 'REFERENCING/REMOVE_REFERENCE_DOC';
export const REMOVE_REFERENCE_DOC_API_REQUESTED = 'REFERENCING/REMOVE_REFERENCE_DOC_API_REQUESTED';
export const REMOVE_REFERENCE_DOC_API_SUCCESS = 'REFERENCING/REMOVE_REFERENCE_DOC_API_SUCCESS';
export const REMOVE_REFERENCE_DOC_API_FAILED = 'REFERENCING/REMOVE_REFERENCE_DOC_API_FAILED';

export const SET_REFERENCE_REVIEW_STORE_KEY = 'REFERENCING/SET_REFERENCE_REVIEW';
export const SET_REFERENCE_REVIEW_API_REQUESTED = 'REFERENCING/SET_REFERENCE_REVIEW_API_REQUESTED';
export const SET_REFERENCE_REVIEW_API_SUCCESS = 'REFERENCING/SET_REFERENCE_REVIEW_API_SUCCESS';
export const SET_REFERENCE_REVIEW_API_FAILED = 'REFERENCING/SET_REFERENCE_REVIEW_API_FAILED';

export function pollForReferenceRequested(referenceId: string) {
  return {
    type: POLL_FOR_REFERENCE_REQUESTED,
    payload: {
      referenceId,
    },
  } as const;
}

export function pollForReferenceSuccess(referenceId: string, documents?: any[]) {
  return {
    type: POLL_FOR_REFERENCE_SUCCESS,
    payload: {
      referenceId,
      documents,
    },
  } as const;
}

export function pollForReferenceFailed(error: string) {
  return {
    type: POLL_FOR_REFERENCE_FAILED,
    payload: error,
  } as const;
}

export function pollForReferenceCancelled() {
  return {
    type: POLL_FOR_REFERENCE_CANCELLED,
  } as const;
}

export function createReferenceRequested(orderId: string, customerId: string) {
  return {
    type: CREATE_REFERENCE_API_REQUESTED,
    payload: {
      orderId,
      customerId,
    },
  } as const;
}

export function createReferenceSuccess(referenceId: string) {
  return {
    type: CREATE_REFERENCE_API_SUCCESS,
    payload: {
      referenceId,
    },
  } as const;
}

export function createReferenceFailed(error: string) {
  return {
    type: CREATE_REFERENCE_API_FAILED,
    payload: error,
  } as const;
}

export function getReferenceChecksRequested(referenceId: string) {
  return {
    type: GET_REFERENCE_CHECKS_API_REQUESTED,
    payload: {
      referenceId,
    },
  } as const;
}

export function getReferenceChecksSuccess(checkIds: string[]) {
  return {
    type: GET_REFERENCE_CHECKS_API_SUCCESS,
    payload: {
      checkIds,
    },
  } as const;
}

export function getReferenceChecksFailed(error: string) {
  return {
    type: GET_REFERENCE_CHECKS_API_FAILED,
    payload: error,
  } as const;
}

export function uploadReferenceDocsRequested(data: UploadReferenceDocsRequestedPayload) {
  return {
    type: UPLOAD_REFERENCE_DOCS_API_REQUESTED,
    payload: {
      referenceId: data.referenceId,
      name: data.name,
      type: data.type,
      file: data.file,
    },
  } as const;
}

export function uploadReferenceDocsSuccess(document: any) {
  return {
    type: UPLOAD_REFERENCE_DOCS_API_SUCCESS,
    payload: {
      document,
    },
  } as const;
}

export function uploadReferenceDocsFailed(error: string) {
  return {
    type: UPLOAD_REFERENCE_DOCS_API_FAILED,
    payload: error,
  } as const;
}

export function removeReferenceDocRequested(referenceId: string, referenceDocumentId: string) {
  return {
    type: REMOVE_REFERENCE_DOC_API_REQUESTED,
    payload: {
      referenceId,
      referenceDocumentId,
    },
  } as const;
}

export function removeReferenceDocSuccess(documentId: string) {
  return {
    type: REMOVE_REFERENCE_DOC_API_SUCCESS,
    payload: documentId,
  } as const;
}

export function removeReferenceDocFailed(error: string) {
  return {
    type: REMOVE_REFERENCE_DOC_API_FAILED,
    payload: error,
  } as const;
}

export function setReferenceReviewRequested(referenceId: string) {
  return {
    type: SET_REFERENCE_REVIEW_API_REQUESTED,
    payload: {
      referenceId,
    },
  } as const;
}

export function setReferenceReviewSuccess() {
  return {
    type: SET_REFERENCE_REVIEW_API_SUCCESS,
    payload: {},
  } as const;
}

export function setReferenceReviewFailed(error: string) {
  return {
    type: SET_REFERENCE_REVIEW_API_FAILED,
    payload: error,
  } as const;
}

export type ReferenceActionTypes = ReturnType<
  | typeof pollForReferenceRequested
  | typeof pollForReferenceSuccess
  | typeof pollForReferenceFailed
  | typeof pollForReferenceCancelled
  | typeof createReferenceRequested
  | typeof createReferenceSuccess
  | typeof createReferenceFailed
  | typeof getReferenceChecksRequested
  | typeof getReferenceChecksSuccess
  | typeof getReferenceChecksFailed
  | typeof uploadReferenceDocsRequested
  | typeof uploadReferenceDocsSuccess
  | typeof uploadReferenceDocsFailed
  | typeof removeReferenceDocRequested
  | typeof removeReferenceDocSuccess
  | typeof removeReferenceDocFailed
  | typeof setReferenceReviewRequested
  | typeof setReferenceReviewSuccess
  | typeof setReferenceReviewFailed
>;
