import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Row, Col, Container } from 'react-grid-system';
import { Header2, Header3, P1 } from '../../components/Typography';
import { useAppDispatch } from '../..';
import {
  fetchRepositOfferRequested,
  setRepositOfferResponseModalOpen,
  respondToRepositOfferRequested,
} from '../../redux/reposit-offer/reposit-offer.actions';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { getRepositOfferById, getIsResponseModalOpen } from '../../redux/reposit-offer/reposit-offer.selectors';
import Loading from '../../components/Loading';
import { getAddressFirstLine } from '../../utils/common.utils';
import { penceToPounds } from '../../utils/currency';
import RepositLogo from '../../assets/png/icon-full-colour.png';
import BankLogo from '../../assets/svg/bank.svg';
import Button from '../../components/Button';
import { RepositOfferDecisionModal } from './reposit-offer-decision-modal';
import { RepositOfferRecipientDTO, RepositOfferRecipientDTOChoiceEnum } from '@reposit/api-client';
import { useQuery } from '../../hooks/useQuery';
import { REPOSIT_OFFER_WEBSITE_TENANTS_PAGE } from '../../constants/links';
import { getBreakpoint } from '../../base/style';
import TrustBox from '../../components/TrustPilot';
import { appConfig } from '../../appConfig';

const TOKEN_QUERY = 'token';

const ContentHeader = styled.div`
  text-align: center;
`;

interface RepositOfferProps {
  match: any;
  location: Location<any>;
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.primaryBrand};
  width: 85%;
  border-radius: 12px;
  padding: 10px 10px 20px 10px;
  background: white;
`;
const CardHeader = styled(Header3)`
  margin-top: 0;
  font-size: 1.4em;
`;

const Cost = styled(Header3)`
  font-size: 1.4em;
`;

const CashCard = styled(Card)`
  margin-top: 20px;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 0px;
  }
`;

const Savings = styled(Header3)`
  margin-top: 20px;
  color: ${(props) => props.theme.colors.positive};
  text-align: center;
`;

const LogoImage = styled.img`
  height: 42px;
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

const RepositLink = styled.a`
  color: ${(props) => props.theme.colors.primaryBrand};
`;

const BaseButton = styled(Button)`
  width: 60%;
  justify-content: center;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 0.9em;
`;

const ChooseRepositButton = styled(BaseButton)`
  width: 60%;
  justify-content: center;
  background-image: linear-gradient(to bottom, ${(props) => props.theme.colors.positive}, #2a9c7b);

  &:hover {
    background-image: linear-gradient(to bottom, #2a9c7b, #2a9c7b);
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StandardText = styled(P1)`
  font-size: 1.4em;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 20px;
  }
`;

const Address = styled(P1)`
  font-size: 1rem;
  margin-top: 8px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    font-size: 1.3rem;
  }
`;

const DepositChoiceHeader = styled(Header3)`
  text-align: center;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-bottom: 30px;
  }
`;

export enum RepositOfferResponse {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export const RepositOffer: React.FC<RepositOfferProps> = ({ match, location }) => {
  const dispatch = useAppDispatch();
  const { repositOfferId } = match.params;
  const query = useQuery(location);
  const token = query && query.get(TOKEN_QUERY);
  const [response, setResponse] = useState<RepositOfferResponse>();

  const repositOfferEntity = useSelector(getRepositOfferById(repositOfferId));
  const isResponseModalOpen = useSelector(getIsResponseModalOpen);

  const recipients: RepositOfferRecipientDTO[] = get(repositOfferEntity, 'repositOffer.repositOfferRecipients', []);
  const existingResponse = recipients.find((r) => r.choice);

  useEffect(() => {
    if (repositOfferId && token) {
      dispatch(fetchRepositOfferRequested({ repositOfferId, token }));
    }
  }, [repositOfferId, token, dispatch]);
  if (!repositOfferEntity) {
    return <Loading />;
  }
  const addressFirstLine = getAddressFirstLine(repositOfferEntity.repositOffer.property.address);

  const handleRepositChoice = () => {
    setResponse(RepositOfferResponse.ACCEPTED);
    dispatch(setRepositOfferResponseModalOpen(true));
  };
  const handleCashChoice = () => {
    setResponse(RepositOfferResponse.DECLINED);
    dispatch(setRepositOfferResponseModalOpen(true));
  };
  const onSubmit = (accepted: boolean) => {
    dispatch(
      respondToRepositOfferRequested({
        choice: accepted ? RepositOfferRecipientDTOChoiceEnum.REPOSIT : RepositOfferRecipientDTOChoiceEnum.CASHDEPOSIT,
        repositOfferId,
        token: token || '',
      })
    );
  };
  return (
    <Container fluid>
      {!existingResponse ? (
        <Fragment>
          {isResponseModalOpen && response ? <RepositOfferDecisionModal response={response} onSubmit={onSubmit} /> : null}
          <Row>
            <Col>
              <ContentHeader>
                <Header2 style={{ fontSize: '1.7em', marginBottom: 0 }}>
                  Save £{penceToPounds(repositOfferEntity.repositOffer.savingAmount)} with Reposit!
                </Header2>
                <Address>{addressFirstLine}</Address>
                <StandardText>
                  Thousands of tenants choose Reposit every month to free up their cash and experience a fairer dispute resolution
                  process.
                </StandardText>
                <StandardText style={{ marginTop: 16 }}>
                  <RepositLink href={REPOSIT_OFFER_WEBSITE_TENANTS_PAGE} target="_blank">
                    Find out more about Reposit
                  </RepositLink>
                </StandardText>
              </ContentHeader>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={12}>
              <DepositChoiceHeader>Please choose your deposit option:</DepositChoiceHeader>
            </Col>
            <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Card>
                <CardContainer>
                  <CardHeader>Reposit Fee</CardHeader>

                  <LogoImage src={RepositLogo} />

                  <Savings>
                    Choose Reposit to save £{penceToPounds(repositOfferEntity.repositOffer.savingAmount)} on the upfront costs of
                    renting
                  </Savings>
                  <Cost>
                    <span style={{ fontWeight: 'normal' }}>Total to pay:</span> £
                    {penceToPounds(repositOfferEntity.repositOffer.repositAmount)}
                  </Cost>
                </CardContainer>
                <ChooseRepositButton buttonType="primary" noArrow onClick={handleRepositChoice}>
                  Choose Reposit
                </ChooseRepositButton>
                {appConfig.trustPilot.repositOfferTrustBoxEnabled ? <TrustBox /> : null}
              </Card>
            </Col>
            <Col xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <CashCard>
                <CardContainer>
                  <CardHeader>Cash Deposit</CardHeader>
                  <LogoImage src={BankLogo} style={{ height: 60 }} />
                  <Cost>
                    <span style={{ fontWeight: 'normal' }}>Total to pay:</span> £
                    {penceToPounds(repositOfferEntity.repositOffer.cashDepositAmount)}
                  </Cost>
                </CardContainer>
                <BaseButton buttonType="primary" noArrow onClick={handleCashChoice}>
                  Choose Cash
                </BaseButton>
              </CashCard>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <Fragment>
          <Header2 style={{ fontSize: '1.7em' }}>Your deposit option has been chosen already</Header2>
          {existingResponse.choice === RepositOfferRecipientDTOChoiceEnum.REPOSIT ? (
            <Fragment>
              <StandardText style={{ marginTop: 24 }}>
                <span style={{ fontWeight: 'bold' }}>Reposit</span> has been chosen for this tenancy meaning you will save{' '}
                <span style={{ fontWeight: 'bold' }}>£{penceToPounds(repositOfferEntity.repositOffer.savingAmount)}</span>{' '}
                upfront.
              </StandardText>
              <StandardText>
                Your agent will create a Reposit and you will receive email instructions on how to proceed.
              </StandardText>
              <StandardText>As a reminder, you will remain liable for any valid charges at the end of your tenancy.</StandardText>
            </Fragment>
          ) : (
            <Fragment>
              <StandardText style={{ marginTop: 24 }}>
                A <span style={{ fontWeight: 'bold' }}>cash deposit</span> has been chosen for this tenancy.
              </StandardText>
              <StandardText>
                If you would like to opt for a Reposit and save{' '}
                <span style={{ fontWeight: 'bold' }}>£{penceToPounds(repositOfferEntity.repositOffer.savingAmount)}</span>{' '}
                upfront, please get in contact with your agent.
              </StandardText>
            </Fragment>
          )}
        </Fragment>
      )}
    </Container>
  );
};
