import React, { useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import SlideDown from 'react-slidedown';
import GreenTick from '../../../assets/svg/tick-green.svg';
import Warning from '../../../assets/svg/warning-orange.svg';
import { FILE_UPLOAD_MAX_LIMIT, acceptedFiles } from '../../../constants/system';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { P3 } from '../../Typography/index';
import {
  DocumentLink,
  Drop,
  Progress,
  RemoveButton,
  ShowDocuments,
  ShowDocumentsContainer,
  StatusIcon,
  UploadBox,
} from './components';

export interface FileUploadProgress {
  total: number;
  current: number;
}

const pluralise = (length: number, word: string) => (length > 1 ? `${word}s` : word);

const getErrorFromErrorCode = (error: string, fileName: string) => {
  switch (error) {
    case ErrorCode.FileTooLarge:
      return `${fileName} is too large (max 30MB), please compress this file and re-upload`;
    case ErrorCode.FileInvalidType:
      return `${fileName} is an invalid type, please convert to a PDF`;
    default:
      return 'Something went wrong';
  }
};
interface FileUploadProps {
  required: boolean;
  documents: DocumentEntity[];
  uploadFile: (file: File) => Promise<DocumentEntity>;
  deleteFile: (id: string) => Promise<void>;
  onError: (error: string) => void;
  readOnly?: boolean;
  big?: boolean;
  rounded?: boolean;
  onUploadComplete?: (docs?: DocumentEntity[]) => void;
  onUploadStart?: () => void;
  showDeleteButton: boolean;
  fileUploadProgress: FileUploadProgress | undefined;
  setFileUploadProgress: (progress?: FileUploadProgress) => void;
}

export const FileUpload: React.SFC<FileUploadProps> = ({
  documents,
  uploadFile,
  deleteFile,
  onError,
  required,
  readOnly,
  big,
  rounded,
  onUploadStart,
  onUploadComplete,
  showDeleteButton,
  fileUploadProgress,
  setFileUploadProgress,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const onDrop = async (acceptedFiles: File[], rejections: FileRejection[]) => {
    try {
      const createdDocs = [];
      onUploadStart && onUploadStart();
      if (!rejections.length) {
        let count = 1;
        for (const acceptedFile of acceptedFiles) {
          setFileUploadProgress({ total: acceptedFiles.length, current: count });
          const document = await uploadFile(acceptedFile);
          createdDocs.push(document);
          count++;
        }
      } else {
        const rejection = rejections[0];
        const error = getErrorFromErrorCode(rejection.errors[0].code, rejection.file.name);
        onError(error);
      }
      setFileUploadProgress(undefined);
      onUploadComplete && onUploadComplete(createdDocs);
      if (!isSliderOpen) {
        setIsSliderOpen(true);
      }
    } catch (e) {
      onUploadComplete && onUploadComplete();
      setFileUploadProgress(undefined);
      throw e;
    }
  };
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxSize: FILE_UPLOAD_MAX_LIMIT,
    accept: acceptedFiles,
  });

  const isActive = isDragAccept || isDragReject;

  let statusIcon;

  const isValid = !!documents.length;

  if (isValid) {
    statusIcon = GreenTick;
  } else if (required) {
    statusIcon = Warning;
  }

  const handleDeleteDocument = async (d: DocumentEntity) => {
    setIsDeleting(true);
    return deleteFile(d.id).finally(() => {
      setIsDeleting(false);
    });
  };
  return (
    <>
      {!readOnly ? (
        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <UploadBox rounded={rounded} big={big} isUploading={!!fileUploadProgress} isActive={isActive} {...getRootProps()}>
            <input {...getInputProps()} />
            {!fileUploadProgress ? <Drop isActive={isActive} /> : <Progress progress={fileUploadProgress} />}
          </UploadBox>
        </div>
      ) : null}

      <ShowDocumentsContainer>
        {documents.length ? (
          <ShowDocuments
            onClick={(e) => {
              e.stopPropagation();
              setIsSliderOpen(!isSliderOpen);
            }}
          >
            {isSliderOpen ? (
              <span>
                Hide {documents.length} {pluralise(documents.length, 'document')}
              </span>
            ) : (
              <span>
                Show {documents.length} {pluralise(documents.length, 'document')}
              </span>
            )}
          </ShowDocuments>
        ) : (
          <P3 style={{ marginBottom: 0 }}>No documents uploaded</P3>
        )}
        {!readOnly ? <StatusIcon icon={statusIcon} /> : null}
      </ShowDocumentsContainer>
      <SlideDown style={{ transitionDuration: '0.1s', marginTop: 10 }} closed={!isSliderOpen} className={`evidence-slider`}>
        {documents.map((d) => {
          return (
            <div key={d.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 5, justifyContent: 'space-between' }}>
              <DocumentLink title={d.name} href={d.url} target="_blank" rel="noopener noreferrer" style={{ marginBottom: 0 }}>
                {d.name}
              </DocumentLink>
              {!readOnly && showDeleteButton ? (
                <RemoveButton disabled={isDeleting} mini="yes please" noArrow onClick={() => handleDeleteDocument(d)}>
                  Remove
                </RemoveButton>
              ) : null}
            </div>
          );
        })}
      </SlideDown>
    </>
  );
};
