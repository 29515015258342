import {
  UpdateClaimResponsePayload,
  DeleteClaimResponseDocumentPayload,
  DeleteClaimResponseDocumentSuccessPayload,
  CreateClaimResponseDocumentPayload,
  AcceptProposalSuccessPayload,
} from './claim.types';

export const FETCH_CLAIM_STORE_KEY = 'CLAIM/FETCH_CLAIM';
export const FETCH_CLAIM_API_REQUESTED = `CLAIM/FETCH_CLAIM_API_REQUESTED`;
export const FETCH_CLAIM_API_SUCCESS = 'CLAIM/FETCH_CLAIM_API_SUCCESS';
export const FETCH_CLAIM_API_FAILED = 'CLAIM/FETCH_CLAIM_API_FAILED';

export const RESPOND_TO_CLAIM_STORE_KEY = 'CLAIM/RESPOND_TO_CLAIM';
export const RESPOND_TO_CLAIM_API_REQUESTED = `CLAIM/RESPOND_TO_CLAIM_API_REQUESTED`;
export const RESPOND_TO_CLAIM_API_SUCCESS = 'CLAIM/RESPOND_TO_CLAIM_API_SUCCESS';
export const RESPOND_TO_CLAIM_API_FAILED = 'CLAIM/RESPOND_TO_CLAIM_API_FAILED';

export const SET_CLAIM_RESPONSE_CONFIRM_MODAL = 'CLAIM/SET_CLAIM_RESPONSE_CONFIRM_MODAL';

export const GET_CLAIM_RESPONSE_DOCS_STORE_KEY = 'CLAIM/GET_CLAIM_RESPONSE_DOCS';
export const GET_CLAIM_RESPONSE_DOCS_API_REQUESTED = `CLAIM/GET_CLAIM_RESPONSE_DOCS_API_REQUESTED`;
export const GET_CLAIM_RESPONSE_DOCS_API_SUCCESS = 'CLAIM/GET_CLAIM_RESPONSE_DOCS_API_SUCCESS';
export const GET_CLAIM_RESPONSE_DOCS_API_FAILED = 'CLAIM/GET_CLAIM_RESPONSE_DOCS_API_FAILED';

export const UPDATE_CLAIM_RESPONSE_STORE_KEY = 'CLAIM/UPDATE_CLAIM_RESPONSE';
export const UPDATE_CLAIM_RESPONSE_API_REQUESTED = `CLAIM/UPDATE_CLAIM_RESPONSE_API_REQUESTED`;
export const UPDATE_CLAIM_RESPONSE_API_SUCCESS = 'CLAIM/UPDATE_CLAIM_RESPONSE_API_SUCCESS';
export const UPDATE_CLAIM_RESPONSE_API_FAILED = 'CLAIM/UPDATE_CLAIM_RESPONSE_API_FAILED';

export const DELETE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY = 'CLAIM/DELETE_CLAIM_RESPONSE_DOCUMENT';
export const DELETE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED = `CLAIM/DELETE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED`;
export const DELETE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS = 'CLAIM/DELETE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS';
export const DELETE_CLAIM_RESPONSE_DOCUMENT_API_FAILED = 'CLAIM/DELETE_CLAIM_RESPONSE_DOCUMENT_API_FAILED';

export const CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY = 'CLAIM/CREATE_CLAIM_RESPONSE_DOCUMENT';
export const CREATE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED = `CLAIM/CREATE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED`;
export const CREATE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS = 'CLAIM/CREATE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS';
export const CREATE_CLAIM_RESPONSE_DOCUMENT_API_FAILED = 'CLAIM/CREATE_CLAIM_RESPONSE_DOCUMENT_API_FAILED';

export const RESET_CLAIM_RESPONSE_STORE_KEY = 'CLAIM/RESET_CLAIM_RESPONSE';
export const RESET_CLAIM_RESPONSE_API_REQUESTED = `CLAIM/RESET_CLAIM_RESPONSE_API_REQUESTED`;
export const RESET_CLAIM_RESPONSE_API_SUCCESS = 'CLAIM/RESET_CLAIM_RESPONSE_API_SUCCESS';
export const RESET_CLAIM_RESPONSE_API_FAILED = 'CLAIM/RESET_CLAIM_RESPONSE_API_FAILED';

export const SET_IS_CLAIM_RESPONSE_UPDATE_MODAL_SHOWING = 'CLAIM/SET_IS_CLAIM_RESPONSE_UPDATE_MODAL_SHOWING';
export const ACCEPT_PROPOSAL_SUCCESS = 'CLAIM/ACCEPT_PROPOSAL_SUCCESS';

export const ACCEPT_TENANT_PROPOSAL_STORE_KEY = 'CLAIM/ACCEPT_TENANT_PROPOSAL';
export const COUNTER_TENANT_PROPOSAL_STORE_KEY = 'CLAIM/COUNTER_TENANT_PROPOSAL';
export const REJECT_TENANT_PROPOSAL_STORE_KEY = 'CLAIM/REJECT_TENANT_PROPOSAL';
export const PUBLISH_PROPOSAL_STORE_KEY = 'CLAIM/PUBLISH_PROPOSAL';
export const UNPUBLISH_PROPOSAL_STORE_KEY = 'CLAIM/UNPUBLISH_PROPOSAL';
export const CREATE_ITEM_PROPOSAL_STORE_KEY = 'CLAIM/CREATE_ITEM_PROPOSAL';
export const UPDATE_ITEM_PROPOSAL_STORE_KEY = 'CLAIM/UPDATE_ITEM_PROPOSAL';

export const DELETE_CLAIM_ITEM_DOCUMENT_SUCCESS = 'CLAIM/DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS';

export function fetchClaimRequested(claimId: string) {
  return {
    type: FETCH_CLAIM_API_REQUESTED,
    payload: {
      claimId,
    },
  } as const;
}

export function fetchClaimSuccess() {
  return {
    type: FETCH_CLAIM_API_SUCCESS,
  } as const;
}

export function fetchClaimFailed(error: string) {
  return {
    type: FETCH_CLAIM_API_FAILED,
    payload: error,
  } as const;
}

export function setClaimResponseConfirmModal(modal: string | null) {
  return {
    type: SET_CLAIM_RESPONSE_CONFIRM_MODAL,
    payload: {
      modal,
    },
  } as const;
}

export function getClaimResponseDocsRequested(claimId: string) {
  return {
    type: GET_CLAIM_RESPONSE_DOCS_API_REQUESTED,
    payload: {
      claimId,
    },
  } as const;
}

export function getClaimResponseDocsSuccess(claimResponseDocumentIds: string[]) {
  return {
    type: GET_CLAIM_RESPONSE_DOCS_API_SUCCESS,
    payload: {
      claimResponseDocumentIds,
    },
  } as const;
}

export function getClaimResponseDocsFailed(error: string) {
  return {
    type: GET_CLAIM_RESPONSE_DOCS_API_FAILED,
    payload: error,
  } as const;
}

export function updateClaimResponseRequested(payload: UpdateClaimResponsePayload) {
  return {
    type: UPDATE_CLAIM_RESPONSE_API_REQUESTED,
    payload,
  } as const;
}

export function updateClaimResponseSuccess() {
  return {
    type: UPDATE_CLAIM_RESPONSE_API_SUCCESS,
  } as const;
}

export function updateClaimResponseFailed(error: string) {
  return {
    type: UPDATE_CLAIM_RESPONSE_API_FAILED,
    payload: error,
  } as const;
}

export function deleteClaimResponseDocumentRequested(payload: DeleteClaimResponseDocumentPayload) {
  return {
    type: DELETE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED,
    payload,
  } as const;
}

export function deleteClaimResponseDocumentSuccess(payload: DeleteClaimResponseDocumentSuccessPayload) {
  return {
    type: DELETE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS,
    payload,
  } as const;
}

export function deleteClaimResponseDocumentFailed(error: string) {
  return {
    type: DELETE_CLAIM_RESPONSE_DOCUMENT_API_FAILED,
    payload: error,
  } as const;
}

export function createClaimResponseDocumentRequested(payload: CreateClaimResponseDocumentPayload) {
  return {
    type: CREATE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED,
    payload,
  } as const;
}

export function createClaimResponseDocumentSuccess() {
  return {
    type: CREATE_CLAIM_RESPONSE_DOCUMENT_API_SUCCESS,
  } as const;
}

export function createClaimResponseDocumentFailed(error: string) {
  return {
    type: CREATE_CLAIM_RESPONSE_DOCUMENT_API_FAILED,
    payload: error,
  } as const;
}

export function setIsClaimResponseUpdateModalShowing(payload: boolean) {
  return {
    type: SET_IS_CLAIM_RESPONSE_UPDATE_MODAL_SHOWING,
    payload,
  } as const;
}

export function acceptProposalSuccess(payload: AcceptProposalSuccessPayload) {
  return {
    type: ACCEPT_PROPOSAL_SUCCESS,
    payload,
  } as const;
}

export function deleteClaimItemDocumentSuccess(payload: { claimItemProposalId: string; claimItemDocumentId: string }) {
  return {
    type: DELETE_CLAIM_ITEM_DOCUMENT_SUCCESS,
    payload,
  } as const;
}

export type ClaimActionTypes = ReturnType<
  | typeof fetchClaimRequested
  | typeof fetchClaimSuccess
  | typeof fetchClaimFailed
  | typeof setClaimResponseConfirmModal
  | typeof getClaimResponseDocsRequested
  | typeof getClaimResponseDocsSuccess
  | typeof getClaimResponseDocsFailed
  | typeof updateClaimResponseRequested
  | typeof updateClaimResponseSuccess
  | typeof updateClaimResponseFailed
  | typeof deleteClaimResponseDocumentRequested
  | typeof deleteClaimResponseDocumentSuccess
  | typeof deleteClaimResponseDocumentFailed
  | typeof createClaimResponseDocumentRequested
  | typeof createClaimResponseDocumentSuccess
  | typeof createClaimResponseDocumentFailed
  | typeof setIsClaimResponseUpdateModalShowing
  | typeof acceptProposalSuccess
  | typeof deleteClaimItemDocumentSuccess
>;
