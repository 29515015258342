export const ADDRESS_HISTORY_STORE_KEY = 'ADDRESS_HISTORY/ADDRESS_LOOKUP';
export const ADDRESS_LOOKUP_API_REQUESTED = `ADDRESS_HISTORY/ADDRESS_LOOKUP_API_REQUESTED`;
export const ADDRESS_LOOKUP_API_SUCCESS = 'ADDRESS_HISTORY/ADDRESS_LOOKUP_API_SUCCESS';
export const ADDRESS_LOOKUP_API_FAILED = 'ADDRESS_HISTORY/ADDRESS_LOOKUP_API_FAILED';

export const SET_IS_SHOWING_SEARCH_MODAL = 'ADDRESS_HISTORY/SET_IS_SHOWING_SEARCH_MODAL';
export const SET_IS_ADD_NEW_FORM_OPEN = 'ADDRESS_HISTORY/SET_IS_ADD_NEW_FORM_OPEN';

export const ADD_ADDRESS_STORE_KEY = `ADDRESS_HISTORY/ADD_ADDRESS_STORE_KEY`;
export const ADD_ADDRESS_API_REQUESTED = `ADDRESS_HISTORY/ADD_ADDRESS_API_REQUESTED`;
export const ADD_ADDRESS_API_SUCCESS = 'ADDRESS_HISTORY/ADD_ADDRESS_API_SUCCESS';
export const ADD_ADDRESS_API_FAILED = 'ADDRESS_HISTORY/ADD_ADDRESS_API_FAILED';

export const REMOVE_ADDRESS_STORE_KEY = 'ADDRESS_HISTORY/REMOVE_ADDRESS';
export const REMOVE_ADDRESS_API_REQUESTED = `ADDRESS_HISTORY/REMOVE_ADDRESS_API_REQUESTED`;
export const REMOVE_ADDRESS_API_SUCCESS = 'ADDRESS_HISTORY/REMOVE_ADDRESS_API_SUCCESS';
export const REMOVE_ADDRESS_API_FAILED = 'ADDRESS_HISTORY/REMOVE_ADDRESS_API_FAILED';

export function addressLookupRequested(postcode: string) {
  return {
    type: ADDRESS_LOOKUP_API_REQUESTED,
    payload: {
      postcode
    }
  } as const;
}

export function addressLookupSuccess(results: any[]) {
  return {
    type: ADDRESS_LOOKUP_API_SUCCESS,
    payload: {
      results
    }
  } as const;
}

export function addressLookupFailed(error: string) {
  return {
    type: ADDRESS_LOOKUP_API_FAILED,
    payload: error
  } as const;
}

export function setIsShowingSearchModal(isShowing: boolean) {
  return {
    type: SET_IS_SHOWING_SEARCH_MODAL,
    payload: { isShowingSearchModal: isShowing }
  } as const;
}

export function setIsAddNewFormOpen(isOpen: boolean) {
  return {
    type: SET_IS_ADD_NEW_FORM_OPEN,
    payload: { isAddNewFormOpen: isOpen }
  } as const;
}

export function addAddressRequested(currentUserId: string, data: any) {
  return {
    type: ADD_ADDRESS_API_REQUESTED,
    payload: {
      currentUserId,
      data
    }
  } as const;
}

export function addAddressSuccess() {
  return {
    type: ADD_ADDRESS_API_SUCCESS,
    payload: {}
  } as const;
}

export function addAddressFailed(error: string) {
  return {
    type: ADD_ADDRESS_API_FAILED,
    payload: error
  } as const;
}

export function removeAddressHistoryRequested(id: string) {
  return {
    type: REMOVE_ADDRESS_API_REQUESTED,
    payload: {
      id
    }
  } as const;
}

export function removeAddressHistorySuccess(userId: string, addressId: string) {
  return {
    type: REMOVE_ADDRESS_API_SUCCESS,
    payload: {
      userId,
      addressId
    }
  } as const;
}

export function removeAddressHistoryFailed(error: string) {
  return {
    type: REMOVE_ADDRESS_API_FAILED,
    payload: error
  } as const;
}

export type AddresHistoryActionTypes = ReturnType<
  | typeof addressLookupRequested
  | typeof addressLookupSuccess
  | typeof addressLookupFailed
  | typeof setIsShowingSearchModal
  | typeof setIsAddNewFormOpen
  | typeof addAddressRequested
  | typeof addAddressSuccess
  | typeof addAddressFailed
  | typeof removeAddressHistoryRequested
  | typeof removeAddressHistorySuccess
  | typeof removeAddressHistoryFailed
>;
