import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header3, P1 } from '../Typography';
import { getBreakpoint } from '../../base/style';
import HelpIcon from '../../assets/svg/help.svg';
import { RoundButton } from '../Button';
import ReactTooltip from 'react-tooltip';
import { TenancyUserDTO } from '@reposit/api-client';

interface ProductSummaryProps {
  name: string;
  description: JSX.Element | string;
  tooltip?: string;
  tenancyUser?: TenancyUserDTO | null;
}

const Wrapper = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  min-height: 60px;
  margin: 0 0 36px;
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 60px;
  }
`;

const LiabilityMessage = styled(P1)`
  background: ${(props) => props.theme.colors.negative};
  color: #fff;
  padding: 8px 16px !important;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const Product = styled.div`
  background-image: linear-gradient(
    266deg,
    ${(props) => props.theme.colors.primaryBrand},
    ${(props) => props.theme.colors.secondaryBrand}
  );
  text-align: center;
  margin: 0;
  padding: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    flex: 0 1 24%;
    height: 100%;
  }
`;

const ProductName = styled(Header3)`
  align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const Description = styled.div`
  margin: 0;
  padding: 16px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: -2px 0 0;
    padding: 0 78px 0 16px;
  }
`;

export const HelpButton = styled(RoundButton)`
  display: none;
  top: 0;
  right: 18px;
  margin: 17px;
  position: absolute;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: block;
  }
`;

const ProductSummary: React.FC<ProductSummaryProps> = ({ name, description, tooltip, tenancyUser }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const liabilityMessage =
    tenancyUser && tenancyUser.tenantLiability && tenancyUser.tenantLiability > 1 ? (
      <LiabilityMessage>You are responsible for paying the fee for {tenancyUser.tenantLiability} tenants.</LiabilityMessage>
    ) : undefined;

  return (
    <Wrapper>
      <InnerWrapper>
        <Product>
          <ProductName>{name}</ProductName>
        </Product>
        <Description>
          {tooltip && <HelpButton icon={HelpIcon} data-tip={tooltip} data-place="bottom" />}
          {description}
        </Description>
      </InnerWrapper>
      {liabilityMessage}
    </Wrapper>
  );
};

export default ProductSummary;
