import { CreateClaimItemProposalDTO } from '@reposit/api-client';
import React, { useState } from 'react';
import { ClaimItemProposalEntity } from '../../redux/entities/entities.types';
import { DisableComponent } from '../Common/index';
import ClaimItem, { ClaimItem as IClaimItem } from '../Library/SupplierOffer/ClaimItem/ClaimItem';

interface MediationClaimItemProps {
  claimItem: IClaimItem;
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO, id: string) => Promise<ClaimItemProposalEntity>;
  viewOnly: boolean;
  isTenantNegotiating: boolean;
  disabled: boolean;
  setOpenedClaimItem: (state: string | undefined) => void;
  hasGoneBack: boolean;
}
const MediationClaimItem: React.FC<MediationClaimItemProps> = ({
  claimItem,
  createCounterProposal,
  updateCounterProposal,
  viewOnly,
  isTenantNegotiating,
  disabled,
  setOpenedClaimItem,
  hasGoneBack,
}) => {
  const canRespond = isTenantNegotiating;

  return (
    <DisableComponent disabled={viewOnly ? false : disabled}>
      <ClaimItem
        claimItem={claimItem}
        canRespond={canRespond}
        createCounterProposal={createCounterProposal}
        updateCounterProposal={updateCounterProposal}
        viewOnly={viewOnly}
        setOpenedClaimItem={setOpenedClaimItem}
        hasGoneBack={hasGoneBack}
      />
    </DisableComponent>
  );
};
interface ClaimItemsProps {
  claimItems: IClaimItem[];
  viewOnly: boolean;
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO, id: string) => Promise<ClaimItemProposalEntity>;
  isTenantNegotiating: boolean;
  hasGoneBack: boolean;
}

const ClaimItems: React.FC<ClaimItemsProps> = ({
  claimItems,
  viewOnly,
  createCounterProposal,
  updateCounterProposal,
  isTenantNegotiating,
  hasGoneBack,
}) => {
  const [openedClaimItem, setOpenedClaimItem] = useState<string | undefined>(undefined);
  return (
    <div>
      {claimItems.sort(sortBySettledLast).map((claimItem) => {
        const disabled = !!openedClaimItem && openedClaimItem !== claimItem.id;
        return (
          <MediationClaimItem
            key={claimItem.id}
            claimItem={claimItem}
            createCounterProposal={createCounterProposal}
            updateCounterProposal={updateCounterProposal}
            viewOnly={viewOnly}
            isTenantNegotiating={isTenantNegotiating}
            disabled={disabled}
            setOpenedClaimItem={setOpenedClaimItem}
            hasGoneBack={hasGoneBack}
          />
        );
      })}
    </div>
  );
};

export default ClaimItems;

const sortBySettledLast = (a: IClaimItem, b: IClaimItem): number => {
  if (!a.isSettled && b.isSettled) {
    return -1;
  } else {
    return 1;
  }
};
