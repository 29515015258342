import { ExternalAddressDTO } from '@reposit/api-client';
import React from 'react';
import { getDisplayAddress } from '../../utils/common.utils';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';
import Select from '../FormFields/Select/index';

interface SearchedAddressListProps {
  addresses?: (ExternalAddressDTO)[];
  currentAddress: string;
  setAddress: (value: string) => void;
}

const SearchedAddressList: React.SFC<SearchedAddressListProps> = ({ addresses, currentAddress, setAddress }) => {
  return (
    <FieldWithLabel label="">
      <Select onChange={setAddress} value={currentAddress}>
        <option value="">Please select an address</option>
        {addresses &&
          addresses.map(address => {
            return (
              <option value={address.externalId} key={address.externalId}>
                {getDisplayAddress(address)}
              </option>
            );
          })}
      </Select>
    </FieldWithLabel>
  );
};

export default SearchedAddressList;
