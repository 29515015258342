import Hotjar from '@hotjar/browser';
import { appConfig } from '../../appConfig';

export const initialiseHotJar = () => {
  const {
    hotjar: { enabled, siteId, hotjarVersion },
  } = appConfig;

  if (enabled) Hotjar.init(siteId, hotjarVersion);
};
