import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { P2 } from '../Typography/index';

const Tooltip = styled.div`
  background: white;
  /* border: 3px solid ${(props) => props.theme.colors.primaryBrand}; */
  font-family: ${(props) => props.theme.typography.face.secondary};
  /* box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1); */
  max-width: 240px;
  /* padding: 20px; */
  /* margin: 0px; */
`;

const TooltipContent = styled(P2)`
  color: ${(props) => props.theme.colors.primaryBrand};
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.1px;
  padding: 0;
  margin: 0;
`;

export default () => {
  return (
    <ReactTooltip
      className="tooltip"
      getContent={(dataTip) => (
        <Tooltip>
          <TooltipContent>{dataTip}</TooltipContent>
        </Tooltip>
      )}
      effect="solid"
      border
      type={'light'}
      clickable={true}
    />
  );
};
