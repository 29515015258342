export const FETCH_ARBITRATION_STORE_KEY = 'ARBITRATION/FETCH_ARBITRATION';
export const FETCH_ARBITRATION_API_REQUESTED = `ARBITRATION/FETCH_ARBITRATION_API_REQUESTED`;
export const FETCH_ARBITRATION_API_SUCCESS = 'ARBITRATION/FETCH_ARBITRATION_API_SUCCESS';
export const FETCH_ARBITRATION_API_FAILED = 'ARBITRATION/FETCH_ARBITRATION_API_FAILED';

export function fetchArbitrationRequested(id: string) {
  return {
    type: FETCH_ARBITRATION_API_REQUESTED,
    payload: {
      id,
    },
  } as const;
}

export function fetchArbitrationSuccess() {
  return {
    type: FETCH_ARBITRATION_API_SUCCESS,
  } as const;
}

export function fetchArbitrationFailed(error: string) {
  return {
    type: FETCH_ARBITRATION_API_FAILED,
    payload: error,
  } as const;
}

export type ArbitrationActionTypes = ReturnType<
  typeof fetchArbitrationRequested | typeof fetchArbitrationSuccess | typeof fetchArbitrationFailed
>;
