import styled from 'styled-components';

export const MainContent = styled.div<{ isAuth: boolean }>`
  background: ${(props) => props.theme.colors.bgAccent};
  padding: ${(props) => (props.isAuth ? '0' : '120px 0 48px 0;')};
  width: 100%;
  height: ${(props) => (props.isAuth ? '100vh' : undefined)};
`;

export const DisableComponent = styled.div<{ disabled: boolean }>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  user-select: none;
  transition: 0.22s all ease;
`;
