import { UserDTO } from '@reposit/api-client';

export const identifyHubspotUser = (user: UserDTO) => {
  (window as any).hsConversationsSettings = {
    identificationEmail: user.email,
    identificationToken: user.hubspotVisitorId || '',
  };
};

export const loadHubspotWidget = () => {
  (window as any).HubSpotConversations.widget.load();
};
