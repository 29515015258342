import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentsApi, DocumentDTO, ClaimItemDocumentsApi, ClaimItemDocumentDTO } from '@reposit/api-client';
import { get } from 'lodash';
import { DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../../utils/common.utils';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';
import { runThunkWithAuth, createStandardDocumentsApi, createStandardClaimItemDocumentsApi } from '../utils/api.utils';
import { deleteClaimItemDocumentSuccess } from '../claim/claim.actions';
import { getClaimItemDocumentByDocumentId } from '../selectors/mediation.selectors';

export interface CreateDocumentPayload {
  type: string;
  file: File;
  isClaimItem: boolean;
}

export interface CreateClaimItemDocumentPayload {
  claimId: string;
  claimItemId: string;
  claimItemProposalId: string;
  type: string;
  file: File;
  isClaimItem: boolean;
}

export interface DeleteClaimItemDocumentPayload {
  claimId: string;
  claimItemProposalId: string;
  claimItemId: string;
  documentId: string;
}

const CREATE_DOCUMENT_STORE_KEY = 'DOCUMENTS/CREATE';
export const CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY = 'DOCUMENTS/CREATE_CLAIM_ITEM_DOCUMENT';
const DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY = 'DOCUMENTS/DELETE_CLAIM_ITEM_DOCUMENT';

export const createDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateDocumentPayload,
  {
    state: AppState;
  }
>('document/create-document', async (payload, thunkAPI) => {
  try {
    const documentsApi: DocumentsApi = createStandardDocumentsApi(thunkAPI.getState());
    const apiResponse: AxiosResponse<DocumentDTO> = await runThunkWithAuth(
      () => documentsApi.createDocument(payload.file, payload.file.name, payload.type),
      thunkAPI.dispatch
    );
    return apiResponse.data;
  } catch (e) {
    const error = getErrorMessage(e);
    thunkAPI.dispatch(setFlashMessage({ key: CREATE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const createClaimItemDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateClaimItemDocumentPayload,
  {
    state: AppState;
  }
>('document/create-claim-item-document', async (payload, thunkAPI) => {
  try {
    const api: ClaimItemDocumentsApi = createStandardClaimItemDocumentsApi(thunkAPI.getState());
    const apiResponse: AxiosResponse<ClaimItemDocumentDTO> = await runThunkWithAuth(
      () =>
        api.addClaimItemDocument(
          payload.claimId,
          payload.claimItemId,
          payload.file,
          payload.file.name,
          payload.type,
          payload.claimItemProposalId
        ),
      thunkAPI.dispatch
    );
    return apiResponse.data.document;
  } catch (e) {
    const error = getErrorMessage(e);
    thunkAPI.dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const deleteClaimItemDocumentThunk = createAsyncThunk<
  DocumentEntity,
  DeleteClaimItemDocumentPayload,
  {
    state: AppState;
  }
>('document/delete-claim-item-document', async (payload, thunkAPI) => {
  try {
    const api: ClaimItemDocumentsApi = createStandardClaimItemDocumentsApi(thunkAPI.getState());
    const apiResponse: AxiosResponse<DocumentDTO> = await runThunkWithAuth(
      () => api.deleteClaimItemDocument(payload.claimItemId, payload.claimId, payload.documentId),
      thunkAPI.dispatch
    );
    const claimItemDocument = getClaimItemDocumentByDocumentId(thunkAPI.getState(), payload.documentId);
    const claimItemDocumentId = get(claimItemDocument, 'id', '');
    thunkAPI.dispatch(deleteClaimItemDocumentSuccess({ claimItemProposalId: payload.claimItemProposalId, claimItemDocumentId }));
    return apiResponse.data;
  } catch (e) {
    const error = getErrorMessage(e);
    thunkAPI.dispatch(setFlashMessage({ key: DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
