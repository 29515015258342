import React, { Fragment } from 'react';
import ResponsiveModal from '../../../../components/Modal/responsive-modal';
import TenantTerms from './tenant-terms';
import Button from '../../../../components/Button';

interface TermsModalProps {
  closeTerms: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ closeTerms }) => {
  return (
    <ResponsiveModal onDismiss={closeTerms}>
      <Fragment>
        <TenantTerms />

        <Button
          style={{ margin: 0, position: 'fixed', bottom: 20, left: '50%', zIndex: 1000, transform: 'translateX(-50%)' }}
          onClick={closeTerms}
        >
          Close
        </Button>
      </Fragment>
    </ResponsiveModal>
  );
};

export default TermsModal;
