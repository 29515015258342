import {
  FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_SUCCESS,
  PaymentPlanInstalmentActionTypes,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS,
} from './payment-plan-instalment.actions';
import { PaymentPlanInstalmentState } from './payment-plan-instalment.types';

const initialState: PaymentPlanInstalmentState = {
  instalmentPaymentIntentSecret: '',
  isPolling: false,
};

export default (
  state: PaymentPlanInstalmentState = initialState,
  action: PaymentPlanInstalmentActionTypes
): PaymentPlanInstalmentState => {
  switch (action.type) {
    case FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_SUCCESS: {
      return {
        ...state,
        instalmentPaymentIntentSecret: action.payload.paymentIntentSecret,
      };
    }

    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED: {
      return {
        ...state,
        isPolling: true,
      };
    }

    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS: {
      return {
        ...state,
        isPolling: false,
      };
    }

    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED: {
      return {
        ...state,
        isPolling: false,
      };
    }

    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED: {
      return {
        ...state,
        isPolling: false,
      };
    }
    default:
      return state;
  }
};
