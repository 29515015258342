import { ArbitrationsApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createArbitrationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ArbitrationActionTypes,
  fetchArbitrationFailed,
  fetchArbitrationSuccess,
  FETCH_ARBITRATION_API_REQUESTED,
} from './arbitration.actions';
import { FetchArbitrationPayload } from './arbitration.types';

// ****************
// WORKERS
// ****************
export function* fetchArbitration({ payload }: { type: string; payload: FetchArbitrationPayload }) {
  try {
    const arbitrationsApi: ArbitrationsApi = yield createArbitrationsApi();
    const { data } = yield call(runSagaWithAuth(() => arbitrationsApi.getArbitrationById(payload.id)));
    yield syncEntitiesAndGetResults({ ...data }, SCHEMA.arbitration);
    yield put<ArbitrationActionTypes>(fetchArbitrationSuccess());
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ArbitrationActionTypes>(fetchArbitrationFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchArbitrationSagas() {
  yield takeLatest(FETCH_ARBITRATION_API_REQUESTED, fetchArbitration);
}
