export const PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_STORE_KEY = 'PAYMENT_PLAN_ACTIONS/PAYMENT_DETAILS';
export const PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_API_SUCCESS = 'PAYMENT_PLAN_ACTIONS/PAYMENT_DETAILS_API_SUCCESS';
export const PAYMENT_PLAN_ACTIONS_ADDRESS_STORE_KEY = 'PAYMENT_PLAN_ACTIONS/ADDRESS';
export const PAYMENT_PLAN_ACTIONS_CONFIRM_STORE_KEY = 'PAYMENT_PLAN_ACTIONS/CONFIRM';

export function paymentPlanActionsCardDetailsSuccess(paymentPlanId: string) {
  return {
    type: PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_API_SUCCESS,
    payload: {
      paymentPlanId,
    },
  } as const;
}

export type PaymentPlanActionActionTypes = ReturnType<typeof paymentPlanActionsCardDetailsSuccess>;
