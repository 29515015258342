import { AddressLookupState } from './address-history.types';
import {
  AddresHistoryActionTypes,
  ADDRESS_LOOKUP_API_SUCCESS,
  ADDRESS_LOOKUP_API_FAILED,
  SET_IS_SHOWING_SEARCH_MODAL,
  SET_IS_ADD_NEW_FORM_OPEN
} from './address-history.actions';

const initialState: AddressLookupState = {
  searchedAddresses: [],
  isShowingSearchModal: false,
  isAddNewFormOpen: false,
  error: null
};

export default (state: AddressLookupState = initialState, action: AddresHistoryActionTypes): AddressLookupState => {
  switch (action.type) {
    case ADDRESS_LOOKUP_API_SUCCESS: {
      return {
        ...state,
        searchedAddresses: action.payload.results
      };
    }
    case ADDRESS_LOOKUP_API_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }

    case SET_IS_SHOWING_SEARCH_MODAL:
      return {
        ...state,
        isShowingSearchModal: action.payload.isShowingSearchModal
      };

    case SET_IS_ADD_NEW_FORM_OPEN:
      return {
        ...state,
        isAddNewFormOpen: action.payload.isAddNewFormOpen
      };

    default:
      return state;
  }
};
