import { AccountState } from './account.types';
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchMeSuccess,
  fetchMeFailed,
  fetchCustomerSuccess,
  setHasRequestedPasswordReset,
  setHasSessionBeenCleared,
} from './account.actions';
import { resetPasswordSuccess } from '../auth/auth.actions';

const initialState: AccountState = {
  currentUserId: '',
  currentCustomerId: '',
  hasFetched: false,
  hasRequestedResetPassword: false,
  hasResetPassword: false,
  persistedEmail: '',
  hasSessionBeenCleared: false,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMeSuccess, (state, action) => {
      state.hasFetched = true;
      state.currentUserId = action.payload;
    })
    .addCase(fetchMeFailed, (state, action) => {
      state.hasFetched = true;
      state.error = action.payload;
    })
    .addCase(fetchCustomerSuccess, (state, action) => {
      state.currentCustomerId = action.payload;
    })
    .addCase(setHasRequestedPasswordReset, (state, action) => {
      state.hasRequestedResetPassword = action.payload;
    })
    .addCase(resetPasswordSuccess, (state, action) => {
      state.hasResetPassword = true;
      state.persistedEmail = action.payload;
    })
    .addCase(setHasSessionBeenCleared, (state, action) => {
      state.hasSessionBeenCleared = action.payload;
    });
});
