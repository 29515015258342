import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { Header3, P1 } from '../../components/Typography/index';

export const AddPaymentMethodSuccess: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Payment Method Added</PageTitle>
          <Card>
            <Header3>Thanks!</Header3>
            <P1>Your payment method has been added to your account, we will be in touch if we need anything further.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
