import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCardCheckErrorModal } from '../../redux/card-check/card-check.actions';
import Button from '../Button';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import { CardCheckErrorData } from '../../redux/card-check/card-check.types';
import BlockNegativeIcon from '../../assets/svg/block-negative.svg';
const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface CardCheckErrorProps {
  failedChecks: CardCheckErrorData;
}

const RedText = styled(P1)`
  color: ${(props) => props.theme.colors.negative};
  font-weight: bold;
`;

export const renderFailedCheckMessage = (check: keyof CardCheckErrorData) => {
  switch (check) {
    case 'nameMatch':
      return (
        <RedText bold key={check}>
          The cardholder's name does not match the name you provided during registration. Your payment card must be in your own
          name.
        </RedText>
      );
    case 'cvc':
      return (
        <RedText bold key={check}>
          The security code check failed. Please ensure you've entered the correct security code for this card.
        </RedText>
      );
    case 'funding':
      return (
        <RedText bold key={check}>
          We are unable to accept prepaid cards. Please try again with a credit or debit card.
        </RedText>
      );
    case 'postcode':
      return (
        <RedText bold key={check}>
          The postal code check failed. Please ensure you've entered the correct billing address.
        </RedText>
      );
    default:
      return null;
  }
};

const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    margin-bottom: 8px;
    padding-left: 20px;

    &::before {
      content: '•';
      position: absolute;
      left: 0;
      color: ${(props) => props.theme.colors.negative};
    }
  }
`;

export const FailedChecksMessages: React.FC<{ failedChecks: CardCheckErrorData }> = ({ failedChecks }) => (
  <StyledUl>
    {Object.entries(failedChecks)
      .filter(([_, status]) => status === 'fail')
      .map(([check]) => (
        <li key={check}>{renderFailedCheckMessage(check as keyof CardCheckErrorData)}</li>
      ))}
  </StyledUl>
);

const HeaderWithIcon = styled(Header3)`
  display: flex;
  align-items: center;

  &:after {
    content: '';
    background: transparent url(${BlockNegativeIcon}) center center no-repeat;
    background-size: 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-left: 8px;
  }
`;

const CardCheckErrorModal: React.FC<CardCheckErrorProps> = ({ failedChecks }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCardCheckErrorModal(false));
  };

  return (
    <Modal onDismiss={handleClose}>
      <HeaderWithIcon>Payment Failed</HeaderWithIcon>
      <P1>Your payment failed for the following reason(s):</P1>
      <FailedChecksMessages failedChecks={failedChecks} />
      <P1>Please try to make payment again, ensuring you have entered the correct details.</P1>
      <Action>
        <Button onClick={handleClose}>Close</Button>
      </Action>
    </Modal>
  );
};

export default CardCheckErrorModal;
