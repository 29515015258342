import { CardCheckErrorData } from './card-check.types';

export const SET_CARD_CHECK_ERROR_MODAL = 'SET_CARD_CHECK_ERROR_MODAL';
export const SET_CARD_CHECK_ERROR_DATA = 'SET_CARD_CHECK_ERROR_DATA';

export function setCardCheckErrorModal(isOpen: boolean) {
  return {
    type: SET_CARD_CHECK_ERROR_MODAL,
    payload: isOpen,
  } as const;
}

export function setCardCheckErrorData(failedChecks: CardCheckErrorData) {
  return {
    type: SET_CARD_CHECK_ERROR_DATA,
    payload: failedChecks,
  } as const;
}

export type CardCheckActionTypes = ReturnType<typeof setCardCheckErrorModal> | ReturnType<typeof setCardCheckErrorData>;
