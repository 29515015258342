import React, { Fragment, useState } from 'react';
import SecondaryPanel from '../../../../components/SecondaryPanel';
import { Header4, P2, Header3 } from '../../../../components/Typography';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import { getBreakpoint } from '../../../../base/style';
import { getAddressFirstLine } from '../../../../utils/common.utils';
import { AddressWithDatesEntity } from '../../../../redux/entities/entities.types';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeAddressHistoryRequested,
  REMOVE_ADDRESS_STORE_KEY,
} from '../../../../redux/address-history/address-history.actions';
import Modal from '../../../../components/Modal/index';
import Loading from '../../../../components/Loading/index';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';

interface AddressItemProps {
  address: AddressWithDatesEntity;
}

const Inner = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
  }
`;

const Action = styled.div`
  margin: 24px 0 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 auto;
  }
`;

const AddressSummary = styled(Header4)`
  margin: 0;
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;
  margin: 0 0 0 10px;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const AddressItem: React.FC<AddressItemProps> = ({ address }) => {
  const dispatch = useDispatch();
  const [isModalShowing, setIsModalShowing] = useState(false);
  const getIsRemoveLoading = createLoadingSelector([REMOVE_ADDRESS_STORE_KEY]);
  const isRemoveLoading = useSelector(getIsRemoveLoading);

  return (
    <Fragment>
      <SecondaryPanel>
        <Inner>
          <div>
            <AddressSummary>{getAddressFirstLine(address)}</AddressSummary>
          </div>
          <Action>
            <RemoveButton
              noArrow
              mini={'true'}
              onClick={() => {
                setIsModalShowing(true);
              }}
            >
              Remove
            </RemoveButton>
          </Action>
        </Inner>
      </SecondaryPanel>
      {isModalShowing ? (
        <Modal onDismiss={() => setIsModalShowing(false)}>
          {isRemoveLoading ? (
            <Loading />
          ) : (
            <Fragment>
              <Header3 style={{ marginBottom: 6 }}>You are about to remove your current address</Header3>
              <P2 style={{ marginBottom: 24 }}>Are you sure you wish to remove it?</P2>
              <ModalAction>
                <Button buttonType="secondary" noArrow={true} onClick={() => setIsModalShowing(false)}>
                  No, return to form
                </Button>
                <Button onClick={() => dispatch(removeAddressHistoryRequested(address.id))}>Yes</Button>
              </ModalAction>
            </Fragment>
          )}
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default AddressItem;
