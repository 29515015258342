import React from 'react';
import Card from '../../../../../components/Card';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';
import { Header3, P1 } from '../../../../../components/Typography';

interface ReferenceFailedProps {}

const Subtitle = styled(Header3)`
  font-family: ${props => props.theme.typography.face.secondary};
  color: ${props => props.theme.colors.negative};
`;

const ReferenceFailed: React.FC<ReferenceFailedProps> = props => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>It’s not good news</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <Subtitle>You haven’t been successful in passing your reference check</Subtitle>
            <P1>
              Your reference with Reposit has failed based on your current status. This happens when both you and your guarantor
              fail to meet our criteria.
            </P1>
            <P1>
              Unfortunatley this means you cannot use our service at this time. Please speak to your landlord or letting agent to
              find out how to proceed with your tenancy.
            </P1>
            <P1>You may find you are eligible next time you rent, so we hope you try Reposit again in the future.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceFailed;
