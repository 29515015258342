import React from 'react';
import styled from 'styled-components';
import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import TickSuccess from '../../assets/svg/tick-success.svg';

export interface StageProps {
  label?: string;
  icon?: string | null;
  iconActive?: string | null;
  isActive?: boolean;
  isComplete?: boolean;
  urls?: string[];
  actions?: OrderCustomerActionDTOTypeIdEnum[];
  isSingle?: boolean;
}

const StageItem = styled.div<StageProps>`
  align-items: center;
  background: ${(props) => (props.isActive ? '#fff' : props.theme.colors.bgAccent)};
  border: ${(props) => (props.isActive ? `1px solid transparent` : `1px solid ${props.theme.colors.tertiaryBrand}`)};
  border-radius: 18.5px;
  display: flex;
  flex: 1 1 0px;
  padding: 9px 20px;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: ${(props) => (props.isActive ? props.theme.colors.body : props.theme.colors.tertiaryBrand)};
  justify-content: flex-start;
  margin: 0 10px;
  z-index: 2;
  transition: all 0.5s;

  box-shadow: ${(props) => (props.isActive ? `0 2px 3px 0 rgba(0, 0, 0, 0.1)` : `none`)};

  &:first-child {
    margin: 0 10px 0 0;
  }

  &:last-child {
    margin: 0 0 0 10px;
  }

  &:only-child {
    margin: 0;
  }

  &:before {
    background: transparent url(${(props) => (props.isActive ? props.iconActive : props.icon)}) no-repeat center center;
    background-size: 11px 12px;
    content: '';
    display: block;
    margin: 0 8px 0 0;
    height: 16px;
    width: 16px;
  }

  &.complete {
    background: #fff;
    border: none;

    &:before {
      background: transparent url(${TickSuccess}) no-repeat center center;
      background-size: 11px 12px;
      content: '';
      display: block;
      margin: 0 8px 0 0;
      height: 16px;
      width: 16px;
    }
  }
`;

const Stage: React.FC<StageProps> = ({ label, isActive, icon, iconActive, isComplete }) => {
  return (
    <StageItem icon={icon} iconActive={iconActive} isActive={isActive} className={isComplete ? `complete` : ''}>
      {label}
    </StageItem>
  );
};

export default Stage;
