import {
  PaymentPlanInstalmentActionTypes,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS,
} from '../../payment-plan-instalment/payment-plan-instalment.actions';
import { PaymentPlanEntity } from '../entities.types';
import { PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client/dist';
import {
  PaymentPlanActionActionTypes,
  PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_API_SUCCESS,
} from '../../payment-plan-actions/payment-plan-actions.actions';

export const paymentPlanEntityReducer = (
  state: any = {},
  action: PaymentPlanInstalmentActionTypes | PaymentPlanActionActionTypes
) => {
  switch (action.type) {
    case POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS: {
      const { paymentPlanId, paymentPlanInstalmentId } = action.payload;

      const pp: PaymentPlanEntity = state[paymentPlanId];

      const newInstalments = pp.instalments?.map((i) => {
        if (i.id === paymentPlanInstalmentId) {
          return {
            ...i,
            status: PaymentPlanInstalmentDTOStatusEnum.PAID,
          };
        }
        return i;
      });

      return {
        ...state,
        [paymentPlanId]: {
          ...state[paymentPlanId],
          instalments: newInstalments,
        },
      };
    }

    case PAYMENT_PLAN_ACTIONS_PAYMENT_DETAILS_API_SUCCESS: {
      const { paymentPlanId } = action.payload;
      const pp: PaymentPlanEntity = state[paymentPlanId];
      return {
        ...state,
        [paymentPlanId]: {
          ...pp,
          nextAction: undefined,
        } as PaymentPlanEntity,
      };
    }
  }
  return state;
};
