import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createFlashMessagesSelector } from '../../redux/flash-messages/flash-messages.selector';
import { dismissFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import styled from 'styled-components';
import FlashMessage from '../../components/FlashMessage/index';

import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';

interface FlashMessageContainerProps {}

const subscribedMessages: string[] = [];

const StyledFlashMessageContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  margin: 24px 0 0 240px;
  padding: 0 50px;
  width: calc(100% - 240px);
  z-index: 9000;
`;

export const FLASH_MESSAGE_TIMOUT = 5000;

export const useFlashMessage = (storeKeys: string[]): [FlashMessagePayload, () => void] => {
  const dispatch = useDispatch();
  const getFlashMessageSelector = createFlashMessagesSelector(storeKeys);
  const flashMessage = useSelector(getFlashMessageSelector);
  const onDismissFlashMessage = () => dispatch(dismissFlashMessage(storeKeys));

  return [flashMessage, onDismissFlashMessage];
};

const FlashMessageContainer: React.FC<FlashMessageContainerProps> = props => {
  const [flashMessage, onDismissFlashMessage] = useFlashMessage(subscribedMessages);
  return flashMessage ? (
    <StyledFlashMessageContainer>
      <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} payload={flashMessage} />
    </StyledFlashMessageContainer>
  ) : null;
};

export default FlashMessageContainer;
