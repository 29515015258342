import { ClaimActionTypes, DELETE_CLAIM_ITEM_DOCUMENT_SUCCESS } from '../../claim/claim.actions';

export const claimItemProposalEntityReducer = (state: any = {}, action: ClaimActionTypes) => {
  if (action.type === DELETE_CLAIM_ITEM_DOCUMENT_SUCCESS) {
    return {
      ...state,
      [action.payload.claimItemProposalId]: {
        ...state[action.payload.claimItemProposalId],
        documents: state[action.payload.claimItemProposalId].documents.filter(
          (d: string) => d !== action.payload.claimItemDocumentId
        ),
      },
    };
  }
  return state;
};
