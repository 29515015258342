import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import { useAppDispatch } from '../..';
import ThumbsUp from '../../assets/svg/material-thumbs-up.svg';
import CardReady from '../../assets/svg/progress/card-active.svg';
import Button from '../../components/Button';
import FlashMessage from '../../components/FlashMessage';
import RepositCard from '../../components/Reposit/RepositCard';
import { Header2, Header3, P1 } from '../../components/Typography';
import {
  completeOnboardingIntroductionThunk,
  USER_COMPLETE_ONBOARDING_INTRODUCTION_STORE_KEY,
} from '../../redux/user/user.thunk';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage';
import { IconAndSubheading } from '../MediationIntroduction';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

interface OnboardingIntroductionProps {
  setShowOnboardingIntroduction: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnboardingIntroduction: React.FC<OnboardingIntroductionProps> = ({ setShowOnboardingIntroduction }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([USER_COMPLETE_ONBOARDING_INTRODUCTION_STORE_KEY]);

  const completeOnboardingIntroduction = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(completeOnboardingIntroductionThunk()).then(unwrapResult);
      setShowOnboardingIntroduction(false);
    } catch (error) {
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Header2>Welcome to Reposit</Header2>
      <RepositCard
        title="In good hands"
        icon={ThumbsUp}
        flashMessage={
          flashMessagePayload && (
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} payload={flashMessagePayload} />
          )
        }
        children={
          <>
            <P1>Welcome to Reposit! We're excited to have you on board. Before we proceed, there's an important reminder:</P1>
            <Header3 style={{ marginTop: '24px' }}>
              <IconAndSubheading subheading="Having your card ready" icon={CardReady} />
            </Header3>
            <P1>
              You'll need a <BoldSpan>debit or credit card in your name</BoldSpan> to proceed. This is crucial for account setup
              and future transactions.
            </P1>
            <P1>
              If you have your card ready, click the button below to proceed. If not, please prepare your card before continuing.
            </P1>
          </>
        }
      />

      <Action>
        <Button onClick={completeOnboardingIntroduction} disabled={isSubmitting}>
          {isSubmitting ? 'Processing...' : 'I understand'}
        </Button>
      </Action>
    </Container>
  );
};

export default OnboardingIntroduction;
