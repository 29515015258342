import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import * as Yup from 'yup';
import poundIcon from '../../../assets/svg/pound-sterling.svg';
import { getBreakpoint } from '../../../base/style';
import { RepositTheme } from '../../../base/theme';
import { CLAIM_UPLOAD_INFO } from '../../../constants/claim';
import { useIsMobile } from '../../../hooks/useViewport';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { LOW_PROPOSAL_PERCENTAGE_THRESHOLD } from '../../../redux/selectors/mediation.selectors';
import { penceToPounds, poundsToPence } from '../../../utils/common.utils';
import { twoDPRegex } from '../../../utils/regex/number.regex';
import Button from '../../Button/index';
import FormErrorMessage from '../../FormFields/ErrorMessage';
import FieldWithLabel, { FieldLabel } from '../../FormFields/FieldWithLabel/index';
import { Input } from '../../FormFields/index';
import { P3 } from '../../Typography';
import { FileUpload, FileUploadProgress } from '../FileUpload/FileUpload';
import { HEADER_HEIGHT } from './ClaimItem/ClaimItemDropdown';

interface ExplanationProps {
  showOffer: boolean;
  showBorder?: boolean;
  required: boolean;
  uploadFile: (file: File) => Promise<DocumentEntity>;
  deleteFile: (id: string) => Promise<any>;
  onError: (error: string) => void;
  onUploadComplete?: () => void;
  showDeleteButton: boolean;
  onSubmit: (values: ExplanationFormValues) => Promise<any>;
  isSubmitting: boolean;
  negotiationDocuments?: DocumentEntity[];
  negotiationAmount?: number;
  negotiationExplanation?: string;
  agentProposalAmount: number;
  panelId: string;
}

interface TextAreaProps {
  touched: boolean | undefined;
  error: string | undefined;
}

export interface ExplanationFormValues {
  amount?: number;
  documentIds?: string[];
  explanation?: string;
}

interface NotifierProps {
  color?: string;
}

type TenancyProposalWarningOptions = 'zero' | 'low' | 'none';

const Schema = Yup.object().shape({
  explanation: Yup.string().required('You must provide reasoning for your new proposal for this item'),
  amount: Yup.number()
    .when(['$agentProposalAmount'], (agentProposalAmount: number) =>
      Yup.number()
        .max(agentProposalAmount / 100, `New offer cannot exceed the charges raised (£${penceToPounds(agentProposalAmount)}).`)
        .typeError('Amount must be a number')
        .min(0)
        .required('Required')
        .test('two-decimals', 'Item amount must have a maximum of two decimal places', (value) => {
          return twoDPRegex.test(value);
        })
    )
    .typeError('Amount must be a number')
    .min(0)
    .required('Required')
    .test('two-decimals', 'Item amount must have a maximum of two decimal places', (value) => {
      return twoDPRegex.test(value);
    }),
  documentIds: Yup.array(Yup.string()),
});

const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: ${(props) => props.theme.typography.face.primary};
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid ${(props) => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0.1)')};
  min-height: 110px;
  border-radius: 3px;
  outline: none;
  font-size: 16px;

  &:focus {
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${(props) => props.theme.colors.body};
  }

  @media screen and (min-width: ${getBreakpoint('md')}) {
    max-height: 130px;
  }
`;

const Notifier = styled(P3)<NotifierProps>`
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 8px;
  display: inline-block;
  color: ${(props) => (props.color ? props.color : props.theme.colors.body2)};
`;

const UploadInfo = () => (
  <Notifier data-tip={CLAIM_UPLOAD_INFO} data-offset="{'right': 70}">
    What can I upload?
  </Notifier>
);

const ProposalWarning: React.FC<{ tenantProposalWarning: TenancyProposalWarningOptions }> = ({ tenantProposalWarning }) => {
  const warningText =
    tenantProposalWarning === 'zero'
      ? 'Warning! It’s unlikely your offer will be accepted, unless this charge is incorrect. Please consider a proposal which covers a portion of the cost'
      : 'Woah! Your offer looks a little low. We recommend double checking the reason for the charge and consider entering a higher amount, to increase the chances of your proposal being accepted';
  return (
    <P3
      style={{
        display: tenantProposalWarning === 'none' ? 'none' : 'block',
        textDecoration: 'none',
        color: RepositTheme.colors.warning,
      }}
    >
      {warningText}
    </P3>
  );
};

export const Explanation = (props: ExplanationProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const [documents, setDocuments] = useState<DocumentEntity[]>(get(props, 'negotiationDocuments', []));
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();
  const [tenantProposalWarning, setTenantProposalWarning] = useState<TenancyProposalWarningOptions>('none');

  useEffect(() => {
    if (!!props.negotiationAmount || props.negotiationAmount === 0) {
      const zeroProposal = props.negotiationAmount === 0;
      const lowProposal = props.negotiationAmount < props.agentProposalAmount * LOW_PROPOSAL_PERCENTAGE_THRESHOLD;
      if (zeroProposal) {
        setTenantProposalWarning('zero');
      } else if (lowProposal) {
        setTenantProposalWarning('low');
      }
    }
  }, [props.negotiationAmount, props.agentProposalAmount]);

  const initValues: ExplanationFormValues = {
    amount: props.negotiationAmount !== undefined && props.negotiationAmount !== null ? props.negotiationAmount / 100 : undefined,
    explanation: props.negotiationExplanation,
    documentIds: props.negotiationDocuments && props.negotiationDocuments.map((nd) => nd.id),
  };

  const isEditing = !!(props.negotiationAmount || props.negotiationExplanation);

  const handleDeleteDocument = async (id: string) => {
    if (isEditing) {
      await props.deleteFile(id);
    }
    return Promise.resolve(setDocuments((currDocs) => currDocs.filter((d) => d.id !== id)));
  };

  const isMobile = useIsMobile();

  const SaveButton = styled(Button)`
    justify-content: center;
    font-size: 0.76em;
    width: 100%;
    padding: 8px 4px;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      font-size: 0.68em;
      width: auto;
      // original - had to set because we changed it above - ugh
      padding: 0.5em 1.5em 0.5em 1.75em;
    }
  `;

  const FileUploadCol = styled(Col)<{ explanationErrorMessageShowing: boolean }>`
    order: 2;
    margin-top: ${(props) => (props.explanationErrorMessageShowing ? '30px' : '4px')};

    @media screen and (min-width: ${getBreakpoint('md')}) {
      order: 1;
      margin-top: 0px;
    }
  `;

  const TextAreaCol = styled(Col)`
    order: 1;
    margin-top: 8px;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      order: 2;
      margin-top: 10px;
    }
  `;

  const scrollToClaimItem = () => {
    const el = document.getElementById(props.panelId);
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - HEADER_HEIGHT;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <Formik
      initialValues={initValues}
      validate={async (values) => {
        try {
          if (documents && documents.length) {
            values.documentIds = documents.map((doc) => doc && doc.id);
          } else {
            // need this for when items are added and then removed
            // values.documentIds stays in state other wise
            values.documentIds = [];
          }

          await validateYupSchema(values, Schema, true, {
            agentProposalAmount: props.agentProposalAmount,
          });
        } catch (e) {
          const formErrors = yupToFormErrors(e);
          // v2 formik requires a resolved promise
          return formErrors;
        }
      }}
      onSubmit={async (values) => {
        await props.onSubmit({
          ...values,
          amount: values.amount !== null && values.amount !== undefined ? poundsToPence(values.amount) : undefined,
          documentIds: documents.map((doc) => doc.id),
        });
        scrollToClaimItem();
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }: FormikProps<ExplanationFormValues>) => {
        const explanationErrorMessageShowing = !!(touched.explanation && errors.explanation);
        const updateTenantProposalWarning = () => {
          const tenantProposalThreshold = (props.agentProposalAmount * LOW_PROPOSAL_PERCENTAGE_THRESHOLD) / 100;
          const tenantEnteredZero = values.amount === 0;
          const tenantProposalLow = !!(values.amount && values.amount < tenantProposalThreshold);
          if (tenantEnteredZero) {
            setTenantProposalWarning('zero');
          } else if (tenantProposalLow) {
            setTenantProposalWarning('low');
          } else {
            setTenantProposalWarning('none');
          }
        };
        return (
          <div style={{ width: '100%' }}>
            <form onSubmit={handleSubmit}>
              {props.showOffer ? (
                <Row>
                  <Col xs={12} md={6}>
                    <FieldWithLabel label="Proposed Settlement*" touched={touched.amount} error={errors.amount}>
                      <Input
                        icon={poundIcon}
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={(val) => {
                          handleBlur(val);
                          updateTenantProposalWarning();
                        }}
                        touched={touched.amount}
                        error={errors.amount}
                        type="number"
                      />
                    </FieldWithLabel>
                    <ProposalWarning tenantProposalWarning={tenantProposalWarning} />
                  </Col>
                </Row>
              ) : null}

              <FieldLabel style={{ marginTop: 10 }}>Supporting Evidence</FieldLabel>
              <UploadInfo />
              <Row>
                <FileUploadCol xs={12} md={6} explanationErrorMessageShowing={explanationErrorMessageShowing}>
                  <FileUpload
                    {...props}
                    documents={documents}
                    deleteFile={handleDeleteDocument}
                    onUploadComplete={(docs) => {
                      if (docs) {
                        setDocuments((currDocs) => [...currDocs, ...docs]);
                      }
                    }}
                    fileUploadProgress={fileUploadProgress}
                    setFileUploadProgress={setFileUploadProgress}
                  />
                </FileUploadCol>
                <TextAreaCol xs={12} md={6}>
                  <TextArea
                    placeholder="Enter your explanation"
                    name="explanation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.explanation && values.explanation.trimStart()}
                    error={errors.explanation}
                    touched={touched.explanation}
                  />
                  {touched.explanation && errors.explanation ? <FormErrorMessage error={errors.explanation} /> : null}
                </TextAreaCol>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10 }}>
                <SaveButton noArrow={isMobile} buttonType="primary" disabled={props.isSubmitting} type="submit">
                  Save
                </SaveButton>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};
