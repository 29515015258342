import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import RepositLogo from '../../../assets/png/primary-full-colour.png';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import { Header4, P2, StyledLink } from '../../../components/Typography/index';
import { setHasRequestedPasswordReset } from '../../../redux/account/account.actions';
import { getHasRequestedResetPassword } from '../../../redux/account/account.selectors';
import { resetPasswordRequestRequested, RESET_PASSWORD_REQUEST_STORE_KEY } from '../../../redux/auth/auth.actions';
import { getAccessToken } from '../../../redux/auth/auth.selectors';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { AuthButton, FormContainer, AuthCard, AuthContainer, LogoContainer, LogoImage } from '../Login/login';

interface ResetPasswordRequestFormProps {
  email: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
});

const FormView: React.FC<{ setEmail: (email: string) => void; email: string }> = ({ setEmail, email }) => {
  const dispatch = useDispatch();
  const selector = createLoadingSelector([RESET_PASSWORD_REQUEST_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([RESET_PASSWORD_REQUEST_STORE_KEY]);
  const isLoading = useSelector(selector);
  const error = useSelector(errorSelector);
  const errorMessage = error ? 'Something went wrong' : '';
  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Forgot Your Password?</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>
          Enter your email address and we will send you instructions to reset your password.
        </P2>
      </LogoContainer>
      <FormContainer>
        <Formik
          initialValues={{ email }}
          validationSchema={Schema}
          onSubmit={(data) => {
            setEmail(data.email);
            dispatch(resetPasswordRequestRequested(data.email));
          }}
        >
          {(props: FormikProps<ResetPasswordRequestFormProps>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <FieldWithLabel label="Email" error={errorMessage || props.errors.email} touched={props.touched.email}>
                  <Input
                    name="email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.email}
                    touched={props.touched.email}
                  />
                </FieldWithLabel>
                <AuthButton type="submit" disabled={isLoading}>
                  Continue
                </AuthButton>

                <StyledLink to="/auth/login" style={{ marginTop: '18px', display: 'block', textAlign: 'center' }}>
                  Back to Login
                </StyledLink>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </>
  );
};

const EmailSent: React.FC<{ email: string }> = ({ email }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(setHasRequestedPasswordReset(false));

  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Check Your Email</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>
          {`Please check the email address ${email} for instructions to reset your password.`}
        </P2>
        <AuthButton onClick={onClick}>Resend Email</AuthButton>
      </LogoContainer>
    </>
  );
};

export const ResetPasswordRequest: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAccessToken);
  const hasRequestedResetPassword = useSelector(getHasRequestedResetPassword);

  const [email, setEmail] = useState('');

  useEffect(() => {
    return function reset() {
      dispatch(setHasRequestedPasswordReset(false));
    };
  }, [dispatch]);

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <AuthContainer>
      <AuthCard>
        {hasRequestedResetPassword ? <EmailSent email={email} /> : <FormView setEmail={setEmail} email={email} />}
      </AuthCard>
    </AuthContainer>
  );
};
