import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Caption, Header3 } from '../../../../../components/Typography';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-grid-system';
import LoadingIcon from '../../../../../assets/svg/loader.svg';
import PageTitle from '../../../../../components/PageTitle';
import Card from '../../../../../components/Card';
import { pollForReferenceCancelled } from '../../../../../redux/referencing/referencing.actions';

interface ReferenceLoadingProps {}

const ReferenceInProgress = styled.div`
  padding: 36px 0 24px;
  text-align: center;
`;

const Loader = styled.div`
  background: transparent url(${LoadingIcon}) no-repeat 0 0;
  background-size: 100%;
  height: 160px;
  margin: 48px auto 36px;
  width: 160px;
`;

const Subtitle = styled(Header3)`
  font-family: ${(props) => props.theme.typography.face.secondary};
  color: ${(props) => props.theme.colors.positive};
`;

const ReferenceLoading: React.FC<ReferenceLoadingProps> = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [timeoutId, setTimeoutId] = useState<number>();

  useEffect(() => {
    if (!timeoutId) {
      // Wait for 30s and hide the loader
      const id = setTimeout(() => {
        if (isLoading) {
          dispatch(pollForReferenceCancelled());
          setLoading(false);
        }
      }, 30000);
      setTimeoutId(id);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId, dispatch, isLoading]);

  const renderContent = () => {
    if (isLoading) {
      // show the loader between 30s
      return (
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            <ReferenceInProgress>
              <Loader />
              <Caption>Reference check in progress...</Caption>
            </ReferenceInProgress>
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <Row>
            <Col lg={10} push={{ lg: 1 }}>
              <PageTitle>We're working on this</PageTitle>
            </Col>
          </Row>
          <Row>
            <Col lg={10} push={{ lg: 1 }}>
              <Card>
                <Subtitle>Sorry, this is taking longer than usual. Please check back later.</Subtitle>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default ReferenceLoading;
