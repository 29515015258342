import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header2, P1 } from '../../components/Typography';

export const TenancyInventoryError: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header2 style={{ fontSize: '1.7em' }}>Something has gone wrong. </Header2>
          <P1 style={{ fontSize: '1.4em', marginTop: 24 }}>Please contact us via live chat and we'll get this sorted for you.</P1>
        </Col>
      </Row>
    </Container>
  );
};
