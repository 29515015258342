import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../../../components/Typography';
import WarningIcon from '../../../../assets/svg/warning.svg';

interface ErrorMessageProps {
  error?: string;
}

// copied from the form error message component
// didn't want to refactor the whole thing just for this
// (throwing this app away soon anyway)
const Error = styled.div`
  padding: 0;
`;

const ErrorMessage = styled(P3)`
  align-items: flex-start;
  color: ${(props) => props.theme.colors.negative};
  font-weight: bold;
  display: flex;
  line-height: 1;

  &:before {
    background: ${(props) => props.theme.colors.negative} url(${WarningIcon}) no-repeat 3px 3px;
    background-size: 8px;
    border-radius: 100px;
    color: #fff;
    content: '';
    font-size: 10px;
    flex: 0 0 14px;
    display: block;
    margin: -1px 8px 2px 0;
    height: 14px;
    width: 14px;
  }
`;

export const AboutYouErrorMessage: React.SFC<ErrorMessageProps> = ({ error }) => (
  <Error>
    <ErrorMessage>{error}</ErrorMessage>
  </Error>
);
