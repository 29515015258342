import React from 'react';
import styled from 'styled-components';
import { getBreakpoint } from '../../../../base/style';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../../components/FormFields/index';
import Button from '../../../../components/Button/index';
import { ExternalAddressDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import SelectAddressModal from '../../../../components/SelectAddressModal/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  addressLookupRequested,
  setIsShowingSearchModal,
  ADDRESS_HISTORY_STORE_KEY
} from '../../../../redux/address-history/address-history.actions';
import { getIsShowingSearchModal, getSearchedAddresses } from '../../../../redux/address-history/address-history-selectors';
import { StyledLink } from '../../../../components/Typography/index';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';

interface PostcodeLookupProps {
  onAddressSelect: (address?: ExternalAddressDTO) => void;
}

const PostcodeSearchForm = styled.form`
  padding: 0 36px 12px;
`;

const Wrapper = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: flex-start;
    display: flex;
  }
`;

const Action = styled.div`
  margin: 16px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 28px 20px 0;
  }
`;

const ManualLink = styled(StyledLink)`
  display: block;
  margin: 12px 0 0;
`;

interface PostcodeLookupFormValues {
  postcode: string;
}

const Schema = Yup.object().shape({
  postcode: Yup.string().required('Postcode is required')
});

const PostcodeLookup: React.FC<PostcodeLookupProps> = ({ onAddressSelect }) => {
  const dispatch = useDispatch();
  const isModalShowing = useSelector(getIsShowingSearchModal);
  const addressList = useSelector(getSearchedAddresses);
  const isAddressLookUpLoadingSelector = createLoadingSelector([ADDRESS_HISTORY_STORE_KEY]);
  const isAddressLookupLoading = useSelector(isAddressLookUpLoadingSelector);

  const showManualForm = () => {
    dispatch(setIsShowingSearchModal(false));
    onAddressSelect();
  };

  return (
    <Formik
      initialValues={{
        postcode: ''
      }}
      validationSchema={Schema}
      onSubmit={data => {
        dispatch(addressLookupRequested(data.postcode));
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }: FormikProps<PostcodeLookupFormValues>) => {
        return (
          <PostcodeSearchForm onSubmit={handleSubmit}>
            <Wrapper>
              {isModalShowing ? (
                <SelectAddressModal
                  addresses={addressList}
                  onDismiss={() => dispatch(setIsShowingSearchModal(false))}
                  onSubmit={address => {
                    dispatch(setIsShowingSearchModal(false));
                    onAddressSelect(address);
                  }}
                  onManualAddressSelect={showManualForm}
                />
              ) : null}
              <FieldWithLabel
                label="Postcode"
                style={{ width: '100%', maxWidth: 455 }}
                error={errors.postcode}
                touched={touched.postcode}
              >
                <Input
                  name="postcode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.postcode}
                  touched={touched.postcode}
                  value={values.postcode}
                />
              </FieldWithLabel>
              <Action>
                <Button buttonType="secondary" type="submit" disabled={isAddressLookupLoading}>
                  {isAddressLookupLoading ? `Loading...` : `Search Postcode`}
                </Button>
              </Action>
            </Wrapper>
            <ManualLink to="#" onClick={showManualForm}>
              Enter address manually
            </ManualLink>
          </PostcodeSearchForm>
        );
      }}
    </Formik>
  );
};

export default PostcodeLookup;
