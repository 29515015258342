import { Stripe, StripeElements } from '@stripe/stripe-js';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InfoIcon from '../../assets/svg/info-purple.svg';
import Card from '../../components/Card';
import CardCheckErrorModal, { FailedChecksMessages } from '../../components/CardCheckErrorModal/index';
import { HEADER_HEIGHT } from '../../components/Library/SupplierOffer/ClaimItem/ClaimItemDropdown';
import Loading from '../../components/Loading/index';
import { InfoModal } from '../../components/Modal/InfoModal';
import { Header3, P1, P2, P3 } from '../../components/Typography';
import { getCardCheckErrorData, getIsCardCheckErrorModalOpen } from '../../redux/card-check/card-check.selector';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { penceToPounds } from '../../utils/currency';
import { Price, Text, Total } from './components';
import { ElementsForm } from './ElementsForm';

const Icon = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 4px;
`;

const CardCheckFailuresCard = styled(Card)`
  margin-top: 0;
`;

interface PaymentContainerProps {
  fullWidth?: boolean;
  warningMessage?: React.FC;
  amount?: string;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, elements: StripeElements) => void;
  type: PaymentType;
  title: string;
  paymentAmountType?: PaymentAmountType;
  redWarningMessage?: boolean;
  paymentMode: 'setup' | 'payment';
}

export enum PaymentAmountType {
  SHARE = 'SHARE',
  REMAINING_BALANCE = 'REMAINING_BALANCE',
}

const TITLE_COLUMN_ID = 'title_column';

const PaymentContainer: React.FC<PaymentContainerProps> = ({
  fullWidth,
  warningMessage,
  amount,
  isSubmitting,
  submitCard,
  title,
  paymentAmountType,
  redWarningMessage = true,
  paymentMode,
}) => {
  const scrollToPaymentSection = () => {
    const el = document.getElementById(TITLE_COLUMN_ID);
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - HEADER_HEIGHT;
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 300);
    }
  };

  const [showShareInfoModal, setShowShareInfoModal] = useState(false);
  const isCardCheckErrorModalOpen = useSelector(getIsCardCheckErrorModalOpen);
  const failedChecks = useSelector(getCardCheckErrorData);

  useEffect(() => {
    scrollToPaymentSection();
  }, []);
  const infoModalParagraph1 = `To make things easier, we split the total charges between all of the tenants. This gives everyone a chance to settle the charges individually.`;
  const infoModalParagraph2 = `However, our terms & conditions state that all tenants are jointly and severally liable for the charges raised. This means that if your co-tenants do not pay, you remain equally responsible for these charges until they are fully paid off and could be charged later on.`;
  const infoModalParagraph3 = `Make sure you remind everyone to log in to their Reposit dashboard!`;
  return (
    <Container id={TITLE_COLUMN_ID}>
      {isSubmitting ? <Loading /> : null}
      <Row style={{ display: isSubmitting ? 'none' : 'flex' }}>
        <Col lg={fullWidth ? 6 : 4} push={{ lg: fullWidth ? 0 : 1 }}>
          <Card flush style={{ padding: 0 }}>
            <Fragment>
              <Text>{title}</Text>
              <Total>
                <Price>£{amount && penceToPounds(+amount)}</Price>
                <P1>payable today</P1>
              </Total>
            </Fragment>
          </Card>
          {paymentAmountType === PaymentAmountType.SHARE ? (
            <P3 style={{ textAlign: 'right', textDecoration: 'underline', cursor: 'pointer' }}>
              <span onClick={() => setShowShareInfoModal(true)} style={{ display: 'inline' }}>
                <Icon src={InfoIcon} />
                What does paying my share mean?
              </span>
            </P3>
          ) : null}
        </Col>
        <Col lg={6} push={{ lg: fullWidth ? 0 : 1 }}>
          {failedChecks ? (
            <CardCheckFailuresCard>
              <>
                <Header3>Payment card issues:</Header3>
                <FailedChecksMessages failedChecks={failedChecks} />
              </>
            </CardCheckFailuresCard>
          ) : null}
          {amount ? (
            <ElementsForm
              warningMessage={warningMessage}
              isSubmitting={isSubmitting}
              submitCard={submitCard}
              buttonText={'Confirm and pay now'}
              redWarningMessage={redWarningMessage}
              amount={Number(amount)}
              paymentMode={paymentMode}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
      {showShareInfoModal ? (
        <InfoModal
          title={'What does paying my share mean?'}
          content={
            <>
              <P2>{infoModalParagraph1}</P2>
              <P2>{infoModalParagraph2}</P2>
              <P2 style={{ marginBottom: 24 }}>{infoModalParagraph3}</P2>
            </>
          }
          onDismiss={() => setShowShareInfoModal(false)}
        />
      ) : null}
      {isCardCheckErrorModalOpen && failedChecks ? <CardCheckErrorModal failedChecks={failedChecks} /> : null}
    </Container>
  );
};

export default PaymentContainer;
