import { FetchTenancyUserPayload } from './tenancy-user.types';
import { TenancyUserDTO } from '@reposit/api-client';

export const FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_STORE_KEY =
  'TENANCY_USER/FETCH_TENANFETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_STORE_KEY';
export const FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED = `TENANCY_USER/FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED`;
export const FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_SUCCESS =
  'TENANCY_USER/FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_SUCCESS';
export const FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_FAILED = 'TENANCY_USER/FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_FAILED';

export function fetchTenancyUserFromOrderCustomerRequested(payload: FetchTenancyUserPayload) {
  return {
    type: FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED,
    payload,
  } as const;
}

export function fetchTenancyUserFromOrderCustomerSuccess(data: TenancyUserDTO) {
  return {
    type: FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_SUCCESS,
    payload: data,
  } as const;
}

export function fetchTenancyUserFromOrderCustomerFailed(error: string) {
  return {
    type: FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_FAILED,
    payload: error,
  } as const;
}

export type TenancyUserActionTypes = ReturnType<
  | typeof fetchTenancyUserFromOrderCustomerRequested
  | typeof fetchTenancyUserFromOrderCustomerSuccess
  | typeof fetchTenancyUserFromOrderCustomerFailed
>;
