import './utils/polyfills';

import createSagaMiddleware from '@redux-saga/core';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { persistStore, persistReducer, PersistConfig, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { IntercomProvider } from 'react-use-intercom';

import App from './App';
import { GlobalStyle } from './base/style';
import { RepositTheme } from './base/theme';
import reducer from './redux/root.reducer';
import rootSaga from './redux/root.saga';
import Tooltip from './components/Tooltip/index';
import 'react-slidedown/lib/slidedown.css';
import { configureStore } from '@reduxjs/toolkit';
import { appConfig } from './appConfig';

const persistConfig: PersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const composeEnhancers =
//   typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//       })
//     : compose;

export const history: History<any> = createBrowserHistory();
// const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));
const persistedReducer = persistReducer(persistConfig, reducer(history));

// mount it on the Store
// const store = createStore(persistedReducer, enhancer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware, routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

const persistor = persistStore(store);

// then run the saga
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={RepositTheme}>
        <IntercomProvider appId={appConfig.intercom.appId}>
          <Fragment>
            <App />
            <GlobalStyle />
            <Tooltip />
          </Fragment>
        </IntercomProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
