import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import { setClaimResponseConfirmModal } from '../../../redux/claim/claim.actions';
import Button from '../../Button/index';
import Loading from '../../Loading/index';
import Modal from '../../Modal/index';
import { Header3, P2 } from '../../Typography/index';

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    button:nth-child(2) {
      margin: 10px 6px;
    }
  }
`;

interface DeclineClaimModalProps {
  onSubmit: () => void;
  isLoading: boolean;
}

const DeclineClaimModal: React.FC<DeclineClaimModalProps> = ({ onSubmit, isLoading }) => {
  const dispatch = useDispatch();

  return (
    <Modal onDismiss={() => dispatch(setClaimResponseConfirmModal(null))}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Header3 style={{ marginBottom: 6 }}>Proceed to a Formal Dispute?</Header3>
          <P2 style={{ marginBottom: 6 }} bold>
            To continue, you will be required to pay the dispute fee of £60. Your case will then be sent to an independent
            adjudication service who will look at all the evidence submitted and assess accordingly.
          </P2>
          <P2 style={{ marginBottom: 6 }}>If the landlord is not awarded any money, the £60 will be refunded to you.</P2>
          <P2>
            If the adjudication result has partially awarded money to the agent/landlord, the £60 will be credited against the
            amount.
          </P2>
          <P2>
            Lastly, if the decision is wholly in favour of the agent/landlord, the £60 dispute fee will not be refunded back to
            you.
          </P2>
          <P2 style={{ marginBottom: 24 }} bold>
            You must pay the dispute fee for this dispute to be considered. If payment is not made, the charges will be
            automatically accepted and you will be liable for the charges in full.
          </P2>
          <ModalAction>
            <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setClaimResponseConfirmModal(null))}>
              No, return to form
            </Button>
            <Button onClick={onSubmit}>Yes</Button>
          </ModalAction>
        </Fragment>
      )}
    </Modal>
  );
};

export default DeclineClaimModal;
