import React, { useEffect, Fragment } from 'react';
import Card from '../../../../../components/Card';
import { P1, P2, Header3 } from '../../../../../components/Typography';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';
import Button from '../../../../../components/Button';
import SecondaryPanel from '../../../../../components/SecondaryPanel';
import MultiDocUploader from '../../../../../components/MultiDocUploader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReferenceChecksRequested,
  GET_REFERENCE_CHECKS_STORE_KEY,
  uploadReferenceDocsRequested,
  UPLOAD_REFERENCE_DOCS_STORE_KEY,
  removeReferenceDocRequested,
  setReferenceReviewRequested
} from '../../../../../redux/referencing/referencing.actions';
import { getLatestIdentityCheck, getReferenceDocumentsByType } from '../../../../../redux/referencing/referencing.selectors';
import { createLoadingSelector } from '../../../../../redux/loading/loading.selector';
import { FullPageLoader } from '../../../../../components/Loading/index';
import { AppState } from '../../../../../redux/root.reducer';

interface ReferenceProofRequiredProps {
  referenceId: any;
}

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  p {
    margin: 0 20px 0 0;
  }
`;

const Subtitle = styled(Header3)`
  font-family: ${props => props.theme.typography.face.secondary};
`;

const Inner = styled.div`
  padding: 0 36px;
`;

interface AdditionalInfo {
  id: string;
  label: string;
  description: string;
  uploadComponent: JSX.Element;
}

const ReferenceProofRequired: React.FC<ReferenceProofRequiredProps> = ({ referenceId }) => {
  const dispatch = useDispatch();
  const identityReferenceCheck = useSelector(getLatestIdentityCheck);
  const getReferenceChecksRequestedLoading = createLoadingSelector([GET_REFERENCE_CHECKS_STORE_KEY]);
  const isLoading = useSelector(getReferenceChecksRequestedLoading);
  let requiredInformation: AdditionalInfo[] = [];

  const proofOfIdentityDocuments = useSelector((state: AppState) => getReferenceDocumentsByType(state, `PROOF_OF_IDENTITY`));
  const proofOfAddressDocuments = useSelector((state: AppState) => getReferenceDocumentsByType(state, `PROOF_OF_ADDRESS`));

  const getUploadingDocLoading = createLoadingSelector([UPLOAD_REFERENCE_DOCS_STORE_KEY]);
  const isDocumentUploading = useSelector(getUploadingDocLoading);

  const isSubmitDisabled = proofOfIdentityDocuments.length === 0 || proofOfAddressDocuments.length === 0;

  useEffect(() => {
    dispatch(getReferenceChecksRequested(referenceId));
  }, [dispatch, referenceId]);

  const removeIdDocumentByType = (docId: string, documentList: any[]) => {
    const refDocId = documentList.find(d => d.document.id === docId);
    dispatch(removeReferenceDocRequested(referenceId, refDocId.id));
  };

  if (!identityReferenceCheck || isLoading) {
    return <FullPageLoader />;
  }

  if (identityReferenceCheck) {
    requiredInformation = [
      {
        id: 'IDENTITY',
        label: 'Identity (Passport etc)',
        description: `Please upload evidence to support your identity.`,
        uploadComponent: (
          <MultiDocUploader
            createDocument={file =>
              dispatch(
                uploadReferenceDocsRequested({
                  name: 'IDENTITY',
                  type: `PROOF_OF_IDENTITY`,
                  referenceId,
                  file
                })
              )
            }
            documents={proofOfIdentityDocuments.map(d => d.document)}
            showDeleteButton
            isDocumentUploading={isDocumentUploading}
            deleteDocument={docId => removeIdDocumentByType(docId, proofOfIdentityDocuments)}
          />
        )
      },
      {
        id: 'PROOF_OF_ADDRESS',
        label: 'Proof of your address',
        description: `Please upload evidence to support your address history.`,
        uploadComponent: (
          <MultiDocUploader
            createDocument={file =>
              dispatch(
                uploadReferenceDocsRequested({
                  name: 'IDENTITY',
                  type: `PROOF_OF_ADDRESS`,
                  referenceId,
                  file
                })
              )
            }
            documents={proofOfAddressDocuments.map(d => d.document)}
            showDeleteButton
            isDocumentUploading={isDocumentUploading}
            deleteDocument={docId => removeIdDocumentByType(docId, proofOfAddressDocuments)}
          />
        )
      }
    ];
  }

  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Not quite there</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card flush>
            <Fragment>
              <Inner>
                <Subtitle>We need some more information from you</Subtitle>
              </Inner>
              {requiredInformation.map((info: AdditionalInfo) => (
                <SecondaryPanel key={info.id}>
                  <P1 bold>{info.label}</P1>
                  <P2>{info.description}</P2>
                  {info.uploadComponent}
                </SecondaryPanel>
              ))}
            </Fragment>
          </Card>
          <Action>
            <Button disabled={isSubmitDisabled} onClick={() => dispatch(setReferenceReviewRequested(referenceId))}>
              Send for Review
            </Button>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceProofRequired;
