import { createAction } from '@reduxjs/toolkit';
import { AddPaymentMethodPayload } from './account.types';

export const FETCH_ME_STORE_KEY = 'ACCOUNT/FETCH_ME';
export const FETCH_ME_API_REQUESTED = `ACCOUNT/FETCH_ME_API_REQUESTED`;
export const FETCH_ME_API_SUCCESS = 'ACCOUNT/FETCH_ME_API_SUCCESS';
export const FETCH_ME_API_FAILED = 'ACCOUNT/FETCH_ME_API_FAILED';

export const FETCH_CUSTOMER_STORE_KEY = 'ACCOUNT/FETCH_CUSTOMER';
export const FETCH_CUSTOMER_API_REQUESTED = `ACCOUNT/FETCH_CUSTOMER_API_REQUESTED`;
export const FETCH_CUSTOMER_API_SUCCESS = 'ACCOUNT/FETCH_CUSTOMER_API_SUCCESS';
export const FETCH_CUSTOMER_API_FAILED = 'ACCOUNT/FETCH_CUSTOMER_API_FAILED';

export const ADD_PAYMENT_METHOD_STORE_KEY = 'ACCOUNT/ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD_API_REQUESTED = `ACCOUNT/ADD_PAYMENT_METHOD_API_REQUESTED`;
export const ADD_PAYMENT_METHOD_API_SUCCESS = 'ACCOUNT/ADD_PAYMENT_METHOD_API_SUCCESS';
export const ADD_PAYMENT_METHOD_API_FAILED = 'ACCOUNT/ADD_PAYMENT_METHOD_API_FAILED';

export const HAS_REQUESTED_PASSWORD_RESET = 'AUTH/HAS_REQUESTED_PASSWORD_RESET';
export const HAS_RESET_PASSWORD = 'AUTH/HAS_RESET_PASSWORD';

export const SET_HAS_SESSION_BEEN_CLEARED = 'ACCOUNT/SET_HAS_SESSION_BEEN_CLEARED';

export const fetchMeRequested = createAction(FETCH_ME_API_REQUESTED);
export const fetchMeSuccess = createAction<string>(FETCH_ME_API_SUCCESS);
export const fetchMeFailed = createAction<string>(FETCH_ME_API_FAILED);

export const fetchCustomerRequested = createAction(FETCH_CUSTOMER_API_REQUESTED);
export const fetchCustomerSuccess = createAction<string>(FETCH_CUSTOMER_API_SUCCESS);
export const fetchCustomerFailed = createAction<string>(FETCH_CUSTOMER_API_FAILED);

export const addPaymentMethodRequested = createAction<AddPaymentMethodPayload>(ADD_PAYMENT_METHOD_API_REQUESTED);
export const addPaymentMethodSuccess = createAction(ADD_PAYMENT_METHOD_API_SUCCESS);
export const addPaymentMethodFailed = createAction<string>(ADD_PAYMENT_METHOD_API_FAILED);

export const setHasRequestedPasswordReset = createAction<boolean>(HAS_REQUESTED_PASSWORD_RESET);

export const setHasSessionBeenCleared = createAction<boolean>(SET_HAS_SESSION_BEEN_CLEARED);
