import React from 'react';
import styled from 'styled-components';
import { Row, Container, Col } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';
import Card from '../../../../../components/Card';
import { P1 } from '../../../../../components/Typography';
import { NavLink } from 'react-router-dom';
import Button from '../../../../../components/Button';

const Action = styled.div`
  padding: 16px 0 0;
  text-align: right;

  a {
    text-decoration: none;
  }
`;

const WaitingPayment: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Your payment is taking longer than expected!</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <P1>Sorry, it’s taking us longer than usual to process your payment.</P1>
            <P1>If the problem persists, please contact customer support via the live chat feature below.</P1>
          </Card>
          <Action>
            <NavLink to={`/`}>
              <Button>Take me to my Reposit</Button>
            </NavLink>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default WaitingPayment;
