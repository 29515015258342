import { OrderCustomerDTO, TenancyAnniversaryDTO } from '@reposit/api-client';
import { find } from 'lodash';
import { denormalize } from 'normalizr';
import { RepositFilter } from '../../constants/reposit';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { isTenancyOrderExpired } from '../utils/is-tenancy-order-expired';

export const getCurrentOrderCustomer = (state: AppState): OrderCustomerDTO => {
  return denormalize(state.entities.orderCustomer[state.order.currentOrderCustomerId], SCHEMA.orderCustomer, state.entities);
};

export const getOrderCustomerById = (state: AppState, id: string) => {
  return denormalize(state.entities.orderCustomer[id], SCHEMA.orderCustomer, state.entities);
};

export const getTenancyOrders = (state: AppState) => {
  return state.order.tenancyOrders.map((to: any) => {
    return denormalize(state.entities.tenancyOrder[to], SCHEMA.tenancyOrder, state.entities);
  });
};

export const getTenancyAnniversaries = (state: AppState): TenancyAnniversaryDTO[] => {
  return state.order.tenancyAnniversaries.map((ttu: any) => {
    return denormalize(state.entities.tenancyAnniversary[ttu], SCHEMA.tenancyAnniversary, state.entities);
  });
};

export const getTenancyOrder = (state: AppState) => {
  return denormalize(state.entities.tenancyOrder[state.order.tenancyOrder], SCHEMA.tenancyOrder, state.entities);
};

export const getFilteredTenancyOrders = (state: AppState, filter: string) => {
  const tenancyOrders = getTenancyOrders(state);
  return tenancyOrders.filter((tenancyOrder: any) => {
    const isExpired = isTenancyOrderExpired(tenancyOrder);
    switch (filter) {
      case RepositFilter.ACTIVE:
        return !isExpired;
      case RepositFilter.EXPIRED:
        return isExpired;
      default:
        return null;
    }
  });
};

export const getTenancyFromTenancyOrderOrder = (state: AppState, orderId: string) => {
  const tenancyOrder = find(state.entities.tenancyOrder, (tor) => tor.order === orderId);
  if (!tenancyOrder) return undefined;

  return denormalize(state.entities.tenancy[tenancyOrder.tenancy], SCHEMA.tenancy, state.entities);
};

export const getTenancyFromTenancyAnniversaryOrder = (state: AppState, orderId: string) => {
  const tenancyAnniversary = find(state.entities.tenancyAnniversary, (ttu) => ttu.order === orderId);
  if (!tenancyAnniversary) return undefined;

  return denormalize(state.entities.tenancy[tenancyAnniversary.tenancy], SCHEMA.tenancy, state.entities);
};

export const getOrderAmountRemaining = (state: AppState) => state.order.amountRemaining;
