import { DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getDocumentById = (state: AppState, documentId?: string): DocumentEntity | undefined => {
  if (!state.entities.document) return undefined;

  if (!documentId) return undefined;

  return state.entities.document[documentId];
};

export const getDocumentsByIds = (state: AppState, ids: string[]): DocumentEntity[] => {
  const docIds: DocumentEntity[] = [];

  for (let i = 0; i < ids.length; i++) {
    const doc = getDocumentById(state, ids[i]);
    doc && docIds.push(doc);
  }

  return docIds;
};
