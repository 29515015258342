import { call, put, select } from 'redux-saga/effects';
import axios, { AxiosInstance } from 'axios';
import {
  TenanciesApi,
  UsersApi,
  TenancyOrdersApi,
  OrganizationsApi,
  AddressesApi,
  TenancyCheckoutsApi,
  ClaimsApi,
  ClaimItemsApi,
  TenancyExpiriesApi,
  OrderCustomerActionsApi,
  OrderCustomersApi,
  ReferencesApi,
  ReferenceDocumentsApi,
  ClaimResponseDocumentsApi,
  OrdersApi,
  CustomersApi,
  ArbitrationsApi,
  AuthApi,
  ClaimProposalsApi,
  ClaimItemProposalsApi,
  DocumentsApi,
  ClaimItemDocumentsApi,
  PaymentPlansApi,
  PaymentPlanActionsApi,
  PaymentPlanInstalmentsApi,
  RepositOffersApi,
} from '@reposit/api-client';

import { logout } from '../auth/auth.actions';
import { getAccessToken } from '../auth/auth.selectors';
import { appConfig } from '../../appConfig';
import { AppState } from '../root.reducer';
import { AppDispatch } from '../..';

const HTTP_UNAUTHORIZED = 401;

export const runSagaWithAuth = (saga: () => any) =>
  function* () {
    try {
      const result = yield call(() => saga());
      return result;
    } catch (e) {
      if (e.response.status === HTTP_UNAUTHORIZED) {
        yield put(logout());
      }
      throw e;
    }
  };

export const runThunkWithAuth = (thunk: () => Promise<any>, dispatch: AppDispatch) => {
  return thunk().catch((e) => {
    if (e.response.status === HTTP_UNAUTHORIZED) {
      dispatch(logout());
    }
    throw e;
  });
};

export function* createAxiosInstance() {
  const accessToken = yield select(getAccessToken);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache',
      'Cache-control': 'no-store',
      Pragma: ' no-cache',
      Expires: 0,
    },
  });
}

export function createStandardAxiosInstance(state: AppState) {
  const accessToken = getAccessToken(state);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function* createTenanciesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenanciesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimItemsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimItemsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createArbitrationsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ArbitrationsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createUsersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new UsersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createCustomersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new CustomersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyOrdersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyOrdersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAddressesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AddressesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyCheckoutsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyCheckoutsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyExpiriesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyExpiriesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrderCustomerActionApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrderCustomerActionsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrderCustomersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrderCustomersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createReferencesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ReferencesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createReferenceDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ReferenceDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimResponseDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimResponseDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrdersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrdersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAuthApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AuthApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createPaymentPlanInstalmentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new PaymentPlanInstalmentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createRepositOffersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new RepositOffersApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimProposalsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimProposalsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimItemProposalsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimItemProposalsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new DocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimResponseDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimResponseDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimItemDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimItemDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardPaymentPlansApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new PaymentPlansApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardPaymentPlanActionsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new PaymentPlanActionsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardCustomersApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new CustomersApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardOrdersApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new OrdersApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardUsersApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new UsersApi({}, appConfig.api.basePath, axiosInstance);
}
