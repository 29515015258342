import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import PDFLogo from '../../../../assets/svg/acrobat-doc.svg';
import DownloadIcon from '../../../../assets/svg/download-white.svg';
import { getBreakpoint } from '../../../../base/style';
import Button, { RoundButton } from '../../../../components/Button';
import FlashMessage from '../../../../components/FlashMessage';
import { FullPageLoader } from '../../../../components/Loading/index';
import PageTitle from '../../../../components/PageTitle';
import RepositCard from '../../../../components/Reposit/RepositCard';
import ToggleButton from '../../../../components/ToggleButton/index';
import { P1, P2 } from '../../../../components/Typography';
import { DRAFT_ADDENDUM_LINK } from '../../../../constants/links';
import { getTenantById } from '../../../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import {
  SIGN_ADDENDUM_STORE_KEY,
  signAddendumRequested,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { FETCH_TENANCY_ORDERS_STORE_KEY, fetchTenancyOrdersRequested } from '../../../../redux/order/order.actions';
import { getCurrentOrderCustomer, getTenancyFromTenancyOrderOrder } from '../../../../redux/order/order.selectors';
import { AppState } from '../../../../redux/root.reducer';
import { getCurrentTenancyUser } from '../../../../redux/tenancy-user/tenancy-user.selector';
import { useFlashMessage } from '../../../FlashMessage';

interface AddendumProps {}

const Action = styled.div`
  margin-bottom: 120px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`;

const ActionInfo = styled(P1)`
  font-size: 17px;
  margin: 0 20px 20px 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 20px;
  }
`;

const AgreeAction = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: ${(props) => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${(props) => props.theme.typography.face.secondary};
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 2px;

  &:before {
    background: transparent url(${PDFLogo}) no-repeat center center;
    background-size: 20px;
    content: '';
    display: block;
    height: 20px;
    margin: 0 7px 0 0;
    width: 20px;
  }
`;

interface AddendumFormValues {
  agreed: boolean;
}

const Schema = Yup.object().shape({
  agreed: Yup.bool().oneOf([true]),
});

const Addendum: React.FC<AddendumProps> = (props) => {
  const dispatch = useDispatch();
  const { order, customer } = useSelector(getCurrentOrderCustomer);
  const tenancyOrdersLoadingSelector = createLoadingSelector([FETCH_TENANCY_ORDERS_STORE_KEY]);
  const istenancyOrdersLoading = useSelector(tenancyOrdersLoadingSelector);
  const tenancy = useSelector((state: AppState) => getTenancyFromTenancyOrderOrder(state, order.id));
  const currentTenancyUser = useSelector(getCurrentTenancyUser);
  const currentTenancyUserId = get(currentTenancyUser, 'id', '');
  const currentTenant = useSelector(getTenantById(currentTenancyUserId));

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([SIGN_ADDENDUM_STORE_KEY]);

  const isUpdateSubmittingSelector = createLoadingSelector([SIGN_ADDENDUM_STORE_KEY]);
  const isUpdateSubmitting = useSelector(isUpdateSubmittingSelector);

  useEffect(() => {
    dispatch(fetchTenancyOrdersRequested());
  }, [dispatch]);

  if (istenancyOrdersLoading || !tenancy) {
    return <FullPageLoader />;
  }

  return (
    <Formik
      initialValues={{
        agreed: false,
      }}
      validationSchema={Schema}
      onSubmit={() => {
        dispatch(signAddendumRequested({ customerId: customer.id, orderId: order.id }));
      }}
    >
      {({ handleChange, handleSubmit, isValid }: FormikProps<AddendumFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <PageTitle>Addendum to Tenancy Agreement</PageTitle>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <RepositCard
                    title=""
                    flashMessage={
                      flashMessagePayload ? (
                        <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessagePayload} />
                      ) : undefined
                    }
                  >
                    <Fragment>
                      <P1>Please confirm your agreement to the addendum linked below.</P1>
                      <P1>This document will be used to confirm the changes to your original agreement.</P1>
                      <Label>
                        Draft Addendum
                        <a href={DRAFT_ADDENDUM_LINK} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 18 }}>
                          <RoundButton icon={DownloadIcon} />
                        </a>
                      </Label>
                      <AgreeAction>
                        <ToggleButton name="agreed" onToggle={handleChange} label="I agree" />
                      </AgreeAction>
                      <P2>
                        By agreeing to the addendum above, the document will be e-signed with the following wording: I
                        {currentTenant ? `, ${currentTenant.firstName} ${currentTenant.lastName},` : ', [your name],'} have read
                        and agree to this addendum.
                      </P2>
                      <P2>
                        Your IP address will be recorded as part of the e-signature process and will be visible on the final
                        addendum.
                      </P2>
                    </Fragment>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <Action>
                    <ActionInfo>Next: Payment</ActionInfo>
                    <Button type="submit" disabled={!isValid || isUpdateSubmitting}>
                      Continue
                    </Button>
                  </Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default Addendum;
