import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import RoundToggleButton from '../RoundToggleButton/index';
import { P1 } from '../Typography/index';

interface ConfirmStepProps {
  name: string;
  text: string;
  onToggle: (e: ChangeEvent<any>) => void;
}

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;

  background: ${props => props.theme.colors.bgAccent};
  margin: 3px 0;
  padding: 24px 34px;

  &:hover {
    background: #efefef;
  }
`;

const ConfirmStep: React.FC<ConfirmStepProps> = ({ name, onToggle, text }) => {
  return (
    <StyledLabel htmlFor={name}>
      <RoundToggleButton name={name} onToggle={onToggle} />
      <P1>{text}</P1>
    </StyledLabel>
  );
};
export default ConfirmStep;
