import qs from 'qs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Loading from '../../components/Loading/index';
import { setToken } from '../../redux/auth/auth.actions';

interface ILocationHash {
  access_token: string;
}

const AuthenticateView = (props: RouteComponentProps) => {
  const { location, history } = props;

  const dispatch = useDispatch();
  const params: ILocationHash = qs.parse(location.hash.substr(1));
  const accessToken = params.access_token;

  useEffect(() => {
    if (accessToken) {
      dispatch(setToken(accessToken));
      history.push('/');
    } else {
      history.push('/unauthorised');
    }
  }, [dispatch, history, accessToken]);

  return <Loading />;
};

export default AuthenticateView;
