import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { Header3, P1 } from '../../components/Typography/index';

const ArbitrationPaymentSuccess: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Payment Success</PageTitle>
          <Card>
            <Header3>Thanks!</Header3>
            <P1>Your payment has been received.</P1>
            <P1>
              Your letting agent/landlord will now be asked to provide all of the necessary evidence and documents for your
              tenancy if they haven't already done so.
            </P1>
            <P1>
              Your case will be forwarded to an independent third party adjudicator when everything has been provided, who will
              review all of the evidence provided and give an impartial result on what is owed.
            </P1>
            <P1 bold>
              When this result is finalised, you will have 24 hours to pay the outstanding balance if your formal dispute
              administration fee does not cover the total awarded.
            </P1>
            <P1>This process may take up to 6 weeks. We will update you as soon as possible.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ArbitrationPaymentSuccess;
