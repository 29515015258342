export const DRAFT_ADDENDUM_LINK = 'https://d105lee3jcviqy.cloudfront.net/ast/draft-tenancy-addendum.pdf';

export const END_OF_TENANCY_GUIDE =
  'https://d105lee3jcviqy.cloudfront.net/end-of-tenancy/reposit-end-of-tenancy-guide-tenants.pdf';

export const REPOSIT_WEBSITE_TENANTS_PAGE = 'https://reposit.co.uk/tenants/';

export const REPOSIT_OFFER_WEBSITE_TENANTS_PAGE = 'https://reposit.co.uk/tenants-offer-reposit/';

export const REPOSIT_TENANT_FAQS = 'https://support.reposit.co.uk/en/knowledge/tenant-faqs';

export const REPOSIT_TENANT_TERMS_AND_CONDITIONS =
  'https://d105lee3jcviqy.cloudfront.net/terms/reposit-tenant-terms-and-conditions-march-2024.pdf';
