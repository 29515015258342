import {
  POLL_FOR_REFERENCE_FAILED,
  POLL_FOR_REFERENCE_REQUESTED,
  POLL_FOR_REFERENCE_SUCCESS,
  ReferenceActionTypes,
  CREATE_REFERENCE_API_REQUESTED,
  GET_REFERENCE_CHECKS_API_SUCCESS,
  UPLOAD_REFERENCE_DOCS_API_SUCCESS,
  REMOVE_REFERENCE_DOC_API_SUCCESS,
} from './referencing.actions';
import { ReferenceState } from './referencing.types';

const initialState: ReferenceState = {
  reference: '',
  isPolling: false,
  checks: [],
  documents: {
    identity: [],
    proofOfAddress: [],
  },
};

export default (state: ReferenceState = initialState, action: ReferenceActionTypes): ReferenceState => {
  switch (action.type) {
    case POLL_FOR_REFERENCE_REQUESTED: {
      return {
        ...state,
        isPolling: true,
      };
    }

    case POLL_FOR_REFERENCE_SUCCESS: {
      const documents = action.payload.documents || [];
      const identity: any[] = [];
      const proofOfAddress: any[] = [];

      documents.map((doc: any) => {
        if (doc.document.typeId === 'PROOF_OF_IDENTITY') {
          identity.push(doc);
        } else if (doc.document.typeId === 'PROOF_OF_ADDRESS') {
          proofOfAddress.push(doc);
        }

        return doc;
      });

      return {
        ...state,
        reference: action.payload.referenceId,
        isPolling: false,
        documents: {
          identity,
          proofOfAddress,
        },
      };
    }

    case POLL_FOR_REFERENCE_FAILED: {
      return {
        ...state,
        isPolling: false,
      };
    }

    case CREATE_REFERENCE_API_REQUESTED: {
      return {
        ...state,
        isPolling: true,
      };
    }

    case GET_REFERENCE_CHECKS_API_SUCCESS: {
      return {
        ...state,
        checks: action.payload.checkIds,
      };
    }

    case REMOVE_REFERENCE_DOC_API_SUCCESS: {
      const identity = state.documents.identity.filter((doc) => doc.id !== action.payload);
      const proofOfAddress = state.documents.proofOfAddress.filter((doc) => doc.id !== action.payload);
      return {
        ...state,
        documents: {
          identity,
          proofOfAddress,
        },
      };
    }

    case UPLOAD_REFERENCE_DOCS_API_SUCCESS: {
      const document = action.payload.document;
      const identity = [];
      const proofOfAddress = [];

      if (document.document.typeId === 'PROOF_OF_IDENTITY') {
        identity.push(document);
      } else if (document.document.typeId === 'PROOF_OF_ADDRESS') {
        proofOfAddress.push(document);
      }

      return {
        ...state,
        documents: {
          identity: [...state.documents.identity, ...identity],
          proofOfAddress: [...state.documents.proofOfAddress, ...proofOfAddress],
        },
      };
    }

    default:
      return state;
  }
};
