import { OrderCustomersApi, UsersApi, TenancyOrdersApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import {
  OrderActionTypes,
  fetchOrderCustomerFailed,
  fetchOrderCustomerSuccess,
  FETCH_ORDER_CUSTOMER_API_REQUESTED,
  fetchTenancyOrdersSuccess,
  fetchTenancyOrdersFailed,
  FETCH_TENANCY_ORDERS_API_REQUESTED,
  fetchTenancyOrderByIdSuccess,
  fetchTenancyOrderByIdFailed,
  FETCH_TENANCY_ORDER_BY_ID_API_REQUESTED,
  FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED,
  fetchTenancyAnniversariesFailed,
  fetchTenancyAnniversariesSuccess,
} from './order.actions';
import { runSagaWithAuth, createOrderCustomersApi, createUsersApi, createTenancyOrdersApi } from '../utils/api.utils';
import { FetchOrderCustomerPayload } from './order.types';
import { getCurrentCustomerId } from '../account/account.selectors';
import { push } from 'connected-react-router';

// ****************
// WORKERS
// ****************
export function* fetchOrderCustomer({ payload }: { type: string; payload: FetchOrderCustomerPayload }) {
  try {
    const { customerId, orderId } = payload;
    const orderCustomersApi: OrderCustomersApi = yield createOrderCustomersApi();
    const currentCustomerId = yield select(getCurrentCustomerId);

    if (currentCustomerId !== customerId) {
      // not sure if this should be here ?
      yield put(push(`/`));
    }

    const { data } = yield call(runSagaWithAuth(() => orderCustomersApi.getOrderCustomerById(orderId, customerId)));
    const id: string = yield syncEntitiesAndGetResults({ ...data }, SCHEMA.orderCustomer);

    yield put<OrderActionTypes>(fetchOrderCustomerSuccess(id));
  } catch (e) {
    yield put<OrderActionTypes>(fetchOrderCustomerFailed(get(e, 'response.data.message', e)));
  }
}

export function* fetchTenancyOrders() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.getTenancyOrdersByProductId('REPOSIT')));

    const ids: string[] = yield syncEntitiesAndGetResults(data, SCHEMA.tenancyOrders);
    yield put<OrderActionTypes>(fetchTenancyOrdersSuccess(ids));
  } catch (e) {
    yield put<OrderActionTypes>(fetchTenancyOrdersFailed(get(e, 'response.data.message', e)));
  }
}

export function* fetchTenancyAnniversaries() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.getUserTenancyAnniversaries()));

    const ids: string[] = yield syncEntitiesAndGetResults(data, SCHEMA.tenancyAnniversaries);
    yield put<OrderActionTypes>(fetchTenancyAnniversariesSuccess(ids));
  } catch (e) {
    yield put<OrderActionTypes>(fetchTenancyAnniversariesFailed(get(e, 'response.data.message', e)));
  }
}

export function* fetchTenancyOrderById({ payload }: { type: string; payload: any }) {
  try {
    const tenancyOrdersApi: TenancyOrdersApi = yield createTenancyOrdersApi();
    const { data } = yield call(runSagaWithAuth(() => tenancyOrdersApi.getTenancyOrderById(payload.id)));
    const id: string = yield syncEntitiesAndGetResults(data, SCHEMA.tenancyOrder);
    yield put<OrderActionTypes>(fetchTenancyOrderByIdSuccess(id));
  } catch (e) {
    yield put<OrderActionTypes>(fetchTenancyOrderByIdFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrderSagas() {
  yield takeLatest(FETCH_ORDER_CUSTOMER_API_REQUESTED, fetchOrderCustomer);
  yield takeLatest(FETCH_TENANCY_ORDERS_API_REQUESTED, fetchTenancyOrders);
  yield takeLatest(FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED, fetchTenancyAnniversaries);
  yield takeLatest(FETCH_TENANCY_ORDER_BY_ID_API_REQUESTED, fetchTenancyOrderById);
}
