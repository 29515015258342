import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { Header3 } from '../../components/Typography/index';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  getClaimPaymentIntentRequested,
  GET_CLAIM_PAYMENT_INTENT_STORE_KEY,
  payRequested,
  PAY_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getClaimPaymentIntentSecret,
  getIsPollingForPaymentCompletion,
  getOrderCustomerActionsByIds,
} from '../../redux/order-customer-actions/order-customer-actions.selectors';
import { PaymentType } from '../../redux/order-customer-actions/order-customer-actions.types';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import PaymentContainer from '../Payment/index';
import { PaymentOptionButton, PaymentOptionWrapper } from './components';
import { getClaimRespondentsByClaimId } from '../../redux/entities/entities.selectors';

interface ArbitrationOutcomePaymentProps {
  orderId: string;
  claimId: string;
}

const ArbitrationOutcomePayment: React.FC<ArbitrationOutcomePaymentProps> = ({ orderId, claimId }) => {
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState<OrderCustomerActionDTOTypeIdEnum | undefined>();

  const currentCustomerId = useSelector(getCurrentCustomerId);
  const paymentIntentSecret = useSelector(getClaimPaymentIntentSecret);
  const getPayLoadingSelector = createLoadingSelector([PAY_STORE_KEY]);
  const isPayLoading = useSelector(getPayLoadingSelector);
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const currentOrderCustomerFee = get(currentOrderCustomer, 'fee');
  const nextActionIds = get(currentOrderCustomer, 'nextAction.orderCustomerActionIds', []);

  const claimRespondents = useSelector(getClaimRespondentsByClaimId(claimId));
  const onlyOneClaimRespondent = claimRespondents && Object.values(claimRespondents).length === 1;

  const nextActions = useSelector(getOrderCustomerActionsByIds(nextActionIds));
  const remainingBalanceAction = nextActions.find(
    (a) => a.typeId === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
  );

  const amountRemaining = get(remainingBalanceAction, 'details.amountRemaining');
  const amount = paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY ? currentOrderCustomerFee : amountRemaining;
  const completeNextAction = nextActions.find((a) => a.completedAt);

  const paymentTitle =
    paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
      ? 'The remaining balance for this adjudication is'
      : 'Your share of this adjudication is';
  const isRemaningBalanceNotEqualToOrderCustomerFee = currentOrderCustomerFee !== amountRemaining;

  useEffect(() => {
    // check everything is loaded
    // AND
    // paymentType has not been set
    if (nextActionIds.length && currentOrderCustomerFee && !paymentType) {
      const initialType = remainingBalanceAction
        ? OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE
        : OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY;
      setPaymentType(initialType);
    }
  }, [
    nextActionIds,
    remainingBalanceAction,
    completeNextAction,
    paymentType,
    currentOrderCustomerFee,
    amountRemaining,
    isRemaningBalanceNotEqualToOrderCustomerFee,
  ]);

  const pageLoadingSelector = createLoadingSelector([FETCH_ORDER_CUSTOMER_STORE_KEY, GET_CLAIM_PAYMENT_INTENT_STORE_KEY]);
  const isPageLoading = useSelector(pageLoadingSelector);
  const isPolling = useSelector(getIsPollingForPaymentCompletion);

  const submitPayment = (stripe: Stripe, elements: StripeElements) =>
    dispatch(
      payRequested({
        stripe,
        paymentIntentSecret,
        type: remainingBalanceAction ? PaymentType.ARBITRATION_REMAINING_BALANCE : PaymentType.ARBITRATION,
        elements,
        singleTenant: onlyOneClaimRespondent,
      })
    );

  useEffect(() => {
    dispatch(fetchOrderCustomerRequested({ customerId: currentCustomerId, orderId }));
  }, [dispatch, orderId, currentCustomerId]);

  useEffect(() => {
    if (paymentType) {
      dispatch(getClaimPaymentIntentRequested(currentCustomerId, orderId, paymentType));
    }
  }, [dispatch, orderId, currentCustomerId, paymentType]);

  const actionsGreaterThanOne = nextActionIds.length > 1;

  // more than one action
  // AND
  // NO complete actions
  // AND
  // remaining balance is not equal to OC fee
  const shouldShowPaymentOptions = actionsGreaterThanOne && !completeNextAction && isRemaningBalanceNotEqualToOrderCustomerFee;

  const isLoading = isPageLoading || isPolling || isPayLoading;

  return (
    <>
      {shouldShowPaymentOptions ? (
        <Container>
          <Row>
            <Col sm={12}>
              <div style={{ paddingTop: '1.75rem' }}>
                <PaymentOptionWrapper>
                  <Header3>How much would you like to pay?</Header3>
                  <div>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE}
                      selected={paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE}
                      buttonType="tertiary"
                      onClick={() => setPaymentType(OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAYREMAININGBALANCE)}
                    >
                      Remaining Balance
                    </PaymentOptionButton>
                    <PaymentOptionButton
                      paymentType={OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY}
                      selected={paymentType === OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY}
                      buttonType="tertiary"
                      onClick={() => setPaymentType(OrderCustomerActionDTOTypeIdEnum.ARBITRATIONPAY)}
                    >
                      Your Share
                    </PaymentOptionButton>
                  </div>
                </PaymentOptionWrapper>
              </div>
            </Col>
          </Row>
        </Container>
      ) : null}

      {amount ? (
        <PaymentContainer
          fullWidth
          isSubmitting={isLoading}
          amount={`${amount}`}
          submitCard={submitPayment}
          type={PaymentType.ARBITRATION}
          title={paymentTitle}
          paymentMode={'payment'}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ArbitrationOutcomePayment;
