import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { Header3, P1 } from '../../components/Typography/index';

const TopUpFeePaymentSuccess: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Payment Success</PageTitle>
          <Card>
            <Header3>Thanks!</Header3>
            <P1>Thanks! Your annual fee payment has been received.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TopUpFeePaymentSuccess;
