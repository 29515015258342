import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Location } from 'history';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import Megaphone from '../../assets/svg/megaphone.svg';
import FlashMessage from '../../components/FlashMessage/index';
import { Caption, Header2 } from '../../components/Typography/index';
import { REPOSIT_PAYMENT_WARNING } from '../../constants/reposit';
import { addPaymentMethodRequested, ADD_PAYMENT_METHOD_STORE_KEY } from '../../redux/account/account.actions';
import { getCurrentCustomerId } from '../../redux/account/account.selectors';
import { getCustomerById } from '../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage/index';
import { ElementsForm } from '../Payment/ElementsForm';

interface Props {
  location: Location<any>;
  match: any;
}

const Title = styled(Header2)`
  color: ${(props) => props.theme.colors.negative};
  margin: 24px 0 1rem;
`;

export const WarningMessage = () => {
  return <div>{REPOSIT_PAYMENT_WARNING}</div>;
};

export const AddPaymentMethod: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(getCurrentCustomerId);
  const customer = useSelector(getCustomerById(customerId));

  const getLoadingSelector = createLoadingSelector([ADD_PAYMENT_METHOD_STORE_KEY]);
  const isLoading = useSelector(getLoadingSelector);
  const [flashMessage, dismissFlashMessage] = useFlashMessage([ADD_PAYMENT_METHOD_STORE_KEY]);

  const { hasDuplicatePaymentMethod, requiresNewPaymentMethod } = customer;

  if (!hasDuplicatePaymentMethod && !requiresNewPaymentMethod) {
    return <Redirect to="/" />;
  }

  const captionText = hasDuplicatePaymentMethod
    ? 'You currently share a payment method with another tenant. Your payment details should be unique to you to comply with our terms and conditions, please update these below.'
    : 'We need a new payment method on your Reposit account, please update these details below.';

  return (
    <Container style={{ paddingTop: 36 }}>
      <Row>
        <Col lg={4} style={{ textAlign: 'center' }}>
          <img src={Megaphone} width="273" alt="megaphone" />
        </Col>
        <Col lg={8}>
          <Title>Update your details</Title>
          <Caption>{captionText}</Caption>
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={10} push={{ lg: 0 }}>
          {flashMessage ? (
            <FlashMessage
              onDismiss={dismissFlashMessage}
              timeRemaining={FLASH_MESSAGE_TIMOUT}
              noMargin={true}
              payload={flashMessage}
            />
          ) : undefined}
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={10} push={{ lg: 0 }}>
          <ElementsForm
            warningMessage={WarningMessage}
            isSubmitting={isLoading}
            submitCard={(stripe: Stripe, elements: StripeElements) => {
              dispatch(addPaymentMethodRequested({ stripe, elements }));
            }}
            buttonText="Confirm"
            paymentMode={'setup'}
          />
        </Col>
      </Row>
    </Container>
  );
};
