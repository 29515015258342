import React, { useState } from 'react';
import styled from 'styled-components';
import PostcodeLookup from './PostcodeLookup';
import ManualAddressForm from './ManualAddressForm';
import { ExternalAddressDTO } from '@reposit/api-client';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAddNewFormOpen } from '../../../../redux/address-history/address-history.actions';
import { getIsAddNewFormOpen } from '../../../../redux/address-history/address-history-selectors';

interface AddressFormProps {}

const FormContainer = styled.div`
  padding: 0 0 36px;
`;

const AddressForm: React.FC<AddressFormProps> = (props) => {
  const dispatch = useDispatch();
  const isManualFormShowing = useSelector(getIsAddNewFormOpen);
  const [selectedAddress, setSelectedAddress] = useState<ExternalAddressDTO | null>(null);

  return (
    <FormContainer>
      {isManualFormShowing ? (
        <ManualAddressForm address={selectedAddress} onFormClose={() => dispatch(setIsAddNewFormOpen(false))} />
      ) : (
        <PostcodeLookup
          onAddressSelect={(address?: ExternalAddressDTO) => {
            address && setSelectedAddress(address);
            dispatch(setIsAddNewFormOpen(true));
          }}
        />
      )}
    </FormContainer>
  );
};

export default AddressForm;
