import { ClaimDocumentDTO } from '@reposit/api-client';
import { capitalize, split } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import ImageIcon from '../../assets/svg/image.svg';
import SecondaryPanel from '../SecondaryPanel';
import { Header4, P2, P3 } from '../Typography';

interface ClaimDocumentProps {
  claimDocument: ClaimDocumentDTO;
}

const Inner = styled.div`
  align-items: center;
  display: flex;
`;

const Description = styled(Header4)`
  margin: 0 36px 0 0;
  width: 100%;
`;

const DocumentRow = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 16px;

  &:before {
    background: transparent url(${ImageIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
  }
`;

const Link = styled(P2)`
  a {
    color: inherit;
  }
`;

const Title = styled(P3)`
  font-weight: bold;
  margin: 6px 0 12px;
`;

const ClaimDocument: React.FC<ClaimDocumentProps> = ({ claimDocument }) => {
  const documentType = claimDocument.document.typeId;
  const formattedDocumentType = capitalize(split(documentType, '_').join(' '));
  return (
    <SecondaryPanel>
      <Inner>
        <Description>{formattedDocumentType}</Description>
      </Inner>
      <Inner style={{ marginTop: 6 }}>
        <div>
          <div>
            <Title>Document provided by managing agent (click to view)</Title>
            <DocumentRow>
              <Link style={{ display: 'inline' }}>
                <a href={claimDocument.document.url} target="_blank" rel="noopener noreferrer">
                  {claimDocument.document.name}
                </a>
              </Link>
            </DocumentRow>
          </div>
        </div>
      </Inner>
    </SecondaryPanel>
  );
};

export default ClaimDocument;
