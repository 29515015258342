import { ClaimState } from './claim.types';
import {
  ClaimActionTypes,
  SET_CLAIM_RESPONSE_CONFIRM_MODAL,
  GET_CLAIM_RESPONSE_DOCS_API_SUCCESS,
  SET_IS_CLAIM_RESPONSE_UPDATE_MODAL_SHOWING
} from './claim.actions';

const initialState: ClaimState = {
  activeModal: null,
  claimResponseDocuments: [],
  isClaimResponseUpdateModalShowing: false
};

export default (state = initialState, action: ClaimActionTypes): ClaimState => {
  switch (action.type) {
    case SET_CLAIM_RESPONSE_CONFIRM_MODAL:
      return {
        ...state,
        activeModal: action.payload.modal
      };

    case GET_CLAIM_RESPONSE_DOCS_API_SUCCESS:
      return {
        ...state,
        claimResponseDocuments: action.payload.claimResponseDocumentIds
      };
    case SET_IS_CLAIM_RESPONSE_UPDATE_MODAL_SHOWING:
      return {
        ...state,
        isClaimResponseUpdateModalShowing: action.payload
      };
    default:
      return state;
  }
};
