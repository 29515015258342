import { UpdateUserDTO } from '@reposit/api-client';

export const UPDATE_USER_STORE_KEY = 'USER/UPDATE_USER';
export const UPDATE_USER_API_REQUESTED = `USER/UPDATE_USER_API_REQUESTED`;
export const UPDATE_USER_API_SUCCESS = 'USER/UPDATE_USER_API_SUCCESS';
export const UPDATE_USER_API_FAILED = 'USER/UPDATE_USER_API_FAILED';

export function updateUserRequested(currentUserId: string, data: UpdateUserDTO) {
  return {
    type: UPDATE_USER_API_REQUESTED,
    payload: {
      currentUserId,
      data
    }
  } as const;
}

export function updateUserSuccess(payload: any) {
  return {
    type: UPDATE_USER_API_SUCCESS,
    payload
  } as const;
}

export function updateUserFailed(error: string) {
  return {
    type: UPDATE_USER_API_FAILED,
    payload: error
  } as const;
}

export type UserActionTypes = ReturnType<typeof updateUserRequested | typeof updateUserSuccess | typeof updateUserFailed>;
