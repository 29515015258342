import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Card from '../Card';
import { getBreakpoint } from '../../base/style';

interface ResponsiveModalProps {
  onClick?: (e: any) => any;
  onDismiss?: () => void;
}

const Overlay = styled.div`
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 9999;
  overflow-y: scroll;
`;

const ModalDialog = styled(Card)<ResponsiveModalProps>`
  margin: 10vh 24px 0;
  max-width: 600px;
  width: 100%;
  z-index: 9999;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 1000px;
  }
`;

interface ResponsiveModalProps {}

const handleModalClick = (e: any) => e.stopPropagation();

const ResponsiveModal: React.SFC<ResponsiveModalProps> = ({ onDismiss, children }) => {
  const modalDiv = document.getElementById('modal');
  if (!modalDiv) return null;

  return ReactDOM.createPortal(
    <Overlay onClick={onDismiss}>
      <ModalDialog onClick={handleModalClick}>
        <div>{children}</div>
      </ModalDialog>
    </Overlay>,
    modalDiv
  );
};

export default ResponsiveModal;
