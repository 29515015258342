import { ClaimResponseDocumentsApi, ClaimsApi } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createClaimResponseDocumentsApi, createClaimsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ClaimActionTypes,
  createClaimResponseDocumentFailed,
  createClaimResponseDocumentSuccess,
  CREATE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED,
  CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
  fetchClaimFailed,
  fetchClaimRequested,
  fetchClaimSuccess,
  FETCH_CLAIM_API_REQUESTED,
  getClaimResponseDocsFailed,
  getClaimResponseDocsSuccess,
  GET_CLAIM_RESPONSE_DOCS_API_REQUESTED,
} from './claim.actions';
import { CreateClaimResponseDocumentPayload, FetchClaimPayload, FetchClaimResponseDocsPayload } from './claim.types';

// ****************
// WORKERS
// ****************
export function* fetchClaim({ payload }: { type: string; payload: FetchClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const { data } = yield call(runSagaWithAuth(() => claimsApi.findClaimById(payload.claimId)));

    // // AGENT
    // const itemProposalOne: ClaimItemProposalDTO = {
    //   id: 'ip_1',
    //   claimItemId: 'cmi_ipP2BcF31A2XVzzT',
    //   claimProposalId: 'prop_1',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 15000,
    //   explanation: undefined,
    //   settled: undefined,
    //   documents: [
    //     { id: 'cid_1', document, claimItemId: 'cmi_ipP2BcF31A2XVzzT', claimItemProposalId: 'ip_1', createdByUserId: 'usr_1' },
    //   ],
    // };
    // const itemProposalTwo: ClaimItemProposalDTO = {
    //   id: 'ip_2',
    //   claimItemId: 'cmi_l4y4Lhj894Sr2nSR',
    //   claimProposalId: 'prop_1',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 25000,
    //   documents: [
    //     { id: 'cid_2', document, claimItemId: 'cmi_l4y4Lhj894Sr2nSR', claimItemProposalId: 'ip_2', createdByUserId: 'usr_1' },
    //   ],
    // };
    // const claimProposalOne: ClaimProposalDTO = {
    //   id: 'prop_1',
    //   claimId: data.id,
    //   round: 1,
    //   source: ClaimProposalDTOSourceEnum.SUPPLIER,
    //   status: ClaimProposalDTOStatusEnum.COUNTERED,
    //   createdByUserId: '',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   itemProposals: [itemProposalOne, itemProposalTwo],
    // };

    // // TENANT
    // const itemProposalThree: ClaimItemProposalDTO = {
    //   id: 'ip_3',
    //   claimItemId: 'cmi_ipP2BcF31A2XVzzT',
    //   claimProposalId: 'prop_2',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 10000,
    //   explanation: undefined,
    //   settled: undefined,
    //   documents: [
    //     { id: 'cid_1', document, claimItemId: 'cmi_ipP2BcF31A2XVzzT', claimItemProposalId: 'ip_1', createdByUserId: 'usr_1' },
    //   ],
    // };
    // const itemProposalFour: ClaimItemProposalDTO = {
    //   id: 'ip_4',
    //   claimItemId: 'cmi_l4y4Lhj894Sr2nSR',
    //   claimProposalId: 'prop_1',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 25000,
    //   settled: true,
    //   documents: [
    //     { id: 'cid_2', document, claimItemId: 'cmi_l4y4Lhj894Sr2nSR', claimItemProposalId: 'ip_2', createdByUserId: 'usr_1' },
    //   ],
    // };

    // const claimProposalTwo: ClaimProposalDTO = {
    //   id: 'prop_2',
    //   claimId: data.id,
    //   round: 2,
    //   source: ClaimProposalDTOSourceEnum.TENANT,
    //   status: ClaimProposalDTOStatusEnum.DRAFT,
    //   createdByUserId: '',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   itemProposals: [],
    // };

    // const itemProposalFive: ClaimItemProposalDTO = {
    //   id: 'ip_5',
    //   claimItemId: 'cmi_ipP2BcF31A2XVzzT',
    //   claimProposalId: 'prop_3',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 12500,
    //   explanation: undefined,
    //   settled: false,
    //   documents: [
    //     { id: 'cid_1', document, claimItemId: 'cmi_ipP2BcF31A2XVzzT', claimItemProposalId: 'ip_1', createdByUserId: 'usr_1' },
    //   ],
    // };
    // const itemProposalSix: ClaimItemProposalDTO = {
    //   id: 'ip_6',
    //   claimItemId: 'cmi_l4y4Lhj894Sr2nSR',
    //   claimProposalId: 'prop_3',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   amount: 22500,
    //   settled: true,
    //   documents: [
    //     { id: 'cid_2', document, claimItemId: 'cmi_l4y4Lhj894Sr2nSR', claimItemProposalId: 'ip_2', createdByUserId: 'usr_1' },
    //   ],
    // };
    // const claimProposalThree: ClaimProposalDTO = {
    //   id: 'prop_3',
    //   claimId: data.id,
    //   round: 3,
    //   source: ClaimProposalDTOSourceEnum.SUPPLIER,
    //   status: ClaimProposalDTOStatusEnum.DISPUTED,
    //   createdByUserId: '',
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    //   itemProposals: [itemProposalFive, itemProposalSix],
    // };

    // const newData = { ...data, proposals: [claimProposalOne, claimProposalTwo] };
    yield syncEntitiesAndGetResults(data, SCHEMA.claim);
    yield put<ClaimActionTypes>(fetchClaimSuccess());
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ClaimActionTypes>(fetchClaimFailed(error));
  }
}

export function* fetchClaimResponseDocs({ payload }: { type: string; payload: FetchClaimResponseDocsPayload }) {
  try {
    const { claimId } = payload;
    const claimResponseDocumentsApi: ClaimResponseDocumentsApi = yield createClaimResponseDocumentsApi();
    const { data } = yield call(runSagaWithAuth(() => claimResponseDocumentsApi.findManyClaimResponseDocuments(claimId)));
    const ids = yield syncEntitiesAndGetResults(data, SCHEMA.claimResponseDocuments);
    yield put<ClaimActionTypes>(getClaimResponseDocsSuccess(ids));
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ClaimActionTypes>(getClaimResponseDocsFailed(error));
  }
}

export function* createClaimResponseDocument({ payload }: { type: string; payload: CreateClaimResponseDocumentPayload }) {
  try {
    const { file, claimId } = payload;
    const api: ClaimResponseDocumentsApi = yield createClaimResponseDocumentsApi();
    yield call(runSagaWithAuth(() => api.addClaimResponseDocument(claimId, file, file.name, 'CLAIM_RESPONSE')));
    yield put<ClaimActionTypes>(createClaimResponseDocumentSuccess());
    yield put<ClaimActionTypes>(fetchClaimRequested(claimId));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
        message: `Claim response document successfully created`,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = get(e, 'response.data.message', e);
    yield put<ClaimActionTypes>(createClaimResponseDocumentFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimSagas() {
  yield takeLatest(FETCH_CLAIM_API_REQUESTED, fetchClaim);
  yield takeLatest(GET_CLAIM_RESPONSE_DOCS_API_REQUESTED, fetchClaimResponseDocs);
  // yield takeLatest(DELETE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED, deleteClaimResponseDocument);
  yield takeLatest(CREATE_CLAIM_RESPONSE_DOCUMENT_API_REQUESTED, createClaimResponseDocument);
}
