import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersApi } from '@reposit/api-client';
import { FlashState } from '../../components/FlashMessage';
import { UserEntity } from '../entities/entities.types';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import { createStandardUsersApi, runThunkWithAuth } from '../utils/api.utils';

export const USER_COMPLETE_ONBOARDING_INTRODUCTION_STORE_KEY = 'user/complete-onboarding-introduction';

export const completeOnboardingIntroductionThunk = createAsyncThunk<UserEntity, void, { state: AppState }>(
  USER_COMPLETE_ONBOARDING_INTRODUCTION_STORE_KEY,
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    try {
      const usersApi: UsersApi = createStandardUsersApi(state);
      const response = await runThunkWithAuth(() => usersApi.completeOnboardingIntroduction(), dispatch);
      return response.data;
    } catch (error) {
      dispatch(
        setFlashMessage({
          key: USER_COMPLETE_ONBOARDING_INTRODUCTION_STORE_KEY,
          message: 'Something went wrong! Please try again',
          state: FlashState.ERROR,
        })
      );
      throw error;
    }
  }
);
