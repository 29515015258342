import { PaymentPlanInstalmentsApi, PaymentPlanInstalmentDTO, PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { createPaymentPlanInstalmentsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchPaymentIntentSecretFailed,
  fetchPaymentIntentSecretSuccess,
  FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_STORE_KEY,
  PaymentPlanInstalmentActionTypes,
  pollInstalmentPaymentSuccessSuccess,
  pollInstalmentPaymentSuccessFailed,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED,
  FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_REQUESTED,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED,
  POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED,
  instalmentPayFailed,
  INSTALMENT_PAY_STORE_KEY,
  instalmentPaySuccess,
  pollInstalmentPaymentSuccessRequested,
  INSTALMENT_PAY_API_REQUESTED,
} from './payment-plan-instalment.actions';
import {
  FetchInstalmentPaymentIntentPayload,
  PollInstalmentPaymentSuccessPayload,
  InstalmentPayPayload,
} from './payment-plan-instalment.types';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { PaymentMethod, StripeError, PaymentIntent } from '@stripe/stripe-js';

// ****************
// WORKERS
// ****************
function delay(duration: number) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

export function* getPaymentIntent({ payload }: { type: string; payload: FetchInstalmentPaymentIntentPayload }) {
  try {
    const { paymentPlanId, paymentPlanInstalmentId } = payload;
    const paymentPlanInstalmentsApi: PaymentPlanInstalmentsApi = yield createPaymentPlanInstalmentsApi();
    const { data } = yield call(
      runSagaWithAuth(() => paymentPlanInstalmentsApi.getInstalmentPaymentIntentSecret(paymentPlanInstalmentId, paymentPlanId))
    );
    yield put<PaymentPlanInstalmentActionTypes>(fetchPaymentIntentSecretSuccess(data.paymentIntentSecret));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<PaymentPlanInstalmentActionTypes>(fetchPaymentIntentSecretFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* pay({ payload }: { type: string; payload: InstalmentPayPayload }) {
  try {
    const { paymentIntentSecret, stripe, elements, paymentPlanId, paymentPlanInstalmentId } = payload;

    const {
      paymentMethod,
      error,
    }: {
      paymentMethod?: PaymentMethod | undefined;
      error?: StripeError | undefined;
    } = yield stripe.createPaymentMethod({
      elements: elements as any,
    });

    if (!paymentMethod || error) {
      yield put<PaymentPlanInstalmentActionTypes>(
        instalmentPayFailed(error && error.message ? error.message : 'An error has occured')
      );
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: INSTALMENT_PAY_STORE_KEY,
          message: error && error.message ? error.message : 'An error has occured',
          state: FlashState.ERROR,
        })
      );
      return;
    }

    const {
      paymentIntent,
      error: confirmError,
    }: {
      paymentIntent?: PaymentIntent | undefined;
      error?: StripeError | undefined;
    } = yield stripe.confirmCardPayment(paymentIntentSecret, {
      payment_method: paymentMethod.id,
      // this sets this new payment method to be able to be used off session
      // fixed a bug where we couldn't save the PM to the customer
      setup_future_usage: 'off_session',
    });

    if (!paymentIntent || confirmError) {
      yield put<PaymentPlanInstalmentActionTypes>(
        instalmentPayFailed(confirmError && confirmError.message ? confirmError.message : 'An error has occured')
      );
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: INSTALMENT_PAY_STORE_KEY,
          message: confirmError && confirmError.message ? confirmError.message : 'An error has occured',
          state: FlashState.ERROR,
        })
      );
      return;
    }

    yield put<PaymentPlanInstalmentActionTypes>(instalmentPaySuccess());
    yield put<PaymentPlanInstalmentActionTypes>(
      pollInstalmentPaymentSuccessRequested({
        paymentIntentId: paymentIntent.id,
        paymentPlanId,
        paymentPlanInstalmentId,
      })
    );
  } catch (e) {
    yield put<PaymentPlanInstalmentActionTypes>(instalmentPayFailed(e.message));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: INSTALMENT_PAY_STORE_KEY,
        message: e.message,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* pollInstalmentPaymentSuccess({ payload }: { type: string; payload: PollInstalmentPaymentSuccessPayload }) {
  try {
    const { paymentPlanInstalmentId, paymentPlanId } = payload;
    const paymentPlanInstalmentsApi: PaymentPlanInstalmentsApi = yield createPaymentPlanInstalmentsApi();
    let running = true;
    while (running) {
      const apiResponse: AxiosResponse<PaymentPlanInstalmentDTO> = yield call(
        runSagaWithAuth(() => paymentPlanInstalmentsApi.getInstalmentById(paymentPlanInstalmentId, paymentPlanId))
      );
      const { data } = apiResponse;
      if (data.status !== PaymentPlanInstalmentDTOStatusEnum.PAID) {
        yield call(delay, 2000);
      } else {
        running = false;
        yield put(push(`/payment-plan/${paymentPlanId}/payment-success`));
        yield put<PaymentPlanInstalmentActionTypes>(pollInstalmentPaymentSuccessSuccess(paymentPlanId, paymentPlanInstalmentId));
      }
    }
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<PaymentPlanInstalmentActionTypes>(pollInstalmentPaymentSuccessFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: INSTALMENT_PAY_STORE_KEY, message: error, state: FlashState.ERROR })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchPaymentPlanInstalmentSagas() {
  yield takeLatest(POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED, pollInstalmentPaymentSuccess);
  yield takeLatest(FETCH_INSTALMENT_PAYMENT_INTENT_SECRET_API_REQUESTED, getPaymentIntent);
  yield takeLatest(INSTALMENT_PAY_API_REQUESTED, pay);

  while (true) {
    const action = yield take(POLL_INSTALMENT_PAYMENT_SUCCESS_API_REQUESTED);
    yield race({
      poll: call(pollInstalmentPaymentSuccess, action),
      success: take(POLL_INSTALMENT_PAYMENT_SUCCESS_API_SUCCESS),
      cancelled: take(POLL_INSTALMENT_PAYMENT_SUCCESS_API_CANCELLED),
      failed: take(POLL_INSTALMENT_PAYMENT_SUCCESS_API_FAILED),
    });
  }
}
