import { AddressesApi, UsersApi, UserDTO } from '@reposit/api-client';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getErrorMessage } from '../../utils/common.utils';
import { createAddressesApi, runSagaWithAuth, createUsersApi } from '../utils/api.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import {
  AddresHistoryActionTypes,
  addressLookupFailed,
  addressLookupSuccess,
  ADDRESS_LOOKUP_API_REQUESTED,
  ADDRESS_HISTORY_STORE_KEY,
  setIsShowingSearchModal,
  ADD_ADDRESS_API_REQUESTED,
  addAddressSuccess,
  addAddressFailed,
  setIsAddNewFormOpen,
  removeAddressHistorySuccess,
  removeAddressHistoryFailed,
  REMOVE_ADDRESS_API_REQUESTED,
  REMOVE_ADDRESS_STORE_KEY,
} from './address-history.actions';
import { AddressLookupPayload, AddAddressPayload, RemoveAddressPayload } from './address-history.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';
import { AxiosResponse } from 'axios';
import SCHEMA from '../schema';
import { getCurrentUser } from '../selectors/user.selectors';
import { AddressWithDatesEntity } from '../entities/entities.types';
import { get, omit } from 'lodash';

// ****************
// WORKERS
// ****************
export function* addressLookup({ payload }: AddressLookupPayload) {
  try {
    const addressesApi: AddressesApi = yield createAddressesApi();
    const { data } = yield call(runSagaWithAuth(() => addressesApi.searchAddressesByPostcode(payload.postcode)));

    yield put<AddresHistoryActionTypes>(addressLookupSuccess(data));

    if (data && !data.length) {
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: ADDRESS_HISTORY_STORE_KEY,
          message: 'No addresses were found for this postcode.',
          state: FlashState.WARNING,
        })
      );
    } else {
      yield put<AddresHistoryActionTypes>(setIsShowingSearchModal(true));
    }
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<AddresHistoryActionTypes>(addressLookupFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADDRESS_HISTORY_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* addAddress({ payload }: AddAddressPayload) {
  try {
    const { data } = payload;
    const usersApi: UsersApi = yield createUsersApi();
    const currentUser = yield select(getCurrentUser);
    const addresses = (currentUser.attributes.addresses || []).map((add: any) => omit(add, 'id'));

    const apiResponse = yield call(
      runSagaWithAuth(() =>
        usersApi.updateAddressHistory({
          addresses: [...addresses, data],
        })
      )
    );

    const user = apiResponse.data;

    const adds = get(user.attributes, 'addresses', []);
    const addressesWithIds = adds.map((address: any, i: number) => {
      address.id = `tmp_id_${i + 1}`;
      return address;
    });

    const userWithAddressIds = {
      ...user,
      attributes: {
        ...user.attributes,
        addresses: addressesWithIds,
      },
    };

    yield syncEntitiesAndGetResults({ ...userWithAddressIds }, SCHEMA.user);

    yield put<AddresHistoryActionTypes>(addAddressSuccess());
    yield put<AddresHistoryActionTypes>(setIsAddNewFormOpen(false));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADDRESS_HISTORY_STORE_KEY,
        message: 'Address successfully added',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);

    yield put<AddresHistoryActionTypes>(addAddressFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADDRESS_HISTORY_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* removeAddress({ payload }: RemoveAddressPayload) {
  try {
    const { id } = payload;
    const usersApi: UsersApi = yield createUsersApi();
    const currentUser = yield select(getCurrentUser);
    const addresses = currentUser.attributes.addresses || [];

    let updatedAddresses = addresses.filter((address: AddressWithDatesEntity) => {
      return address.id !== id;
    });

    updatedAddresses = updatedAddresses.map((add: any) => omit(add, 'id'));

    const apiResponse: AxiosResponse<UserDTO> = yield call(
      runSagaWithAuth(() =>
        usersApi.updateAddressHistory({
          addresses: [...updatedAddresses],
        })
      )
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.user);
    yield put<AddresHistoryActionTypes>(removeAddressHistorySuccess(currentUser.id, id));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REMOVE_ADDRESS_STORE_KEY,
        message: 'Address successfully removed',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);

    yield put<AddresHistoryActionTypes>(removeAddressHistoryFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REMOVE_ADDRESS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAddressHistorySagas() {
  yield takeLatest(ADDRESS_LOOKUP_API_REQUESTED, addressLookup);
  yield takeLatest(ADD_ADDRESS_API_REQUESTED, addAddress);
  yield takeLatest(REMOVE_ADDRESS_API_REQUESTED, removeAddress);
}
