import {
  TenancyUserActionTypes,
  FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_FAILED,
  FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_SUCCESS,
  FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED
} from './tenancy-user.actions';
import { TenancyUserState } from './tenancy-user.types';

const initialState: TenancyUserState = {
  currentTenancyUser: null
};

export default (state: TenancyUserState = initialState, action: TenancyUserActionTypes): TenancyUserState => {
  switch (action.type) {
    case FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_REQUESTED: {
      return {
        ...state,
        currentTenancyUser: null
      };
    }

    case FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_SUCCESS: {
      return {
        ...state,
        currentTenancyUser: action.payload
      };
    }

    case FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_API_FAILED: {
      return {
        ...state,
        currentTenancyUser: null
      };
    }

    default:
      return state;
  }
};
