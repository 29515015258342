import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../../../base/style';
import Button from '../../../../components/Button';
import ConfirmStep from '../../../../components/ConfirmStep';
import { FullPageLoader } from '../../../../components/Loading/index';
import PageTitle from '../../../../components/PageTitle';
import RepositCard from '../../../../components/Reposit/RepositCard';
import TenantList from '../../../../components/TenantList';
import ToggleButton from '../../../../components/ToggleButton/index';
import { Caption, Header5, P1 } from '../../../../components/Typography';
import { getCurrentCustomerId } from '../../../../redux/account/account.selectors';
import { getTenantById } from '../../../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { confirmRepositRequested } from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { fetchTenancyOrdersRequested, FETCH_TENANCY_ORDERS_STORE_KEY } from '../../../../redux/order/order.actions';
import { getCurrentOrderCustomer, getTenancyFromTenancyOrderOrder } from '../../../../redux/order/order.selectors';
import { AppState } from '../../../../redux/root.reducer';
import { getCurrentTenancyUser } from '../../../../redux/tenancy-user/tenancy-user.selector';
import { getAddressFirstLine, getAddressTownPostcode } from '../../../../utils/common.utils';
import { penceToPounds } from '../../../../utils/currency';

interface ConfirmProps {}

const Action = styled.div`
  margin-bottom: 120px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`;

const ActionInfo = styled(P1)`
  font-size: 17px;
  margin: 0 20px 20px 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 20px;
  }
`;

const PageSubtitle = styled(Caption)`
  font-size: 18px;
  margin: 0 0 12px 70px;
`;

const Inner = styled.div`
  padding: 0 36px 24px;
`;

const ConfirmAction = styled.div`
  padding: 40px 40px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const SummaryData = styled.div`
  padding: 0 40px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
  }
`;

const SmallContainer = styled.div`
  flex: 0 1 20%;
`;

const LargeContainer = styled.div`
  flex: 0 1 40%;
`;

const Label = styled(Header5)`
  margin: 0 0 0.5rem;
  padding: 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 1rem;
  }
`;

interface ConfirmFormValues {
  confirm: boolean;
  one: boolean;
  two: boolean;
  three: boolean;
  four: boolean;
}

const Schema = Yup.object().shape({
  confirm: Yup.bool().oneOf([true]),
  one: Yup.bool().oneOf([true]),
  two: Yup.bool().oneOf([true]),
  three: Yup.bool().oneOf([true]),
  four: Yup.bool().oneOf([true]),
});

const Confirm: React.FC<ConfirmProps> = (props) => {
  const dispatch = useDispatch();
  const customerId = useSelector(getCurrentCustomerId);
  const { order } = useSelector(getCurrentOrderCustomer);
  const tenancyOrdersLoadingSelector = createLoadingSelector([FETCH_TENANCY_ORDERS_STORE_KEY]);
  const istenancyOrdersLoading = useSelector(tenancyOrdersLoadingSelector);
  const tenancy = useSelector((state: AppState) => getTenancyFromTenancyOrderOrder(state, order.id));
  const currentTenancyUser = useSelector(getCurrentTenancyUser);
  const currentTenancyUserId = get(currentTenancyUser, 'id', '');
  const currentTenant = useSelector(getTenantById(currentTenancyUserId));
  const guarantor = get(currentTenant, 'guarantor');

  const orgName = get(tenancy, 'organization.name');

  useEffect(() => {
    dispatch(fetchTenancyOrdersRequested());
  }, [dispatch]);

  if (istenancyOrdersLoading || !tenancy) {
    return <FullPageLoader />;
  }

  return (
    <Formik
      initialValues={{
        confirm: false,
        one: false,
        two: false,
        three: false,
        four: false,
      }}
      validationSchema={Schema}
      onSubmit={() => {
        dispatch(confirmRepositRequested(customerId, order.id));
      }}
    >
      {({ handleChange, handleSubmit, isValid }: FormikProps<ConfirmFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <PageTitle>Is everything correct?</PageTitle>
                  <PageSubtitle>Please take a look at the following summary and confirm that everything is correct.</PageSubtitle>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <RepositCard flush title="">
                    <Fragment>
                      <SummaryData>
                        <SmallContainer>
                          <Label>Rent Per Month</Label>
                          <P1>£{penceToPounds(tenancy.PPM)}</P1>
                        </SmallContainer>
                        <LargeContainer>
                          <Label>Reposit Address</Label>
                          <P1>
                            {getAddressFirstLine(tenancy.property.address)}
                            <br />
                            {getAddressTownPostcode(tenancy.property.address)}
                          </P1>
                        </LargeContainer>
                        <SmallContainer>
                          <Label>Tenancy Agent</Label>
                          <P1>{orgName}</P1>
                        </SmallContainer>
                        {/* current tenant has guarantor?  */}
                        {guarantor && (
                          <SmallContainer>
                            <Label>Guarantor</Label>
                            <P1>{`${guarantor.firstName} ${guarantor.lastName}`}</P1>
                          </SmallContainer>
                        )}
                      </SummaryData>
                      <TenantList tenants={tenancy.tenants} />
                      <ConfirmAction>
                        <ToggleButton name="confirm" onToggle={handleChange} label="I confirm this is correct" />
                      </ConfirmAction>
                    </Fragment>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <RepositCard flush title="A few things to be aware of">
                    <Fragment>
                      <Inner>
                        <Caption>
                          It's important to us that you're fully aware of how Reposit works before you continue. Make sure you're
                          happy with the following points:
                        </Caption>
                      </Inner>
                      <ConfirmStep
                        name="one"
                        onToggle={handleChange}
                        text="I understand that I am still liable for any charges at the end of the tenancy"
                      />
                      <ConfirmStep
                        name="two"
                        onToggle={handleChange}
                        text="I understand that at the end of the tenancy, my Landlord may register charges via Reposit. I will be notified of these and can accept them or raise a dispute"
                      />
                      <ConfirmStep
                        name="three"
                        onToggle={handleChange}
                        text="I understand that if I do not respond within 7 days, the charges will be accepted and I will be automatically charged or held legally liable for the full amount. If amounts remain unpaid, this can affect my credit score"
                      />
                      <ConfirmStep
                        name="four"
                        onToggle={handleChange}
                        text="I understand that it's my responsibility to ensure my contact details are updated so Reposit can notify me of any claims or updates to my Reposit"
                      />
                    </Fragment>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <Action>
                    <ActionInfo>Next: Agree to our Terms</ActionInfo>
                    <Button type="submit" disabled={!isValid}>
                      Continue
                    </Button>
                  </Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default Confirm;
