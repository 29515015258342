import { PaymentPlanActionDTOTypeIdEnum, PaymentPlanActionDTO } from '@reposit/api-client';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import CardIconActive from '../../assets/svg/progress/card-active.svg';
import CardIcon from '../../assets/svg/progress/card.svg';
import PersonActiveIcon from '../../assets/svg/progress/person-active.svg';
import PersonIcon from '../../assets/svg/progress/person.svg';
import TickIconActive from '../../assets/svg/progress/tick-active.svg';
import TickIcon from '../../assets/svg/progress/tick.svg';
import Stage, { StageProps } from './Stage';

interface ProgressProps {
  currentUrl: string;
  paymentPlanActions: PaymentPlanActionDTO[];
}

const Stages: StageProps[] = [
  {
    label: 'Payment Proposal Outline',
    icon: TickIcon,
    iconActive: TickIconActive,
    urls: ['/confirm'],
    action: PaymentPlanActionDTOTypeIdEnum.CONFIRM,
  },
  {
    label: 'Address Confirmation',
    icon: PersonIcon,
    iconActive: PersonActiveIcon,
    urls: ['/address'],
    action: PaymentPlanActionDTOTypeIdEnum.ADDRESS,
  },
  {
    label: 'Payment Details',
    icon: CardIcon,
    iconActive: CardIconActive,
    urls: ['/card-details'],
    action: PaymentPlanActionDTOTypeIdEnum.CARDDETAILS,
  },
];

const Wrapper = styled.div`
  overflow-y: auto;
  margin: 0;
  padding: 0 0 6px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 930px;

  &:after {
    content: '';
    display: block;
    background: ${(props) => props.theme.colors.dividerDark};
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
`;

const isActive = (currentUrl: string, urls?: string[]): boolean => {
  if (!urls) return false;
  return !!urls.find((url) => currentUrl.includes(`${url}`));
};

const getStages = (paymentPlanActions: any[]) => {
  let stages;

  stages = Stages.map((stage) => {
    const ppa = find(paymentPlanActions, (orderAction) => stage.action === orderAction.typeId);
    stage.isComplete = ppa && ppa.completedAt;
    return stage;
  });

  stages = stages.filter((stage) => find(paymentPlanActions, (paymentPlanAction) => stage.action === paymentPlanAction.typeId));

  return stages;
};

const finishStage = Stages.pop();

const PaymentPlanProgress: React.FC<ProgressProps> = ({ currentUrl, paymentPlanActions }) => {
  const stages = getStages(paymentPlanActions);

  return (
    <Wrapper>
      <Inner>
        {stages.map((stage) => (
          <Stage
            key={stage.label}
            label={stage.label}
            isComplete={stage.isComplete}
            isActive={isActive(currentUrl, stage.urls)}
            icon={stage.icon}
            iconActive={stage.iconActive}
          />
        ))}
        {finishStage && (
          <Stage
            key={finishStage.label}
            label={finishStage.label}
            isComplete={finishStage.isComplete}
            isActive={isActive(currentUrl, finishStage.urls)}
            icon={finishStage.icon}
            iconActive={finishStage.iconActive}
          />
        )}
      </Inner>
    </Wrapper>
  );
};

export default PaymentPlanProgress;
