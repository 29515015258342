import React from 'react';
import styled from 'styled-components';
import { Row, Container, Col } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';
import Card from '../../../../../components/Card';
import { P1 } from '../../../../../components/Typography';
import { NavLink } from 'react-router-dom';
import Button from '../../../../../components/Button';

const Action = styled.div`
  padding: 16px 0 0;
  text-align: right;

  a {
    text-decoration: none;
  }
`;

const SuccessPayment: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>You're all set!</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <P1 bold>Thank you for your payment!</P1>
            <P1>
              There is nothing more for you to action at this moment in time. We’ll be in touch if you are required to pay your
              annual fee or if your landlord submits end of tenancy charges.
            </P1>
            <P1>Enjoy your new home!</P1>
          </Card>
          <Action>
            <NavLink to={`/`}>
              <Button>Take me to my Reposit</Button>
            </NavLink>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessPayment;
