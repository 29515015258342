import React, { Fragment, useState } from 'react';
import { ExternalAddressDTO } from '@reposit/api-client';
import styled from 'styled-components';
import Modal from '../Modal/index';
import { Header3, P2, StyledLink } from '../Typography/index';
import SearchedAddressList from '../SearchedAddressList/index';
import Button from '../Button/index';

interface SelectAddressModalProps {
  addresses: ExternalAddressDTO[];
  onSubmit: (address: ExternalAddressDTO) => void;
  onDismiss: () => void;
  onManualAddressSelect: () => void;
}

const ManualLink = styled(StyledLink)`
  display: inline;
`;

const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 10px;
`;

const SelectAddressModal = ({ addresses, onSubmit, onDismiss, onManualAddressSelect }: SelectAddressModalProps) => {
  const [currentAddress, setAddress] = useState('');

  const handleSubmit = () => {
    const address = addresses.find(a => a.externalId === currentAddress);
    if (!address) return;
    onSubmit(address);
  };

  const hasAddresses = addresses && addresses.length;

  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>Choose address</Header3>
        <P2>
          Select your address from the list below. If it's not listed{', '}
          <ManualLink to="#" onClick={onManualAddressSelect}>
            enter it manually
          </ManualLink>
        </P2>
        {hasAddresses ? (
          <SearchedAddressList addresses={addresses} currentAddress={currentAddress} setAddress={setAddress} />
        ) : (
          <P2>No results found</P2>
        )}
        {hasAddresses ? (
          <ButtonContainer>
            <Button disabled={!currentAddress} onClick={handleSubmit}>
              USE THIS ADDRESS
            </Button>
          </ButtonContainer>
        ) : null}
      </Fragment>
    </Modal>
  );
};

export default SelectAddressModal;
