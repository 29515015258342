import { ConnectedRouter, getLocation } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '.';
import { appConfig } from './appConfig';
import { FullPageLoader } from './components/Loading';
import { useHubspot } from './hooks/useHubspot';
import {
  fetchCustomerRequested,
  fetchMeRequested,
  FETCH_CUSTOMER_STORE_KEY,
  FETCH_ME_STORE_KEY,
  setHasSessionBeenCleared,
} from './redux/account/account.actions';
import FlashMessageContainer from './containers/FlashMessage/index';
import { getHasSessionBeenCleared, getMeHasFetched } from './redux/account/account.selectors';
import { logout } from './redux/auth/auth.actions';
import { getAccessToken } from './redux/auth/auth.selectors';
import { createLoadingSelector } from './redux/loading/loading.selector';
import { AppState } from './redux/root.reducer';
import { getCurrentUser } from './redux/selectors/user.selectors';
import Routes from './Routes';
import {
  bootGoogleAnalytics,
  initialiseGoogleAnalytics,
  sendPageViewToGoogleAnalytics,
} from './utils/integrators/analytics.integrator';
import { initialiseHotJar } from './utils/integrators/hotjar.integrator';
import { identifyHubspotUser, loadHubspotWidget } from './utils/integrators/hubspot.integrator';
import { useQuery } from './hooks/useQuery';
import { IntercomProps, useIntercom } from 'react-use-intercom';
import { PRIMARY_PURPLE } from './base/theme';

const CLEAR_SESSION_QUERY = 'clear_session';

interface AppProps {}

initialiseGoogleAnalytics();
initialiseHotJar();

const App: React.FC<AppProps> = () => {
  const getMeLoadingSelector = createLoadingSelector([FETCH_ME_STORE_KEY, FETCH_CUSTOMER_STORE_KEY]);
  const token = useSelector(getAccessToken);
  const isGetMeLoading = useSelector(getMeLoadingSelector);
  const hasGetMeFetched = useSelector(getMeHasFetched);
  const dispatch = useDispatch();

  const location = useSelector((state: AppState) => getLocation(state));
  const currentUser = useSelector(getCurrentUser);
  const { hasHubspotLoaded } = useHubspot(appConfig.hubspot.accountId);
  const { boot: bootIntercom } = useIntercom();

  const hasSessionBeenCleared = useSelector(getHasSessionBeenCleared);

  const query = useQuery(location);

  useEffect(() => {
    const clearSessionQuery = query && query.get(CLEAR_SESSION_QUERY);
    if (clearSessionQuery && !hasSessionBeenCleared) {
      dispatch(logout());
      dispatch(setHasSessionBeenCleared(true));
    }
  }, [query, dispatch, hasSessionBeenCleared]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMeRequested());
      dispatch(fetchCustomerRequested());
    }
  }, [dispatch, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    sendPageViewToGoogleAnalytics(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser) {
      bootGoogleAnalytics(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && hasHubspotLoaded && appConfig.hubspot.enabled) {
      identifyHubspotUser(currentUser);
      loadHubspotWidget();
    }
  }, [currentUser, hasHubspotLoaded]);

  useEffect(() => {
    if (currentUser && appConfig.intercom.enabled) {
      const params: IntercomProps = {
        actionColor: PRIMARY_PURPLE,
        userId: currentUser.id,
        userHash: currentUser.intercomUserHash,
        email: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        phone: currentUser.attributes && currentUser.attributes.mobileNumber,
        customAttributes: {
          user_type: currentUser.userTypeId,
        },
      };

      bootIntercom(params);
    }
  }, [currentUser, bootIntercom]);

  if (isGetMeLoading || (token && !hasGetMeFetched)) {
    return <FullPageLoader />;
  }

  return (
    <ConnectedRouter history={history}>
      <FlashMessageContainer />
      <Routes />
    </ConnectedRouter>
  );
};

export default App;
