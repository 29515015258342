import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '../Button/index';
import Modal from '../Modal/index';
import { Header3, P2 } from '../Typography/index';

interface ConfirmationModalProps {
  title: string;
  text?: string | JSX.Element;
  onSubmit: () => void;
  onDismiss: () => void;
  isSubmitting?: boolean;
}

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const ConfirmationModal = ({ onSubmit, onDismiss, title, text, isSubmitting }: ConfirmationModalProps) => {
  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>{title}</Header3>
        {text && <P2 style={{ marginBottom: 24 }}>{text}</P2>}
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={isSubmitting}>
            Confirm
          </Button>
        </Action>
      </Fragment>
    </Modal>
  );
};

export default ConfirmationModal;
