import { Schema, normalize } from 'normalizr';
import { put } from 'redux-saga/effects';

import { EntityActionTypes, updateEntities } from './entities.actions';
import { AppDispatch } from '../..';

export function* syncEntitiesAndGetResults(data: any, schema: Schema<any>) {
  const { entities, result } = normalize(data, schema);

  yield put<EntityActionTypes>(updateEntities(entities as any));

  return result;
}

export function standardSyncEntitiesAndGetResults(data: any, schema: Schema<any>, dispatch: AppDispatch) {
  const { entities, result } = normalize(data, schema);

  dispatch(updateEntities(entities as any));
  return result;
}
