import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateClaimItemProposalDTO,
  ClaimProposalDTO,
  ClaimItemProposalDTO,
  ClaimDTO,
  ClaimResponseDocumentDTO,
} from '@reposit/api-client';
import { get } from 'lodash';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { ClaimEntity, ClaimItemProposalEntity, ClaimProposalEntity, DocumentEntity } from '../entities/entities.types';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { getClaimResponseDocumentByDocumentId } from '../selectors/mediation.selectors';
import {
  createStandardClaimItemProposalsApi,
  createStandardClaimProposalsApi,
  createStandardClaimResponseDocumentsApi,
  createStandardClaimsApi,
  runThunkWithAuth,
} from '../utils/api.utils';
import {
  acceptProposalSuccess,
  ACCEPT_TENANT_PROPOSAL_STORE_KEY,
  COUNTER_TENANT_PROPOSAL_STORE_KEY,
  CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
  CREATE_ITEM_PROPOSAL_STORE_KEY,
  deleteClaimResponseDocumentSuccess,
  DELETE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
  fetchClaimRequested,
  PUBLISH_PROPOSAL_STORE_KEY,
  REJECT_TENANT_PROPOSAL_STORE_KEY,
  UPDATE_ITEM_PROPOSAL_STORE_KEY,
  UNPUBLISH_PROPOSAL_STORE_KEY,
} from './claim.actions';
import { AxiosResponse } from 'axios';

interface AcceptTenantProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface CounterTenantProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface PublishProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface UnpublishProposalPayload {
  claimId: string;
  claimProposalId: string;
}
interface CreateItemProposalPayload {
  claimId: string;
  claimProposalId: string;
  payload: CreateClaimItemProposalDTO;
}

interface UpdateItemProposalPayload {
  claimId: string;
  claimProposalId: string;
  claimItemProposalId: string;
  payload: CreateClaimItemProposalDTO;
}

interface UpdateDisputeMessage {
  claimId: string;
  message: string;
}

interface CreateClaimResponseDocumentPayload {
  claimId: string;
  file: File;
}

interface DeleteClaimResponseDocumentPayload {
  claimId: string;
  documentId: string;
}

export const acceptProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  AcceptTenantProposalPayload,
  {
    state: AppState;
  }
>('claim/accept-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const { claimId, claimProposalId } = payload;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.agreeClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    // this should be just from this thunk - but I cant get the reducers to work atm
    dispatch(acceptProposalSuccess({ claimId, claimProposalId }));
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: ACCEPT_TENANT_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const counterProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  CounterTenantProposalPayload,
  {
    state: AppState;
  }
>('claim/counter-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const { claimId, claimProposalId } = payload;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.counterClaimProposal(claimId, claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: COUNTER_TENANT_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const rejectProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  CounterTenantProposalPayload,
  {
    state: AppState;
  }
>('claim/reject-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.disputeClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: REJECT_TENANT_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const publishProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  PublishProposalPayload,
  {
    state: AppState;
  }
>('claim/publish-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.publishClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: PUBLISH_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const unpublishProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  UnpublishProposalPayload,
  {
    state: AppState;
  }
>('claim/unpublish-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();

  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.unpublishClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: UNPUBLISH_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const createClaimItemProposalThunk = createAsyncThunk<
  ClaimItemProposalEntity,
  CreateItemProposalPayload,
  {
    state: AppState;
  }
>('claim/claim-item-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimItemProposalsApi(state);
    const { data }: AxiosResponse<ClaimItemProposalDTO> = await runThunkWithAuth(
      () => api.createClaimItemProposal(payload.claimId, payload.claimProposalId, payload.payload),
      dispatch
    );
    const entity: ClaimItemProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: CREATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const updateClaimItemProposalThunk = createAsyncThunk<
  ClaimItemProposalEntity,
  UpdateItemProposalPayload,
  {
    state: AppState;
  }
>('claim/update-item-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimItemProposalsApi(state);

    const { data }: AxiosResponse<ClaimItemProposalDTO> = await runThunkWithAuth(
      () => api.updateClaimItemProposal(payload.claimId, payload.claimProposalId, payload.claimItemProposalId, payload.payload),
      dispatch
    );
    const entity: ClaimItemProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested(payload.claimId));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: UPDATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const updateDisputeMessageThunk = createAsyncThunk<
  ClaimEntity,
  UpdateDisputeMessage,
  {
    state: AppState;
  }
>('claim/update-dispute-message', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimsApi(state);
    const { data }: AxiosResponse<ClaimDTO> = await runThunkWithAuth(
      () => api.updateTenantDisputeMessage(payload.claimId, { message: payload.message }),
      dispatch
    );
    dispatch(fetchClaimRequested(payload.claimId));
    const entity: ClaimEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claim, dispatch);
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: UPDATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const createClaimResponseDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateClaimResponseDocumentPayload,
  {
    state: AppState;
  }
>('claim/create-claim-response-document', async (payload, thunkAPI) => {
  const { file, claimId } = payload;
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimResponseDocumentsApi(state);

    const { data }: AxiosResponse<ClaimResponseDocumentDTO> = await runThunkWithAuth(
      () => api.addClaimResponseDocument(claimId, file, file.name, 'CLAIM_RESPONSE'),
      dispatch
    );
    dispatch(fetchClaimRequested(claimId));
    standardSyncEntitiesAndGetResults(data, SCHEMA.claimResponseDocument, dispatch);
    return data.document;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const deleteClaimResponseDocumentThunk = createAsyncThunk<
  void,
  DeleteClaimResponseDocumentPayload,
  {
    state: AppState;
  }
>('claim/delete-claim-response-document', async (payload, thunkAPI) => {
  const { documentId, claimId } = payload;
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const claimResponseDocument = getClaimResponseDocumentByDocumentId(state, documentId);
    const api = createStandardClaimResponseDocumentsApi(state);

    await runThunkWithAuth(() => api.deleteClaimResponseDocument(claimId, documentId), dispatch);

    dispatch(fetchClaimRequested(claimId));
    dispatch(deleteClaimResponseDocumentSuccess({ claimId, claimResponseDocumentId: get(claimResponseDocument, 'id', '') }));
    dispatch(
      setFlashMessage({
        key: DELETE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY,
        message: 'Success! You deleted the document.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    console.error({ error });
    dispatch(setFlashMessage({ key: DELETE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
