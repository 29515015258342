import { RepositOffersApi, RepositOfferWithRecipientDTO, RepositOfferRecipientDTOChoiceEnum } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createRepositOffersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchRepositOfferFailed,
  fetchRepositOfferSuccess,
  FETCH_REPOSIT_OFFER_API_REQUESTED,
  RepositOfferActionTypes,
  respondToRepositOfferSuccess,
  respondToRepositOfferFailed,
  RESPOND_TO_REPOSIT_OFFER_API_REQUESTED,
} from './reposit-offer.actions';
import { FetchRepositOfferParams, RespondToRepositOfferParams } from './reposit-offer.types';
import { push } from 'connected-react-router';

export function* fetchRepositOffer({ payload }: { type: string; payload: FetchRepositOfferParams }) {
  try {
    const api: RepositOffersApi = yield createRepositOffersApi();

    const { data }: AxiosResponse<RepositOfferWithRecipientDTO> = yield call(
      runSagaWithAuth(() => api.getRepositOffer(payload.repositOfferId, payload.token))
    );

    // adding id here because of the shape of the data
    yield syncEntitiesAndGetResults({ ...data, id: data.repositOffer.id }, SCHEMA.repositOffer);

    yield put<RepositOfferActionTypes>(fetchRepositOfferSuccess());
  } catch (e) {
    yield put<RepositOfferActionTypes>(fetchRepositOfferFailed(get(e, 'response.data.message', e)));
  }
}

export function* respondToRepositOffer({ payload }: { type: string; payload: RespondToRepositOfferParams }) {
  try {
    const api: RepositOffersApi = yield createRepositOffersApi();

    yield call(runSagaWithAuth(() => api.respondToOffer(payload.repositOfferId, payload.choice, payload.token)));

    yield put<RepositOfferActionTypes>(respondToRepositOfferSuccess());
    const route =
      payload.choice === RepositOfferRecipientDTOChoiceEnum.REPOSIT ? '/reposit-offer-accepted' : '/reposit-offer-rejected';
    yield put(push(route));
  } catch (e) {
    yield put<RepositOfferActionTypes>(respondToRepositOfferFailed(get(e, 'response.data.message', e)));
  }
}

//

// ****************
// WATCHERS
// ****************
export function* watchRepositOfferSagas() {
  yield takeLatest(FETCH_REPOSIT_OFFER_API_REQUESTED, fetchRepositOffer);
  yield takeLatest(RESPOND_TO_REPOSIT_OFFER_API_REQUESTED, respondToRepositOffer);
}
