import React from 'react';
import { Container, Col, Row } from 'react-grid-system';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { P1 } from '../../components/Typography/index';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';

const Action = styled.div`
  padding: 16px 0 0;
  text-align: right;

  a {
    text-decoration: none;
  }
`;

const ClaimProposalAuthorisationSuccess: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Payment Success</PageTitle>
          <Card>
            <P1 bold>Thank you, your payment has been received. </P1>
            <P1>Your proposal has been sent to your letting agent/landlord for consideration.</P1>
            <P1>The proposal payment will be held for 7 days.</P1>
            <P1>
              If the letting agent/landlord accepts your proposal for the end of tenancy charges, you will receive a notification
              and the payment will be transferred to the agent/landlord. This will settle the Reposit and it will be closed.
            </P1>
            <P1>
              If your letting agent/landlord does not accept your proposal, you will be notified to take further action and your
              proposal payment will be refunded to you in full.
            </P1>
          </Card>
          <Action>
            <NavLink to={`/`}>
              <Button>Take me to my Reposit</Button>
            </NavLink>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default ClaimProposalAuthorisationSuccess;
