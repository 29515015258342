import React, { Fragment } from 'react';
import styled from 'styled-components';
import Modal from '.';
import Button from '../Button';
import { Header3 } from '../Typography';

interface InfoModalProps {
  content: JSX.Element;
  onDismiss: () => void;
  title: string;
}

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

export const InfoModal: React.FC<InfoModalProps> = ({ onDismiss, content, title }) => {
  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 20 }}>{title}</Header3>
        {content}
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            I understand
          </Button>
        </Action>
      </Fragment>
    </Modal>
  );
};
