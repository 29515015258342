import React from 'react';
import styled from 'styled-components';
import { appConfig } from '../../appConfig';

const LogoutWrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

const LoginView = () => {
  window.location.href = appConfig.links.auth0.login;
  return <LogoutWrapper />;
};

export default LoginView;
