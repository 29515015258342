import React from 'react';
import styled from 'styled-components';
import { Header4, P2 } from '../Typography';
import WarningIcon from '../../assets/svg/warning.svg';
import { getBreakpoint } from '../../base/style';

type CardMessageType = 'primary' | 'warning';

interface CardMessageProps {
  title: string;
  subtitle?: string | JSX.Element;
  cta?: JSX.Element;
  type: CardMessageType;
}

const Wrapper = styled.div`
  align-items: center;
  background-image: linear-gradient(
    267deg,
    ${props => props.theme.colors.primaryBrand},
    ${props => props.theme.colors.secondaryBrand}
  );
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 0 5px 14px;
  padding: 24px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    height: 48px;
    padding: 0 5px 0 30px;
  }
`;

const WarningWrapper = styled(Wrapper)`
  background-image: linear-gradient(267deg, ${props => props.theme.colors.negative}, #8a0101);
`;

const Action = styled.div`
  margin: 0 0 0 auto;
`;

const Title = styled(Header4)`
  color: #fff;
  display: flex;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 8px 0 0;
    padding: 0;
  }

  &:before {
    background: transparent url(${WarningIcon}) center center no-repeat;
    content: '';
    display: block;
    height: 20px;
    margin: 0 8px 0 0;
    width: 20px;

    @media screen and (min-width: ${getBreakpoint('lg')}) {
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(P2)`
  color: #fff;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
    padding: 0;
  }
`;

const CardMessage: React.FC<CardMessageProps> = ({ title, subtitle, cta, type }) => {
  let content = (
    <Wrapper>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Action>{cta}</Action>
    </Wrapper>
  );

  if (type === 'warning') {
    content = (
      <WarningWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Action>{cta}</Action>
      </WarningWrapper>
    );
  }

  return content;
};

export default CardMessage;
