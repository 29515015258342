import styled from 'styled-components';
import { P1, Header2 } from '../../components/Typography/index';

export const Text = styled(P1)`
  font-size: 17px;
  padding: 12px 36px 4px;
  text-align: center;
`;

export const Total = styled.div`
  align-items: center;
  background-image: linear-gradient(
    255deg,
    ${(props) => props.theme.colors.primaryBrand},
    ${(props) => props.theme.colors.secondaryBrand}
  );
  border-radius: 0 0 12px 12px;

  display: flex;
  justify-content: center;
  padding: 20px 0;

  p {
    color: #fff;
    margin: 0;
  }
`;

export const Price = styled(Header2)`
  color: #fff;
  margin: 0 16px 0 0;
  padding: 0;
`;
