import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderAmountRemainingDTO, OrdersApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { AppState } from '../root.reducer';
import { createStandardOrdersApi, runThunkWithAuth } from '../utils/api.utils';
import { fetchAmountRemainingForPaymentPlanOrderSuccess } from './order.actions';
import { FetchAmountRemainingForOrderPayload } from './order.types';

export const fetchAmountRemainingForPaymentPlanOrderThunk = createAsyncThunk<
  number,
  FetchAmountRemainingForOrderPayload,
  {
    state: AppState;
  }
>('order/amount-remaining', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const api: OrdersApi = createStandardOrdersApi(state);
    const { orderId } = payload;

    const apiResponse: AxiosResponse<OrderAmountRemainingDTO> = await runThunkWithAuth(
      () => api.getAmountRemaining(orderId, true),
      dispatch
    );
    const { amountRemaining } = apiResponse.data;
    dispatch(fetchAmountRemainingForPaymentPlanOrderSuccess(amountRemaining));
    return amountRemaining;
  } catch (e) {
    throw e;
  }
});
