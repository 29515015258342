import { UsersApi, UserDTO } from '@reposit/api-client';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createUsersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  UserActionTypes,
  UPDATE_USER_API_REQUESTED,
  updateUserFailed,
  updateUserSuccess,
  UPDATE_USER_STORE_KEY,
} from './user.actions';
import { FlashState } from '../../components/FlashMessage/index';
import { setFlashMessage, FlashMessagesActionTypes } from '../flash-messages/flash-messages.actions';
import { getErrorMessage } from '../../utils/common.utils';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { getCurrentOrderCustomer } from '../order/order.selectors';

// ****************
// WORKERS
// ****************
export function* updateUser({ payload }: { type: string; payload: any }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const apiResponse: AxiosResponse<UserDTO> = yield call(
      runSagaWithAuth(() => usersApi.updateUser(payload.currentUserId, payload.data))
    );

    const id = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.user);
    yield put<UserActionTypes>(updateUserSuccess(id));

    const orderCustomer = yield select(getCurrentOrderCustomer);

    yield put(push(`/checkout/${orderCustomer.order.id}/${orderCustomer.customer.id}/address-history`));
  } catch (e) {
    const error = getErrorMessage(e);

    yield put<UserActionTypes>(updateUserFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_USER_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchUserSagas() {
  yield takeLatest(UPDATE_USER_API_REQUESTED, updateUser);
}
