import {
  ClaimWithCheckoutRelationsDTO,
  OrderCustomerDTO,
  PaymentPlanDTO,
  PaymentPlanInstalmentDTO,
  PaymentPlanInstalmentDTOStatusEnum,
} from '@reposit/api-client/dist';
import { get } from 'lodash';
import { denormalize } from 'normalizr';
import { AddressEntity, PaymentPlanEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';

export const getPaymentPlanById = (state: AppState, paymentPlanId: string): PaymentPlanEntity =>
  denormalize(state.entities.paymentPlan[paymentPlanId], SCHEMA.paymentPlan, state.entities);

export const getPaymentPlanByClaimId =
  (claimId: string) =>
  (state: AppState): PaymentPlanDTO | undefined => {
    const claim = getFullClaimById(state, claimId);
    if (!claim) {
      return undefined;
    }
    const arbOrderCustomers: OrderCustomerDTO[] = get(claim, 'arbitration.repaymentOrder.orderCustomers', []);
    if (arbOrderCustomers.length) {
      const ocWithPaymentPlan = arbOrderCustomers.find((oc) => oc.paymentPlan);
      return ocWithPaymentPlan && ocWithPaymentPlan.paymentPlan;
    } else {
      // latest proposal
      const firstAgentProposal = get(claim, 'proposals', []).find((p) => p.round === 1);
      const firstOrderCustomerWithPaymentPlan: OrderCustomerDTO = get(firstAgentProposal, 'order.orderCustomers', []).find(
        (oc: OrderCustomerDTO) => oc.paymentPlan
      );
      const secondAgentProposal = get(claim, 'proposals', []).find((p) => p.round === 3);
      const secondOrderCustomerWithPaymentPlan: OrderCustomerDTO = get(secondAgentProposal, 'order.orderCustomers', []).find(
        (oc: OrderCustomerDTO) => oc.paymentPlan
      );
      const firstProposalPP = get(firstOrderCustomerWithPaymentPlan, 'paymentPlan');
      const secondProposalPP = get(secondOrderCustomerWithPaymentPlan, 'paymentPlan');

      return secondProposalPP || firstProposalPP;
    }
  };

export const getFullClaimById = (state: AppState, claimId: string): ClaimWithCheckoutRelationsDTO => {
  const claim = denormalize(state.entities.claim[claimId], SCHEMA.claim, state.entities);
  return claim;
};

export const getIsPaymentDue = (state: AppState, paymentPlanId: string): boolean => {
  const paymentPlan = getPaymentPlanById(state, paymentPlanId);
  if (!paymentPlan) return false;
  return !!(
    paymentPlan.instalments && paymentPlan.instalments.some((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.FAILED)
  );
};

export const getFailedPaymentPlanInstalment = (state: AppState, paymentPlanId: string): PaymentPlanInstalmentDTO | undefined => {
  const paymentPlan = getPaymentPlanById(state, paymentPlanId);
  if (!paymentPlan) return undefined;
  return paymentPlan.instalments?.find((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.FAILED);
};

export const getPaymentPlanPropertyAddress = (state: AppState): AddressEntity | undefined => {
  return state.paymentPlan.propertyAddress;
};
