import { RepositOfferState } from './reposit-offer.types';
import { RepositOfferActionTypes, SET_REPOSIT_OFFER_RESPONSE_MODAL_OPEN } from './reposit-offer.actions';

const initialState: RepositOfferState = {
  isResponseModalOpen: false,
};

export default (state: RepositOfferState = initialState, action: RepositOfferActionTypes): RepositOfferState => {
  switch (action.type) {
    case SET_REPOSIT_OFFER_RESPONSE_MODAL_OPEN: {
      return {
        ...state,
        isResponseModalOpen: action.payload,
      };
    }
    default:
      return state;
  }
};
