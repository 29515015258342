import React, { Fragment } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/index';
import Loading from '../Loading/index';
import { Header3, P2 } from '../Typography/index';
import Button from '../Button/index';

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface ApproveClaimResponseUpdateModalProps {
  isLoading: boolean;
  onAccept: (payload: any) => void;
  onDismiss: () => void;
}

const ApproveClaimResponseUpdateModal: React.FC<ApproveClaimResponseUpdateModalProps> = ({ isLoading, onDismiss, onAccept }) => {
  return (
    <Modal onDismiss={onDismiss}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Header3 style={{ marginBottom: 16, lineHeight: 1.4 }}>
            You are about to reject this claim with no evidence provided
          </Header3>
          <P2>
            If you reject this claim, it will be sent for review by an external arbitrator. If they resolve the claim entirely in
            the landlord's favour, the cost of the arbitration (£60) will not be refunded.
          </P2>
          <P2>
            If the arbitrator resolves some or all of the claim in your favour, the cost of the arbitration (£60) will be refunded
            to you.
          </P2>
          <P2 style={{ marginBottom: 24 }}>Are you sure you wish to reject the claim?</P2>
          <ModalAction>
            <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
              No, return to form
            </Button>
            <Button onClick={onAccept}>Yes</Button>
          </ModalAction>
        </Fragment>
      )}
    </Modal>
  );
};

export default ApproveClaimResponseUpdateModal;
