import { createReducer } from '@reduxjs/toolkit';
import { PaymentPlanState } from './payment-plan.types';
import { fetchPaymentPlanPropertyAddressThunk } from './payment-plan.thunk';
import { clearPaymentPlanPropertyAddress } from './payment-plan.action';

const initialState: PaymentPlanState = {
  propertyAddress: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPaymentPlanPropertyAddressThunk.fulfilled, (state, action) => {
      state.propertyAddress = action.payload;
    })
    .addCase(clearPaymentPlanPropertyAddress.type, (state) => {
      state.propertyAddress = undefined;
    });
});
