import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import accountReducer from './account/account.reducer';
import addressHistoryReducer from './address-history/address-history.reducer';
import { LOGOUT } from './auth/auth.actions';
import authReducer from './auth/auth.reducer';
import cardCheckReducer from './card-check/card-check.reducer';
import claimReducer from './claim/claim.reducer';
import entitiesReducer from './entities/entities.reducer';
import errorReducer from './error/error.reducer';
import flashMessagesReducer from './flash-messages/flash-messages.reducer';
import loadingReducer from './loading/loading.reducer';
import orderCustomerActionsReducer from './order-customer-actions/order-customer-actions.reducer';
import orderReducer from './order/order.reducer';
import paymentPlanInstalmentReducer from './payment-plan-instalment/payment-plan-instalment.reducer';
import paymentPlanReducer from './payment-plan/payment-plan.reducer';
import referencingReducer from './referencing/referencing.reducer';
import repositOfferReducer from './reposit-offer/reposit-offer.reducer';
import tenancyUserReducer from './tenancy-user/tenancy-user.reducer';

const appReducer = (history: History<any>) =>
  combineReducers({
    auth: authReducer,
    addressHistory: addressHistoryReducer,
    account: accountReducer,
    claim: claimReducer,
    entities: entitiesReducer,
    flashMessages: flashMessagesReducer,
    loading: loadingReducer,
    error: errorReducer,
    router: connectRouter(history),
    referencing: referencingReducer,
    order: orderReducer,
    orderCustomerActions: orderCustomerActionsReducer,
    tenancyUser: tenancyUserReducer,
    paymentPlanInstalment: paymentPlanInstalmentReducer,
    paymentPlan: paymentPlanReducer,
    repositOffer: repositOfferReducer,
    cardCheck: cardCheckReducer,
  });

export const rootReducer = (history: History, state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

const wrappedWithHistoryReducer = (history: History) => (state: any, action: any) => rootReducer(history, state, action);

export type AppState = ReturnType<typeof rootReducer>;

export default wrappedWithHistoryReducer;
