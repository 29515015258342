import React from 'react';
import styled from 'styled-components';
import FemaleActiveIcon from '../../assets/svg/gender/female-active.svg';
import FemaleIcon from '../../assets/svg/gender/female.svg';
import MaleActiveIcon from '../../assets/svg/gender/male-active.svg';
import MaleIcon from '../../assets/svg/gender/male.svg';
import UnknownActiveIcon from '../../assets/svg/gender/not-say-active.svg';
import UnknownIcon from '../../assets/svg/gender/not-say.svg';
import RepositCard from '../RepositCard';
import GenderOption from './GenderOption';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';

interface GenderSelectProps {
  selected?: GenderFormOptions;
  onSelect: (option: string) => void;
  touched?: boolean;
  error: string | undefined;
}

export enum GenderFormOptions {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN'
}

export interface GenderDefinition {
  id: string;
  label: string;
  icon: string;
  iconActive: string;
}

const GenderOptions: GenderDefinition[] = [
  {
    id: 'MALE',
    label: 'Male',
    icon: MaleIcon,
    iconActive: MaleActiveIcon
  },
  {
    id: 'FEMALE',
    label: 'Female',
    icon: FemaleIcon,
    iconActive: FemaleActiveIcon
  },
  {
    id: 'UNKNOWN',
    label: 'Rather not say',
    icon: UnknownIcon,
    iconActive: UnknownActiveIcon
  }
];

const Wrapper = styled.div`
  padding: 0 20px 20px;
`;

const Inner = styled.div`
  align-items: flex-start;

  display: flex;
  margin-bottom: 36px;
`;

const GenderSelect: React.FC<GenderSelectProps> = ({ selected, onSelect, touched, error }) => {
  return (
    <RepositCard title="What’s your Gender?" tooltip="We need to ask this for your eligibility check." flush>
      <Wrapper>
        <FieldWithLabel touched={touched} error={error}>
          <Inner>
            {GenderOptions.map(genderOption => (
              <GenderOption
                key={genderOption.id}
                isSelected={selected === genderOption.id}
                onClick={() => onSelect(genderOption.id)}
                option={genderOption}
              />
            ))}
          </Inner>
        </FieldWithLabel>
      </Wrapper>
    </RepositCard>
  );
};

export default GenderSelect;
