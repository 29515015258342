import { ExternalAddressDTO } from '@reposit/api-client/dist';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import RepositCard from '../../components/RepositCard';
import { Header2, P1 } from '../../components/Typography';
import { getIsAddNewFormOpen } from '../../redux/address-history/address-history-selectors';
import { setIsAddNewFormOpen } from '../../redux/address-history/address-history.actions';
import PostcodeLookup from '../Checkout/Sections/AddressHistory/PostcodeLookup';
import ManualPaymentPlanAddressForm from './AddressForm';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage';
import FlashMessage from '../../components/FlashMessage';
import { PAYMENT_PLAN_ACTIONS_ADDRESS_STORE_KEY } from '../../redux/payment-plan-actions/payment-plan-actions.actions';

interface AddressProps {
  match: any;
}

const Address: React.FC<AddressProps> = ({ match }) => {
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([PAYMENT_PLAN_ACTIONS_ADDRESS_STORE_KEY]);
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Header2>Current Address</Header2>
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={10} push={{ lg: 0 }}>
          <P1>
            To finalise your payment plan, kindly provide your current property address. This is imperative for maintaining clear
            and consistent communication, and will ensure that all postal confirmations regarding your payment plan are directed
            to the appropriate location.
          </P1>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <RepositCard
            title="What’s your current address?"
            flush
            flashMessage={
              flashMessagePayload && (
                <FlashMessage
                  payload={flashMessagePayload}
                  timeRemaining={FLASH_MESSAGE_TIMOUT}
                  onDismiss={onDismissFlashMessage}
                />
              )
            }
          >
            <AddressFormContainer match={match} />
          </RepositCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Address;

const FormContainer = styled.div`
  padding: 0 0 36px;
`;

interface AddressFormContainer {
  match: any;
}
const AddressFormContainer: React.FC<AddressFormContainer> = ({ match }) => {
  const dispatch = useDispatch();
  const isManualFormShowing = useSelector(getIsAddNewFormOpen);
  const [selectedAddress, setSelectedAddress] = useState<ExternalAddressDTO | null>(null);

  return (
    <FormContainer>
      {isManualFormShowing ? (
        <ManualPaymentPlanAddressForm
          address={selectedAddress}
          onFormClose={() => dispatch(setIsAddNewFormOpen(false))}
          match={match}
        />
      ) : (
        <PostcodeLookup
          onAddressSelect={(address?: ExternalAddressDTO) => {
            address && setSelectedAddress(address);
            dispatch(setIsAddNewFormOpen(true));
          }}
        />
      )}
    </FormContainer>
  );
};
