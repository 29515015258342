import { get } from 'lodash';
import { AppState } from '../root.reducer';
import { getFullClaimById } from '../payment-plan/payment-plan.selectors';
import { ClaimRespondentDTO } from '@reposit/api-client/dist';

export const getTenancyById = (id: string) => (state: AppState) => get(state, `entities.tenancy.${id}`);

export const getTenantById = (id: string) => (state: AppState) => {
  return state.entities.tenant[id];
};

export const getCustomerById = (id: string) => (state: AppState) => {
  return state.entities.customer[id];
};

export const getClaimRespondentsByClaimId = (claimId: string) => (state: AppState) => {
  const claim = getFullClaimById(state, claimId);
  if (!claim) {
    return undefined;
  }
  const respondents: ClaimRespondentDTO[] = get(claim, 'respondents', []);
  return respondents;
};
