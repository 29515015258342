import React, { Fragment } from 'react';
import Modal from '../../components/Modal';
import { useAppDispatch } from '../..';
import {
  setRepositOfferResponseModalOpen,
  RESPOND_TO_REPOSIT_OFFER_STORE_KEY,
} from '../../redux/reposit-offer/reposit-offer.actions';
import { RepositOfferResponse } from './reposit-offer';
import { Header3, P1 } from '../../components/Typography';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import Button from '../../components/Button';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { useSelector } from 'react-redux';
interface RepositOfferResponseModalProps {
  response: RepositOfferResponse;
  onSubmit: (accepted: boolean) => void;
}

const ModalAction = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }

  @media screen and (max-width: ${getBreakpoint('sm')}) {
    button:nth-child(2) {
      margin: 10px 6px;
    }
  }
`;

const RepositConfirmation = () => (
  <Fragment>
    <P1 style={{ marginTop: 14 }}>Thank you for choosing Reposit!</P1>
    <P1>
      Please note that this Reposit fee is non-refundable and you will remain liable for any valid charges at the end of your
      tenancy.
    </P1>
    <P1>
      Please click below to notify your agent of this choice. This choice will be final for this tenancy, and your co-tenants will
      not be able to change this decision.
    </P1>
  </Fragment>
);

const CashConfirmation = () => (
  <Fragment>
    <P1 style={{ marginTop: 14 }}>You have chosen to pay for a cash deposit on your tenancy. </P1>
    <P1>If you wish to change your mind and save money upfront, please click cancel and Choose Reposit! </P1>
    <P1>
      To continue with a cash deposit, click confirm below to notify your agent of this choice. This choice will be final for this
      tenancy, and your co-tenants will not be able to change this decision.
    </P1>
  </Fragment>
);

export const RepositOfferDecisionModal: React.FC<RepositOfferResponseModalProps> = ({ response, onSubmit }) => {
  const dispatch = useAppDispatch();
  const accepted = response === RepositOfferResponse.ACCEPTED;
  const headerCopy = accepted ? 'Confirm Reposit' : 'Confirm a Cash Deposit';
  const handleSubmit = () => onSubmit(accepted);
  const isSubmittingSelector = createLoadingSelector([RESPOND_TO_REPOSIT_OFFER_STORE_KEY]);
  const isSubmitting = useSelector(isSubmittingSelector);
  return (
    <Modal onDismiss={() => dispatch(setRepositOfferResponseModalOpen(false))}>
      <Header3 style={{ marginBottom: 6 }}>{headerCopy}</Header3>
      {response === RepositOfferResponse.ACCEPTED ? <RepositConfirmation /> : <CashConfirmation />}
      <ModalAction>
        <Button buttonType="secondary" noArrow={true} onClick={() => dispatch(setRepositOfferResponseModalOpen(false))}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting}>
          Confirm
        </Button>
      </ModalAction>
    </Modal>
  );
};
