import { SET_CARD_CHECK_ERROR_MODAL, SET_CARD_CHECK_ERROR_DATA, CardCheckActionTypes } from './card-check.actions';
import { CardCheckErrorData } from './card-check.types';

interface CardCheckState {
  isCardCheckErrorModalOpen: boolean;
  cardCheckErrorData: CardCheckErrorData | null;
}

const initialState: CardCheckState = {
  isCardCheckErrorModalOpen: false,
  cardCheckErrorData: null,
};

const cardCheckReducer = (state = initialState, action: CardCheckActionTypes): CardCheckState => {
  switch (action.type) {
    case SET_CARD_CHECK_ERROR_MODAL:
      return { ...state, isCardCheckErrorModalOpen: action.payload };
    case SET_CARD_CHECK_ERROR_DATA:
      return { ...state, cardCheckErrorData: action.payload };
    default:
      return state;
  }
};

export default cardCheckReducer;
