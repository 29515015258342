import { sortBy, get } from 'lodash';
import {
  ClaimItemEntity,
  ClaimItemProposalEntity,
  ClaimProposalEntity,
  DocumentEntity,
  OrderEntity,
  ClaimResponseDocumentEntity,
  ClaimItemDocumentEntity,
} from '../entities/entities.types';
import { AppState } from '../root.reducer';
import { getClaimById } from './claim.selectors';
import { getClaimItemById } from './claim-item.selectors';
import {
  ClaimProposalDTOStatusEnum,
  ClaimItemDTOTypeEnum,
  OrderWithOrderCustomersDTO,
  ClaimWithCheckoutRelationsDTO,
  OrderWithOrderCustomersDTOStatusIdEnum,
} from '@reposit/api-client';
import { ClaimItem, ClaimProposal, ItemProposal } from '../../components/Library/SupplierOffer/ClaimItem/ClaimItem';
import { useMemo } from 'react';
import { denormalize } from 'normalizr';
import SCHEMA from '../schema';

export const LOW_PROPOSAL_PERCENTAGE_THRESHOLD = 0.25;

export const getProposalsByClaimId = (state: AppState, claimId: string): ClaimProposalEntity[] => {
  const claim = getClaimById(state, claimId);
  if (!claim || !claim.proposals) return [];
  return claim.proposals.map((p) => state.entities.claimProposal[p]);
};

const getProposalById = (state: AppState, proposalId: string): ClaimProposalEntity | undefined => {
  return state.entities.claimProposal[proposalId];
};

export interface ClaimItemProposal {
  proposal: ClaimItemProposalEntity;
  claimItem: ClaimItemEntity;
  documents: DocumentEntity[];
}

export interface AgentDocumentsAndProposals {
  firstDocuments: DocumentEntity[];
  firstProposal?: ClaimProposalEntity;
  secondDocuments: DocumentEntity[];
  secondProposal?: ClaimProposalEntity;
}

export interface TenantDocumentsAndProposal {
  documents: DocumentEntity[];
  proposal: ClaimProposalEntity;
}

export const getProposalItemsByProposalId = (state: AppState, proposalId: string): ClaimItemProposal[] => {
  const proposal = getProposalById(state, proposalId);
  if (!proposal || !proposal.itemProposals) return [];

  return proposal.itemProposals.map((ip) => {
    const proposal = state.entities.claimItemProposal[ip];
    const claimItem = state.entities.claimItem[proposal.claimItemId];
    const documents = getDocumentsByItemProposalId(state, ip);
    return {
      proposal,
      claimItem,
      documents,
    };
  });
};

export const getDocumentsByItemProposalId = (state: AppState, id: string): DocumentEntity[] => {
  const itemProposal = state.entities.claimItemProposal[id];
  if (!itemProposal) return [];

  const claimItemDocs = itemProposal.documents.map((d: string) => state.entities.claimItemDocument[d]);

  return claimItemDocs.map((d) => state.entities.document[d.document]);
};

export const getFirstAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 1);
};

export const getFirstTenantProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 2);
};

export const getSecondAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 3);
};

export const getFirstAgentProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 1);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);
  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getSecondAgentProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 3);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);
  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getFirstTenantProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 2);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);
  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getProposalTotalAmountsAreEqual = (state: AppState, claimId: string): boolean => {
  const firstAgentProposalTotalAmount = getFirstAgentProposalTotalAmount(state, claimId);
  const firstTenantProposalTotalAmount = getFirstTenantProposalTotalAmount(state, claimId);
  if (!firstAgentProposalTotalAmount || !firstTenantProposalTotalAmount) return false;
  return firstAgentProposalTotalAmount === firstTenantProposalTotalAmount;
};

export const getTenantProposalTotalIsZero = (state: AppState, claimId: string): boolean => {
  const firstTenantProposalTotalAmount = getFirstTenantProposalTotalAmount(state, claimId);
  return firstTenantProposalTotalAmount === 0;
};

export const getTenantProposalTotalIsLow = (state: AppState, claimId: string): boolean => {
  const firstTenantProposalTotalAmount = getFirstTenantProposalTotalAmount(state, claimId);
  const firstAgentProposalTotalAmount = getFirstAgentProposalTotalAmount(state, claimId);
  if (!firstTenantProposalTotalAmount || !firstAgentProposalTotalAmount) return false;
  return firstTenantProposalTotalAmount < firstAgentProposalTotalAmount * LOW_PROPOSAL_PERCENTAGE_THRESHOLD;
};

const getLatestProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return sortBy(proposals, (p) => p.round).pop();
};

export const getLatestProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const latestProposal = getLatestProposal(state, claimId);
  if (!latestProposal) return undefined;
  const currentItems = getProposalItemsByProposalId(state, latestProposal.id);
  const previousProposal = proposals.find((p) => p.round === latestProposal.round - 1);
  if (!previousProposal) return currentItems.reduce((acc, item) => acc + item.proposal.amount, 0);
  const previousItems = getProposalItemsByProposalId(state, previousProposal.id);

  return previousItems.reduce((acc, currentItem) => {
    const found = currentItems.find((i) => i.claimItem.id === currentItem.claimItem.id);
    const value = found ? found.proposal.amount : currentItem.proposal.amount;
    return acc + value;
  }, 0);
};

export const getAllAgentDocumentsAndProposalsForClaimItem = (
  state: AppState,
  claimItemId: string
): AgentDocumentsAndProposals | undefined => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;

  const firstProposal = getFirstAgentProposal(state, claimItem.claimId);
  const secondProposal = getSecondAgentProposal(state, claimItem.claimId);

  if (!firstProposal && !secondProposal) return undefined;

  const claim = getClaimById(state, claimItem.claimId);
  const claimDocs = claim ? claim.documents : [];
  const checkoutReportClaimDocs = claimDocs.filter((cd) => cd.document.typeId === 'INVENTORY_CHECK_OUT');

  const addCheckoutReportToDocs = (docs: DocumentEntity[]): DocumentEntity[] => {
    const checkoutDocs = checkoutReportClaimDocs.length ? checkoutReportClaimDocs.map((d) => d.document) : [];
    if (checkoutDocs && claimItem.type !== ClaimItemDTOTypeEnum.RENTARREARS) return [...docs, ...checkoutDocs];
    return docs;
  };

  const firstProposalItems = firstProposal ? getProposalItemsByProposalId(state, firstProposal.id) : [];
  const secondProposalItems = secondProposal ? getProposalItemsByProposalId(state, secondProposal.id) : [];

  const firstClaimItemProposal = firstProposalItems.find((i) => i.claimItem.id === claimItemId);
  const firstDocs = firstClaimItemProposal ? getDocumentsByItemProposalId(state, firstClaimItemProposal.proposal.id) : [];
  const secondClaimItemProposal = secondProposalItems.find((i) => i.claimItem.id === claimItemId);
  const secondDocs = secondClaimItemProposal ? getDocumentsByItemProposalId(state, secondClaimItemProposal.proposal.id) : [];

  return {
    firstDocuments: addCheckoutReportToDocs(firstDocs),
    firstProposal,
    // only want to add the checkout once
    secondDocuments: secondDocs,
    secondProposal,
  };
};

export const getAllTenantDocumentsAndProposalsForClaimItem = (
  state: AppState,
  claimItemId: string
): TenantDocumentsAndProposal | undefined => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;

  const tenantProposal = getFirstTenantProposal(state, claimItem.claimId);
  if (!tenantProposal) return undefined;

  const tenantProposalItems = getProposalItemsByProposalId(state, tenantProposal.id);
  const claimItemProposal = tenantProposalItems.find((i) => i.claimItem.id === claimItemId);

  const docs = claimItemProposal ? getDocumentsByItemProposalId(state, claimItemProposal.proposal.id) : [];
  return { documents: docs, proposal: tenantProposal };
};

export const getLatestProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const latestProposal = getLatestProposal(state, claimId);
  if (!latestProposal) return [];
  return getProposalItemsByProposalId(state, latestProposal.id);
};

export const getHasTenantDisputed = (state: AppState, claimId: string): boolean => {
  const firstProposal = getFirstAgentProposal(state, claimId);
  const secondProposal = getSecondAgentProposal(state, claimId);
  const isFirstDisputed = !!(firstProposal && firstProposal.status === ClaimProposalDTOStatusEnum.DISPUTED);
  const isSecondDisputed = !!(secondProposal && secondProposal.status === ClaimProposalDTOStatusEnum.DISPUTED);
  return isFirstDisputed || isSecondDisputed;
};

export const getFirstTenantProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getFirstTenantProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};

export const getFirstAgentProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getFirstAgentProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};

export const getSecondAgentProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getSecondAgentProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};
export const getIsTenantNegotiating = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.DRAFT);
};

export const getHasTenantAcceptedCharges = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstAgentProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT);
};

export const getHasTenantSubmitted = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE);
};

export const getHaveChargesBeenRaisedAgainstTheTenant = (state: AppState, claimId: string): boolean => {
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  return !!(
    (firstAgentProposal && firstAgentProposal.status !== ClaimProposalDTOStatusEnum.COUNTERED) ||
    (secondAgentProposal && secondAgentProposal.status !== ClaimProposalDTOStatusEnum.COUNTERED)
  );
};

export const getHasTenantAcceptedAnyCharges = (state: AppState, claimId: string): boolean => {
  const agentFirstProposal = getFirstAgentProposal(state, claimId);
  const agentSecondProposal = getSecondAgentProposal(state, claimId);
  const hasTenantAcceptedFirst = !!(
    agentFirstProposal && agentFirstProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT
  );
  const hasTenantAcceptedSecond = !!(
    agentSecondProposal && agentSecondProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT
  );
  return hasTenantAcceptedFirst || hasTenantAcceptedSecond;
};

export const getHasTenantAutoAcceptedAnyCharges = (state: AppState, claimId: string): boolean => {
  const agentFirstProposal = getFirstAgentProposal(state, claimId);

  const agentSecondProposal = getSecondAgentProposal(state, claimId);
  const hasTenantAutoAcceptedFirst = !!(
    agentFirstProposal &&
    agentFirstProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT &&
    !!agentFirstProposal.autoResponded
  );

  const hasTenantAutoAcceptedSecond = !!(
    agentSecondProposal &&
    agentSecondProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT &&
    !!agentSecondProposal.autoResponded
  );
  return hasTenantAutoAcceptedFirst || hasTenantAutoAcceptedSecond;
};

export const getIsTenantAuthorisingPayment = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT);
};

export const getLatestAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  return secondAgentProposal || firstAgentProposal;
};

export const getOrderById = (state: AppState, orderId: string): OrderEntity | undefined => {
  return state.entities.order[orderId];
};

export const getArbitrationAdminFeeOrder = (state: AppState, claimId: string): OrderEntity | undefined => {
  const claim = getClaimById(state, claimId);
  if (!claim) return undefined;
  const orderId = get(claim, 'adjudicationFeeOrder', '');
  return getOrderById(state, orderId);
};

export const getIsAgentDeciding = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE);
};

export const getIsAgentNegotiating = (state: AppState, claimId: string): boolean => {
  const proposal = getSecondAgentProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.DRAFT);
};

export const getMediationClaimItemsByClaimId = (state: AppState, claimId: string): ClaimItem[] => {
  const claim = getClaimById(state, claimId);
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const firstTenantProposal = getFirstTenantProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);

  const firstTenantProposalItems = firstTenantProposal ? getProposalItemsByProposalId(state, firstTenantProposal.id) : [];
  const secondAgentProposalItems = secondAgentProposal ? getProposalItemsByProposalId(state, secondAgentProposal.id) : [];

  const proposalsInRoundOrder = [firstAgentProposal, firstTenantProposal, secondAgentProposal].filter(
    (i) => i
  ) as ClaimProposal[];

  if (!claim || !claim.items) return [];

  const isTenantDeciding = getIsTenantDeciding(state, claimId);
  const isAgentDeciding = getIsAgentDeciding(state, claimId);
  const isAgentNegotiating = getIsAgentNegotiating(state, claimId);

  return claim.items.map((cid) => {
    const entity = state.entities.claimItem[cid];
    // if tenant has agreed - it is settled
    const foundTenantItem = firstTenantProposalItems.find((i) => i.claimItem.id === cid);
    const hasTenantSettled = !!(firstTenantProposal && !isTenantDeciding && foundTenantItem && foundTenantItem.proposal.settled);
    const foundAgentItem = secondAgentProposalItems.find((i) => i.claimItem.id === cid);
    const hasAgentSettled = !!(
      secondAgentProposal &&
      foundAgentItem &&
      !isAgentDeciding &&
      !isAgentNegotiating &&
      foundAgentItem.proposal.settled
    );

    const isSettled = hasTenantSettled || hasAgentSettled;
    const itemProposals: ItemProposal[] = proposalsInRoundOrder
      .map((proposal) => {
        const items = getProposalItemsByProposalId(state, proposal.id);
        const itemProposal = items.find((i) => i.claimItem.id === cid);
        return itemProposal
          ? ({ ...itemProposal.proposal, claimProposal: proposal, documents: itemProposal.documents } as ItemProposal)
          : undefined;
      })
      .filter((i) => i) as ItemProposal[];
    return {
      ...entity,
      isSettled,
      itemProposals,
    };
  });
};

export const getHasTenantRespondedToAllItems = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  if (!proposal) return false;
  const claimItems = getMediationClaimItemsByClaimId(state, claimId);
  const items = getProposalItemsByProposalId(state, proposal.id);
  if (!claimItems || !items) return false;
  return claimItems.length === items.length;
};

export const getLatestProposalitems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposals = getProposalsByClaimId(state, claimId);
  const latestProposal = getLatestProposal(state, claimId);
  if (!latestProposal) return [];
  const currentItems = getProposalItemsByProposalId(state, latestProposal.id);
  const previousProposal = proposals.find((p) => p.round === latestProposal.round - 1);
  if (!previousProposal) return currentItems;
  const previousItems = getProposalItemsByProposalId(state, previousProposal.id);

  const items = previousItems.map((i) => {
    const found = currentItems.find((ci) => ci.claimItem.id === i.claimItem.id);
    return found || i;
  });
  return items;
};

export const getClaimResponseDocumentByDocumentId = (
  state: AppState,
  documentId: string
): ClaimResponseDocumentEntity | undefined => {
  const claimReponseDocuments = Object.values(state.entities.claimResponseDocument);
  return claimReponseDocuments.find((crd) => crd.document === documentId);
};

export const getClaimItemDocumentByDocumentId = (state: AppState, documentId: string): ClaimItemDocumentEntity | undefined => {
  const claimItemDocuments = Object.values(state.entities.claimItemDocument);
  return claimItemDocuments.find((cid) => cid.document === documentId);
};

export const getIsTenantDeciding = (state: AppState, claimId: string): boolean => {
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  const firstTenantProposal = getFirstTenantProposal(state, claimId);
  const firstDeciding =
    !!(firstAgentProposal && firstAgentProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE) ||
    !!(
      firstTenantProposal &&
      (firstTenantProposal.status === ClaimProposalDTOStatusEnum.DRAFT ||
        firstTenantProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT)
    );
  const secondDeciding = !!(secondAgentProposal && secondAgentProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE);
  return firstDeciding || secondDeciding;
};

const getFullClaimById = (state: AppState, claimId: string): ClaimWithCheckoutRelationsDTO => {
  const claim = denormalize(state.entities.claim[claimId], SCHEMA.claim, state.entities);
  return claim;
};

export const getClaimOrder =
  (claimId: string) =>
  (state: AppState): OrderWithOrderCustomersDTO | undefined => {
    // if arb exists choose that
    // otherwise its the latest pending claim proposal claim proposal
    let order;

    const claim = useMemo(() => getFullClaimById(state, claimId), [state]);

    if (!claim) {
      return undefined;
    }
    if (claim.arbitration && claim.arbitration.repaymentOrder) {
      return claim.arbitration.repaymentOrder;
    }

    const firstAgentProposal = getFirstAgentProposal(state, claimId);
    const firstOrderIsPending =
      firstAgentProposal && firstAgentProposal.order
        ? firstAgentProposal.order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.PENDING
        : false;
    const secondAgentProposal = getSecondAgentProposal(state, claimId);
    const secondOrderIsPending =
      secondAgentProposal && secondAgentProposal.order
        ? secondAgentProposal.order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.PENDING
        : false;

    if (!firstOrderIsPending && !secondOrderIsPending) return undefined;

    if (secondAgentProposal && secondOrderIsPending) {
      order = secondAgentProposal.order;
    }
    if (firstAgentProposal && firstOrderIsPending) {
      order = firstAgentProposal.order;
    }

    return order;
  };

export const getLatestAgentProposalResponseDeadline = (state: AppState, claimId: string): string | undefined => {
  const latestAgentProposal = getLatestAgentProposal(state, claimId);
  if (!latestAgentProposal) return undefined;
  return latestAgentProposal.responseDeadline;
};
