import { OrderCustomerActionDTO, OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { Location } from 'history';
import { find } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import FlashMessage from '../../components/FlashMessage/index';
import { FullPageLoader } from '../../components/Loading/index';
import ProductSummary from '../../components/ProductSummary';
import RepositProgress from '../../components/RepositProgress';
import { P1 } from '../../components/Typography';
import { ADDRESS_HISTORY_STORE_KEY, REMOVE_ADDRESS_STORE_KEY } from '../../redux/address-history/address-history.actions';
import { dismissAllFlashMessages } from '../../redux/flash-messages/flash-messages.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  CONFIRM_REPOSIT_STORE_KEY,
  PAY_STORE_KEY,
  SIGN_REPOSIT_STORE_KEY,
  UPDATE_ABOUT_YOU_STORE_KEY,
} from '../../redux/order-customer-actions/order-customer-actions.actions';
import { fetchOrderCustomerRequested, FETCH_ORDER_CUSTOMER_STORE_KEY } from '../../redux/order/order.actions';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import {
  REMOVE_REFERENCE_DOC_STORE_KEY,
  SET_REFERENCE_REVIEW_STORE_KEY,
  UPLOAD_REFERENCE_DOCS_STORE_KEY,
} from '../../redux/referencing/referencing.actions';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import {
  fetchTenancyUserFromOrderCustomerRequested,
  FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_STORE_KEY,
} from '../../redux/tenancy-user/tenancy-user.actions';
import { getCurrentTenancyUser } from '../../redux/tenancy-user/tenancy-user.selector';
import { UPDATE_USER_STORE_KEY } from '../../redux/user/user.actions';
import { penceToPounds } from '../../utils/currency';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage/index';
import OnboardingIntroduction from '../OnboardingIntroduction';
import AboutYou from './Sections/AboutYou';
import Addendum from './Sections/Addendum';
import AddressHistory from './Sections/AddressHistory';
import Confirm from './Sections/Confirm';
import Payment from './Sections/Payment';
import Referencing from './Sections/Referencing';
import Sign from './Sections/Sign/index';

interface CheckoutProps {
  location: Location<any>;
  match: any;
}

const ProductDescription = styled(P1)`
  margin: 0;
  padding: 0;

  span {
    font-weight: 700;
  }
`;

const FlashWrapper = styled.div`
  margin: 24px 0 0;
`;

const RouterContainer = styled.div`
  padding: 70px 0;
`;

interface RouteDefinition {
  path: string;
  exact: boolean;
  component: (props: any) => JSX.Element;
  action?: OrderCustomerActionDTOTypeIdEnum | null;
}

const routes: RouteDefinition[] = [
  {
    path: 'about-you',
    component: (props) => <AboutYou {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITABOUTYOU,
  },
  {
    path: 'address-history',
    component: (props) => <AddressHistory {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITADDRESSHISTORY,
  },
  {
    path: 'referencing',
    component: (props) => <Referencing {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITREFERENCING,
  },
  {
    path: 'confirm',
    component: (props) => <Confirm {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITCONFIRM,
  },
  {
    path: 'addendum',
    component: (props) => <Addendum {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITSIGNADDENDUM,
  },
  {
    path: 'pay',
    component: (props) => <Payment {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITPAY,
  },
  {
    path: 'sign',
    component: (props) => <Sign {...props} />,
    exact: true,
    action: OrderCustomerActionDTOTypeIdEnum.REPOSITSIGN,
  },
  {
    path: '*',
    component: (props) => <Redirect key={'not-found'} to={'about-you'} />,
    exact: false,
  },
];

const getNextAction = (actions: OrderCustomerActionDTO[], nextActionId: string) => {
  return find(actions, (action) => action.id === nextActionId);
};

const Checkout: React.FC<CheckoutProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location, match } = props;
  const { customerId, orderId } = match.params;
  const [flashMessage, dismissFlashMessage] = useFlashMessage([
    UPDATE_USER_STORE_KEY,
    CONFIRM_REPOSIT_STORE_KEY,
    SIGN_REPOSIT_STORE_KEY,
    PAY_STORE_KEY,
    UPDATE_ABOUT_YOU_STORE_KEY,
    ADDRESS_HISTORY_STORE_KEY,
    SET_REFERENCE_REVIEW_STORE_KEY,
    REMOVE_ADDRESS_STORE_KEY,
    REMOVE_REFERENCE_DOC_STORE_KEY,
    UPLOAD_REFERENCE_DOCS_STORE_KEY,
  ]);
  const orderCustomerLoadingSelector = createLoadingSelector([
    FETCH_ORDER_CUSTOMER_STORE_KEY,
    FETCH_TENANCY_USER_FROM_ORDER_CUSTOMER_STORE_KEY,
  ]);
  const isOrderCustomerLoading = useSelector(orderCustomerLoadingSelector);
  const orderCustomer = useSelector(getCurrentOrderCustomer);
  const tenancyUser = useSelector(getCurrentTenancyUser);
  const currentUser = useSelector(getCurrentUser);
  const [showOnboardingIntroduction, setShowOnboardingIntroduction] = useState(
    !currentUser.attributes?.onboardingIntroductionComplete
  );
  const repositInfoTooltip =
    "Reposit is a deposit alternative for tenants, where you can pay an upfront fee of one week's rent (subject to a minimum of £150) instead of a full cash deposit. This fee is non-refundable and you remain liable for damages or any end-of-tenancy charges, just like with a traditional cash deposit.";

  useEffect(() => {
    ReactTooltip.rebuild();
    dispatch(dismissAllFlashMessages());
  }, [location.pathname, dispatch]);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'POP') {
        history.push('/');
      }
    });
  }, [history]);

  useEffect(() => {
    if (customerId && orderId) {
      dispatch(fetchOrderCustomerRequested({ customerId, orderId }));
    }
    dispatch(
      fetchTenancyUserFromOrderCustomerRequested({
        orderId,
        customerId,
      })
    );
  }, [dispatch, customerId, orderId]);

  if (!orderCustomer || isOrderCustomerLoading) {
    return <FullPageLoader />;
  }

  // get the next action
  const nextAction = getNextAction(
    orderCustomer.actions || [],
    orderCustomer.nextAction ? orderCustomer.nextAction.orderCustomerActionIds[0] : ''
  );

  if (!nextAction) return <Redirect key={'dashboard'} to={'/'} />;

  // get the current route
  const routesCopy = [...routes];
  const catchAllRoute = routesCopy.pop();
  const currentRoute = routesCopy.find((route) => history.location.pathname.includes(`/${route.path}`)) || catchAllRoute;

  if (!currentRoute) return <Redirect key={'not-found'} to={'about-you'} />; // This should never happen

  // if the user is not on the route corresponding to the next action, redirect them there.
  if (currentRoute.action !== nextAction.typeId) {
    const nextRoute = find(routes, (route) => route.action === nextAction.typeId);
    if (nextRoute) return <Redirect key={nextRoute.path} to={`${match.url}/${nextRoute.path}`} />;
    return <Redirect key={'not-found'} to={'about-you'} />;
  }

  if (showOnboardingIntroduction) {
    return <OnboardingIntroduction setShowOnboardingIntroduction={setShowOnboardingIntroduction} />;
  }

  return (
    <Fragment>
      <Container>
        <Row>
          <Col sm={12}>
            <ProductSummary
              name="Your Reposit"
              tenancyUser={tenancyUser}
              description={
                <ProductDescription>
                  Reposits are equal to 1 week’s rent (subject to a min fee of £150). This means your total fee is{' '}
                  <span>£{penceToPounds(orderCustomer.fee)}</span>
                </ProductDescription>
              }
              tooltip={repositInfoTooltip}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <RepositProgress currentUrl={location.pathname} orderActions={orderCustomer.actions || []} />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={12}>
            <FlashWrapper>
              {flashMessage ? (
                <FlashMessage onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} payload={flashMessage} />
              ) : undefined}
            </FlashWrapper>
          </Col>
        </Row>
      </Container>
      <RouterContainer>
        <Switch location={location}>
          {routes.map((route: RouteDefinition) => (
            <Route key={route.path} path={`${match.path}/${route.path}`} exact component={route.component} />
          ))}
        </Switch>
      </RouterContainer>
    </Fragment>
  );
};

export default Checkout;
