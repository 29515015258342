import { AddressDTO } from '@reposit/api-client';
import { get } from 'lodash';
import countries from 'i18n-iso-countries';

type excludedKeys = 'id' | 'organizationId' | 'createdAt' | 'updatedAt';

interface CoreAddress extends Omit<AddressDTO, excludedKeys> {}

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const getAddressFirstLine = (address: CoreAddress): string => {
  const { roomNumber, flatNumber, buildingNumber, buildingName, street, country } = address;
  const streetConcat = buildingNumber ? `${buildingNumber} ${street}` : street;
  const firstLineItems = {
    room: roomNumber ? `${roomNumber}` : null,
    flat: flatNumber ? `${flatNumber}` : null,
    buildingName,
    street: streetConcat,
    country: countries.getName(country, 'en'),
  };

  const result = Object.values(firstLineItems);
  return result.filter(Boolean).join(', ');
};

export const getAddressTownPostcode = (address: CoreAddress): string => {
  const result = [address.town, address.postcode];
  return result.filter(Boolean).join(', ');
};

export const getDisplayAddress = (address: CoreAddress) => {
  return `${getAddressFirstLine(address)}, ${getAddressTownPostcode(address)}`;
};

export const isANotFoundError = (error: string) => {
  return error.includes('not found');
};

export const getErrorMessage = (error: any) => {
  const err = get(error, 'response.data.message', error);
  return Array.isArray(err) ? 'Something went wrong.' : err;
};

export const penceToPounds = (value: number, decimalPlaces: number = 2): string => {
  return (value / 100).toFixed(decimalPlaces);
};

export const poundsToPence = (value: number): number => Math.round(value * 100);
