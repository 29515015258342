import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../components/Card/index';
import PageTitle from '../../components/PageTitle/index';
import { P1 } from '../../components/Typography/index';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';

const Action = styled.div`
  padding: 16px 0 0;
  text-align: right;

  a {
    text-decoration: none;
  }
`;

const ClaimPaymentSuccess: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Payment Success</PageTitle>
          <Card>
            <P1 bold>Thank you! Your payment has been received.</P1>
            <P1>
              If there are any outstanding charges which a co-tenant has not settled yet, you will remain jointly and severally
              liable until this is paid off in full.
            </P1>
            <P1>Please give your co-tenant(s) a reminder to pay any outstanding charges, if applicable.</P1>
            <P1>You will receive a confirmation email once all charges have been settled.</P1>
          </Card>
          <Action>
            <NavLink to={`/`}>
              <Button>Take me to my Reposit</Button>
            </NavLink>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default ClaimPaymentSuccess;
