/* eslint-disable */
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../..';
import FlashMessage from '../../components/FlashMessage';
import { FullPageLoader } from '../../components/Loading';
import { Header1, Header4, P1 } from '../../components/Typography';
import { INSTALMENT_PAY_STORE_KEY } from '../../redux/payment-plan-instalment/payment-plan-instalment.actions';
import { getPaymentPlanById, getPaymentPlanPropertyAddress } from '../../redux/payment-plan/payment-plan.selectors';
import { fetchPaymentPlanThunk, redirectToPaymentPlanPortal } from '../../redux/payment-plan/payment-plan.thunk';
import { AppState } from '../../redux/root.reducer';
import { getAddressFirstLine } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMOUT, useFlashMessage } from '../FlashMessage';
import PaymentPlanDashboard from './components/Dashboard';

interface ActivePlanProps {
  match: any;
}

const ActivePlan: React.FC<ActivePlanProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const { paymentPlanId } = match.params;

  useEffect(() => {
    const fetchPaymentPlan = async () => {
      return dispatch(fetchPaymentPlanThunk({ id: paymentPlanId })).then(unwrapResult);
    };
    const redirect = async () => {
      return dispatch(redirectToPaymentPlanPortal({ paymentPlanId }));
    };
    fetchPaymentPlan();
    redirect();
  }, [dispatch, paymentPlanId]);

  const paymentPlan = useSelector((state: AppState) => getPaymentPlanById(state, paymentPlanId));
  const propertyAddress = useSelector((state: AppState) => getPaymentPlanPropertyAddress(state));
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([INSTALMENT_PAY_STORE_KEY]);
  return <FullPageLoader />;
  // leaving this code here
  // if (!paymentPlan || !propertyAddress) return <FullPageLoader />;
  // return (
  //   <Container>
  //     <Row>
  //       <Col>
  //         <Header1>Your Payment Plan</Header1>
  //       </Col>
  //     </Row>
  //     <Row>
  //       <Col>
  //         <Header4>{getAddressFirstLine(propertyAddress)}</Header4>
  //         <P1>{propertyAddress.postcode}</P1>
  //       </Col>
  //     </Row>
  //     {flashMessagePayload ? (
  //       <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMOUT} />
  //     ) : null}
  //     <Row>
  //       <Col>
  //         <PaymentPlanDashboard paymentPlan={paymentPlan} />
  //       </Col>
  //     </Row>
  //   </Container>
  // );
};

export default ActivePlan;
