import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Card from '../../../../../components/Card';
import PageTitle from '../../../../../components/PageTitle';
import { P1 } from '../../../../../components/Typography';

interface ReferenceInReviewProps {}

const ReferenceInReview: React.FC<ReferenceInReviewProps> = props => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>We’ll get back to you</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <P1>Thank you for providing additional information to support your reference.</P1>
            <P1>We'll be in touch once the Reposit team have reviewed the documents provided.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceInReview;
