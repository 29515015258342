import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import TickIcon from '../../assets/svg/tick-white.svg';
import { getBreakpoint } from '../../base/style';

interface ToggleButtonProps {
  name: string;
  label: string;
  onToggle: (e: ChangeEvent<any>) => void;
}

const ToggleWrapper = styled.label`
  div {
    align-items: center;
    border: none;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: ${(props) => props.theme.typography.face.primary};
    font-size: 0.75em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    outline: none;
    text-decoration: none;
    padding: 0.625em 1.5em 0.625em 1.75em;
    text-transform: uppercase;
    overflow: hidden;
    min-width: 182px;
    text-align: center;
    height: calc(20px - 0.625em);

    @media screen and (min-width: ${getBreakpoint('lg')}) {
      height: calc(26px - 0.625em);
    }

    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.tertiaryBrand},
      ${(props) => props.theme.colors.primaryBrand}
    );

    &:active {
      box-shadow: inset 0px 6px 17px -6px rgba(0, 0, 0, 0.49);
    }

    &:hover {
      background-image: linear-gradient(
        to bottom,
        ${(props) => props.theme.colors.primaryBrand},
        ${(props) => props.theme.colors.primaryBrand}
      );
    }
  }

  input {
    display: none;
    opacity: 0;
    height: 0;
  }

  input:checked + div {
    background: ${(props) => props.theme.colors.positive} url(${TickIcon}) no-repeat center center;

    &:hover {
      background-color: ${(props) => props.theme.colors.positive};
    }

    span {
      display: none;
    }
  }
`;

const ToggleButton: React.FC<ToggleButtonProps> = ({ name, label, onToggle }) => {
  return (
    <ToggleWrapper htmlFor={name}>
      <input id={name} name={name} type="checkbox" onChange={onToggle} />
      <div>
        <span>{label}</span>
      </div>
    </ToggleWrapper>
  );
};

export default ToggleButton;
