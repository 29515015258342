import { FetchOrderCustomerPayload } from './order.types';

export const FETCH_ORDER_CUSTOMER_STORE_KEY = 'ORDER/FETCH_ORDER_CUSTOMER';
export const FETCH_ORDER_CUSTOMER_API_REQUESTED = `ORDER/FETCH_ORDER_CUSTOMER_API_REQUESTED`;
export const FETCH_ORDER_CUSTOMER_API_SUCCESS = 'ORDER/FETCH_ORDER_CUSTOMER_API_SUCCESS';
export const FETCH_ORDER_CUSTOMER_API_FAILED = 'ORDER/FETCH_ORDER_CUSTOMER_API_FAILED';

export const FETCH_TENANCY_ORDERS_STORE_KEY = 'ORDER/FETCH_TENANCY_ORDERS';
export const FETCH_TENANCY_ORDERS_API_REQUESTED = `ORDER/FETCH_TENANCY_ORDERS_API_REQUESTED`;
export const FETCH_TENANCY_ORDERS_API_SUCCESS = 'ORDER/FETCH_TENANCY_ORDERS_API_SUCCESS';
export const FETCH_TENANCY_ORDERS_API_FAILED = 'ORDER/FETCH_TENANCY_ORDERS_API_FAILED';

export const FETCH_TENANCY_ANNIVERSARIES_STORE_KEY = 'ORDER/FETCH_TENANCY_ANNIVERSARIES';
export const FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED = `ORDER/FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED`;
export const FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS = 'ORDER/FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS';
export const FETCH_TENANCY_ANNIVERSARIES_API_FAILED = 'ORDER/FETCH_TENANCY_ANNIVERSARIES_API_FAILED';

export const FETCH_TENANCY_ORDER_BY_ID_STORE_KEY = 'ORDER/FETCH_TENANCY_ORDER_BY_ID';
export const FETCH_TENANCY_ORDER_BY_ID_API_REQUESTED = 'ORDER/FETCH_TENANCY_ORDER_BY_ID_API_REQUESTED';
export const FETCH_TENANCY_ORDER_BY_ID_API_SUCCESS = 'ORDER/FETCH_TENANCY_ORDER_BY_ID_API_SUCCESS';
export const FETCH_TENANCY_ORDER_BY_ID_API_FAILED = 'ORDER/FETCH_TENANCY_ORDER_BY_ID_API_FAILED';

export const CLEAR_ORDER_CUSTOMER_ID = 'ORDER/CLEAR_ORDER_CUSTOMER_ID';

export const CLEAR_AMOUNT_REMAINING = 'ORDER/CLEAR_AMOUNT_REMAINING';

export const FETCH_AMOUNT_REMAINING_FOR_PAYMENT_PLAN_ORDER_SUCCESS =
  'ORDER/FETCH_AMOUNT_REMAINING_FOR_PAYMENT_PLAN_ORDER_SUCCESS';

export function fetchOrderCustomerRequested(payload: FetchOrderCustomerPayload) {
  return {
    type: FETCH_ORDER_CUSTOMER_API_REQUESTED,
    payload,
  } as const;
}

export function fetchOrderCustomerSuccess(orderCustomerId: string) {
  return {
    type: FETCH_ORDER_CUSTOMER_API_SUCCESS,
    payload: {
      orderCustomerId,
    },
  } as const;
}

export function fetchOrderCustomerFailed(error: string) {
  return {
    type: FETCH_ORDER_CUSTOMER_API_FAILED,
    payload: error,
  } as const;
}

export function fetchTenancyOrdersRequested() {
  return {
    type: FETCH_TENANCY_ORDERS_API_REQUESTED,
  } as const;
}

export function fetchTenancyOrdersSuccess(tenancyOrders: string[]) {
  return {
    type: FETCH_TENANCY_ORDERS_API_SUCCESS,
    payload: {
      tenancyOrders,
    },
  } as const;
}

export function fetchTenancyOrdersFailed(error: string) {
  return {
    type: FETCH_TENANCY_ORDERS_API_FAILED,
    payload: error,
  } as const;
}

export function fetchTenancyAnniversariesRequested() {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_REQUESTED,
  } as const;
}

export function fetchTenancyAnniversariesSuccess(tenancyAnniversaries: string[]) {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_SUCCESS,
    payload: {
      tenancyAnniversaries,
    },
  } as const;
}

export function fetchTenancyAnniversariesFailed(error: string) {
  return {
    type: FETCH_TENANCY_ANNIVERSARIES_API_FAILED,
    payload: error,
  } as const;
}

export function fetchTenancyOrderByIdRequested(id: string) {
  return {
    type: FETCH_TENANCY_ORDER_BY_ID_API_REQUESTED,
    payload: {
      id,
    },
  } as const;
}

export function fetchTenancyOrderByIdSuccess(id: string) {
  return {
    type: FETCH_TENANCY_ORDER_BY_ID_API_SUCCESS,
    payload: {
      id,
    },
  } as const;
}

export function fetchTenancyOrderByIdFailed(error: string) {
  return {
    type: FETCH_TENANCY_ORDER_BY_ID_API_FAILED,
    payload: error,
  } as const;
}

export function clearCurrentOrderCustomerId() {
  return {
    type: CLEAR_ORDER_CUSTOMER_ID,
  } as const;
}

export function clearAmountRemaining() {
  return {
    type: CLEAR_AMOUNT_REMAINING,
  } as const;
}

export function fetchAmountRemainingForPaymentPlanOrderSuccess(amount: number) {
  return {
    type: FETCH_AMOUNT_REMAINING_FOR_PAYMENT_PLAN_ORDER_SUCCESS,
    payload: {
      amount,
    },
  } as const;
}

export type OrderActionTypes = ReturnType<
  | typeof fetchOrderCustomerRequested
  | typeof fetchOrderCustomerSuccess
  | typeof fetchOrderCustomerFailed
  | typeof fetchTenancyOrdersRequested
  | typeof fetchTenancyOrdersSuccess
  | typeof fetchTenancyOrdersFailed
  | typeof fetchTenancyOrderByIdRequested
  | typeof fetchTenancyOrderByIdSuccess
  | typeof fetchTenancyOrderByIdFailed
  | typeof fetchTenancyAnniversariesRequested
  | typeof fetchTenancyAnniversariesSuccess
  | typeof fetchTenancyAnniversariesFailed
  | typeof clearCurrentOrderCustomerId
  | typeof clearAmountRemaining
  | typeof fetchAmountRemainingForPaymentPlanOrderSuccess
>;
