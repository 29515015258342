import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import PageTitle from '../../../../../components/PageTitle';
import { Header3, P1 } from '../../../../../components/Typography';

interface ReferenceSuccessProps {
  onNext: () => void;
}

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  p {
    margin: 0 20px 0 0;
  }
`;

const Subtitle = styled(Header3)`
  font-family: ${props => props.theme.typography.face.secondary};
  color: ${props => props.theme.colors.positive};
`;

const ReferenceSuccess: React.FC<ReferenceSuccessProps> = ({ onNext }) => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Looking Good!</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <Subtitle>You have successfully passed the reference check</Subtitle>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Action>
            <P1>Next: Confirm Reposit</P1>
            <Button onClick={onNext}>Continue</Button>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceSuccess;
