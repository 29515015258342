import React from 'react';
import Card from '../../../../../components/Card';
import styled from 'styled-components';
import { Col, Row, Container } from 'react-grid-system';
import PageTitle from '../../../../../components/PageTitle';
import { Header3, P1 } from '../../../../../components/Typography';

interface ReferenceGuarantorRequiredProps {}

const Subtitle = styled(Header3)`
  font-family: ${props => props.theme.typography.face.secondary};
  color: ${props => props.theme.colors.positive};
`;

const ReferenceGuarantorRequired: React.FC<ReferenceGuarantorRequiredProps> = props => {
  return (
    <Container>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Guarantor Required</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Card>
            <Subtitle>You need a guarantor</Subtitle>
            <P1>
              Unfortunatley we've not been able to approve your reference at this time. You can still use Reposit, but we'll need
              a guarantor in place before you can proceed.
            </P1>
            <P1>Your guarantor will need to pass referencing on your behalf and must be a UK citizen.</P1>
            <P1>
              Please speak directly with your agent regarding their process in obtaining a guarantor. Once your agent has
              confirmed to Reposit that they have carried out satisfactory checks on your guarantor we will pass you through the
              referencing process and you will be able to continue with your Reposit.
            </P1>
            <P1>If you fail to do this, your letting agent may ask you for a full deposit.</P1>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferenceGuarantorRequired;
