import React from 'react';
import styled from 'styled-components';
import { GenderDefinition } from '.';

interface GenderOptionProps {
  isSelected: boolean;
  onClick: () => void;
  option: GenderDefinition;
}

interface AvatarProps {
  icon: string;
  iconActive: string;
  isSelected: boolean;
}

const Option = styled.div`
  cursor: pointer;
  text-align: center;
  margin: 0 5px;
  width: 90px;

  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${props => props.theme.colors.body};

  &:first-child {
    margin: 0 5px 0 0;
  }
`;

const Avatar = styled.div<AvatarProps>`
  background: #fff url(${props => (props.isSelected ? props.iconActive : props.icon)}) no-repeat center center;
  border: ${props => (props.isSelected ? `1px solid transparent` : `1px solid ${props.theme.colors.inactive}`)};
  border-radius: 60px;
  box-shadow: ${props => (props.isSelected ? '0 5px 25px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  height: 60px;
  margin: 0 auto 10px;
  transition: 0.3s all;
  width: 60px;
`;

const GenderOption: React.FC<GenderOptionProps> = ({ isSelected, onClick, option }) => {
  return (
    <Option onClick={onClick}>
      <Avatar isSelected={isSelected} icon={option.icon} iconActive={option.iconActive} />
      {option.label}
    </Option>
  );
};

export default GenderOption;
