import { AppState } from '../root.reducer';
import { AddressEntity } from '../entities/entities.types';

export const getAddressByClaimId = (state: AppState, claimId: string): AddressEntity | undefined => {
  if (!state.entities.claim) return undefined;
  const claim = state.entities.claim[claimId];
  if (!claim) return undefined;
  const checkout = state.entities.checkout[claim.checkout];
  const tenancy = checkout && state.entities.tenancy[checkout.tenancy];
  const property = tenancy && state.entities.property[tenancy.property];
  const address = property && state.entities.address[property.address];

  return address;
};
