import { REMOVE_ADDRESS_API_SUCCESS, AddresHistoryActionTypes } from '../../address-history/address-history.actions';
import { AddressWithDatesEntity } from '../entities.types';

export const userEntityReducer = (state: any = {}, action: AddresHistoryActionTypes) => {
  if (action.type === REMOVE_ADDRESS_API_SUCCESS) {
    const user = state[action.payload.userId];

    const updatedAddressList = user.attributes.addresses.filter(
      (address: AddressWithDatesEntity) => address.id !== action.payload.addressId
    );

    return {
      ...state,
      [user.id]: {
        ...user,
        attributes: {
          ...user.attributes,
          addresses: updatedAddressList,
        },
      },
    };
  }
  return state;
};
