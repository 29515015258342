import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import Help from '../../assets/svg/material-help.svg';
import Info from '../../assets/svg/material-info.svg';
import ThumbsUp from '../../assets/svg/material-thumbs-up.svg';
import Button from '../../components/Button';
import RepositCard from '../../components/Reposit/RepositCard';
import { Header2, Header3, P1 } from '../../components/Typography';
import { END_OF_TENANCY_GUIDE, REPOSIT_TENANT_FAQS } from '../../constants/links';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const Icon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 20px;
`;

export const DocumentLinks = styled.ul`
  padding: 0 0 0 14px;
  list-style: square;
  margin-top: 10px;
`;

export const DocumentLink = styled.li`
  margin: 0 0 6px;

  a {
    color: ${(props) => props.theme.colors.primaryBrand};
    display: inline;
    font-family: ${(props) => props.theme.typography.face.secondary};
  }
`;

interface MediationIntroductionProps {
  setShowMediationIntroduction: React.Dispatch<React.SetStateAction<boolean>>;
  organizationName: string;
}

export const IconAndSubheading: React.FC<{ subheading: string; icon: string }> = ({ subheading, icon }) => {
  return (
    <span style={{ display: 'inline', alignItems: 'center' }}>
      <Icon src={icon} />
      {subheading}
    </span>
  );
};

const MediationIntroduction: React.FC<MediationIntroductionProps> = ({ setShowMediationIntroduction, organizationName }) => {
  return (
    <Container>
      <Header2>Explaining your end of tenancy process</Header2>
      <Row>
        <Col sm={12}>
          <RepositCard
            title="Let's recap"
            icon={Info}
            children={
              <>
                <P1>
                  At the beginning of your tenancy, you chose to use Reposit over a traditional cash deposit. This means you paid
                  an upfront fee of 1 week's rent to Reposit (subject to a minimum of £150) instead of a cash deposit of up to 5
                  weeks' rent.
                </P1>
                <P1>
                  Now your tenancy has finished, your agent/landlord, {organizationName}, has submitted some final charges for you
                  to settle. These are usually for cleaning, dilapidations or for any outstanding rent.
                </P1>
                <P1>You can see full details of the charges on the following page.</P1>
                <Header3 style={{ marginTop: '24px' }}>
                  <IconAndSubheading subheading="We're here to help" icon={ThumbsUp} />
                </Header3>
                <P1>It is common for there to be some charges to pay when a tenancy finishes.</P1>
                <P1>
                  Reposit is here to provide you with the information you need to respond to the charges submitted and reach a
                  resolution.
                </P1>
                <Header3>
                  <IconAndSubheading subheading="How to respond" icon={Help} />
                </Header3>
                <P1>To help you respond to your charges, we have created some resources that you can reference at any time.</P1>
                <P1>Please see our short video tutorial below to get yourself up to speed on what to do next.</P1>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <iframe
                    width="auto"
                    height="auto"
                    src="https://www.youtube.com/embed/Dl8IT6lPvQo?si=jNh8Oht7zYzL5dW9"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </div>
                <P1>If you would prefer to read, these two links should answer any questions you may have:</P1>
                <DocumentLinks>
                  <DocumentLink>
                    <a href={END_OF_TENANCY_GUIDE} target="_blank" rel="noopener noreferrer">
                      End of tenancy guide
                    </a>
                  </DocumentLink>
                  <DocumentLink>
                    <a href={REPOSIT_TENANT_FAQS} target="_blank" rel="noopener noreferrer">
                      FAQs
                    </a>
                  </DocumentLink>
                </DocumentLinks>
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <P1>When you’re ready to continue and respond to your charges, click the button below and get started.</P1>
          <Action>
            <Button onClick={() => setShowMediationIntroduction(false)}>Continue to charges</Button>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default MediationIntroduction;
