import React from 'react';
import styled from 'styled-components';

const TrustBoxContainer = styled.div`
  margin-top: 20px;
`;

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <TrustBoxContainer
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="5c66c6e09e0d730001dac99e"
      data-style-height="40px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://uk.trustpilot.com/review/reposit.co.uk" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </TrustBoxContainer>
  );
};
export default TrustBox;
