import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import TickIcon from '../../assets/svg/tick-disabled.svg';
import TickIconWhite from '../../assets/svg/tick-white.svg';

interface ToggleButtonProps {
  name: string;
  onToggle: (e: ChangeEvent<any>) => void;
}

const ToggleWrapper = styled.div`
  div {
    background: #fff url(${TickIcon}) no-repeat center center;
    background-size: 12px;
    cursor: pointer;
    border: solid 1.3px ${(props) => props.theme.colors.disabled};
    border-radius: 30px;
    flex: 0 0 30px;
    margin: 0 20px 0 0;
    height: 30px;
    width: 30px;

    &:hover {
      background-color: #eee;
    }
  }

  input {
    display: none;
    opacity: 0;
    height: 0;
  }

  input:checked + div {
    background: ${(props) => props.theme.colors.positive} url(${TickIconWhite}) no-repeat center center;
    background-size: 12px;
    border: solid 1.3px ${(props) => props.theme.colors.positive};

    &:hover {
      background-color: ${(props) => props.theme.colors.positive};
    }

    span {
      display: none;
    }
  }
`;

const RoundToggleButton: React.FC<ToggleButtonProps> = ({ name, onToggle }) => {
  return (
    <ToggleWrapper>
      <input id={name} name={name} type="checkbox" onChange={onToggle} />
      <div></div>
    </ToggleWrapper>
  );
};

export default RoundToggleButton;
